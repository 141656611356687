import React, { Fragment } from 'react';
import MaterialTable, { Action } from 'material-table';
import { useHistory } from 'react-router-dom';
import { formatAddress, formatDateTime, formatDollars } from '../../helpers/formatters';
import { Sends_sends, SendStatus } from '../../__generated__/types';
import SendStatusComponent from '../../components/SendStatusComponent/SendStatusComponent';
import Grid from '@material-ui/core/Grid';
import CopyTextField from '../../components/CopyTextField/CopyTextField';
import Avatar from '@material-ui/core/Avatar';

const DetailPane: React.FC<Sends_sends> = ({
  address,
  sendItems,
  amazonItem,
  shopifyOrderName
}) => {
  return (
    <Grid
      container
      justify={'flex-start'}
      alignItems={'center'}
      spacing={2}
      style={{ padding: `8px 8px 24px 8px` }}
    >
      {amazonItem && (
        <Grid item>
          <Avatar src={'https://img.icons8.com/color/48/000000/amazon.png'} />
        </Grid>
      )}
      {amazonItem && (
        <Grid item xs>
          <CopyTextField
            fullWidth
            label={'Amazon Product Url'}
            id={'productUrl'}
            value={amazonItem.productUrl}
          />
        </Grid>
      )}
      {shopifyOrderName && (
        <Grid item xs>
          <CopyTextField label={'PO#'} id={'po'} fullWidth value={shopifyOrderName} />
        </Grid>
      )}
      <Grid item xs>
        Address: {address ? formatAddress(address) : 'None'}
      </Grid>
      {sendItems?.map((sendItem) => (
        <Grid item key={sendItem.id} xs>
          {sendItem.title} - {sendItem.description} ({sendItem.quantity}) - ${sendItem.price}
        </Grid>
      ))}
      {amazonItem && (
        <Grid item xs>
          {amazonItem.name} - ${amazonItem.price} (Size: {amazonItem.size})
        </Grid>
      )}
    </Grid>
  );
};
export type DropShipTableProps = {
  onUpdateTracking?: (send: Sends_sends) => void;
  onPlaceOrder?: (send: Sends_sends) => void;
  onMarkDelivered?: (send: Sends_sends) => void;
  data: Sends_sends[];
  loading?: boolean;
  title: string;
};

export default function DropShipTable({
  data,
  loading,
  title,
  onUpdateTracking,
  onPlaceOrder,
  onMarkDelivered
}: DropShipTableProps) {
  const history = useHistory();
  const actions:
    | (Action<Sends_sends> | ((rowData: Sends_sends) => Action<Sends_sends>))[]
    | undefined = [
    {
      icon: 'more_horiz',
      tooltip: 'View Details',
      onClick: (event, rowData) => {
        // Make sure that selected data is only one row
        if (Array.isArray(rowData)) return;
        history.push(`/dashboard/send/${rowData.id}`);
      }
    }
  ];
  if (onPlaceOrder) {
    actions.push({
      icon: 'attach_money',
      onClick: (event, rowData) => {
        // Make sure that selected data is only one row
        if (Array.isArray(rowData)) return;
        onPlaceOrder(rowData);
      }
    });
  }
  if (onUpdateTracking) {
    actions.push({
      icon: 'send',
      onClick: (event, rowData) => {
        // Make sure that selected data is only one row
        if (Array.isArray(rowData)) return;
        onUpdateTracking(rowData);
      }
    });
  }
  if (onMarkDelivered) {
    actions.push({
      icon: 'home',
      onClick: (event, rowData) => {
        // Make sure that selected data is only one row
        if (Array.isArray(rowData)) return;
        onMarkDelivered(rowData);
      }
    });
  }
  return (
    <Fragment>
      <MaterialTable
        isLoading={loading}
        options={{
          search: true,
          showTitle: true,
          loadingType: 'overlay'
        }}
        detailPanel={(send) => {
          return <DetailPane {...send} />;
        }}
        actions={actions}
        columns={[
          { field: 'id', hidden: true },
          { field: 'shopifyOrderName', title: 'Shopify Order#'},
          {
            field: 'status',
            title: 'Status',
            lookup: SendStatus,
            filtering: true,
            render: (data) => (
              <>
                <SendStatusComponent status={data.status || SendStatus.NEW} />
              </>
            )
          },
          { field: 'creatorEmail', title: 'Created by' },
          { field: 'externalId', title: 'External Order ID' },
          { field: 'trackingNumber', title: 'Tracking #' },
          { field: 'trackingUrl', title: 'Tracking URL' },
          {
            field: 'grandTotal',
            title: 'Total Charge',
            render: (data) => <>{formatDollars(data.grandTotal)}</>
          },
          // { field: 'shippingCost', title: 'Shipping Paid', render: data => <>{formatDollars(Number(data.shippingCost || 0))}</> },
          {
            field: 'createdAt',
            defaultSort: 'desc',
            title: 'Created At',
            render: (data) => <>{formatDateTime(data.createdAt)}</>
          }
        ]}
        data={processData(data)}
        title={title}
      />
    </Fragment>
  );
}

function processData(data: Sends_sends[] | null): Sends_sends[] {
  return data
    ? data.map((send) => ({
        ...send,
        creatorEmail: send.creator.email,
        recipientEmail: send.recipient.email,
        orgName: (send.org && send.org.formattedName) || ''
      }))
    : [];
}
