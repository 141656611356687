import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CardActions } from '@material-ui/core';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ME, ORG_PROFILE, ORG_USERS } from '../../../graphql/queries';
import { Me, OrgProfile, UpdateUser, UpdateUserVariables } from '../../../__generated__/types';
import Loading from '../../../components/Loading/Loading';
import { UPDATE_USER } from '../../../graphql/mutations';
import PageSection from '../../../components/PageSection/PageSection';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import PrimaryTextField from '../../../components/PrimaryTextField/PrimaryTextField';

export default function General() {
  const { data: { me = null } = {}, loading: meLoading } = useQuery<Me>(ME);
  const { data: { org = null } = {}, loading: orgLoading } = useQuery<OrgProfile>(ORG_PROFILE);
  const [updateUser, { loading: updateUserLoading, error }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    refetchQueries: [{ query: ME }, { query: ORG_USERS }]
  });
  const loading = meLoading || orgLoading;
  return (
    <Fragment>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Breadcrumbs />
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">General Settings</Typography>
          <Divider light />
          <br />
        </Grid>
        <Grid item xs={12}>
          {loading && <Loading />}
          {!loading && me && org && (
            <Fragment>
              <PageSection title={'Account'}>
                <Formik
                  enableReinitialize
                  onSubmit={(values) =>
                    updateUser({ variables: { input: { id: me.id, name: values.name } } })
                  }
                  initialValues={{
                    name: (me && me.name) || ''
                  }}
                >
                  {({ values, handleChange, handleSubmit, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                              <Typography>
                                <strong>Org</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Typography>{org.formattedName}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography>
                                <strong>Team</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Typography>{me.team && me.team.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography>
                                <strong>Email</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <Typography>{me.email}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography>
                                <strong>Name</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <TextField
                                required
                                id="name"
                                name="name"
                                autoComplete="no"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider light />
                        <CardActions>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={updateUserLoading}
                          >
                            {updateUserLoading ? 'Loading...' : 'Save'}
                          </Button>
                        </CardActions>
                      </Card>
                    </form>
                  )}
                </Formik>
              </PageSection>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
