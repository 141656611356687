import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

export default function Loading() {
  const classes = useStyles();
  return <CircularProgress value={80} className={classes.loading} disableShrink />;
}

const useStyles = makeStyles(() => ({
  loading: {
    display: 'block',
    margin: 'auto',
    animationDuration: '550ms'
  }
}));
