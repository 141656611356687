import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Loading from '../../../components/Loading/Loading';
import {
  OrgTeams,
  OrgTeams_org_teams,
  OrgTeams_org_teams_invitations,
  OrgTeams_org_teams_users
} from '../../../__generated__/types';
import MoveUserDialog from './components/MoveUserDialog/MoveUserDialog';
import EditTeamDialog from './components/EditTeamDialog/EditTeamDialog';
import DeleteTeamDialog from './components/DeleteTeamDialog/DeleteTeamDialog';
import { ORG_TEAMS } from '../../../graphql/queries';
import FormAlert from '../../../components/FormAlert/FormAlert';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

export default function Teams() {
  const [selectedTeam, setSelectedTeam] = useState<OrgTeams_org_teams | null>(null);
  const [selectedUser, setSelectedUser] = useState<OrgTeams_org_teams_users | null>(null);
  const [
    selectedInvitation,
    setSelectedInvitation
  ] = useState<OrgTeams_org_teams_invitations | null>(null);
  const [userTeamId, setUserTeamId] = useState<string | null>(null);
  const [editTeamOpen, setEditTeamOpen] = useState<boolean>(false);
  const [deleteTeamOpen, setDeleteTeamOpen] = useState<boolean>(false);
  const { loading, error, data: { org: { teams = null } = {} } = {} } = useQuery<OrgTeams>(
    ORG_TEAMS
  );

  function handleUserClick(user: OrgTeams_org_teams_users, teamId: string) {
    setSelectedInvitation(null);
    setSelectedUser(user);
    setUserTeamId(teamId);
  }

  function handleInvitationClick(invitation: OrgTeams_org_teams_invitations, teamId: string) {
    setSelectedInvitation(invitation);
    setSelectedUser(null);
    setUserTeamId(teamId);
  }

  function handleEditTeamClick(team: OrgTeams_org_teams) {
    setSelectedTeam(team);
    setEditTeamOpen(true);
  }

  function handleDeleteTeamClick(team: OrgTeams_org_teams) {
    setSelectedTeam(team);
    setDeleteTeamOpen(true);
  }

  function handleNewTeamClick() {
    setSelectedTeam(null);
    setEditTeamOpen(true);
  }

  function closeMoveUser() {
    setSelectedUser(null);
    setSelectedInvitation(null);
    setUserTeamId(null);
  }

  function closeEditTeam() {
    setEditTeamOpen(false);
    setSelectedTeam(null);
  }

  function closeDeleteTeam() {
    setDeleteTeamOpen(false);
    setSelectedTeam(null);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Teams</Typography>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Fragment>
          <EditTeamDialog
            open={editTeamOpen}
            team={selectedTeam}
            onClose={closeEditTeam}
            onSuccess={closeEditTeam}
          />
          <DeleteTeamDialog
            open={deleteTeamOpen}
            team={selectedTeam}
            onClose={closeDeleteTeam}
            onSuccess={closeDeleteTeam}
          />
          <MoveUserDialog
            onSuccess={closeMoveUser}
            teams={teams}
            currentTeamId={userTeamId}
            user={selectedUser}
            invitation={selectedInvitation}
            onClose={closeMoveUser}
            open={!!((selectedUser || selectedInvitation) && userTeamId)}
          />
          <FormAlert error={error} />
          <Grid container justify={'flex-start'} spacing={2}>
            <Grid item xs={12}>
              <Button color={'primary'} variant={'contained'} onClick={handleNewTeamClick}>
                New
              </Button>
            </Grid>
            {teams &&
              teams.map((team) => (
                <Grid key={team.id} item xs={12} sm={12} md={6} xl={4}>
                  <Card>
                    <CardHeader
                      title={team.name}
                      subheader={team.isAdmin ? 'These users have full permissions' : undefined}
                      action={
                        team.isAdmin ? undefined : (
                          <Fragment>
                            <IconButton onClick={() => handleEditTeamClick(team)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteTeamClick(team)}>
                              <DeleteIcon />
                            </IconButton>
                          </Fragment>
                        )
                      }
                    />
                    <Divider light />
                    <CardContent>
                      <List dense>
                        {team.users &&
                          team.users.map((user) => (
                            <ListItem
                              button
                              key={user.id}
                              onClick={() => handleUserClick(user, team.id)}
                            >
                              <ListItemText primary={user.name} />
                              <ListItemSecondaryAction
                                onClick={() => handleUserClick(user, team.id)}
                              >
                                <IconButton edge="end" aria-label="move">
                                  <SyncAltIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        {team.invitations &&
                          team.invitations.map((invitation) => (
                            <ListItem
                              button
                              key={invitation.id}
                              onClick={() => handleInvitationClick(invitation, team.id)}
                            >
                              <ListItemText primary={`Invite to ${invitation.email}`} />
                              <ListItemSecondaryAction
                                onClick={() => handleInvitationClick(invitation, team.id)}
                              >
                                <IconButton edge="end" aria-label="move">
                                  <SyncAltIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Fragment>
      </Grid>
    </Grid>
  );
}
