import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';

const styles = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 14,
      marginBottom: theme.spacing(0.25),
      // This property, in combination with an empty object for 'focused' property of
      // createStyles input, will affect the focused input
      '&$focused': {
        color: theme.palette.text.primary
      },
      '&$error': {
        color: theme.palette.text.primary
      },
      width: '100%'
    },
    error: {},
    focused: {}
  })
);

export default styles(FormLabel);
