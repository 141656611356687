import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Container from '@material-ui/core/Container';
import { UserByInviteCode, UserByInviteCodeVariables } from '../../__generated__/types';
import Loading from '../../components/Loading/Loading';
import FormAlert from '../../components/FormAlert/FormAlert';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import AcceptInviteForm from './AcceptInviteForm';
import { USER_BY_INVITE_CODE } from '../../graphql/queries';

export default function SignupWithInvitation() {
  const { inviteCode } = useParams<any>();
  // Get the orgName
  const {
    data: { userByInviteCode = null } = {},
    loading,
    error
  } = useQuery<UserByInviteCode, UserByInviteCodeVariables>(USER_BY_INVITE_CODE, {
    variables: { input: { inviteCode } }
  });

  if (loading) {
    return <Loading />;
  }
  if (!userByInviteCode?.org || !inviteCode) {
    return (
      <Grid container justify={'center'} spacing={4} alignItems={'center'}>
        <Grid item xs={12} sm={8} md={6} lg={3}>
          <Alert severity={'error'}>
            That invitation is no longer valid. Ask your admin to send you another.
          </Alert>
        </Grid>
      </Grid>
    );
  }
  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <br />
        {error && <FormAlert error={error} />}
        {!error && (
          <AcceptInviteForm
            orgName={userByInviteCode.org.formattedName}
            email={userByInviteCode.email}
            name={userByInviteCode.name}
            inviteCode={inviteCode}
          />
        )}
      </Container>
    </Fragment>
  );
}
