import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useQuery } from '@apollo/react-hooks';
import {
  PlacementMethod,
  Sends,
  Sends_sends,
  SendStatus,
  SendsVariables
} from '../../__generated__/types';
import DropShipTrackingForm from './DropShipTrackingForm';
import DropShipDeliverForm from './DropShipDeliverForm';
import { ADMIN_SENDS } from '../../graphql/queries';
import DropShipTable from './DropShipTable';
import DropShipOrderForm from './DropShipOrderForm';

export type DropShipFulfillmentProps = {};
export default function DropShipFulfillment({}: DropShipFulfillmentProps) {
  const [selectedSend, setSelectedSend] = React.useState<Sends_sends | null>(null);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState<boolean>(false);
  const [shipDialogOpen, setShipDialogOpen] = React.useState<boolean>(false);
  const [deliverDialogOpen, setDeliverDialogOpen] = React.useState<boolean>(false);

  function onFulfillSelect(send: Sends_sends) {
    setSelectedSend(send);
    setOrderDialogOpen(true);
  }

  function onShipSelect(send: Sends_sends) {
    setSelectedSend(send);
    setShipDialogOpen(true);
  }

  function onDeliverSelect(send: Sends_sends) {
    setSelectedSend(send);
    setDeliverDialogOpen(true);
  }

  const refetchQueries = [
    {
      query: ADMIN_SENDS,
      variables: {
        input: {
          placementMethods: [PlacementMethod.RS_CS, PlacementMethod.RS_CS_TO_SHOPIFY],
          statuses: [
            SendStatus.NEW,
            SendStatus.ADDRESS_SUBMITTED,
            SendStatus.PROCESSING,
            SendStatus.SHIPPED_OUTBOUND
          ]
        }
      }
    }
  ];

  const { loading, data: { sends = [] } = {} } = useQuery<Sends, SendsVariables>(ADMIN_SENDS, {
    variables: {
      input: {
        placementMethods: [PlacementMethod.RS_CS, PlacementMethod.RS_CS_TO_SHOPIFY],
        statuses: [
          SendStatus.NEW,
          SendStatus.ADDRESS_SUBMITTED,
          SendStatus.PROCESSING,
          SendStatus.SHIPPED_OUTBOUND
        ]
      }
    }
  });

  console.log(sends);

  return (
    <div>
      <DropShipTable
        onPlaceOrder={(send) => onFulfillSelect(send)}
        loading={loading}
        data={sends.filter(
          (send) => send.status === SendStatus.NEW || send.status === SendStatus.ADDRESS_SUBMITTED
        )}
        title="New"
      />
      <br />
      <DropShipTable
        onUpdateTracking={(send) => onShipSelect(send)}
        onMarkDelivered={(send) => onDeliverSelect(send)}
        loading={loading}
        data={sends.filter((send) => send.status === SendStatus.PROCESSING)}
        title="Ordered"
      />
      <br />
      <DropShipTable
        onUpdateTracking={(send) => onShipSelect(send)}
        onMarkDelivered={(send) => onDeliverSelect(send)}
        loading={loading}
        data={sends.filter((send) => send.status === SendStatus.SHIPPED_OUTBOUND)}
        title="Shipped"
      />

      <Dialog open={orderDialogOpen} onClose={() => setOrderDialogOpen(false)}>
        <DialogContent>
          {selectedSend && (
            <DropShipOrderForm
              send={selectedSend}
              onSuccess={() => setOrderDialogOpen(false)}
              refetchQueries={refetchQueries}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={shipDialogOpen} onClose={() => setShipDialogOpen(false)}>
        <DialogContent>
          {selectedSend && (
            <DropShipTrackingForm
              sendId={selectedSend.id}
              onSuccess={() => setShipDialogOpen(false)}
              refetchQueries={refetchQueries}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={deliverDialogOpen} onClose={() => setDeliverDialogOpen(false)}>
        <DialogContent>
          {selectedSend && (
            <DropShipDeliverForm
              send={selectedSend}
              onSuccess={() => setDeliverDialogOpen(false)}
              refetchQueries={refetchQueries}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
