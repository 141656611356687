import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import {
  HubSpotDeals_hubSpotDeals,
  HubSpotDeals_hubSpotDeals_edges_node
} from '../../../../__generated__/types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { formatDate, formatDollars } from '../../../../helpers/formatters';

type HubSpotDealsTableProps = {
  hubSpotDeals: HubSpotDeals_hubSpotDeals | null;
  loading: boolean;
};
const HubSpotDealsTable: React.FC<HubSpotDealsTableProps> = (props) => {
  const processData = (
    hubSpotDeals: HubSpotDealsTableProps['hubSpotDeals']
  ): Array<HubSpotDeals_hubSpotDeals_edges_node> => {
    if (!hubSpotDeals) {
      return [];
    }
    return hubSpotDeals.edges.map((hubSpotDealEdge) => ({ ...hubSpotDealEdge.node }));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={'Deals with Sends'} />
          <CardContent>
            <MaterialTable
              isLoading={props.loading}
              components={{
                Container: (props: any) => <Fragment>{props.children}</Fragment>
              }}
              options={{
                search: false,
                showTitle: false,
                toolbar: false,
                loadingType: 'linear',
                showEmptyDataSourceMessage: !props.loading
              }}
              columns={[
                { field: 'id', hidden: true },
                { field: 'dealname', title: 'Name' },
                {
                  field: 'dealstageLabel',
                  title: 'Stage',
                  render: (props) => <>{props.dealstageLabel || props.dealstage}</>
                },
                {
                  field: 'amount',
                  title: 'Amount',
                  render: (props) => <>{formatDollars(props.amount)}</>
                },
                {
                  field: 'createdate',
                  title: 'Create Date',
                  render: (props) => <>{formatDate(props.createdate)}</>
                },
                {
                  field: 'closedate',
                  title: 'Close Date',
                  render: (props) => <>{formatDate(props.closedate)}</>
                }
              ]}
              data={processData(props.hubSpotDeals)}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HubSpotDealsTable;
