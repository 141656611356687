import { InMemoryCache, InMemoryCacheConfig, makeVar } from '@apollo/client';
import gql from 'graphql-tag';

export type AmazonItemFormVar = {
  productUrl: string | null;
  productName: string | null;
  amazonProductId: string | null;
  quantity: number | null;
  price: number | null;
  isPrime: boolean | null;
  size: string | null;
  imgUrl: string | null;
} | null;
export const amazonItemFormVar = makeVar<AmazonItemFormVar>(null);

// @See https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#storing-local-state-in-reactive-variables
export const typePolicies: InMemoryCacheConfig['typePolicies'] = {
  Query: {
    fields: {
      amazonItemForm: {
        read() {
          return amazonItemFormVar();
        }
      }
    }
  }
};

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    handwrittenSenderForm: AddressFormVars
    recipientAddressForm: AddressFormVars
    selectedSalesForceContact: SalesForceContact
    selectedHubSpotContact: HubSpotContact
    isCreateSalesForceTaskChecked: Boolean!
    isCreateHubSpotTaskChecked: Boolean!
    isPrivateAddressChecked: Boolean!
    loginPage: LoginPage
    recipientForm: RecipientFormVars
    createdSend: Send
    storefrontProducts: [StorefrontProduct!]!
    storefrontCollections: [StorefrontCollection!]!
    storefrontCollection(id: ID!): StorefrontCollection
    giftFilterFormValues: GiftFilterFormValues
  }

  extend type Mutation {
    logout: Boolean!
  }

  type RecipientFormVars {
    name: String!
    email: String!
    orgName: String!
  }

  type AddressFormVars {
    name: String!
    street1: String!
    street2: String
    city: String!
    state: String!
    zip: String!
  }

  type GiftFilterFormValues {
    storefrontCollectionId: String!
  }

  type LoginPage {
    message: String
    isSuccess: Boolean
    redirectRoute: String
  }

  # Storefront Typings
  # See https://shopify.dev/docs/storefront-api and https://shopify.github.io/js-buy-sdk/
  # Note that the types returned from the js-buy-sdk are not identical to the Storefront GraphQL API
  type StorefrontCollection {
    "Globally unique identifier."
    id: ID!
    "The colelction's title."
    title: String!
    "The products in the collection."
    products: [StorefrontProduct!]!
  }

  type StorefrontProduct {
    "Globally unique identifier."
    id: ID!
    "The product’s title."
    title: String!
    "Indicates if at least one product variant is available for sale."
    availableForSale: Boolean!
  }
`;
