import { useQuery } from '@apollo/react-hooks';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import PrimaryTextField from '../../../components/PrimaryTextField/PrimaryTextField';
import { HUBSPOT_TOKEN, ME, ORG_PROFILE } from '../../../graphql/queries';
import {
  HubSpotContacts_hubSpotContacts,
  HubSpotToken,
  Me,
  OrgProfile,
  SalesForceContact_salesForceContact
} from '../../../__generated__/types';
import isBoolean from 'lodash/isBoolean';
import SalesForceContactSearch from './SalesForceContactSearch';
import salesForceIcon from '../../../assets/images/salesforce-avatar.png';
import hubSpotLogo from '../../../assets/images/hubspot-logo.png';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import HubSpotContactSearch from './HubSpotContactSearch';
import Alert from '@material-ui/lab/Alert';

export type RecipientFormValues = {
  name: string;
  email: string;
  orgName: string;
  salesForceContactId: string;
  salesForceAccountId: string;
  isCreateSalesForceTaskChecked: boolean;
  hubSpotContactId: string;
  hubSpotCompanyId: string;
  isCreateHubSpotTaskChecked: boolean;
};

export type RecipientFormProps = {
  onChange: (values: RecipientFormValues) => void;
  errors: Record<string, string>;
};

export default function RecipientForm(props: RecipientFormProps) {
  const { onChange, errors } = props;
  // Queries
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const { data: { hubSpotToken = null } = {} } = useQuery<HubSpotToken>(HUBSPOT_TOKEN);

  // The recipient form values
  const [recipientForm, setRecipientForm] = React.useState<RecipientFormValues>({
    name: '',
    email: '',
    orgName: '',
    salesForceContactId: '',
    salesForceAccountId: '',
    isCreateSalesForceTaskChecked: false,
    hubSpotContactId: '',
    hubSpotCompanyId: '',
    isCreateHubSpotTaskChecked: false
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value, checked } = event.target;
    setRecipientForm((prevForm) => {
      // Handle Checkboxes
      if (isBoolean(prevForm[name as keyof RecipientFormValues])) {
        return {
          ...prevForm,
          [name]: checked
        };
      }
      // Handle text change
      return {
        ...prevForm,
        [name]: value
      };
    });
  }

  React.useEffect(() => {
    onChange(recipientForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientForm]);

  return (
    <>
      <Card>
        <CardContent>
          {/* Hubspot Contact Section */}
          {hubSpotToken ? (
            <>
              <Grid container alignItems={'center'} spacing={1} style={{ marginBottom: 8 }}>
                <Grid item>
                  <Avatar src={hubSpotLogo} />
                </Grid>
                <Grid item>
                  <Typography>HubSpot Connection</Typography>
                </Grid>
                <Grid item xs>
                  <Divider light />
                </Grid>
              </Grid>
              <HubSpotContactSearch
                onSelect={(hubSpotContact) => {
                  setRecipientForm((prevForm) => ({
                    ...prevForm,
                    hubSpotContactId: hubSpotContact.id,
                    hubSpotCompanyId: hubSpotContact.companyId,
                    email: hubSpotContact.email,
                    name: hubSpotContact.name,
                    orgName: hubSpotContact.companyName,
                    // Clear the checkbox to create task if hubspot contact is cleared (empty hubspotContact.id)
                    isCreateHubSpotTaskChecked: !hubSpotContact.id
                      ? false
                      : prevForm.isCreateHubSpotTaskChecked
                  }));
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!hubSpotToken || !recipientForm.hubSpotContactId}
                    checked={
                      !hubSpotToken || !recipientForm.hubSpotContactId
                        ? false
                        : recipientForm.isCreateHubSpotTaskChecked
                    }
                    tabIndex={2}
                    onChange={(event, checked) =>
                      setRecipientForm((prevForm) => ({
                        ...prevForm,
                        isCreateHubSpotTaskChecked: checked
                      }))
                    }
                    inputProps={{ 'aria-labelledby': 'Is Create HubSpot Task Selected' }}
                  />
                }
                label="Create Task in HubSpot"
              />
              <Divider light />
              <br />
            </>
          ) : null}
          {/* SalesForce Contact Section */}
          {me?.salesForceUserId ? (
            <>
              <Grid container alignItems={'center'} spacing={1} style={{ marginBottom: 8 }}>
                <Grid item>
                  <Avatar src={salesForceIcon} />
                </Grid>
                <Grid item>
                  <Typography>SalesForce Connection</Typography>
                </Grid>
                <Grid item xs>
                  <Divider light />
                </Grid>
              </Grid>
              {errors.salesForceContactId ? (
                <Alert severity="error">{errors.salesForceContactId}</Alert>
              ) : null}
              <SalesForceContactSearch
                onSelect={(salesForceContact) =>
                  setRecipientForm((prevForm) => ({
                    ...prevForm,
                    salesForceContactId: salesForceContact.id,
                    salesForceAccountId: salesForceContact.accountId,
                    email: salesForceContact.email,
                    name: salesForceContact.name,
                    orgName: salesForceContact.accountName,
                    // Clear the checkbox to create task if contact is cleared (empty salesForceContact.id)
                    isCreateSalesForceTaskChecked: !salesForceContact.id
                      ? false
                      : prevForm.isCreateSalesForceTaskChecked
                  }))
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!me?.salesForceUserId || !recipientForm.salesForceContactId}
                    checked={
                      !me?.salesForceUserId || !recipientForm.salesForceContactId
                        ? false
                        : recipientForm.isCreateSalesForceTaskChecked
                    }
                    tabIndex={2}
                    onChange={(event, checked) =>
                      setRecipientForm((prevForm) => ({
                        ...prevForm,
                        isCreateSalesForceTaskChecked: checked
                      }))
                    }
                    inputProps={{ 'aria-labelledby': 'Is Create SalesForce Task Selected' }}
                  />
                }
                label="Create Task in SalesForce"
              />
              <Divider light />
              <br />
            </>
          ) : null}
          <PrimaryTextField
            required
            fullWidth
            id="name"
            inputProps={{ 'data-testid': 'name' }}
            label="Name"
            name="name"
            autoComplete="no"
            value={recipientForm.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name ?? ''}
          />
          <PrimaryTextField
            required
            fullWidth
            id="email"
            inputProps={{ 'data-testid': 'email' }}
            label="Email"
            name="email"
            autoComplete="no"
            value={recipientForm.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={
              errors.email ??
              'Your recipient will NOT be sent an email. This is required to uniquely identify recipients.'
            }
          />
          <PrimaryTextField
            fullWidth
            id="orgName"
            inputProps={{ 'data-testid': 'orgName' }}
            label="Organization"
            name="orgName"
            autoComplete="no"
            value={recipientForm.orgName}
            onChange={handleChange}
          />
        </CardContent>
      </Card>
    </>
  );
}
