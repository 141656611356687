import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
};

const LoadingProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      animationDuration: '550ms',
      margin: `0 0 0 ${theme.spacing(1)}px`
    }
  })
)(CircularProgress);

const Button: React.FC<ButtonProps> = (props) => {
  const { loading, children, disabled } = props;
  return (
    <>
      <MuiButton {...props} disabled={disabled || loading}>
        {children}
        {loading && <LoadingProgress value={80} size={20} disableShrink color={'inherit'} />}
      </MuiButton>
    </>
  );
};

export default Button;
