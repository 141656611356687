import ResetPassword from 'views/ResetPassword/ResetPassword';
import RedeemEGift from 'views/RedeemEGift/RedeemEGift';
import { Page } from './types';
import Confirmation from 'views/Confirmation/Confirmation';
import Slack from 'views/Slack/Slack';
import RaiseGiftCode from 'views/GiftCode/RaiseGiftCode';
import { Minimal } from 'layouts';
import None from 'layouts/None/None';
import RecipientLanding from 'views/RecipientLanding/RecipientLanding';
import DigitalCampaignLanding from 'views/RecipientLanding/DigitalCampaignLanding/DigitalCampaignLanding';

const unprotectedPages: Array<Page> = [
  {
    path: '/reset-password/:resetPasswordCode?',
    content: ResetPassword,
    layout: Minimal
  },
  {
    path: '/redeem-e-gift/:sendId',
    content: RedeemEGift,
    layout: Minimal
  },
  {
    path: '/gift-code/:giftCodeId',
    content: RaiseGiftCode,
    layout: Minimal
  },
  {
    path: '/confirmation/:sendId',
    content: Confirmation,
    layout: Minimal
  },
  {
    path: '/view/:sendId',
    content: RecipientLanding,
    layout: None
  },
  {
    path: '/redeem/:campaignId',
    content: DigitalCampaignLanding,
    layout: None
  },
  {
    path: '/slack',
    content: Slack,
    layout: Minimal
  }
];

export default unprotectedPages;
