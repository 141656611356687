/// <reference types="@types/chrome" />
import Button from '@material-ui/core/Button';
import React from 'react';
import { openAmazonTab } from '../../../../../../../chrome/utils';


export default function AmazonButton() {
  
  function handleAmazonClick() {
    return openAmazonTab().catch(error => console.error(error))
  }

  return (
      <Button color="primary" variant="contained" onClick={handleAmazonClick}>
        Shop on Amazon
      </Button>
  );
}
