/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Logout
// ====================================================

export interface Logout {
  logout: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_login_tokens {
  __typename: "StringToStringPair";
  key: string;
  value: string;
}

export interface Login_login {
  __typename: "AuthenticationResponse";
  tokens: (Login_login_tokens | null)[];
}

export interface Login {
  login: Login_login | null;
}

export interface LoginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginAsUser
// ====================================================

export interface LoginAsUser_loginAsUser_tokens {
  __typename: "StringToStringPair";
  key: string;
  value: string;
}

export interface LoginAsUser_loginAsUser {
  __typename: "AuthenticationResponse";
  tokens: (LoginAsUser_loginAsUser_tokens | null)[];
}

export interface LoginAsUser {
  loginAsUser: LoginAsUser_loginAsUser;
}

export interface LoginAsUserVariables {
  input: LoginAsUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptInvite
// ====================================================

export interface AcceptInvite_acceptInvite_tokens {
  __typename: "StringToStringPair";
  key: string;
  value: string;
}

export interface AcceptInvite_acceptInvite {
  __typename: "AuthenticationResponse";
  tokens: (AcceptInvite_acceptInvite_tokens | null)[];
}

export interface AcceptInvite {
  acceptInvite: AcceptInvite_acceptInvite;
}

export interface AcceptInviteVariables {
  input: AcceptInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeInvite
// ====================================================

export interface RevokeInvite {
  revokeInvite: boolean | null;
}

export interface RevokeInviteVariables {
  input: RevokeInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAddress
// ====================================================

export interface CreateAddress_createAddress {
  __typename: "Address";
  id: string;
}

export interface CreateAddress {
  createAddress: CreateAddress_createAddress;
}

export interface CreateAddressVariables {
  input: CreateAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertNotificationSettings
// ====================================================

export interface UpsertNotificationSettings_upsertNotificationSettings {
  __typename: "NotificationSettings";
  id: string;
  channel: NotificationChannel | null;
  onSendCreated: boolean | null;
  onSendStatusRedeemed: boolean | null;
  onSendStatusDelivered: boolean | null;
  onSendStatusCanceled: boolean | null;
  onSendStatusShipped: boolean | null;
}

export interface UpsertNotificationSettings {
  upsertNotificationSettings: UpsertNotificationSettings_upsertNotificationSettings;
}

export interface UpsertNotificationSettingsVariables {
  input: UpsertNotificationSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSend
// ====================================================

export interface CreateSend_createSend_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface CreateSend_createSend_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface CreateSend_createSend {
  __typename: "Send";
  id: string;
  recipient: CreateSend_createSend_recipient;
  creator: CreateSend_createSend_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
}

export interface CreateSend {
  createSend: CreateSend_createSend;
}

export interface CreateSendVariables {
  input: CreateSendInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBudget
// ====================================================

export interface UpdateBudget_updateBudget {
  __typename: "Budget";
  id: string;
  isRollover: boolean;
  amount: number;
  refillAmount: number;
  frequency: Frequency;
}

export interface UpdateBudget {
  updateBudget: UpdateBudget_updateBudget | null;
}

export interface UpdateBudgetVariables {
  input: UpdateBudgetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCard
// ====================================================

export interface UpdateCard_updateCard_card {
  __typename: "Card";
  id: string;
  last4: string;
  brand: string;
}

export interface UpdateCard_updateCard {
  __typename: "Customer";
  id: string;
  card: UpdateCard_updateCard_card | null;
}

export interface UpdateCard {
  updateCard: UpdateCard_updateCard | null;
}

export interface UpdateCardVariables {
  input: UpdateCardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCredit
// ====================================================

export interface AddCredit {
  addCredit: boolean | null;
}

export interface AddCreditVariables {
  dollars?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSlackToken
// ====================================================

export interface CreateSlackToken {
  createSlackToken: boolean;
}

export interface CreateSlackTokenVariables {
  input: CreateSlackTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSalesForceToken
// ====================================================

export interface CreateSalesForceToken_createSalesForceToken {
  __typename: "SalesForceToken";
  id: string;
  instanceUrl: string;
}

export interface CreateSalesForceToken {
  createSalesForceToken: CreateSalesForceToken_createSalesForceToken | null;
}

export interface CreateSalesForceTokenVariables {
  input?: CreateSalesForceTokenInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateHubSpotToken
// ====================================================

export interface CreateHubSpotToken_createHubSpotToken {
  __typename: "HubSpotToken";
  user: string | null;
  hubSpotUserId: string;
  appId: string;
  hubId: string;
  hubDomain: string | null;
}

export interface CreateHubSpotToken {
  createHubSpotToken: CreateHubSpotToken_createHubSpotToken;
}

export interface CreateHubSpotTokenVariables {
  input: CreateHubSpotTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnlinkSalesForce
// ====================================================

export interface UnlinkSalesForce {
  unlinkSalesForce: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHubSpotToken
// ====================================================

export interface DeleteHubSpotToken {
  deleteHubSpotToken: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendHandwrittenNote
// ====================================================

export interface SendHandwrittenNote {
  sendHandwrittenNote: boolean | null;
}

export interface SendHandwrittenNoteVariables {
  input?: SendHandwrittenNoteInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RedeemGiftCard
// ====================================================

export interface RedeemGiftCard {
  redeemGiftCard: string;
}

export interface RedeemGiftCardVariables {
  input: RedeemGiftCardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrg
// ====================================================

export interface UpdateOrg_updateOrg_address {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface UpdateOrg_updateOrg_settings {
  __typename: "OrgSettings";
  id: string;
  requireSalesForceContact: boolean;
}

export interface UpdateOrg_updateOrg {
  __typename: "Org";
  id: string;
  address: UpdateOrg_updateOrg_address | null;
  settings: UpdateOrg_updateOrg_settings | null;
}

export interface UpdateOrg {
  updateOrg: UpdateOrg_updateOrg;
}

export interface UpdateOrgVariables {
  input: UpdateOrgInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestPasswordReset
// ====================================================

export interface RequestPasswordReset {
  requestPasswordReset: boolean | null;
}

export interface RequestPasswordResetVariables {
  input?: RequestPasswordResetInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword_resetPassword_tokens {
  __typename: "StringToStringPair";
  key: string;
  value: string;
}

export interface ResetPassword_resetPassword {
  __typename: "AuthenticationResponse";
  tokens: (ResetPassword_resetPassword_tokens | null)[];
}

export interface ResetPassword {
  resetPassword: ResetPassword_resetPassword;
}

export interface ResetPasswordVariables {
  input?: ResetPasswordInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  status: UserStatus;
  name: string;
  salesForceUserId: string | null;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  input: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginAsOrg
// ====================================================

export interface LoginAsOrg_loginAsOrg_tokens {
  __typename: "StringToStringPair";
  key: string;
  value: string;
}

export interface LoginAsOrg_loginAsOrg {
  __typename: "AuthenticationResponse";
  tokens: (LoginAsOrg_loginAsOrg_tokens | null)[];
}

export interface LoginAsOrg {
  loginAsOrg: LoginAsOrg_loginAsOrg;
}

export interface LoginAsOrgVariables {
  input: LoginAsOrgInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSend
// ====================================================

export interface UpdateSend_updateSend {
  __typename: "Send";
  id: string;
  status: SendStatus | null;
}

export interface UpdateSend {
  updateSend: UpdateSend_updateSend;
}

export interface UpdateSendVariables {
  input: UpdateSendInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTeam
// ====================================================

export interface UpdateTeam_updateTeam {
  __typename: "Team";
  id: string;
}

export interface UpdateTeam {
  updateTeam: UpdateTeam_updateTeam;
}

export interface UpdateTeamVariables {
  input: UpdateTeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTeam
// ====================================================

export interface CreateTeam_createTeam {
  __typename: "Team";
  id: string;
}

export interface CreateTeam {
  createTeam: CreateTeam_createTeam;
}

export interface CreateTeamVariables {
  input: CreateTeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTeam
// ====================================================

export interface DeleteTeam {
  deleteTeam: boolean;
}

export interface DeleteTeamVariables {
  input: DeleteTeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignUserToTeam
// ====================================================

export interface AssignUserToTeam_assignUserToTeam_users {
  __typename: "User";
  id: string;
}

export interface AssignUserToTeam_assignUserToTeam {
  __typename: "Team";
  id: string;
  users: AssignUserToTeam_assignUserToTeam_users[] | null;
}

export interface AssignUserToTeam {
  assignUserToTeam: AssignUserToTeam_assignUserToTeam;
}

export interface AssignUserToTeamVariables {
  input: AssignUserToTeamInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateInvitation
// ====================================================

export interface UpdateInvitation_updateInvitation {
  __typename: "Invitation";
  id: string;
  email: string;
}

export interface UpdateInvitation {
  updateInvitation: UpdateInvitation_updateInvitation;
}

export interface UpdateInvitationVariables {
  input: UpdateInvitationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendInvitationEmail
// ====================================================

export interface ResendInvitationEmail {
  resendInvitationEmail: boolean | null;
}

export interface ResendInvitationEmailVariables {
  input: IdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteInvitation
// ====================================================

export interface DeleteInvitation {
  deleteInvitation: boolean | null;
}

export interface DeleteInvitationVariables {
  input: IdInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInvoiceItem
// ====================================================

export interface CreateInvoiceItem_createInvoiceItem {
  __typename: "InvoiceItem";
  id: string;
}

export interface CreateInvoiceItem {
  createInvoiceItem: CreateInvoiceItem_createInvoiceItem;
}

export interface CreateInvoiceItemVariables {
  input: CreateInvoiceItemInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GiftCreate
// ====================================================

export interface GiftCreate_giftCreate {
  __typename: "Gift";
  id: string;
  title: string;
  description: string | null;
}

export interface GiftCreate {
  giftCreate: GiftCreate_giftCreate;
}

export interface GiftCreateVariables {
  input: GiftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GiftRequestNew
// ====================================================

export interface GiftRequestNew {
  giftRequestNew: boolean | null;
}

export interface GiftRequestNewVariables {
  input: GiftRequestNewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GiftDelete
// ====================================================

export interface GiftDelete {
  giftDelete: boolean | null;
}

export interface GiftDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GiftUpdate
// ====================================================

export interface GiftUpdate_giftUpdate {
  __typename: "Gift";
  id: string;
  title: string;
  description: string | null;
}

export interface GiftUpdate {
  giftUpdate: GiftUpdate_giftUpdate;
}

export interface GiftUpdateVariables {
  input: GiftInput;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ValidateAddress
// ====================================================

export interface ValidateAddress_validateAddress {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface ValidateAddress {
  validateAddress: ValidateAddress_validateAddress;
}

export interface ValidateAddressVariables {
  input: CreateAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGiftCardBarcode
// ====================================================

export interface CreateGiftCardBarcode_createGiftCardBarcode {
  __typename: "GiftCardBarcode";
  /**
   * The Raise primary key. UUID.
   */
  id: string;
  /**
   * Encoding for the raw card barcode. This is needed to render the barcode image.
   */
  barcodeKind: string | null;
  /**
   * The raw value for the gift card barcode: the input for barcode rendering.
   */
  barcodeValue: string | null;
  /**
   * Primary gift card number
   */
  number: string | null;
  /**
   * Secondary, security number
   */
  csc: string | null;
}

export interface CreateGiftCardBarcode {
  createGiftCardBarcode: CreateGiftCardBarcode_createGiftCardBarcode;
}

export interface CreateGiftCardBarcodeVariables {
  input: CreateGiftCardBarcodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssetUploadUrlCreate
// ====================================================

export interface AssetUploadUrlCreate_assetUploadUrlCreate {
  __typename: "AssetUploadUrl";
  url: string;
  key: string;
}

export interface AssetUploadUrlCreate {
  assetUploadUrlCreate: AssetUploadUrlCreate_assetUploadUrlCreate | null;
}

export interface AssetUploadUrlCreateVariables {
  input: AssetUploadUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MessageTemplateUpdate
// ====================================================

export interface MessageTemplateUpdate_messageTemplateUpdate {
  __typename: "MessageTemplate";
  id: string;
}

export interface MessageTemplateUpdate {
  messageTemplateUpdate: MessageTemplateUpdate_messageTemplateUpdate;
}

export interface MessageTemplateUpdateVariables {
  id: string;
  input: MessageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MessageTemplateCreate
// ====================================================

export interface MessageTemplateCreate_messageTemplateCreate {
  __typename: "MessageTemplate";
  id: string;
}

export interface MessageTemplateCreate {
  messageTemplateCreate: MessageTemplateCreate_messageTemplateCreate;
}

export interface MessageTemplateCreateVariables {
  input: MessageTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MessageTemplateDelete
// ====================================================

export interface MessageTemplateDelete {
  messageTemplateDelete: string;
}

export interface MessageTemplateDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCreateFromCampaign
// ====================================================

export interface SendCreateFromCampaign_sendCreateFromCampaign_gift {
  __typename: "Gift";
  id: string;
  isCharity: boolean | null;
}

export interface SendCreateFromCampaign_sendCreateFromCampaign {
  __typename: "Send";
  id: string;
  gift: SendCreateFromCampaign_sendCreateFromCampaign_gift | null;
}

export interface SendCreateFromCampaign {
  sendCreateFromCampaign: SendCreateFromCampaign_sendCreateFromCampaign;
}

export interface SendCreateFromCampaignVariables {
  input: SendCreateFromCampaignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignCreate
// ====================================================

export interface CampaignCreate_campaignCreate {
  __typename: "Campaign";
  id: string;
}

export interface CampaignCreate {
  campaignCreate: CampaignCreate_campaignCreate;
}

export interface CampaignCreateVariables {
  input: CampaignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignUpdate
// ====================================================

export interface CampaignUpdate_campaignUpdate_recipients {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  salesForceAccountId: string | null;
  salesForceContactId: string | null;
  hubSpotContactId: string | null;
  hubSpotCompanyId: string | null;
}

export interface CampaignUpdate_campaignUpdate_gifts {
  __typename: "Gift";
  id: string;
  title: string;
}

export interface CampaignUpdate_campaignUpdate {
  __typename: "Campaign";
  id: string;
  message: string | null;
  recipients: CampaignUpdate_campaignUpdate_recipients[] | null;
  gifts: CampaignUpdate_campaignUpdate_gifts[] | null;
  expiresAt: any | null;
  name: string;
}

export interface CampaignUpdate {
  campaignUpdate: CampaignUpdate_campaignUpdate;
}

export interface CampaignUpdateVariables {
  id: string;
  input: CampaignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignAddRecipient
// ====================================================

export interface CampaignAddRecipient_campaignAddRecipients {
  __typename: "Campaign";
  id: string;
}

export interface CampaignAddRecipient {
  campaignAddRecipients: CampaignAddRecipient_campaignAddRecipients;
}

export interface CampaignAddRecipientVariables {
  input: CampaignAddRecipientsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendGiftCodeEmail
// ====================================================

export interface SendGiftCodeEmail {
  sendGiftBarcodeEmail: boolean | null;
}

export interface SendGiftCodeEmailVariables {
  sendId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsLoggedIn
// ====================================================

export interface IsLoggedIn {
  isLoggedIn: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CreatedSend
// ====================================================

export interface CreatedSend_createdSend_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface CreatedSend_createdSend_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface CreatedSend_createdSend {
  __typename: "Send";
  id: string;
  recipient: CreatedSend_createdSend_recipient;
  creator: CreatedSend_createdSend_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
}

export interface CreatedSend {
  createdSend: CreatedSend_createdSend | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsCreateSalesForceTaskChecked
// ====================================================

export interface IsCreateSalesForceTaskChecked {
  isCreateSalesForceTaskChecked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsCreateHubSpotTaskChecked
// ====================================================

export interface IsCreateHubSpotTaskChecked {
  isCreateHubSpotTaskChecked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsPrivateAddressChecked
// ====================================================

export interface IsPrivateAddressChecked {
  isPrivateAddressChecked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoginPage
// ====================================================

export interface LoginPage_loginPage {
  __typename: "LoginPage";
  message: string | null;
  isSuccess: boolean | null;
  redirectRoute: string | null;
}

export interface LoginPage {
  loginPage: LoginPage_loginPage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectedSalesForceContact
// ====================================================

export interface SelectedSalesForceContact_selectedSalesForceContact_account {
  __typename: "SalesForceAccount";
  id: string;
  name: string | null;
}

export interface SelectedSalesForceContact_selectedSalesForceContact_mailingAddress {
  __typename: "SalesForceMailingAddress";
  street: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface SelectedSalesForceContact_selectedSalesForceContact {
  __typename: "SalesForceContact";
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  account: SelectedSalesForceContact_selectedSalesForceContact_account | null;
  mailingAddress: SelectedSalesForceContact_selectedSalesForceContact_mailingAddress | null;
}

export interface SelectedSalesForceContact {
  selectedSalesForceContact: SelectedSalesForceContact_selectedSalesForceContact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesForceContact
// ====================================================

export interface SalesForceContact_salesForceContact_account {
  __typename: "SalesForceAccount";
  id: string;
  name: string | null;
}

export interface SalesForceContact_salesForceContact_mailingAddress {
  __typename: "SalesForceMailingAddress";
  street: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface SalesForceContact_salesForceContact {
  __typename: "SalesForceContact";
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  account: SalesForceContact_salesForceContact_account | null;
  mailingAddress: SalesForceContact_salesForceContact_mailingAddress | null;
}

export interface SalesForceContact {
  salesForceContact: SalesForceContact_salesForceContact | null;
}

export interface SalesForceContactVariables {
  input: SalesForceContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HubSpotDeals
// ====================================================

export interface HubSpotDeals_hubSpotDeals_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string;
}

export interface HubSpotDeals_hubSpotDeals_edges_node {
  __typename: "HubSpotDeal";
  id: string;
  dealname: string | null;
  dealstage: string | null;
  dealstageLabel: string | null;
  amount: number | null;
  closedate: any | null;
  createdate: any | null;
}

export interface HubSpotDeals_hubSpotDeals_edges {
  __typename: "HubSpotDealEdge";
  cursor: string;
  node: HubSpotDeals_hubSpotDeals_edges_node;
}

export interface HubSpotDeals_hubSpotDeals {
  __typename: "HubSpotDealConnection";
  pageInfo: HubSpotDeals_hubSpotDeals_pageInfo;
  edges: HubSpotDeals_hubSpotDeals_edges[];
}

export interface HubSpotDeals {
  hubSpotDeals: HubSpotDeals_hubSpotDeals | null;
}

export interface HubSpotDealsVariables {
  connection: ConnectionInput;
  input: HubSpotDealsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectedHubSpotContact
// ====================================================

export interface SelectedHubSpotContact_selectedHubSpotContact_company {
  __typename: "HubSpotCompany";
  id: string;
  name: string | null;
}

export interface SelectedHubSpotContact_selectedHubSpotContact {
  __typename: "HubSpotContact";
  id: string;
  companyId: string | null;
  company: SelectedHubSpotContact_selectedHubSpotContact_company | null;
  name: string;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

export interface SelectedHubSpotContact {
  selectedHubSpotContact: SelectedHubSpotContact_selectedHubSpotContact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecipientForm
// ====================================================

export interface RecipientForm_recipientForm {
  __typename: "RecipientFormVars";
  name: string;
  email: string;
  orgName: string;
}

export interface RecipientForm {
  recipientForm: RecipientForm_recipientForm | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecipientAddressForm
// ====================================================

export interface RecipientAddressForm_recipientAddressForm {
  __typename: "AddressFormVars";
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface RecipientAddressForm {
  recipientAddressForm: RecipientAddressForm_recipientAddressForm | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HandwrittenSenderForm
// ====================================================

export interface HandwrittenSenderForm_handwrittenSenderForm {
  __typename: "AddressFormVars";
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface HandwrittenSenderForm {
  handwrittenSenderForm: HandwrittenSenderForm_handwrittenSenderForm | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_permissions_gift {
  __typename: "Gift";
  id: string;
}

export interface Me_me_permissions {
  __typename: "Permission";
  id: string;
  type: PermissionType;
  gift: Me_me_permissions_gift | null;
}

export interface Me_me_team {
  __typename: "Team";
  id: string;
  name: string;
  isAdmin: boolean;
}

export interface Me_me_invitationsCreated {
  __typename: "Invitation";
  id: string;
  createdAt: any | null;
  isAccepted: boolean;
  email: string;
}

export interface Me_me_budget {
  __typename: "Budget";
  id: string;
  amount: number;
  refillAmount: number;
}

export interface Me_me_notificationSettings {
  __typename: "NotificationSettings";
  id: string;
  channel: NotificationChannel | null;
  onSendStatusDelivered: boolean | null;
  onSendStatusRedeemed: boolean | null;
  onSendStatusShipped: boolean | null;
  onSendStatusCanceled: boolean | null;
}

export interface Me_me {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  permissions: Me_me_permissions[] | null;
  team: Me_me_team | null;
  role: Role;
  invitationsCreated: Me_me_invitationsCreated[] | null;
  budget: Me_me_budget | null;
  salesForceUserId: string | null;
  notificationSettings: Me_me_notificationSettings[] | null;
  isSlackConnected: boolean | null;
}

export interface Me {
  me: Me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserByInviteCode
// ====================================================

export interface UserByInviteCode_userByInviteCode_org {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface UserByInviteCode_userByInviteCode {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  org: UserByInviteCode_userByInviteCode_org | null;
}

export interface UserByInviteCode {
  userByInviteCode: UserByInviteCode_userByInviteCode | null;
}

export interface UserByInviteCodeVariables {
  input: UserByInviteCodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyProfile
// ====================================================

export interface MyProfile_me_permissions_gift {
  __typename: "Gift";
  id: string;
}

export interface MyProfile_me_permissions {
  __typename: "Permission";
  id: string;
  type: PermissionType;
  gift: MyProfile_me_permissions_gift | null;
}

export interface MyProfile_me_team {
  __typename: "Team";
  id: string;
  name: string;
  isAdmin: boolean;
}

export interface MyProfile_me_invitationsCreated {
  __typename: "Invitation";
  id: string;
  createdAt: any | null;
  isAccepted: boolean;
  email: string;
}

export interface MyProfile_me_budget {
  __typename: "Budget";
  id: string;
  amount: number;
  refillAmount: number;
}

export interface MyProfile_me_notificationSettings {
  __typename: "NotificationSettings";
  id: string;
  channel: NotificationChannel | null;
  onSendStatusDelivered: boolean | null;
  onSendStatusRedeemed: boolean | null;
  onSendStatusShipped: boolean | null;
  onSendStatusCanceled: boolean | null;
}

export interface MyProfile_me_opportunities_account {
  __typename: "Account";
  name: string | null;
}

export interface MyProfile_me_opportunities_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
}

export interface MyProfile_me_opportunities_sends {
  __typename: "Send";
  id: string;
  grandTotal: number;
  giftName: string;
  status: SendStatus | null;
  createdAt: any;
  invoiceItems: MyProfile_me_opportunities_sends_invoiceItems[] | null;
}

export interface MyProfile_me_opportunities {
  __typename: "SalesForceOpp";
  id: string;
  name: string | null;
  stageName: string | null;
  amount: number | null;
  closeDate: any | null;
  createdDate: any | null;
  account: MyProfile_me_opportunities_account;
  sends: MyProfile_me_opportunities_sends[] | null;
}

export interface MyProfile_me_sends_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface MyProfile_me_sends_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface MyProfile_me_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
  description: string | null;
}

export interface MyProfile_me_sends {
  __typename: "Send";
  id: string;
  recipient: MyProfile_me_sends_recipient;
  creator: MyProfile_me_sends_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
  invoiceItems: MyProfile_me_sends_invoiceItems[] | null;
}

export interface MyProfile_me {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  permissions: MyProfile_me_permissions[] | null;
  team: MyProfile_me_team | null;
  role: Role;
  invitationsCreated: MyProfile_me_invitationsCreated[] | null;
  budget: MyProfile_me_budget | null;
  salesForceUserId: string | null;
  notificationSettings: MyProfile_me_notificationSettings[] | null;
  isSlackConnected: boolean | null;
  opportunities: MyProfile_me_opportunities[] | null;
  sends: MyProfile_me_sends[] | null;
}

export interface MyProfile {
  me: MyProfile_me | null;
}

export interface MyProfileVariables {
  input: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MySends
// ====================================================

export interface MySends_me_sends_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface MySends_me_sends_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface MySends_me_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
  description: string | null;
}

export interface MySends_me_sends {
  __typename: "Send";
  id: string;
  recipient: MySends_me_sends_recipient;
  creator: MySends_me_sends_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
  invoiceItems: MySends_me_sends_invoiceItems[] | null;
}

export interface MySends_me {
  __typename: "User";
  id: string;
  sends: MySends_me_sends[] | null;
}

export interface MySends {
  me: MySends_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Budgets
// ====================================================

export interface Budgets_budgets_user {
  __typename: "User";
  email: string;
  name: string;
}

export interface Budgets_budgets {
  __typename: "Budget";
  id: string;
  refillAmount: number;
  frequency: Frequency;
  amount: number;
  isRollover: boolean;
  user: Budgets_budgets_user | null;
}

export interface Budgets {
  budgets: Budgets_budgets[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Invitation
// ====================================================

export interface Invitation_invitation_org {
  __typename: "Org";
  formattedName: string;
}

export interface Invitation_invitation_creator {
  __typename: "User";
  name: string;
  email: string;
}

export interface Invitation_invitation {
  __typename: "Invitation";
  id: string;
  org: Invitation_invitation_org;
  creator: Invitation_invitation_creator;
  email: string;
}

export interface Invitation {
  invitation: Invitation_invitation;
}

export interface InvitationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UpcomingInvoice
// ====================================================

export interface UpcomingInvoice_upcomingInvoice_lines {
  __typename: "InvoiceItem";
  id: string;
  description: string | null;
  dollars: number;
  createdAt: any;
}

export interface UpcomingInvoice_upcomingInvoice {
  __typename: "Invoice";
  id: string;
  amountDueDollars: number;
  amountPaidDollars: number;
  periodStart: any;
  periodEnd: any;
  totalDollars: number;
  subTotalDollars: number;
  status: InvoiceStatus | null;
  dueDate: any | null;
  lines: UpcomingInvoice_upcomingInvoice_lines[];
}

export interface UpcomingInvoice {
  upcomingInvoice: UpcomingInvoice_upcomingInvoice | null;
}

export interface UpcomingInvoiceVariables {
  input?: UpcomingInvoiceInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Invoices
// ====================================================

export interface Invoices_invoices {
  __typename: "Invoice";
  id: string;
  status: InvoiceStatus | null;
  periodEnd: any;
  totalDollars: number;
  dueDate: any | null;
  hostedInvoiceUrl: string | null;
}

export interface Invoices {
  invoices: Invoices_invoices[];
}

export interface InvoicesVariables {
  input: InvoicesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Gifts
// ====================================================

export interface Gifts_gifts_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
}

export interface Gifts_gifts_items {
  __typename: "GiftItem";
  id: string;
  quantity: number;
  product: Gifts_gifts_items_product;
}

export interface Gifts_gifts {
  __typename: "Gift";
  id: string;
  title: string;
  deliveryType: DeliveryType;
  imageUrl: string | null;
  isEnabled: boolean;
  description: string | null;
  fixedShippingCost: number | null;
  items: Gifts_gifts_items[] | null;
}

export interface Gifts {
  gifts: Gifts_gifts[];
}

export interface GiftsVariables {
  input: QueryGiftsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllGifts
// ====================================================

export interface AllGifts_allGifts_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
}

export interface AllGifts_allGifts_items {
  __typename: "GiftItem";
  id: string;
  quantity: number;
  product: AllGifts_allGifts_items_product;
}

export interface AllGifts_allGifts {
  __typename: "Gift";
  id: string;
  title: string;
  deliveryType: DeliveryType;
  imageUrl: string | null;
  isEnabled: boolean;
  description: string | null;
  fixedShippingCost: number | null;
  items: AllGifts_allGifts_items[] | null;
}

export interface AllGifts {
  allGifts: AllGifts_allGifts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGift
// ====================================================

export interface AdminGift_gift_items_product_variants {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The price of the product variant in the default shop currency.
   */
  price: number;
  /**
   * The title of the product variant.
   */
  title: string;
}

export interface AdminGift_gift_items_product_options {
  __typename: "ProductOption";
  /**
   * A globally-unique identifier.
   */
  id: string;
  /**
   * The product option’s name.
   */
  name: string;
  /**
   * The product option's position.
   */
  position: number;
}

export interface AdminGift_gift_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The title of the product.
   */
  title: string;
  /**
   * The featured image for the product.
   */
  featuredImageSrc: string | null;
  /**
   * A product is an addon if it doesn't belong to a single gift, but can be
   * added on to gifts. E.g. special wrapping paper, or a custom printed card.
   */
  isAddon: boolean | null;
  /**
   * A list of variants associated with the product.
   */
  variants: AdminGift_gift_items_product_variants[];
  /**
   * A list of product options. The limit is specified by Shop.resourceLimits.maxProductOptions.
   */
  options: AdminGift_gift_items_product_options[];
  /**
   * A stripped description of the product, single line with HTML tags removed.
   */
  description: string;
}

export interface AdminGift_gift_items {
  __typename: "GiftItem";
  id: string;
  quantity: number;
  product: AdminGift_gift_items_product;
}

export interface AdminGift_gift_orgSettings_org {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface AdminGift_gift_orgSettings {
  __typename: "OrgGiftSettings";
  org: AdminGift_gift_orgSettings_org;
  isEnabled: boolean | null;
}

export interface AdminGift_gift {
  __typename: "Gift";
  id: string;
  title: string;
  description: string | null;
  deliveryType: DeliveryType;
  fixedShippingCost: number | null;
  items: AdminGift_gift_items[] | null;
  imageUrl: string | null;
  placementMethod: PlacementMethod | null;
  placementId: string | null;
  orgSettings: AdminGift_gift_orgSettings[];
}

export interface AdminGift {
  gift: AdminGift_gift | null;
}

export interface AdminGiftVariables {
  input: FindGiftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Gift
// ====================================================

export interface Gift_gift_items_product_variants {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The price of the product variant in the default shop currency.
   */
  price: number;
  /**
   * The title of the product variant.
   */
  title: string;
}

export interface Gift_gift_items_product_options {
  __typename: "ProductOption";
  /**
   * A globally-unique identifier.
   */
  id: string;
  /**
   * The product option’s name.
   */
  name: string;
  /**
   * The product option's position.
   */
  position: number;
}

export interface Gift_gift_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The title of the product.
   */
  title: string;
  /**
   * The featured image for the product.
   */
  featuredImageSrc: string | null;
  /**
   * A product is an addon if it doesn't belong to a single gift, but can be
   * added on to gifts. E.g. special wrapping paper, or a custom printed card.
   */
  isAddon: boolean | null;
  /**
   * A list of variants associated with the product.
   */
  variants: Gift_gift_items_product_variants[];
  /**
   * A list of product options. The limit is specified by Shop.resourceLimits.maxProductOptions.
   */
  options: Gift_gift_items_product_options[];
  /**
   * A stripped description of the product, single line with HTML tags removed.
   */
  description: string;
}

export interface Gift_gift_items {
  __typename: "GiftItem";
  id: string;
  quantity: number;
  product: Gift_gift_items_product;
}

export interface Gift_gift {
  __typename: "Gift";
  id: string;
  title: string;
  description: string | null;
  deliveryType: DeliveryType;
  fixedShippingCost: number | null;
  items: Gift_gift_items[] | null;
  imageUrl: string | null;
  placementMethod: PlacementMethod | null;
  placementId: string | null;
}

export interface Gift {
  gift: Gift_gift | null;
}

export interface GiftVariables {
  input: FindGiftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Charges
// ====================================================

export interface Charges_charges {
  __typename: "Charge";
  id: string;
  description: string | null;
  dollars: number;
  created: any;
}

export interface Charges {
  charges: Charges_charges[];
}

export interface ChargesVariables {
  input: ChargesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Customer
// ====================================================

export interface Customer_customer_card {
  __typename: "Card";
  id: string;
  last4: string;
  brand: string;
}

export interface Customer_customer {
  __typename: "Customer";
  id: string;
  email: string | null;
  card: Customer_customer_card | null;
}

export interface Customer {
  customer: Customer_customer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftRedemptionSend
// ====================================================

export interface GiftRedemptionSend_send_sendItems {
  __typename: "SendItem";
  id: string;
  price: number;
  title: string;
}

export interface GiftRedemptionSend_send_creator {
  __typename: "User";
  id: string;
  name: string;
}

export interface GiftRedemptionSend_send_org {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface GiftRedemptionSend_send {
  __typename: "Send";
  id: string;
  amount: number | null;
  expiresAt: any | null;
  giftName: string;
  redemptionUrl: string | null;
  status: SendStatus | null;
  placementMethod: PlacementMethod;
  placementId: string | null;
  sendItems: GiftRedemptionSend_send_sendItems[] | null;
  creator: GiftRedemptionSend_send_creator;
  message: string | null;
  org: GiftRedemptionSend_send_org | null;
}

export interface GiftRedemptionSend {
  send: GiftRedemptionSend_send;
}

export interface GiftRedemptionSendVariables {
  input: SendInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecipientViewSend
// ====================================================

export interface RecipientViewSend_send_address {
  __typename: "Address";
  id: string;
  name: string;
}

export interface RecipientViewSend_send_org {
  __typename: "Org";
  id: string;
  formattedName: string;
  logoUrl: string | null;
  logoBackgroundColor: string | null;
  logoPrimaryColor: string | null;
}

export interface RecipientViewSend_send_creator {
  __typename: "User";
  id: string;
  name: string;
}

export interface RecipientViewSend_send_sendItems_productVariant_product {
  __typename: "Product";
  /**
   * The featured image for the product.
   */
  featuredImageSrc: string | null;
  /**
   * A stripped description of the product, single line with HTML tags removed.
   */
  description: string;
}

export interface RecipientViewSend_send_sendItems_productVariant {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The product that this variant belongs to.
   */
  product: RecipientViewSend_send_sendItems_productVariant_product;
}

export interface RecipientViewSend_send_sendItems {
  __typename: "SendItem";
  id: string;
  price: number;
  quantity: number;
  productVariant: RecipientViewSend_send_sendItems_productVariant | null;
}

export interface RecipientViewSend_send_gift {
  __typename: "Gift";
  isCharity: boolean | null;
  id: string;
}

export interface RecipientViewSend_send_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  orgName: string | null;
  email: string;
}

export interface RecipientViewSend_send {
  __typename: "Send";
  id: string;
  amount: number | null;
  expiresAt: any | null;
  giftName: string;
  deliveryType: DeliveryType;
  redemptionUrl: string | null;
  status: SendStatus | null;
  isAddressPrivate: boolean | null;
  address: RecipientViewSend_send_address | null;
  org: RecipientViewSend_send_org | null;
  creator: RecipientViewSend_send_creator;
  sendItems: RecipientViewSend_send_sendItems[] | null;
  message: string | null;
  placementMethod: PlacementMethod;
  isGiftCard: boolean | null;
  grandTotal: number;
  gift: RecipientViewSend_send_gift | null;
  recipient: RecipientViewSend_send_recipient;
}

export interface RecipientViewSend {
  send: RecipientViewSend_send;
}

export interface RecipientViewSendVariables {
  input: SendInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sends
// ====================================================

export interface Sends_sends_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface Sends_sends_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface Sends_sends_sendItems {
  __typename: "SendItem";
  id: string;
  quantity: number;
  title: string;
  description: string | null;
  price: number;
}

export interface Sends_sends_gift {
  __typename: "Gift";
  id: string;
}

export interface Sends_sends_org {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface Sends_sends_address {
  __typename: "Address";
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface Sends_sends_amazonItem {
  __typename: "AmazonItem";
  id: string;
  productUrl: string;
  name: string | null;
  price: number | null;
  size: string | null;
}

export interface Sends_sends {
  __typename: "Send";
  id: string;
  recipient: Sends_sends_recipient;
  creator: Sends_sends_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
  externalId: string | null;
  trackingUrl: string | null;
  trackingNumber: string | null;
  carrierCode: CarrierCode | null;
  message: string | null;
  sendItems: Sends_sends_sendItems[] | null;
  gift: Sends_sends_gift | null;
  org: Sends_sends_org | null;
  address: Sends_sends_address | null;
  amazonItem: Sends_sends_amazonItem | null;
  placementMethod: PlacementMethod;
  placementId: string | null;
}

export interface Sends {
  sends: Sends_sends[];
}

export interface SendsVariables {
  input: QuerySendsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgBranding
// ====================================================

export interface OrgBranding_org_messageTemplates {
  __typename: "MessageTemplate";
  id: string;
  text: string;
  slug: string;
  name: string;
  emailSubject: string | null;
  emailFrom: string | null;
}

export interface OrgBranding_org {
  __typename: "Org";
  id: string;
  name: string;
  formattedName: string;
  logoUrl: string | null;
  logoBackgroundColor: string | null;
  logoPrimaryColor: string | null;
  messageTemplates: OrgBranding_org_messageTemplates[] | null;
}

export interface OrgBranding {
  org: OrgBranding_org;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgSends
// ====================================================

export interface OrgSends_org_sends_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface OrgSends_org_sends_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface OrgSends_org_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
}

export interface OrgSends_org_sends {
  __typename: "Send";
  id: string;
  recipient: OrgSends_org_sends_recipient;
  creator: OrgSends_org_sends_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  invoiceItems: OrgSends_org_sends_invoiceItems[] | null;
}

export interface OrgSends_org {
  __typename: "Org";
  id: string;
  sends: OrgSends_org_sends[] | null;
}

export interface OrgSends {
  org: OrgSends_org;
}

export interface OrgSendsVariables {
  input: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgProfile
// ====================================================

export interface OrgProfile_org_address {
  __typename: "Address";
  id: string;
  name: string;
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface OrgProfile_org_settings {
  __typename: "OrgSettings";
  id: string;
  requireSalesForceContact: boolean;
}

export interface OrgProfile_org_saasSubscriptions {
  __typename: "Subscription";
  id: string;
  status: SubscriptionStatus | null;
}

export interface OrgProfile_org_activeSaasSubscription_plan {
  __typename: "Plan";
  id: string;
  amountDollars: number;
  nickname: string | null;
}

export interface OrgProfile_org_activeSaasSubscription {
  __typename: "Subscription";
  id: string;
  plan: OrgProfile_org_activeSaasSubscription_plan | null;
  periodStart: any;
  periodEnd: any;
  status: SubscriptionStatus | null;
}

export interface OrgProfile_org {
  __typename: "Org";
  id: string;
  name: string;
  formattedName: string;
  address: OrgProfile_org_address | null;
  settings: OrgProfile_org_settings | null;
  saasSubscriptions: OrgProfile_org_saasSubscriptions[] | null;
  activeSaasSubscription: OrgProfile_org_activeSaasSubscription | null;
}

export interface OrgProfile {
  org: OrgProfile_org;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllUsers
// ====================================================

export interface AllUsers_allUsers {
  __typename: "User";
  id: string;
  name: string;
  email: string;
}

export interface AllUsers {
  allUsers: AllUsers_allUsers[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgUsers
// ====================================================

export interface OrgUsers_org_users {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: Role;
  status: UserStatus;
  salesForceUserId: string | null;
}

export interface OrgUsers_org_salesForceUsers {
  __typename: "SalesForceUser";
  id: string;
  email: string;
  name: string;
}

export interface OrgUsers_org {
  __typename: "Org";
  id: string;
  users: OrgUsers_org_users[] | null;
  salesForceUsers: OrgUsers_org_salesForceUsers[] | null;
}

export interface OrgUsers {
  org: OrgUsers_org;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgTeams
// ====================================================

export interface OrgTeams_org_teams_permissions_gift {
  __typename: "Gift";
  id: string;
  title: string;
}

export interface OrgTeams_org_teams_permissions {
  __typename: "Permission";
  id: string;
  type: PermissionType;
  gift: OrgTeams_org_teams_permissions_gift | null;
}

export interface OrgTeams_org_teams_users {
  __typename: "User";
  id: string;
  name: string;
}

export interface OrgTeams_org_teams_invitations {
  __typename: "Invitation";
  id: string;
  email: string;
}

export interface OrgTeams_org_teams {
  __typename: "Team";
  id: string;
  name: string;
  isAdmin: boolean;
  permissions: OrgTeams_org_teams_permissions[];
  users: OrgTeams_org_teams_users[] | null;
  invitations: OrgTeams_org_teams_invitations[] | null;
}

export interface OrgTeams_org {
  __typename: "Org";
  id: string;
  teams: OrgTeams_org_teams[] | null;
}

export interface OrgTeams {
  org: OrgTeams_org;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HubSpotOwners
// ====================================================

export interface HubSpotOwners_hubSpotOwners {
  __typename: "HubSpotOwner";
  id: string;
  email: string | null;
  name: string;
  hubSpotUserId: string | null;
}

export interface HubSpotOwners {
  hubSpotOwners: HubSpotOwners_hubSpotOwners[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgCredit
// ====================================================

export interface OrgCredit {
  credit: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Send
// ====================================================

export interface Send_send_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
  salesForceAccountId: string | null;
  salesForceContactId: string | null;
}

export interface Send_send_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface Send_send_amazonItem {
  __typename: "AmazonItem";
  id: string;
  productUrl: string;
  name: string | null;
  price: number | null;
  size: string | null;
}

export interface Send_send_sendItems {
  __typename: "SendItem";
  id: string;
  quantity: number;
  title: string;
  description: string | null;
  price: number;
}

export interface Send_send_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
  description: string | null;
  createdAt: any;
}

export interface Send_send_org {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface Send_send_address {
  __typename: "Address";
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface Send_send {
  __typename: "Send";
  id: string;
  recipient: Send_send_recipient;
  creator: Send_send_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
  trackingNumber: string | null;
  trackingUrl: string | null;
  amazonItem: Send_send_amazonItem | null;
  sendItems: Send_send_sendItems[] | null;
  invoiceItems: Send_send_invoiceItems[] | null;
  org: Send_send_org | null;
  message: string | null;
  address: Send_send_address | null;
  placementMethod: PlacementMethod;
  placementId: string | null;
}

export interface Send {
  send: Send_send;
}

export interface SendVariables {
  input: SendInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserSends
// ====================================================

export interface UserSends_me_sends_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface UserSends_me_sends_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface UserSends_me_sends {
  __typename: "Send";
  id: string;
  recipient: UserSends_me_sends_recipient;
  creator: UserSends_me_sends_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
}

export interface UserSends_me {
  __typename: "User";
  id: string;
  sends: UserSends_me_sends[] | null;
}

export interface UserSends {
  me: UserSends_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HubSpotToken
// ====================================================

export interface HubSpotToken_hubSpotToken {
  __typename: "HubSpotToken";
  appId: string;
  user: string | null;
  hubSpotUserId: string;
  hubDomain: string | null;
  hubId: string;
}

export interface HubSpotToken {
  hubSpotToken: HubSpotToken_hubSpotToken | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesForceToken
// ====================================================

export interface SalesForceToken_salesForceToken {
  __typename: "SalesForceToken";
  id: string;
  instanceUrl: string;
}

export interface SalesForceToken {
  salesForceToken: SalesForceToken_salesForceToken | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesForceUserInfo
// ====================================================

export interface SalesForceUserInfo_salesForceUserInfo {
  __typename: "SalesForceUserInfo";
  displayName: string;
  id: string;
  nickName: string;
  username: string;
}

export interface SalesForceUserInfo {
  salesForceUserInfo: SalesForceUserInfo_salesForceUserInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesForceContacts
// ====================================================

export interface SalesForceContacts_salesForceContacts_account {
  __typename: "SalesForceAccount";
  id: string;
  name: string | null;
}

export interface SalesForceContacts_salesForceContacts_mailingAddress {
  __typename: "SalesForceMailingAddress";
  street: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface SalesForceContacts_salesForceContacts {
  __typename: "SalesForceContact";
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  account: SalesForceContacts_salesForceContacts_account | null;
  mailingAddress: SalesForceContacts_salesForceContacts_mailingAddress | null;
}

export interface SalesForceContacts {
  salesForceContacts: SalesForceContacts_salesForceContacts[];
}

export interface SalesForceContactsVariables {
  input?: SalesForceContactsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HubSpotContacts
// ====================================================

export interface HubSpotContacts_hubSpotContacts_company {
  __typename: "HubSpotCompany";
  id: string;
  name: string | null;
}

export interface HubSpotContacts_hubSpotContacts {
  __typename: "HubSpotContact";
  id: string;
  companyId: string | null;
  name: string;
  company: HubSpotContacts_hubSpotContacts_company | null;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

export interface HubSpotContacts {
  hubSpotContacts: HubSpotContacts_hubSpotContacts[];
}

export interface HubSpotContactsVariables {
  input: HubSpotContactsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftCardSendDetails
// ====================================================

export interface GiftCardSendDetails_giftCardSendDetails {
  __typename: "GiftCardSendDetailsResponse";
  amount: number;
  link: string | null;
  status: SendStatus;
  expiresAt: any;
}

export interface GiftCardSendDetails {
  giftCardSendDetails: GiftCardSendDetails_giftCardSendDetails;
}

export interface GiftCardSendDetailsVariables {
  input: GiftCardSendDetailsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgUserSends
// ====================================================

export interface OrgUserSends_org_users_budget {
  __typename: "Budget";
  id: string;
  amount: number;
}

export interface OrgUserSends_org_users_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
}

export interface OrgUserSends_org_users_sends {
  __typename: "Send";
  id: string;
  invoiceItems: OrgUserSends_org_users_sends_invoiceItems[] | null;
}

export interface OrgUserSends_org_users {
  __typename: "User";
  id: string;
  name: string;
  budget: OrgUserSends_org_users_budget | null;
  sends: OrgUserSends_org_users_sends[] | null;
}

export interface OrgUserSends_org {
  __typename: "Org";
  id: string;
  users: OrgUserSends_org_users[] | null;
}

export interface OrgUserSends {
  org: OrgUserSends_org;
}

export interface OrgUserSendsVariables {
  sendsInput?: QuerySendsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Opportunities
// ====================================================

export interface Opportunities_opportunities_account {
  __typename: "Account";
  name: string | null;
}

export interface Opportunities_opportunities_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
}

export interface Opportunities_opportunities_sends {
  __typename: "Send";
  id: string;
  grandTotal: number;
  giftName: string;
  status: SendStatus | null;
  createdAt: any;
  invoiceItems: Opportunities_opportunities_sends_invoiceItems[] | null;
}

export interface Opportunities_opportunities {
  __typename: "SalesForceOpp";
  id: string;
  name: string | null;
  stageName: string | null;
  amount: number | null;
  closeDate: any | null;
  createdDate: any | null;
  account: Opportunities_opportunities_account;
  sends: Opportunities_opportunities_sends[] | null;
}

export interface Opportunities {
  opportunities: Opportunities_opportunities[];
}

export interface OpportunitiesVariables {
  input: OpportunitiesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllOrgs
// ====================================================

export interface AllOrgs_allOrgs {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface AllOrgs {
  allOrgs: AllOrgs_allOrgs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StorefrontCollections
// ====================================================

export interface StorefrontCollections_storefrontCollections {
  __typename: "StorefrontCollection";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The colelction's title.
   */
  title: string;
}

export interface StorefrontCollections {
  storefrontCollections: StorefrontCollections_storefrontCollections[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StorefrontCollection
// ====================================================

export interface StorefrontCollection_storefrontCollection_products {
  __typename: "StorefrontProduct";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * Indicates if at least one product variant is available for sale.
   */
  availableForSale: boolean;
  /**
   * The product’s title.
   */
  title: string;
}

export interface StorefrontCollection_storefrontCollection {
  __typename: "StorefrontCollection";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The products in the collection.
   */
  products: StorefrontCollection_storefrontCollection_products[];
}

export interface StorefrontCollection {
  storefrontCollection: StorefrontCollection_storefrontCollection | null;
}

export interface StorefrontCollectionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftFilterFormValues
// ====================================================

export interface GiftFilterFormValues_giftFilterFormValues {
  __typename: "GiftFilterFormValues";
  storefrontCollectionId: string;
}

export interface GiftFilterFormValues {
  giftFilterFormValues: GiftFilterFormValues_giftFilterFormValues | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AddonProducts
// ====================================================

export interface AddonProducts_products_options {
  __typename: "ProductOption";
  /**
   * A globally-unique identifier.
   */
  id: string;
  /**
   * The product option’s name.
   */
  name: string;
  /**
   * The product option's position.
   */
  position: number;
}

export interface AddonProducts_products_variants {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The title of the product variant.
   */
  title: string;
  /**
   * The price of the product variant in the default shop currency.
   */
  price: number;
}

export interface AddonProducts_products {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The title of the product.
   */
  title: string;
  /**
   * A stripped description of the product, single line with HTML tags removed.
   */
  description: string;
  /**
   * The featured image for the product.
   */
  featuredImageSrc: string | null;
  /**
   * A product is an addon if it doesn't belong to a single gift, but can be
   * added on to gifts. E.g. special wrapping paper, or a custom printed card.
   */
  isAddon: boolean | null;
  /**
   * A list of product options. The limit is specified by Shop.resourceLimits.maxProductOptions.
   */
  options: AddonProducts_products_options[];
  /**
   * A list of variants associated with the product.
   */
  variants: AddonProducts_products_variants[];
}

export interface AddonProducts {
  products: AddonProducts_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Locations
// ====================================================

export interface Locations_locations {
  __typename: "Location";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The name of the location.
   */
  name: string;
}

export interface Locations {
  locations: Locations_locations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftCardBrand
// ====================================================

export interface GiftCardBrand_giftCardBrand {
  __typename: "GiftCardBrand";
  /**
   * The Raise primary key. UUID.
   */
  id: string;
  name: string;
  iconUrl: string | null;
  description: string | null;
  redemptionMethods: string[] | null;
  redemptionDisclaimer: string | null;
  legalTerms: string | null;
  /**
   * For displaying the card image
   */
  faceplateUrl: string | null;
}

export interface GiftCardBrand {
  giftCardBrand: GiftCardBrand_giftCardBrand;
}

export interface GiftCardBrandVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftCardBarcode
// ====================================================

export interface GiftCardBarcode_giftCardBarcode {
  __typename: "GiftCardBarcode";
  /**
   * The Raise primary key. UUID.
   */
  id: string;
  /**
   * Encoding for the raw card barcode. This is needed to render the barcode image.
   */
  barcodeKind: string | null;
  /**
   * The raw value for the gift card barcode: the input for barcode rendering.
   */
  barcodeValue: string | null;
  /**
   * Primary gift card number
   */
  number: string | null;
  /**
   * Secondary, security number
   */
  csc: string | null;
  /**
   * The URL for viewing the barcode
   */
  viewCardUrl: string | null;
}

export interface GiftCardBarcode {
  giftCardBarcode: GiftCardBarcode_giftCardBarcode | null;
}

export interface GiftCardBarcodeVariables {
  input: GiftCardBarcodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftCardCode
// ====================================================

export interface GiftCardCode_giftCardCode_send_sendItems {
  __typename: "SendItem";
  id: string;
  price: number;
}

export interface GiftCardCode_giftCardCode_send {
  __typename: "Send";
  id: string;
  giftName: string;
  placementId: string | null;
  sendItems: GiftCardCode_giftCardCode_send_sendItems[] | null;
}

export interface GiftCardCode_giftCardCode {
  __typename: "GiftCardCode";
  id: string;
  expiresAt: any;
  send: GiftCardCode_giftCardCode_send;
}

export interface GiftCardCode {
  giftCardCode: GiftCardCode_giftCardCode;
}

export interface GiftCardCodeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CharityGifts
// ====================================================

export interface CharityGifts_charityGifts_items_product_variants {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The price of the product variant in the default shop currency.
   */
  price: number;
  /**
   * The title of the product variant.
   */
  title: string;
}

export interface CharityGifts_charityGifts_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * A list of variants associated with the product.
   */
  variants: CharityGifts_charityGifts_items_product_variants[];
}

export interface CharityGifts_charityGifts_items {
  __typename: "GiftItem";
  id: string;
  product: CharityGifts_charityGifts_items_product;
}

export interface CharityGifts_charityGifts {
  __typename: "Gift";
  imageUrl: string | null;
  id: string;
  title: string;
  isCharity: boolean | null;
  items: CharityGifts_charityGifts_items[] | null;
}

export interface CharityGifts {
  charityGifts: CharityGifts_charityGifts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Campaigns
// ====================================================

export interface Campaigns_campaigns_recipients {
  __typename: "Recipient";
  id: string;
}

export interface Campaigns_campaigns {
  __typename: "Campaign";
  expiresAt: any | null;
  id: string;
  name: string;
  recipients: Campaigns_campaigns_recipients[] | null;
}

export interface Campaigns {
  campaigns: Campaigns_campaigns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Campaign
// ====================================================

export interface Campaign_campaign_gifts_items_product_variants {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The price of the product variant in the default shop currency.
   */
  price: number;
  /**
   * The title of the product variant.
   */
  title: string;
}

export interface Campaign_campaign_gifts_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * A list of variants associated with the product.
   */
  variants: Campaign_campaign_gifts_items_product_variants[];
}

export interface Campaign_campaign_gifts_items {
  __typename: "GiftItem";
  id: string;
  product: Campaign_campaign_gifts_items_product;
}

export interface Campaign_campaign_gifts {
  __typename: "Gift";
  imageUrl: string | null;
  id: string;
  title: string;
  isCharity: boolean | null;
  items: Campaign_campaign_gifts_items[] | null;
}

export interface Campaign_campaign_creator_org {
  __typename: "Org";
  formattedName: string;
  logoUrl: string | null;
  logoBackgroundColor: string | null;
  logoPrimaryColor: string | null;
}

export interface Campaign_campaign_creator {
  __typename: "User";
  id: string;
  name: string;
  org: Campaign_campaign_creator_org | null;
}

export interface Campaign_campaign {
  __typename: "Campaign";
  message: string | null;
  name: string;
  expiresAt: any | null;
  maxCostPerGift: number;
  gifts: Campaign_campaign_gifts[] | null;
  creator: Campaign_campaign_creator;
}

export interface Campaign {
  campaign: Campaign_campaign | null;
}

export interface CampaignVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSlackToken
// ====================================================

export interface DeleteSlackToken {
  deleteSlackToken: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUser
// ====================================================

export interface AddUser_addUser {
  __typename: "User";
  id: string;
}

export interface AddUser {
  addUser: AddUser_addUser;
}

export interface AddUserVariables {
  input: AddUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Invitations
// ====================================================

export interface Invitations_invitations_org {
  __typename: "Org";
  name: string;
}

export interface Invitations_invitations_creator {
  __typename: "User";
  email: string;
}

export interface Invitations_invitations {
  __typename: "Invitation";
  isAccepted: boolean;
  id: string;
  org: Invitations_invitations_org;
  createdAt: any | null;
  creator: Invitations_invitations_creator;
  email: string;
}

export interface Invitations {
  invitations: (Invitations_invitations | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignupAndCreateOrg
// ====================================================

export interface SignupAndCreateOrg_signupAndCreateOrg_tokens {
  __typename: "StringToStringPair";
  key: string;
  value: string;
}

export interface SignupAndCreateOrg_signupAndCreateOrg {
  __typename: "AuthenticationResponse";
  tokens: (SignupAndCreateOrg_signupAndCreateOrg_tokens | null)[];
}

export interface SignupAndCreateOrg {
  signupAndCreateOrg: SignupAndCreateOrg_signupAndCreateOrg | null;
}

export interface SignupAndCreateOrgVariables {
  orgName: string;
  email: string;
  password: string;
  name: string;
  recaptchaToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesForceOpportunityInfo
// ====================================================

export interface SalesForceOpportunityInfo_account {
  __typename: "Account";
  name: string | null;
}

export interface SalesForceOpportunityInfo_sends_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
}

export interface SalesForceOpportunityInfo_sends {
  __typename: "Send";
  id: string;
  grandTotal: number;
  giftName: string;
  status: SendStatus | null;
  createdAt: any;
  invoiceItems: SalesForceOpportunityInfo_sends_invoiceItems[] | null;
}

export interface SalesForceOpportunityInfo {
  __typename: "SalesForceOpp";
  id: string;
  name: string | null;
  stageName: string | null;
  amount: number | null;
  closeDate: any | null;
  createdDate: any | null;
  account: SalesForceOpportunityInfo_account;
  sends: SalesForceOpportunityInfo_sends[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SalesForceContactInfo
// ====================================================

export interface SalesForceContactInfo_account {
  __typename: "SalesForceAccount";
  id: string;
  name: string | null;
}

export interface SalesForceContactInfo_mailingAddress {
  __typename: "SalesForceMailingAddress";
  street: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
}

export interface SalesForceContactInfo {
  __typename: "SalesForceContact";
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  account: SalesForceContactInfo_account | null;
  mailingAddress: SalesForceContactInfo_mailingAddress | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StripeInvoice
// ====================================================

export interface StripeInvoice_lines {
  __typename: "InvoiceItem";
  id: string;
  description: string | null;
  dollars: number;
  createdAt: any;
}

export interface StripeInvoice {
  __typename: "Invoice";
  id: string;
  amountDueDollars: number;
  amountPaidDollars: number;
  periodStart: any;
  periodEnd: any;
  totalDollars: number;
  subTotalDollars: number;
  status: InvoiceStatus | null;
  dueDate: any | null;
  lines: StripeInvoice_lines[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserInfo
// ====================================================

export interface UserInfo_permissions_gift {
  __typename: "Gift";
  id: string;
}

export interface UserInfo_permissions {
  __typename: "Permission";
  id: string;
  type: PermissionType;
  gift: UserInfo_permissions_gift | null;
}

export interface UserInfo_team {
  __typename: "Team";
  id: string;
  name: string;
  isAdmin: boolean;
}

export interface UserInfo_invitationsCreated {
  __typename: "Invitation";
  id: string;
  createdAt: any | null;
  isAccepted: boolean;
  email: string;
}

export interface UserInfo_budget {
  __typename: "Budget";
  id: string;
  amount: number;
  refillAmount: number;
}

export interface UserInfo_notificationSettings {
  __typename: "NotificationSettings";
  id: string;
  channel: NotificationChannel | null;
  onSendStatusDelivered: boolean | null;
  onSendStatusRedeemed: boolean | null;
  onSendStatusShipped: boolean | null;
  onSendStatusCanceled: boolean | null;
}

export interface UserInfo {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  permissions: UserInfo_permissions[] | null;
  team: UserInfo_team | null;
  role: Role;
  invitationsCreated: UserInfo_invitationsCreated[] | null;
  budget: UserInfo_budget | null;
  salesForceUserId: string | null;
  notificationSettings: UserInfo_notificationSettings[] | null;
  isSlackConnected: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftSummary
// ====================================================

export interface GiftSummary_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
}

export interface GiftSummary_items {
  __typename: "GiftItem";
  id: string;
  quantity: number;
  product: GiftSummary_items_product;
}

export interface GiftSummary {
  __typename: "Gift";
  id: string;
  title: string;
  deliveryType: DeliveryType;
  imageUrl: string | null;
  isEnabled: boolean;
  description: string | null;
  fixedShippingCost: number | null;
  items: GiftSummary_items[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftDetail
// ====================================================

export interface GiftDetail_items_product_variants {
  __typename: "ProductVariant";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The price of the product variant in the default shop currency.
   */
  price: number;
  /**
   * The title of the product variant.
   */
  title: string;
}

export interface GiftDetail_items_product_options {
  __typename: "ProductOption";
  /**
   * A globally-unique identifier.
   */
  id: string;
  /**
   * The product option’s name.
   */
  name: string;
  /**
   * The product option's position.
   */
  position: number;
}

export interface GiftDetail_items_product {
  __typename: "Product";
  /**
   * Globally unique identifier.
   */
  id: string;
  /**
   * The title of the product.
   */
  title: string;
  /**
   * The featured image for the product.
   */
  featuredImageSrc: string | null;
  /**
   * A product is an addon if it doesn't belong to a single gift, but can be
   * added on to gifts. E.g. special wrapping paper, or a custom printed card.
   */
  isAddon: boolean | null;
  /**
   * A list of variants associated with the product.
   */
  variants: GiftDetail_items_product_variants[];
  /**
   * A list of product options. The limit is specified by Shop.resourceLimits.maxProductOptions.
   */
  options: GiftDetail_items_product_options[];
  /**
   * A stripped description of the product, single line with HTML tags removed.
   */
  description: string;
}

export interface GiftDetail_items {
  __typename: "GiftItem";
  id: string;
  quantity: number;
  product: GiftDetail_items_product;
}

export interface GiftDetail {
  __typename: "Gift";
  id: string;
  title: string;
  description: string | null;
  deliveryType: DeliveryType;
  fixedShippingCost: number | null;
  items: GiftDetail_items[] | null;
  imageUrl: string | null;
  placementMethod: PlacementMethod | null;
  placementId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SendSummary
// ====================================================

export interface SendSummary_recipient {
  __typename: "Recipient";
  id: string;
  name: string;
  email: string;
  orgName: string | null;
}

export interface SendSummary_creator {
  __typename: "User";
  id: string;
  email: string;
  name: string;
}

export interface SendSummary {
  __typename: "Send";
  id: string;
  recipient: SendSummary_recipient;
  creator: SendSummary_creator;
  giftName: string;
  grandTotal: number;
  amount: number | null;
  createdAt: any;
  status: SendStatus | null;
  shippingCost: number | null;
  deliveryType: DeliveryType;
  isAddressPrivate: boolean | null;
  shopifyOrderName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SendDetail
// ====================================================

export interface SendDetail_recipient {
  __typename: "Recipient";
  salesForceAccountId: string | null;
  salesForceContactId: string | null;
}

export interface SendDetail_amazonItem {
  __typename: "AmazonItem";
  id: string;
  productUrl: string;
  name: string | null;
  price: number | null;
  size: string | null;
}

export interface SendDetail_sendItems {
  __typename: "SendItem";
  id: string;
  quantity: number;
  title: string;
  description: string | null;
  price: number;
}

export interface SendDetail_invoiceItems {
  __typename: "InvoiceItem";
  id: string;
  dollars: number;
  description: string | null;
  createdAt: any;
}

export interface SendDetail_org {
  __typename: "Org";
  id: string;
  formattedName: string;
}

export interface SendDetail_address {
  __typename: "Address";
  street1: string;
  street2: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface SendDetail {
  __typename: "Send";
  recipient: SendDetail_recipient;
  shippingCost: number | null;
  trackingNumber: string | null;
  trackingUrl: string | null;
  amount: number | null;
  amazonItem: SendDetail_amazonItem | null;
  sendItems: SendDetail_sendItems[] | null;
  invoiceItems: SendDetail_invoiceItems[] | null;
  org: SendDetail_org | null;
  message: string | null;
  address: SendDetail_address | null;
  isAddressPrivate: boolean | null;
  placementMethod: PlacementMethod;
  placementId: string | null;
  shopifyOrderName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CarrierCode {
  DHL_EXPRESS = "DHL_EXPRESS",
  FEDEX = "FEDEX",
  STAMPS_COM = "STAMPS_COM",
  UPS = "UPS",
  USPS = "USPS",
}

export enum DeliveryType {
  DIGITAL = "DIGITAL",
  PHYSICAL = "PHYSICAL",
}

export enum Frequency {
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum InvoiceStatus {
  deleted = "deleted",
  draft = "draft",
  open = "open",
  paid = "paid",
  uncollectible = "uncollectible",
  void = "void",
}

export enum NotificationChannel {
  EMAIL = "EMAIL",
  SLACK = "SLACK",
}

export enum PermissionType {
  All = "All",
  Analytics__Read = "Analytics__Read",
  Sends__Create = "Sends__Create",
  Sends__Create__GiftType__ALCOHOL = "Sends__Create__GiftType__ALCOHOL",
  Sends__Create__GiftType__AMAZON = "Sends__Create__GiftType__AMAZON",
  Sends__Create__GiftType__CHARITY = "Sends__Create__GiftType__CHARITY",
  Sends__Create__GiftType__COFFEE = "Sends__Create__GiftType__COFFEE",
  Sends__Create__GiftType__COOKIE_GOOD = "Sends__Create__GiftType__COOKIE_GOOD",
  Sends__Create__GiftType__E_GIFT_CARD = "Sends__Create__GiftType__E_GIFT_CARD",
  Sends__Create__GiftType__E_WINE = "Sends__Create__GiftType__E_WINE",
  Sends__Create__GiftType__HANDWRITTEN_NOTE = "Sends__Create__GiftType__HANDWRITTEN_NOTE",
  Sends__Create__GiftType__UBER_EATS = "Sends__Create__GiftType__UBER_EATS",
  Users__Create = "Users__Create",
  Users__Delete = "Users__Delete",
  Users__Read = "Users__Read",
  Users__Update = "Users__Update",
}

export enum PlacementMethod {
  HANDWRYTTEN = "HANDWRYTTEN",
  RAISE = "RAISE",
  RS_CS = "RS_CS",
  RS_CS_TO_SHOPIFY = "RS_CS_TO_SHOPIFY",
  RS_SHOPIFY = "RS_SHOPIFY",
  TREMENDOUS = "TREMENDOUS",
}

export enum Role {
  ADMIN = "ADMIN",
  RS_ADMIN = "RS_ADMIN",
  USER = "USER",
}

export enum SendStatus {
  ADDRESS_SUBMITTED = "ADDRESS_SUBMITTED",
  CANCELED = "CANCELED",
  DELIVERED = "DELIVERED",
  EXPIRED = "EXPIRED",
  NEW = "NEW",
  NOT_DELIVERED = "NOT_DELIVERED",
  OPENED = "OPENED",
  PENDING_ADDRESS = "PENDING_ADDRESS",
  PROCESSING = "PROCESSING",
  REDEEMED = "REDEEMED",
  SHIPPED_INBOUND = "SHIPPED_INBOUND",
  SHIPPED_OUTBOUND = "SHIPPED_OUTBOUND",
}

export enum SubscriptionStatus {
  active = "active",
  canceled = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  trialing = "trialing",
  unpaid = "unpaid",
}

export enum SubscriptionType {
  CREDIT = "CREDIT",
  SAAS = "SAAS",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  INVITED = "INVITED",
}

export interface AcceptInviteInput {
  inviteCode: string;
  password: string;
  name: string;
  recaptchaToken: string;
}

export interface AddUserInput {
  salesForceUserId?: string | null;
  email: string;
  teamId: string;
  name: string;
}

export interface AddressInput {
  id?: string | null;
  name: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zip: string;
}

export interface AssetUploadUrlInput {
  contentType: string;
}

export interface AssignUserToTeamInput {
  userId: string;
  teamId: string;
}

export interface CampaignAddRecipientsInput {
  recipients: CreateRecipientInput[];
}

export interface CampaignInput {
  name?: string | null;
  message?: string | null;
  recipients?: CreateRecipientInput[] | null;
  gifts?: string[] | null;
  expiresAt?: any | null;
  maxCostPerGift?: number | null;
  createHubSpotTasks?: boolean | null;
  createSalesForceTasks?: boolean | null;
}

export interface ChargesInput {
  cursor?: string | null;
  range: DateRangeInput;
}

export interface ConnectionInput {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

export interface CreateAddressInput {
  name: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zip: string;
  isPrivate?: boolean | null;
}

export interface CreateAmazonItemInput {
  amazonProductId: string;
  price: number;
  quantity: number;
  productUrl: string;
  imgUrl?: string | null;
  productName: string;
  isPrime?: boolean | null;
  size?: string | null;
}

export interface CreateGiftCardBarcodeInput {
  codeId: string;
}

export interface CreateHubSpotTokenInput {
  code: string;
}

export interface CreateInvoiceItemInput {
  sendId: string;
  amountDollars: number;
  description: string;
}

export interface CreatePermissionInput {
  type: PermissionType;
  giftId?: string | null;
}

export interface CreateRecipientInput {
  email: string;
  name: string;
  salesForceContactId?: string | null;
  salesForceAccountId?: string | null;
  hubSpotContactId?: string | null;
  hubSpotCompanyId?: string | null;
  orgName?: string | null;
}

export interface CreateSalesForceTokenInput {
  code: string;
  redirectUri: string;
}

export interface CreateSendInput {
  giftId: string;
  expiresAt?: any | null;
  recipient: CreateRecipientInput;
  message: string;
  address?: AddressInput | null;
  sendItems: CreateSendItemInput[];
  createSalesForceTask?: boolean | null;
  createHubSpotTask?: boolean | null;
  sendRecipientCtaEmail?: boolean | null;
  messageTemplateId?: string | null;
  isAddressPrivate?: boolean | null;
  amazonItem?: CreateAmazonItemInput | null;
}

export interface CreateSendItemInput {
  productVariantId: string;
  quantity: number;
}

export interface CreateSlackTokenInput {
  code: string;
  redirectUrl: string;
}

export interface CreateTeamInput {
  permissions: CreatePermissionInput[];
  name: string;
}

export interface DateRangeInput {
  to: any;
  from: any;
}

export interface DeleteTeamInput {
  id: string;
}

export interface FindGiftInput {
  id: string;
}

export interface GiftCardBarcodeInput {
  sendId: string;
  giftCodeId: string;
}

export interface GiftCardSendDetailsInput {
  redemptionCode: string;
}

export interface GiftInput {
  title: string;
  description: string;
  imageUrl: string;
  orgSettings: GiftOrgSettingsInput[];
  deliveryType: DeliveryType;
  placementMethod: PlacementMethod;
  placementId: string;
  fixedShippingCost: string;
  items: GiftItemInput[];
}

export interface GiftItemInput {
  productId: string;
  quantity: number;
}

export interface GiftOrgSettingsInput {
  orgId: string;
  isEnabled: boolean;
}

export interface GiftRequestNewInput {
  message?: string | null;
  productUrl?: string | null;
}

export interface HubSpotContactsInput {
  email?: string | null;
  name?: string | null;
}

export interface HubSpotDealsInput {
  from?: any | null;
  to?: any | null;
}

export interface IdInput {
  id: string;
}

export interface InvoicesInput {
  cursor?: string | null;
  subscriptionType: SubscriptionType;
}

export interface LoginAsOrgInput {
  password: string;
  orgId: string;
}

export interface LoginAsUserInput {
  userId: string;
}

export interface MessageTemplateInput {
  name: string;
  slug: string;
  text: string;
  emailFrom?: string | null;
  emailSubject?: string | null;
}

export interface OpportunitiesInput {
  to: any;
  from: any;
  dateFilterField?: string | null;
}

export interface OrgSettingsInput {
  id: string;
  requireSalesForceContact: boolean;
}

export interface QueryGiftsInput {
  products?: string[] | null;
  deliveryType?: DeliveryType | null;
}

export interface QuerySendsInput {
  created?: DateRangeInput | null;
  startingAfter?: string | null;
  startingBefore?: string | null;
  limit?: number | null;
  statuses?: SendStatus[] | null;
  userId?: string | null;
  placementMethods?: PlacementMethod[] | null;
}

export interface RedeemGiftCardInput {
  redemptionCode: string;
  giftCardId?: string | null;
}

export interface RequestPasswordResetInput {
  email: string;
}

export interface ResetPasswordInput {
  resetPasswordCode: string;
  newPassword: string;
}

export interface RevokeInviteInput {
  userId: string;
}

export interface SalesForceContactInput {
  id: string;
  accountId?: string | null;
}

export interface SalesForceContactsInput {
  contactName: string;
}

export interface SendCreateFromCampaignInput {
  email: string;
  campaignId: string;
  giftId: string;
  sendItems: CreateSendItemInput[];
}

export interface SendHandwrittenNoteInput {
  cardId?: number | null;
  message: string;
  fontLabel?: string | null;
  senderAddress: AddressInput;
  senderBusinessName?: string | null;
  recipientAddress: AddressInput;
  recipientBusinessName?: string | null;
  salesForceContactId?: string | null;
  salesForceAccountId?: string | null;
  createSalesForceTask?: boolean | null;
  createHubSpotTask?: boolean | null;
  email: string;
}

export interface SendInput {
  id: string;
}

export interface UpcomingInvoiceInput {
  cursor?: string | null;
  subscriptionType: SubscriptionType;
}

export interface UpdateBudgetInput {
  id: string;
  amount: number;
  refillAmount: number;
  frequency: Frequency;
  isRollover: boolean;
}

export interface UpdateCardInput {
  sources: string[];
}

export interface UpdateInvitationInput {
  id: string;
  teamId: string;
}

export interface UpdateOrgInput {
  address?: AddressInput | null;
  settings?: OrgSettingsInput | null;
  logoUrl?: string | null;
  logoBackgroundColor?: string | null;
  logoPrimaryColor?: string | null;
}

export interface UpdateSendInput {
  id: string;
  externalId?: string | null;
  status?: SendStatus | null;
  trackingNumber?: string | null;
  carrierCode?: CarrierCode | null;
  addressId?: string | null;
}

export interface UpdateTeamInput {
  id: string;
  permissions: CreatePermissionInput[];
  name?: string | null;
}

export interface UpdateUserInput {
  id: string;
  salesForceUserId?: string | null;
  status?: UserStatus | null;
  name?: string | null;
}

export interface UpsertNotificationSettingsInput {
  channel?: NotificationChannel | null;
  onSendStatusProcessing?: boolean | null;
  onSendStatusShipped?: boolean | null;
  onSendStatusDelivered?: boolean | null;
  onSendStatusCanceled?: boolean | null;
  onSendStatusRedeemed?: boolean | null;
  onSendCreated?: boolean | null;
}

export interface UserByInviteCodeInput {
  inviteCode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
