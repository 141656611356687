import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { PureQueryOptions } from 'apollo-client';
import {
  Sends_sends,
  SendStatus,
  UpdateSend,
  UpdateSendVariables
} from '../../__generated__/types';
import { UPDATE_SEND } from '../../graphql/mutations';
import FormAlert from '../../components/FormAlert/FormAlert';
import ProductsFulfillmentSummary from './ProductsFulfillmentSummary';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';
import CopyTextField from '../../components/CopyTextField/CopyTextField';

type DropShipOrderFormComponentProps = {
  serverError: any;
  loading: boolean;
  send: Sends_sends;
} & FormikProps<{ externalId: string }>;

function DropShipOrderFormComponent({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  send,
  serverError
}: DropShipOrderFormComponentProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    textCenter: {
      textAlign: 'center'
    }
  }));
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column" alignItems="stretch">
      <Grid item xs className={classes.textCenter}>
        <SendIcon color="secondary" />
      </Grid>
      <Grid item xs>
        <Typography align="center" component="h1" variant="h5">
          Mark DropShip Send as Ordered
        </Typography>
      </Grid>
      {!!serverError && <FormAlert error={serverError} />}
      <Grid item xs>
        <ProductsFulfillmentSummary
          {...send}
          name={send.creator.name}
          orgName={send.org!.formattedName}
        />
      </Grid>
      <Grid item xs={12}>
        <CopyTextField label={'Product URL'} id={'productUrl'} fullWidth value={send.placementId} />
      </Grid>
      <Grid item xs>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="column" alignItems="stretch">
            <Grid item xs>
              <PrimaryTextField
                required
                fullWidth
                id="externalId"
                label="External Order Id"
                name="externalId"
                autoComplete="externalId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.externalId}
                error={!!(touched.externalId && errors.externalId)}
                helperText={(touched.externalId && errors.externalId) || ' '}
              />
            </Grid>
            <Grid item xs>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Update!
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export type DropShipOrderFormProps = {
  send: Sends_sends;
  onSuccess: () => void;
  refetchQueries?: Array<string | PureQueryOptions>;
};

export default function DropShipOrderForm({
  send,
  onSuccess,
  refetchQueries
}: DropShipOrderFormProps) {
  const [updateSend, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateSend,
    UpdateSendVariables
  >(UPDATE_SEND, {
    refetchQueries,
    onCompleted: () => onSuccess()
  });

  return (
    <Formik
      initialValues={{
        externalId: ''
      }}
      onSubmit={(values) =>
        updateSend({
          variables: { input: { ...values, id: send.id, status: SendStatus.PROCESSING } }
        })
      }
      render={(formikProps) => (
        <DropShipOrderFormComponent
          {...formikProps}
          send={send}
          serverError={updateError}
          loading={updateLoading}
        />
      )}
    />
  );
}
