import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import csvToJson from 'csvtojson';
import { CAMPAIGN_CREATE, CAMPAIGN_UPDATE } from 'graphql/mutations';
import { getMessagesFromApolloError } from 'graphql/utils';
import { update } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { CAMPAIGN, GIFTS } from '../../graphql/queries';
import {
  Campaign,
  CampaignCreate,
  CampaignCreateVariables,
  CampaignUpdate,
  CampaignUpdateVariables,
  CampaignVariables,
  DeliveryType,
  Gifts,
  GiftsVariables
} from '../../__generated__/types';
import CsvDropzone from './CsvDropzone';

type CampaignRow = {
  recipient_name: string;
  recipient_email: string;
  recipient_organization: string;
  salesforce_account_id: string;
  salesforce_contact_id: string;
  hubspot_contact_id: string;
  hubspot_company_id: string;
};
type CampaignData = CampaignRow[];

type CampaignFormFields = {
  gifts: Array<{ giftId: string; checked: boolean; title: string }>;
  message: string;
  name: string;
  expiresAt: string;
};

// Route: /campaigns/:campaignId
const EditCampaign: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { campaignId } = useParams<{ campaignId: string }>();
  const expiresAtRef = React.useRef<HTMLInputElement>(null);

  // Queries
  const {
    data: { gifts = null } = {},
    loading: giftsLoading,
    error: giftsError
  } = useQuery<Gifts, GiftsVariables>(GIFTS, {
    variables: { input: { deliveryType: DeliveryType.DIGITAL } }
  });
  const {
    data: { campaign = null } = {},
    loading,
    error: campaignError
  } = useQuery<Campaign, CampaignVariables>(CAMPAIGN, {
    variables: {
      id: campaignId
    }
  });

  // Mutations
  const [campaignUpdate, { loading: udpateCampaignLoading }] = useMutation<
    CampaignUpdate,
    CampaignUpdateVariables
  >(CAMPAIGN_UPDATE, {
    onCompleted: () => {
      enqueueSnackbar('Success', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(getMessagesFromApolloError(error), { variant: 'error', persist: true });
    }
  });
  // Change expiration date

  // Update gifts

  const { handleSubmit, control, setValue, watch, formState, trigger } =
    useForm<CampaignFormFields>({
      defaultValues: {
        name: '',
        message: '',
        gifts: [],
        expiresAt: ''
      }
    });
  const giftsFieldArray = useFieldArray<CampaignFormFields>({
    name: 'gifts',
    control
  });

  // Initialize the form
  React.useEffect(() => {
    if (campaign && gifts) {
      setValue('name', campaign.name);
      setValue('message', campaign.message ?? '');
      setValue(
        'expiresAt',
        campaign.expiresAt ? moment(campaign.expiresAt).format('YYYY-MM-DD') : ''
      );
      setValue(
        'gifts',
        gifts.map((gift) => ({
          giftId: gift.id,
          title: gift.title,
          checked: Boolean(campaign.gifts?.find((campaignGift) => campaignGift.id === gift.id))
        }))
      );
    }
  }, [campaign, gifts]);

  function onSubmit(values: CampaignFormFields) {
    campaignUpdate({
      variables: {
        id: campaignId,
        input: {
          name: values.name,
          message: values.message,
          expiresAt: values.expiresAt ? moment(values.expiresAt).toDate() : null,
          gifts: values.gifts.filter((gift) => gift.checked).map((gift) => gift.giftId)
        }
      }
    });
  }

  if (loading || giftsLoading) {
    return <Loading />;
  }

  if (!campaign || campaignError || giftsError) {
    return <Typography>Campaign not found.</Typography>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Manage Campaign
          </Typography>
          <Divider light />
          <br />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label="Campaign Name"
                variant="outlined"
                multiline
                fullWidth
                inputProps={{
                  required: true
                }}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextField
                label="Message"
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                inputProps={{
                  required: true,
                  style: {
                    fontFamily: `'Caveat', cursive`,
                    fontSize: 22,
                    whiteSpace: 'pre-wrap'
                  }
                }}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Available Gifts</Typography>
          <List style={{ maxHeight: 400, overflowY: 'scroll' }}>
            {giftsFieldArray.fields.map((field, index) => (
              <ListItem key={field.id}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    checked={field.checked}
                    onChange={(event) =>
                      giftsFieldArray.update(index, {
                        giftId: field.giftId,
                        title: field.title,
                        checked: event.target.checked
                      })
                    }
                  />
                </ListItemIcon>
                <ListItemText id={field.giftId} primary={field.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Controller
                name="expiresAt"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Expiration Date"
                    variant="outlined"
                    type="date"
                    inputRef={expiresAtRef}
                    InputLabelProps={{ shrink: true }}
                    helperText="Leave blank for no expiration date"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setValue('expiresAt', moment().subtract(1, 'd').format('YYYY-MM-DD'), {
                    shouldDirty: true
                  });
                }}
              >
                End Campaign Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {moment(watch('expiresAt')).isBefore(moment()) && (
          <>
            <Grid item>
              <Typography>
                The expiration date for this campaign is in the past. This will end the campaign.
                Changing the expiration date to a future date will reactivate the campaign.
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <Button
            color="primary"
            variant="contained"
            disabled={udpateCampaignLoading || !formState.isDirty}
            type="submit"
            size={'large'}
            fullWidth
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditCampaign;

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxHeight: 300
  }
}));
