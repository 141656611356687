import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  RequestPasswordReset,
  RequestPasswordResetInput,
  RequestPasswordResetVariables
} from '../../__generated__/types';
import { ApolloError } from 'apollo-client';
import { REQUEST_PASSWORD_RESET } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import Alert from '@material-ui/lab/Alert';
import FormAlert from '../../components/FormAlert/FormAlert';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

type ForgotPasswordFormComponentProps = FormikProps<RequestPasswordResetInput> & {
  loading: boolean;
  error?: ApolloError;
  successMessage?: string | null;
};

function ForgotPasswordFormComponent({
  loading,
  successMessage,
  values,
  touched,
  error,
  errors,
  handleChange,
  handleBlur,
  handleSubmit
}: ForgotPasswordFormComponentProps) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Send Password Reset Email
      </Typography>
      <FormAlert error={error} />
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <PrimaryTextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={!!(touched.email && errors.email)}
          helperText={(touched.email && errors.email) || ' '}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default function ForgotPasswordForm() {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [requestPasswordReset, { error, loading }] = useMutation<
    RequestPasswordReset,
    RequestPasswordResetVariables
  >(REQUEST_PASSWORD_RESET, {
    onCompleted: () =>
      setSuccessMessage(
        'A password reset email has been sent. If you have sent multiple of these emails, only the latest is valid.'
      )
  });

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      onSubmit={(values) => {
        requestPasswordReset({ variables: { input: { ...values } } });
        setSuccessMessage(null);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required()
      })}
    >
      {(formikProps) => (
        <ForgotPasswordFormComponent
          {...formikProps}
          successMessage={successMessage}
          loading={loading}
          error={error}
        />
      )}
    </Formik>
  );
}
