import React from 'react';

type AddressForm = {
  name: string;
  street1: string;
  street2: string;
  zip: string;
  state: string;
  city: string;
};

export default function useAddressForm() {
  return React.useState<AddressForm>({
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  });
}
