import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import moment from 'moment-timezone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Invoices_invoices } from '../../../../__generated__/types';
import { formatDateTime, formatDollars } from '../../../../helpers/formatters';
import { scrollingList } from '../../../../helpers/styles';
import { daysDiff } from './utils';
import InvoiceStatusBadge from './InvoiceStatusBadge';

export type PastInvoicesSummaryProps = {
  invoices: Invoices_invoices[];
  title: string;
};

export default function PastInvoicesSummary({ invoices, title }: PastInvoicesSummaryProps) {
  const classes = useStyles();
  return (
    <Fragment>
      <Card>
        <CardHeader title={title} />
        <Divider light />
        <CardContent>
          <List className={classes.list}>
            {invoices.map((invoice) => {
              const daysUntilDue = daysDiff(moment().format(), invoice.dueDate);
              const dueDateMessage =
                invoice.status === 'open'
                  ? daysUntilDue < 0
                    ? `- Overdue by ${daysUntilDue * -1} days`
                    : `- Due in ${daysUntilDue} days`
                  : '';
              return (
                <ListItem key={invoice.id}>
                  <ListItemText
                    primary={`Finalized on: ${formatDateTime(invoice.periodEnd)} ${dueDateMessage}`}
                    secondary={
                      <Fragment>
                        <Typography variant={'body2'} color={'textSecondary'}>
                          Total: {formatDollars(invoice.totalDollars)}.&nbsp;
                          <Link
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={invoice.hostedInvoiceUrl || ''}
                          >
                            {invoice.status === 'open' ? 'Pay now' : 'View Details'}
                          </Link>
                        </Typography>
                      </Fragment>
                    }
                  />
                  <InvoiceStatusBadge status={invoice.status} dueDate={invoice.dueDate} />
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  list: scrollingList(theme)
}));
