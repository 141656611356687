import React from 'react';
import PrimaryTextField, { PrimaryTextFieldProps } from '../TextField/PrimaryTextField';
export type MessageFieldProps = PrimaryTextFieldProps & {
  charLimit: number;
  value: string;
};

const MessageField = React.forwardRef((props: MessageFieldProps, ref) => {
  const { value, charLimit, error, helperText } = props;
  const isCharLimitError = value.length > charLimit;
  const charLimitText = isCharLimitError
    ? `Message is too long (${value.length}/${charLimit})`
    : `${value.length}/${charLimit} characters used`;
  return (
    <PrimaryTextField
      {...props}
      inputRef={ref}
      inputProps={{
        ...props.inputProps,
        style: { fontFamily: `'Caveat', sans-serif`, fontSize: 22, lineHeight: `26px` }
      }}
      error={error || isCharLimitError}
      helperText={helperText || charLimitText}
    />
  );
});
export default MessageField;
