import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Sends_sends_address, Sends_sends_sendItems } from '../../__generated__/types';

export type ProductsFulfillmentSummaryProps = {
  sendItems: Sends_sends_sendItems[] | null;
  address: Sends_sends_address | null;
  message: string | null;
  name: string;
  orgName: string;
};

export default function ProductsFulfillmentSummary({
  sendItems,
  address,
  message,
  name,
  orgName
}: ProductsFulfillmentSummaryProps) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List>
        {sendItems &&
          sendItems.map((sendItem) => (
            <ListItem key={sendItem.title}>
              <ListItemText primary={sendItem.title} />
              <Typography variant={'body1'}>Qty: {sendItem.quantity}</Typography>
            </ListItem>
          ))}
        <br />
        {address && (
          <div>
            <Typography>{address.name}</Typography>
            <Typography>{address.street1}</Typography>
            <Typography>{address.street2}</Typography>
            <Typography>{address.zip}</Typography>
            <Typography>{address.city}</Typography>
            <Typography>{address.state}</Typography>
          </div>
        )}
        <br />
        {message && (
          <div>
            Message:
            <p>{`From ${name} at ${orgName}: \n${message}`}</p>
          </div>
        )}
      </List>
    </React.Fragment>
  );
}
