import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';

const styles = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2)
    }
  })
);

export default styles(FormControl);
