import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import PrimaryTextField from '../../../components/PrimaryTextField/PrimaryTextField';
import { useValidateAddressDialog } from '../../../components/ValidateAddressDialog/ValidateAddressDialog';
import { useFormContext, Controller } from 'react-hook-form';
import theme from 'theme';

export type AddressFormValues = {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  isPrivateAddress: boolean;
};

export default function AddressForm() {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    watch
  } = useFormContext<{ address: AddressFormValues }>();
  // This needs to be moved
  const { renderValidateAddressDialog, validateAddress } = useValidateAddressDialog();
  // Values are only updated AFTER submit
  const values = getValues();
  const isPrivateAddress = watch('address.isPrivateAddress');
  return (
    <div style={{ paddingLeft: 8 }}>
      <FormControlLabel
        control={
          <Controller
            name={'address.isPrivateAddress'}
            control={control}
            render={({ field }) => <Checkbox id={'address.isPrivateAddress'} {...field} />}
          />
        }
        label={'Generate link for recipient to submit their address.'}
      />
      <Collapse in={!isPrivateAddress}>
        {renderValidateAddressDialog({
          onAccept: (address) => {
            setValue('address', {
              ...address,
              street2: values.address.street2,
              isPrivateAddress: values.address.isPrivateAddress
            });
            console.log('acceptedAddress!');
          },
          userAddress: values.address
        })}
        <Controller
          name="address.name"
          control={control}
          render={({ field }) => (
            <PrimaryTextField
              type="text"
              fullWidth
              id="address.name"
              inputProps={{
                'data-testid': 'address.name'
              }}
              label="Name"
              autoComplete="no"
              required={!isPrivateAddress}
              helperText={"The name you'd like to appear on the shipping label."}
              {...field}
            />
          )}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Controller
              control={control}
              name="address.street1"
              render={({ field }) => (
                <PrimaryTextField
                  type="text"
                  fullWidth
                  id="address.street1"
                  inputProps={{
                    'data-testid': 'street1'
                  }}
                  label="Address Line 1"
                  required={!isPrivateAddress}
                  autoComplete="no"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="address.street2"
              control={control}
              render={({ field }) => (
                <PrimaryTextField
                  type="text"
                  fullWidth
                  id="address.street2"
                  inputProps={{
                    'data-testid': 'street2'
                  }}
                  label="Address Line 2"
                  name="address.street2"
                  autoComplete="no"
                />
              )}
            />
          </Grid>

          <Grid item xs={8} sm={12} md={6}>
            <Controller
              name="address.city"
              control={control}
              render={({ field }) => (
                <PrimaryTextField
                  fullWidth
                  id="address.city"
                  inputProps={{
                    'data-testid': 'city'
                  }}
                  label="City"
                  required={!isPrivateAddress}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={12} md={2}>
            <Controller
              name="address.state"
              control={control}
              render={({ field }) => (
                <PrimaryTextField
                  fullWidth
                  id="address.state"
                  inputProps={{
                    'data-testid': 'state'
                  }}
                  label="State"
                  required={!isPrivateAddress}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name="address.zip"
              control={control}
              render={({ field }) => (
                <PrimaryTextField
                  fullWidth
                  id="address.zip"
                  inputProps={{
                    'data-testid': 'zip'
                  }}
                  label="Zip"
                  required={!isPrivateAddress}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}
