import { logger } from 'graphql/client';
import React from 'react';

class ErrorBoundary extends React.Component {
  public state: { hasError: boolean };

  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    logger.error(error?.message);
  }

  render() {
    if (this.state.hasError) {
      return <h5>There's been a temporary issue. Try again later, or contact customer support.</h5>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
