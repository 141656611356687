import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardActions from '@material-ui/core/CardActions';
import { formatDollars } from '../../../../../helpers/formatters';
import { BUDGETS, ORG_CREDIT } from '../../../../../graphql/queries';
import Loading from '../../../../../components/Loading/Loading';
import { Budgets, OrgCredit } from '../../../../../__generated__/types';
import { useQuery } from '@apollo/react-hooks';
import AddCreditForm from './components/AddCreditForm/AddCreditForm';

export default function AccountCreditSummary() {
  const { data: { credit = null } = {}, loading } = useQuery<OrgCredit>(ORG_CREDIT, {
    fetchPolicy: 'network-only'
  });
  const { data: { budgets = [] } = {} } = useQuery<Budgets>(BUDGETS);
  const [showAddCredit, setShowAddCredit] = useState<boolean>(false);

  const unallocatedBudget = credit
    ? credit - budgets.reduce((sum, budget) => sum + budget.amount, 0)
    : 0;

  return (
    <Fragment>
      <Dialog
        open={showAddCredit}
        onClose={() => setShowAddCredit(false)}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Add Credit</DialogTitle>
        <Divider light />
        <DialogContent>
          <AddCreditForm onSuccess={() => setShowAddCredit(false)} />
        </DialogContent>
      </Dialog>
      <Card>
        <CardHeader title="Account Credit" />
        <Divider light />
        <CardContent>
          {loading && <Loading />}
          {!loading && (
            <List>
              <ListItem>
                <ListItemText primary="Total amount of money in RevSend" />
                <Typography variant="body2">{formatDollars(credit || 0)}</Typography>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Unallocated budget"
                  secondary="This is the amount that is available to spend, but not assigned to any users"
                />
                <Typography variant="body2">{formatDollars(unallocatedBudget)}</Typography>
              </ListItem>
            </List>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant={showAddCredit ? 'outlined' : 'contained'}
            color="primary"
            onClick={() => setShowAddCredit(!showAddCredit)}
          >
            {showAddCredit ? 'Cancel' : 'Add Credit'}
          </Button>
          {'  '}
        </CardActions>
      </Card>
    </Fragment>
  );
}
