import React, { Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import OrgUsersTable from './components/OrgUsersTable/OrgUsersTable';
import Button from '@material-ui/core/Button';
import AddUserDialog from './components/AddUserDialog/AddUserDialog';
import InvitationsTable, { INVITATIONS } from './components/InvitationsTable/InvitationsTable';
import { useQuery } from '@apollo/react-hooks';
import { Invitations } from '../../../__generated__/types';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

export default function Users() {
  const [isInvitationDialogOpen, setInvitationDialogOpen] = React.useState<boolean>(false);
  const { data: { invitations = null } = {} } = useQuery<Invitations>(INVITATIONS);

  function openInvitationDialog() {
    setInvitationDialogOpen(true);
  }

  function closeInvitationDialog() {
    setInvitationDialogOpen(false);
  }

  return (
    <Fragment>
      <AddUserDialog
        open={isInvitationDialogOpen}
        onClose={closeInvitationDialog}
        close={closeInvitationDialog}
      />
      <Grid container spacing={2} direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Breadcrumbs />
        </Grid>
        <Grid item>
          <Typography variant="h3">Users</Typography>
          <Divider light />
        </Grid>
        <Grid item xs>
          <Button onClick={openInvitationDialog} variant="contained" color="primary">
            Add
          </Button>
        </Grid>
        <Grid item xs>
          <OrgUsersTable />
        </Grid>
        {invitations && invitations.find((invite) => !invite?.isAccepted) && (
          <Grid item xs>
            <InvitationsTable />
          </Grid>
        )}
        <Grid item xs />
      </Grid>
    </Fragment>
  );
}
