import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  SalesForceToken_salesForceToken,
  Send_send_recipient
} from '../../__generated__/types';
import Link from '@material-ui/core/Link';

export type SendRecipientCardProps = {
  recipient: Send_send_recipient;
  salesForceToken?: SalesForceToken_salesForceToken | null;
};

const SendRecipientCard: React.FC<SendRecipientCardProps> = (props) => {
  const { recipient, salesForceToken } = props;
  return (
    <>
      <Card>
        <CardContent>
          <Typography>{recipient.name}</Typography>
          <Typography>{recipient.email}</Typography>
          {salesForceToken && recipient.salesForceContactId && (
            <>
              <Link
                variant={'body1'}
                target="_blank"
                rel="noopener"
                href={salesForceToken.instanceUrl + '/' + recipient.salesForceContactId}
              >
                SalesForce Contact
              </Link>
              <br />
            </>
          )}
          {salesForceToken && recipient.salesForceAccountId && (
            <Link
              variant={'body1'}
              target="_blank"
              rel="noopener"
              href={salesForceToken.instanceUrl + '/' + recipient.salesForceAccountId}
            >
              SalesForce Account
            </Link>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SendRecipientCard;
