import React from 'react';
import { useParams } from 'react-router-dom';
import AmazonCheckout from './Amazon/AmazonCheckout';
import ProductCheckout from './Product/ProductCheckout';

export default function SendGift() {
  const { giftId } = useParams<any>();
  if (giftId === 'amazon' || giftId === 'AMAZON') {
    return <AmazonCheckout giftId={giftId} />;
  }
  return <ProductCheckout giftId={giftId} />;
}
