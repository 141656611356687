import React, { Fragment } from 'react';
import sumBy from 'lodash/sumBy';
import { formatDollars } from '../../../../helpers/formatters';
import { OrgUserSends_org_users } from '../../../../__generated__/types';
import FilterTable from '../../../../components/FilterTable/FilterTable';

export type UserAnalyticsTableProps = {
  data: Array<OrgUserSends_org_users> | null;
  loading: boolean;
};

export type UserAnalyticsRow = {
  name: string;
  totalSends: number;
  totalExpense: number;
  currentBudget: number;
};
export default function UserAnalyticsTable({ data, loading }: UserAnalyticsTableProps) {
  return (
    <Fragment>
      <FilterTable
        isLoading={loading}
        options={{
          search: true,
          showTitle: true,
          loadingType: 'linear',
          exportButton: true,
          exportAllData: true,
          showEmptyDataSourceMessage: !loading
        }}
        columns={[
          {
            field: 'name',
            title: 'Name',
            filtering: true
          },
          {
            field: 'totalSends',
            title: 'Total Sends',
            type: 'numeric',
            filtering: false
          },
          {
            field: 'totalExpense',
            title: 'Total Expense',
            type: 'numeric',
            filtering: false,
            render: (data: UserAnalyticsRow) => (
              <Fragment>{formatDollars(data.totalExpense)}</Fragment>
            )
          },
          {
            field: 'currentBudget',
            title: 'Current Budget',
            type: 'numeric',
            filtering: false,
            render: (data: UserAnalyticsRow) => (
              <Fragment>{formatDollars(data.currentBudget)}</Fragment>
            )
          }
        ]}
        data={processData(data)}
        title="Users"
      />
    </Fragment>
  );
}

function processData(data: Array<OrgUserSends_org_users> | null): Array<UserAnalyticsRow> {
  if (!data) return [];
  return data.map((user) => {
    return {
      name: user.name || '',
      totalSends: user.sends ? user.sends.length : 0,
      totalExpense: user.sends
        ? user.sends.reduce(
            (sum, send) => sum + (send.invoiceItems ? sumBy(send.invoiceItems, 'dollars') : 0),
            0
          )
        : 0,
      currentBudget: user.budget ? user.budget.amount : 0
    };
  });
}
