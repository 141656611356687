import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useQuery } from '@apollo/react-hooks';
import { Me, NotificationChannel } from '../../../__generated__/types';
import { ME } from '../../../graphql/queries';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import NotificationForm from './components/NotificationForm/NotificationForm';
import Avatar from '@material-ui/core/Avatar';
import Loading from '../../../components/Loading/Loading';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Input from './Input';
import PrimaryTextField from '../../../components/PrimaryTextField/PrimaryTextField';
import PrimaryInputLabel from '../../../components/PrimaryTextField/PrimaryInputLabel/PrimaryInputLabel';
import Alert from '@material-ui/lab/Alert';
export default function Notifications() {
  const { data: { me = null } = {}, loading: meLoading } = useQuery<Me>(ME, {
    pollInterval: 30 * 1000
  });
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const emailSettings = me?.notificationSettings?.find(
    (settings) => settings.channel === NotificationChannel.EMAIL
  );
  const slackSettings = me?.notificationSettings?.find(
    (settings) => settings.channel === NotificationChannel.SLACK
  );
  const history = useHistory();
  if (meLoading) {
    return <Loading />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs />
        <br />
        <Typography variant="h3">Notifications</Typography>
        <Divider light />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardHeader
            title={'Email'}
            titleTypographyProps={{ variant: 'body1' }}
            subheader={'Send me an email when:'}
            avatar={<Avatar src={'https://img.icons8.com/cute-clipart/64/000000/email.png'} />}
          />
          <Divider light />
          <CardContent>
            <NotificationForm settings={emailSettings} channel={NotificationChannel.EMAIL} />
          </CardContent>
        </Card>
      </Grid>
      {/*<Grid item xs={12} sm={6} md={4}>*/}
      {/*  <Card>*/}
      {/*    <CardHeader*/}
      {/*      title={'Slack'}*/}
      {/*      titleTypographyProps={{ variant: 'body1' }}*/}
      {/*      avatar={*/}
      {/*        <Avatar*/}
      {/*          src={'https://cdn.brandfolder.io/5H442O3W/as/pl546j-7le8zk-199wkt/Slack_Mark.svg'}*/}
      {/*        />*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <Divider light />*/}
      {/*    <CardContent>*/}
      {/*      {!me?.isSlackConnected && (*/}
      {/*        <>*/}
      {/*          <Typography gutterBottom>*/}
      {/*            Your RevSend account is not currently connected with Slack*/}
      {/*          </Typography>*/}
      {/*          <Button*/}
      {/*            variant={'contained'}*/}
      {/*            onClick={() => history.push('/settings/integrations/slack')}*/}
      {/*          >*/}
      {/*            Connect Slack*/}
      {/*          </Button>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {me?.isSlackConnected && (*/}
      {/*        <>*/}
      {/*          <Typography gutterBottom variant={'body1'}>*/}
      {/*            Open the RevSend app inside of Slack to adjust these settings.*/}
      {/*          </Typography>*/}
      {/*          <Typography variant={'body2'}>*/}
      {/*            Note that changes in Slack may take a few moments to display here.*/}
      {/*          </Typography>*/}
      {/*          <NotificationForm*/}
      {/*            readOnly*/}
      {/*            settings={slackSettings}*/}
      {/*            channel={NotificationChannel.SLACK}*/}
      {/*          />*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </CardContent>*/}
      {/*  </Card>*/}
      {/*</Grid>*/}
    </Grid>
  );
}
