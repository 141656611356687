import React, { Fragment } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { FieldArray, Formik, FormikProps } from 'formik';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTitle from '@material-ui/core/DialogTitle';
import xorWith from 'lodash/xorWith';
import isEqual from 'lodash/isEqual';
import { GIFTS, ME, teamQueries } from '../../../../../graphql/queries';
import {
  CreatePermissionInput,
  CreateTeam,
  CreateTeamVariables,
  Gifts,
  Gifts_gifts,
  Me,
  OrgTeams_org_teams,
  PermissionType,
  UpdateTeam,
  UpdateTeamVariables
} from '../../../../../__generated__/types';
import { CREATE_TEAM, UPDATE_TEAM } from '../../../../../graphql/mutations';
import Loading from '../../../../../components/Loading/Loading';
import FormAlert from '../../../../../components/FormAlert/FormAlert';
import PrimaryTextField from '../../../../../components/PrimaryTextField/PrimaryTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type EditTeamDialogProps = {
  onSuccess: () => void;
  team: OrgTeams_org_teams | null;
} & DialogProps;

const permissionCategories = ['All'];

export default function EditTeamDialog({ team, onSuccess, open, onClose }: EditTeamDialogProps) {
  const { data: { me = null } = {}, loading: meLoading } = useQuery<Me>(ME);
  const { data: { gifts = null } = {}, loading: giftsLoading } = useQuery<Gifts>(GIFTS);
  const [createTeam, { error: createTeamError, loading: createTeamLoading }] = useMutation<
    CreateTeam,
    CreateTeamVariables
  >(CREATE_TEAM, {
    refetchQueries: teamQueries,
    onCompleted: () => onSuccess()
  });
  const [updateTeam, { error: updateTeamError, loading: updateTeamLoading }] = useMutation<
    UpdateTeam,
    UpdateTeamVariables
  >(UPDATE_TEAM, {
    refetchQueries: teamQueries,
    onCompleted: () => onSuccess()
  });

  const loading = createTeamLoading || updateTeamLoading;
  const isUserInTeam = me && team && team.users && team.users.find((user) => user.id === me.id);
  const classes = useStyles();
  const dataLoading = meLoading || giftsLoading;
  const initialValues = {
    name: (team && team.name) || '',
    isAdmin: Boolean(team?.permissions.find((permission) => permission.type === PermissionType.All))
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{team ? 'Edit' : 'New'} Team</DialogTitle>
      <Divider light />
      {dataLoading && <Loading />}
      {!dataLoading && (
        <DialogContent>
          {createTeamError && <FormAlert error={createTeamError} />}
          {updateTeamError && <FormAlert error={updateTeamError} />}
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              if (team) {
                await updateTeam({
                  variables: {
                    input: {
                      id: team.id,
                      name: values.name,
                      permissions: values.isAdmin ? [{ type: PermissionType.All }] : []
                    }
                  }
                });
              } else {
                await createTeam({
                  variables: {
                    input: {
                      name: values.name,
                      permissions: values.isAdmin ? [{ type: PermissionType.All }] : []
                    }
                  }
                });
              }
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required()
            })}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              touched,
              errors,
              setFieldValue,
              initialValues
            }: FormikProps<{ isAdmin: boolean; name: string }>) => {
              const arePermissionsChanged = initialValues.isAdmin !== values.isAdmin;
              return (
                <form onSubmit={handleSubmit}>
                  <PrimaryTextField
                    required
                    fullWidth
                    id="name"
                    label="Team Name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={!!(touched.name && errors.name)}
                    helperText={(touched.name && errors.name) || ' '}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isAdmin}
                        tabIndex={2}
                        onChange={(event, checked) => setFieldValue('isAdmin', checked)}
                        inputProps={{ 'aria-labelledby': 'Has Admin Permissions' }}
                      />
                    }
                    label={'Has Admin Permissions'}
                  />
                  <br />
                  {arePermissionsChanged && (
                    <Alert severity={'warning'}>
                      Editing the permissions will expire the session and require re-login of ALL
                      users in this team.
                    </Alert>
                  )}
                  <br />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={isUserInTeam && arePermissionsChanged ? classes.warningButton : ''}
                  >
                    {loading
                      ? 'Loading...'
                      : isUserInTeam && arePermissionsChanged
                      ? 'Save and Logout'
                      : 'Save'}
                  </Button>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      )}
    </Dialog>
  );
}

export function getPermissionLabel(
  permission: CreatePermissionInput,
  gifts: Array<Gifts_gifts> | null
) {
  if (!permission.giftId) {
    return permission.type.replace(/Read/gi, 'View').split('__').reverse().join(' ');
  }
  const gift = gifts ? gifts.find((gift) => gift.id === permission.giftId) : null;
  return `Create Send: ${gift ? gift.title : ''}`;
}

function getPermissionOptions(
  permissionTypes: Array<PermissionType>,
  gifts: Array<Gifts_gifts> | null
): Array<CreatePermissionInput> {
  // Get all the permissions based on types
  const permissionTypeOptions = permissionTypes
    .filter(
      // Filter out the permission types that will be deleted in future (e.g. Sends__Create__GiftType__E_GIFT_CARD)
      (permissionType) =>
        permissionType.split('__').length <= 2 &&
        // Filter out any Sends__Create. These relate to gifts, and will be added seperately.
        permissionType !== PermissionType.Sends__Create
    )
    .map((permissionType) => ({ type: permissionType, giftId: null }));
  // Get all the permissions based on gifts
  const giftOptions = gifts
    ? gifts.map((gift) => ({ type: PermissionType.Sends__Create, giftId: gift.id }))
    : [];
  return [...permissionTypeOptions, ...giftOptions];
}

function checkArePermissionsChanged(
  initialValue: Array<CreatePermissionInput> | null,
  value: Array<CreatePermissionInput>
): boolean {
  if (!initialValue) return false;
  const xorResult = xorWith(initialValue, value, isEqual);
  return xorResult.length > 0;
}

const useStyles = makeStyles((theme: Theme) => ({
  permissionsList: {
    maxHeight: 300,
    overflow: 'scroll'
  },
  warningButton: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    },
    '&:active': {
      backgroundColor: theme.palette.warning.light
    },
    '&:focus': {
      backgroundColor: theme.palette.warning.main
    }
  }
}));
