import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: '100%'
  }
}));

const None: React.FC = ({ children }) => {
  const classes = useStyles();
  return <main className={classes.main}>{children}</main>;
};

export default None;
