import moment from 'moment-timezone';

export const isDatePast = (iso8601: string): boolean => {
  return moment().isAfter(moment(iso8601).tz(moment.tz.guess()));
};

/**
 * If comparison is in the past, this will result in a negative return value.
 * @param baseline
 * @param comparison
 */
export const daysDiff = (baseline: string, comparison: string): number => {
  return moment(comparison).diff(moment(baseline), 'days');
};
