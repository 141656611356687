import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useTheme from '@material-ui/core/styles/useTheme';
import { InputProps, TextFieldProps } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PrimaryFormControl from '../PrimaryTextField/PimaryFormControl/PrimaryFormControl';
import PrimaryInputLabel from '../PrimaryTextField/PrimaryInputLabel/PrimaryInputLabel';
import PrimaryFormHelperText from '../PrimaryTextField/PrimaryFormHelperText/PrimaryFormHelperText';
import { Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

/**
 * CSS classes for the Input element. Since the TextField component doesn't accept a renderInput
 * prop, we need to style the input by passing the CSS as a prop.
 * See https://material-ui.com/api/input/#css
 * @param theme
 */
const createInputClasses: (isMultiline: boolean) => () => InputProps['classes'] = (isMultiline) =>
  makeStyles((theme: Theme) => ({
    root: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#f7fafc',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: isMultiline ? '8px' : '0px 8px 0px 8px',
      transition: theme.transitions.create(['border-color', 'box-shadow'])
    },
    focused: {
      borderRadius: 4,
      boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 1px`,
      borderColor: theme.palette.primary.main
    }
  }));

export type PrimaryTextFieldProps = TextFieldProps;
const PrimaryTextField: React.FC<PrimaryTextFieldProps> = (props) => {
  const {
    inputProps,
    InputProps,
    InputLabelProps,
    SelectProps,
    label,
    id,
    required,
    fullWidth,
    error,
    helperText,
    value,
    onChange,
    onBlur,
    name,
    type,
    rows,
    autoComplete,
    classes,
    inputRef
  } = props;
  const theme = useTheme();
  const multiline = Boolean(rows && rows > 0);
  const inputClasses = createInputClasses(multiline)();
  return (
    <PrimaryFormControl error={error} fullWidth={fullWidth}>
      <PrimaryInputLabel htmlFor={id} {...InputLabelProps}>
        {label}
      </PrimaryInputLabel>
      <TextField
        id={id}
        classes={classes}
        inputRef={inputRef}
        multiline={multiline}
        type={type}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        inputProps={inputProps}
        InputProps={{
          ...InputProps,
          rows,
          disableUnderline: true,
          classes: inputClasses
        }}
        SelectProps={SelectProps}
      />
      <Collapse in={Boolean(helperText)}>
        <PrimaryFormHelperText>
          {error && (
            <InfoOutlinedIcon
              style={{
                color: theme.palette.error.main,
                fontSize: 14,
                margin: `0 4px -2px 0px`
              }}
            />
          )}
          {helperText}&nbsp;
        </PrimaryFormHelperText>
      </Collapse>
    </PrimaryFormControl>
  );
};

export default PrimaryTextField;
