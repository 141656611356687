import { RedirectProps } from 'react-router-dom';

const legacyRedirects = [
  {
    from: '/send-history',
    to: '/history'
  },
  {
    from: '/dashboard/*',
    to: '/*'
  },
  {
    from: '/integrations/*',
    to: '/settings/integrations/*'
  }
];

export default legacyRedirects;
