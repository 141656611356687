import React from 'react';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import capitalize from 'lodash/capitalize';
export default function Breadcrumbs() {
  const history = useHistory();
  return (
    <MuiBreadcrumbs>
      {window.location.pathname
        .split('/')
        .filter((part) => part)
        .map((part, index, parts) => {
          if (index === parts.length - 1) {
            return <Typography color="textSecondary">{capitalize(part)}</Typography>;
          }
          return (
            <Link
              component={'button'}
              variant={'body1'}
              onClick={() => history.push('/' + parts.slice(0, index + 1).join('/'))}
            >
              {capitalize(part)}
            </Link>
          );
        })}
    </MuiBreadcrumbs>
  );
}
