import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

const styles = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `2px 4px 2px 4px`,
      '&$error': {
        color: theme.palette.error.dark
      }
    },
    error: {}
  })
);

export default styles(FormHelperText);
