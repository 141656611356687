import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import SignupForm from './SignupForm';
import { SignupAndCreateOrg, SignupAndCreateOrgVariables } from '../../__generated__/types';
import { IS_LOGGED_IN } from '../../graphql/queries';

export const SIGNUP_AND_CREATE_ORG = gql`
  mutation SignupAndCreateOrg(
    $orgName: String!
    $email: String!
    $password: String!
    $name: String!
    $recaptchaToken: String!
  ) {
    signupAndCreateOrg(
      orgName: $orgName
      email: $email
      password: $password
      name: $name
      recaptchaToken: $recaptchaToken
    ) {
      tokens {
        key
        value
      }
    }
  }
`;

export default function ConnectedSignupForm() {
  const client = useApolloClient();
  const [signup, { loading, error }] = useMutation<SignupAndCreateOrg, SignupAndCreateOrgVariables>(
    SIGNUP_AND_CREATE_ORG,
    {
      onCompleted({ signupAndCreateOrg }) {
        if (!(signupAndCreateOrg && signupAndCreateOrg.tokens && signupAndCreateOrg.tokens[0]))
          return;
        localStorage.setItem('token', signupAndCreateOrg.tokens[0].value);
        client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
        window.location.reload();
      }
    }
  );
  return <SignupForm signup={signup} loading={loading} serverError={error} />;
}
