import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import CopyIcon from '@material-ui/icons/FileCopy';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import PrimaryTextField, { PrimaryTextFieldProps } from '../PrimaryTextField/PrimaryTextField';

export default function CopyTextField(props: PrimaryTextFieldProps) {
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <PrimaryTextField
        {...props}
        endAdornment={
          <InputAdornment position="end">
            <CopyToClipboard text={props.value as string} onCopy={() => setIsLinkCopied(true)}>
              <Button color={'primary'} onClick={handleCopyClick}>
                <CopyIcon />
                &nbsp;Copy
              </Button>
            </CopyToClipboard>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Alert severity={'success'}>Copied!</Alert>
            </Popover>
          </InputAdornment>
        }
        inputProps={{
          ...props.inputProps,
          readOnly: true
        }}
      />
    </Fragment>
  );
}
