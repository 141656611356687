import makeStyles from '@material-ui/styles/makeStyles';

export const useInputStyles = makeStyles(() => ({
  neutralInput: {
    fontSize: '16px',
    lineHeight: '24px',
    padding: '6px 14px',
    width: '100%',
    minHeight: '44px',
    border: '1px solid #bfbfbf',
    borderRadius: '4px',
    outlineColor: '#6f6f6f',
    backgroundColor: '#ffffff',
    fontFamily: `'Nunito Sans', 'Arial', 'sans-serif'`
  },
  neutralLabel: { fontSize: 14, fontWeight: 500, display: 'block', marginBottom: 2 }
}));
