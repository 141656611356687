import moment from 'moment-timezone';
import packageJson from '../../package.json';
import semver from 'semver';

export const maybeBustCache = async (): Promise<string> => {
  let version = 'unknown';
  try {
    const meta = await fetch(`/meta.json?${moment().unix()}`, { cache: 'no-cache' }).then((res) =>
      res.json()
    );
    version = meta.version;
    if (semver.lt(packageJson.version, version)) {
      const cacheKeys = await caches.keys();
      await Promise.all(cacheKeys.map((cacheKey) => caches.delete(cacheKey)));
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
  return version;
};
