import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as qs from 'query-string';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import {
  CreateHubSpotToken,
  CreateHubSpotTokenVariables,
  DeleteHubSpotToken,
  HubSpotToken
} from '../../../../../__generated__/types';
import Loading from '../../../../../components/Loading/Loading';
import { HUBSPOT_TOKEN } from '../../../../../graphql/queries';
import { CREATE_HUBSPOT_TOKEN, DELETE_HUBSPOT_TOKEN } from '../../../../../graphql/mutations';
import FormAlert from '../../../../../components/FormAlert/FormAlert';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';

export default function HubSpotIntegration() {
  const history = useHistory();
  const { data: { hubSpotToken = null } = {}, loading: fetchTokenLoading } =
    useQuery<HubSpotToken>(HUBSPOT_TOKEN);
  const [createHubSpotToken, { error, loading: createTokenLoading }] = useMutation<
    CreateHubSpotToken,
    CreateHubSpotTokenVariables
  >(CREATE_HUBSPOT_TOKEN, {
    // Clear the query params
    onCompleted: () => history.push('/integrations/hubspot'),
    refetchQueries: [{ query: HUBSPOT_TOKEN }]
  });
  const [deleteHubSpotToken, { error: deleteError, loading: deleteLoading }] =
    useMutation<DeleteHubSpotToken>(DELETE_HUBSPOT_TOKEN, {
      update: (cache) =>
        cache.writeQuery({
          query: HUBSPOT_TOKEN,
          data: { hubSpotToken: null }
        })
    });

  React.useEffect(() => {
    const { code } = qs.parse(window.location.search);
    if (code && typeof code === 'string') {
      createHubSpotToken({ variables: { input: { code } } });
    }
  }, []);

  if (createTokenLoading || deleteLoading || fetchTokenLoading) {
    return <Loading />;
  }

  return (
    <Grid container direction="row" spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">HubSpot Integration</Typography>
        <Divider light />
      </Grid>
      {error && <FormAlert error={error} />}
      {!hubSpotToken && (
        <Fragment>
          <Grid item xs={12}>
            Your RevSend user account is currently not connected to HubSpot.
            <br />
          </Grid>
          <Grid item xs>
            <Link
              href={
                'https://app.hubspot.com/oauth/authorize?client_id=9e99c2cf-bbf3-4263-adcb-4f20dc2dd357&redirect_uri=https://app.revsend.com/dashboard/integrations/hubspot&scope=contacts%20timeline%20oauth'
              }
            >
              Connect with HubSpot
            </Link>
          </Grid>
        </Fragment>
      )}
      {hubSpotToken && (
        <Fragment>
          <Grid item xs={12}>
            <Typography>
              <DoneIcon />
              &nbsp;You are connected to HubSpot!
            </Typography>
            <br />
            <Typography variant={'body2'} gutterBottom>
              Connected to HubSpot user: {hubSpotToken.user}.
            </Typography>
            <Typography variant={'body2'} gutterBottom>
              Connected domain: {hubSpotToken.hubDomain}.
            </Typography>
          </Grid>
          <Grid item xs>
            <Button variant={'outlined'} color={'secondary'} onClick={() => deleteHubSpotToken()}>
              Disconnect from HubSpot
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}
