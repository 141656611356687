import { ApolloCache, ApolloClient } from '@apollo/client';
import { getInitialCache } from './client';
import { initializeCache } from './cacheUpdates';
import {
  IS_LOGGED_IN,
  LOGIN_PAGE,
  SELECTED_HUBSPOT_CONTACT,
  STOREFRONT_COLLECTION,
  STOREFRONT_COLLECTIONS
} from './queries';
import {
  Gift,
  SelectedHubSpotContact,
  StorefrontCollection,
  StorefrontCollections
} from '../__generated__/types';
import storefrontClient, { Collection } from './storefrontClient';
import { Product } from 'shopify-buy';
import { gql } from '@apollo/client/core';

export const resolvers = {
  Mutation: {
    logout: async (_: any, __: any, { cache }: ApolloClient<unknown>) => {
      // Remove the token
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
      return cache.reset().then(() => {
        cache.writeQuery({
          query: LOGIN_PAGE,
          data: {
            // This is necessary to get other redirects to work.
            // A null LOGIN_PAGE will try to redirect based on current route.
            loginPage: {
              message: 'Successfully logged out',
              isSuccess: true,
              redirectRoute: '/dashboard',
              __typename: 'LoginPage'
            }
          }
        });
        cache.writeQuery({
          query: IS_LOGGED_IN,
          data: { isLoggedIn: false }
        });
        return true;
      });
    }
  },
  Query: {
    selectedHubSpotContact: (_: any, __: any, { cache }: ApolloClient<unknown>) => {
      const foo = storefrontClient.product;
      return cache.readQuery<SelectedHubSpotContact>({ query: SELECTED_HUBSPOT_CONTACT });
    },
    /**
     *
     * @param parent
     * @param args
     * @param cache
     */
    storefrontCollections: async (
      parent: any,
      args: any,
      { cache }: ApolloClient<unknown>
    ): Promise<void> => {
      // Note: Uses the custom defined Collection, since @types/shopify-buy is incorrect / vague
      const collections: Array<Collection> = await storefrontClient.collection.fetchAll(100);
      cache.writeQuery<StorefrontCollections>({
        query: STOREFRONT_COLLECTIONS,
        data: {
          storefrontCollections: collections.map((collection) => ({
            ...collection,
            __typename: 'StorefrontCollection'
          }))
        }
      });
    },
    storefrontCollection: async (
      parent: any,
      args: { id: string },
      { cache }: ApolloClient<unknown>
    ): Promise<void> => {
      const collection = (await storefrontClient.collection.fetchWithProducts(
        args.id
      )) as unknown as Collection;
      const data = {
        storefrontCollection: {
          ...collection,
          products:
            collection.products?.map((product) => ({
              ...product,
              id: String(product.id),
              __typename: 'StorefrontProduct' as 'StorefrontProduct'
            })) ?? [],
          __typename: 'StorefrontCollection' as 'StorefrontCollection'
        }
      };
      console.log(data);
      cache.writeQuery<StorefrontCollection>({
        query: STOREFRONT_COLLECTION,
        variables: { id: collection.id },
        data
      });
    }
  },
  StorefrontCollection: {
    products: async (
      collection: Collection,
      args: any,
      { cache }: ApolloClient<unknown>
    ): Promise<void> => {
      if (collection.products && collection.products.length > 0) {
        return;
      }
      // Find the products from shopify
      const collectionWithProducts = (await storefrontClient.collection.fetchWithProducts(
        collection.id
        // Handle incorrect typings with @types/shopify-buy
      )) as unknown as Collection;

      // Update the field in the cache
      cache.modify({
        id: cache.identify({ __typename: 'StorefrontCollection', id: collection.id }),
        fields: {
          products: () => collectionWithProducts.products ?? []
        }
      });
    }
  }
};
