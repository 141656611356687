import React from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';

export default function RedirectWithSearch(props: RedirectProps) {
  const location = useLocation();
  let toWithSearch = props.to;
  if (location.search) {
    if (typeof props.to === 'string') {
      toWithSearch = props.to + (location.search || '');
    } else if (typeof props.to === 'object') {
      toWithSearch = { ...props.to, search: location.search };
    }
  }

  return <Redirect {...props} to={toWithSearch} />;
}
