import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { resolvers } from './resolvers';
import { typeDefs, typePolicies } from './typeDefs';
import { IS_LOGGED_IN, LOGIN_PAGE } from './queries';
import { LoginPage } from '../__generated__/types';
import { datadogLogs } from '@datadog/browser-logs';
import { GraphQLError } from 'graphql';
import { maybeBustCache } from '../helpers/cacheBuster';
import { initializeCache } from './cacheUpdates';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { stringify } from 'flatted';

datadogLogs.init({
  clientToken: 'pub53f84efba5843b89245a59ef69c0befd',
  forwardErrorsToLogs: true,
  sampleRate: 100
});

export const logger = datadogLogs.logger;

export const getInitialCache = () => ({
  isLoggedIn: !!localStorage.getItem('token'),
  selectedSalesForceContact: null,
  isCreateSalesForceTaskChecked: null,
  loginPage: null
});

export const checkForAuthenticationError = (
  graphQLErrors?: ReadonlyArray<GraphQLError>
): boolean => {
  return Boolean(
    graphQLErrors &&
      graphQLErrors.find((graphQLError) => {
        if (!graphQLError.extensions) return false;
        return Object.entries(graphQLError.extensions).find(
          ([key, value]) =>
            (key === 'code' && value === 'UNAUTHENTICATED') ||
            (key === 'name' && value === 'AuthenticationError')
        );
      })
  );
};

export const getErrorCode = (graphQLError?: GraphQLError): string | null => {
  if (!(graphQLError && graphQLError.extensions)) {
    return null;
  }
  return graphQLError.extensions.code;
};

const revokeAuthentication = (client: ApolloClient<unknown>) => {
  localStorage.clear();
  client.cache.reset().then(() => {
    initializeCache(client.cache);
    client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: false } });
    client.writeQuery<LoginPage>({
      query: LOGIN_PAGE,
      data: {
        loginPage: {
          __typename: 'LoginPage',
          message: 'Your session has expired. Please login.',
          isSuccess: null,
          redirectRoute: '/dashboard'
        }
      }
    });
  });
};

/**
 * Omit-deep the value '__typename'.
 */
const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitted = (key: string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitted);
  }
  return forward(operation).map((data: any) => {
    return data;
  });
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE
});

const setHeadersLink = setContext(async (_, { headers }) => {
  const version = await maybeBustCache();
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
      'client-name': 'rs-client',
      'client-version': version
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  // Log each error
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      if (getErrorCode(graphQLError) === 'BAD_USER_INPUT') {
        datadogLogs.logger.info(stringify(graphQLError));
      } else {
        datadogLogs.logger.error(stringify(graphQLError));
      }
    });
  }
  if (networkError) {
    datadogLogs.logger.error(stringify(networkError));
  }
  // Logout if Authentication error
  if (checkForAuthenticationError(graphQLErrors)) {
    revokeAuthentication(client);
  }
  return forward(operation);
});

export const client = new ApolloClient({
  link: ApolloLink.from([omitTypenameLink, errorLink, setHeadersLink, httpLink]),
  typeDefs,
  resolvers,
  cache: initializeCache(
    new InMemoryCache({
      typePolicies
    })
  )
});
