import { useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import Loading from 'components/Loading/Loading';
import { useValidateAddressDialog } from 'components/ValidateAddressDialog/ValidateAddressDialog';
import { REDEEM_GIFTCARD, SEND_CREATE_FROM_CAMPAIGN, UPDATE_SEND } from 'graphql/mutations';
import { CAMPAIGN, CHARITY_GIFTS, RECIPIENT_VIEW_SEND } from 'graphql/queries';
import { formatDollars } from 'helpers/formatters';
import { Topbar } from 'layouts/Minimal/components';
import { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm, Controller, set } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import theme, { Theme } from 'theme';
import { getContrastTextColor } from 'views/Settings/Branding/utils';
import {
  Campaign,
  CampaignVariables,
  CharityGifts,
  DeliveryType,
  PlacementMethod,
  RecipientViewSend,
  RecipientViewSendVariables,
  RedeemGiftCard,
  RedeemGiftCardVariables,
  SendCreateFromCampaign,
  SendCreateFromCampaignVariables,
  SendStatus,
  UpdateSend,
  ValidateAddress_validateAddress
} from '__generated__/types';
import { AddressFormValues } from '../../SendGift/Product/AddressForm';
import CloseIcon from '@material-ui/icons/Close';
import { VISA_TERMS, VISA_TERMS_URL, VISA_TERMS_URL_LABEL } from 'helpers/gift';
import ActionButton from '../ActionButton';
import { useInputStyles } from '../inputStyles';
import { getGiftCardOptions } from './helpers';
import { getMessagesFromApolloError } from 'graphql/utils';

type StyleProps = {
  logoBackgroundColor: string;
  logoPrimaryColor: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  main: {
    padding: theme.spacing(4)
  },
  giftCardIcon: {
    position: 'absolute',
    margin: `-18px auto 0 auto`,
    left: 0,
    right: 0,
    width: 40,
    color: 'white',
    backgroundColor: '#bdbdbd'
  },
  giftIconAvatar: {
    position: 'absolute'
  },
  logoImg: {
    maxWidth: 200,
    margin: 16,
    flex: '1 0 auto'
  },
  header: {
    backgroundColor: (props) => props.logoBackgroundColor,
    color: (props) => getContrastTextColor(props.logoBackgroundColor),
    display: 'flex',
    justifyContent: 'center'
  },
  actionButton: {
    backgroundColor: (props) => props.logoPrimaryColor,
    color: (props) => getContrastTextColor(props.logoPrimaryColor)
  },
  giftCardPaper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  giftDetails: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1)
  },
  giftInstructions: {
    padding: theme.spacing(1)
  },
  message: {
    fontFamily: `'Caveat', cursive`,
    fontSize: 22,
    whiteSpace: 'pre-wrap'
  },
  columnContainer: {
    maxWidth: 500
  }
}));

type CampaignRedeemFormFields = {
  spendChoice: 'accept' | 'donate';
  selectedGiftId: string;
  selectedVariantId: string;
  email: string;
};

// Route: /redeem/:campaignId
const DigitalCampaignLanding: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [redirecting, setRedirecting] = React.useState<boolean>(false);
  const [createdSendId, setCreatedSendId] = React.useState<undefined | string>();
  const [isCreatedSendCharity, setIsCreatedSendCharity] = React.useState<boolean>(false);

  const {
    data: { campaign } = {},
    error,
    loading
  } = useQuery<Campaign, CampaignVariables>(CAMPAIGN, {
    variables: {
      id: campaignId
    }
  });
  const {
    data: { charityGifts } = {},
    error: charityGiftsError,
    loading: charityGiftsLoading
  } = useQuery<CharityGifts>(CHARITY_GIFTS);
  const [redeemGiftCard, { loading: redeemLoading }] = useMutation<
    RedeemGiftCard,
    RedeemGiftCardVariables
  >(REDEEM_GIFTCARD, {
    onCompleted: () => {
      if (createdSendId) {
        enqueueSnackbar('Success', { variant: 'success' });
        setRedirecting(true);
        window.location.href = '/view/' + createdSendId;
      }
    },
    onError: () => {
      enqueueSnackbar(
        'There was an issue with this reward. Try again, or contact support to redeem your card.',
        { variant: 'error' }
      );
    }
  });

  const [sendCreateFromCampaign, { loading: sendCreateLoading, error: sendCreateError }] =
    useMutation<SendCreateFromCampaign, SendCreateFromCampaignVariables>(
      SEND_CREATE_FROM_CAMPAIGN,
      {
        onCompleted: ({ sendCreateFromCampaign }) => {
          setCreatedSendId(sendCreateFromCampaign.id);
          if (!sendCreateFromCampaign.gift?.isCharity) {
            redeemGiftCard({ variables: { input: { redemptionCode: sendCreateFromCampaign.id } } });
            return;
          }
          setRedirecting(true);
          window.location.href = '/view/' + sendCreateFromCampaign.id;
        },
        onError: (error) =>
          enqueueSnackbar(getMessagesFromApolloError(error), { variant: 'error', persist: true })
      }
    );

  const { control, watch, handleSubmit, setValue } = useForm<CampaignRedeemFormFields>({
    defaultValues: {
      spendChoice: 'accept',
      selectedGiftId: '',
      selectedVariantId: '',
      email: ''
    }
  });
  const creator = campaign?.creator;
  const org = campaign?.creator.org;
  const logoBackgroundColor = org?.logoBackgroundColor ?? '#FFFFFF';
  const logoPrimaryColor = org?.logoPrimaryColor ?? theme.palette.primary.main;
  const logoUrl = org?.logoUrl ?? '';
  const contrastTextColor = getContrastTextColor(logoPrimaryColor);
  const classes = useStyles({ logoBackgroundColor, logoPrimaryColor });
  const inputClasses = useInputStyles();

  if (loading || charityGiftsLoading) {
    return (
      <Grid container spacing={10} justifyContent="center" style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  if (error || !org || !creator || !campaign || !campaign.gifts) {
    return (
      <Grid container spacing={10} justifyContent="center" style={{ marginTop: 8 }}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography>
            <Alert severity="warning">
              Campaign not found. Contact RevSend support for help using the chat bubble at the
              bottom of the page
            </Alert>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const giftOptions = getGiftCardOptions({
    maxCostPerGift: campaign.maxCostPerGift,
    gifts: campaign.gifts
  });
  const charityOptions = getGiftCardOptions({
    maxCostPerGift: campaign.maxCostPerGift,
    gifts: charityGifts ?? []
  });

  function onSubmit(values: CampaignRedeemFormFields) {
    console.log(values);
    const selectedGift = giftOptions.find(
      (giftOption) => values.selectedVariantId === giftOption.highestPricedVariant.id
    );
    const selectedCharity = charityOptions.find(
      (giftOption) => values.selectedVariantId === giftOption.highestPricedVariant.id
    );
    const giftId = selectedGift?.giftId || selectedCharity?.giftId;
    if (!giftId) {
      // Todo handle edge case
      return;
    }

    sendCreateFromCampaign({
      variables: {
        input: {
          sendItems: [
            {
              productVariantId: values.selectedVariantId,
              quantity: 1
            }
          ],
          giftId,
          campaignId,
          email: values.email
        }
      }
    });
  }

  return (
    <>
      <main className={classes.main}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item className={classes.columnContainer}>
            <Card>
              {logoUrl ? (
                <header className={clsx(classes.header)}>
                  <img src={logoUrl} alt={org?.formattedName} className={classes.logoImg} />
                </header>
              ) : null}
              <Divider light />
              <Box sx={{ p: 2 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                      <Typography align="center">
                        {creator.name} sent you a {formatDollars(campaign.maxCostPerGift)} reward!
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <br />
                      <Divider light />
                      <br />
                      <Typography color="textSecondary" className={classes.message}>
                        {campaign.message}
                      </Typography>
                      <br />
                      <Divider light />
                      <br />
                      <Typography gutterBottom>
                        If you can't accept this reward, you may choose to donate the proceeds to a
                        charity of your choice.
                      </Typography>
                      <Controller
                        control={control}
                        name="spendChoice"
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Grid container justifyContent="center">
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value={'accept'}
                                  control={<Radio />}
                                  label="Accept this reward"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                {' '}
                                <FormControlLabel
                                  value={'donate'}
                                  control={<Radio />}
                                  label="Donate to charity"
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        )}
                      />
                      <br />
                      <Divider light />
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom>
                        Choose your {watch('spendChoice') === 'donate' ? 'charity' : 'reward'} by
                        selecting one of the options below
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="selectedVariantId"
                        render={({ field }) => (
                          <>
                            <RadioGroup {...field}>
                              {watch('spendChoice') === 'accept' ? (
                                <List style={{ maxHeight: 400, overflow: 'scroll' }}>
                                  {giftOptions.map((giftOption) => {
                                    const id = giftOption.highestPricedVariant.id;
                                    const price = giftOption.highestPricedVariant.price;
                                    const isChecked = watch('selectedVariantId') === id;
                                    const labelId = `checkbox-list-secondary-label-${giftOption.highestPricedVariant.id}`;
                                    return (
                                      <ListItem
                                        style={{
                                          marginBottom: 8,
                                          border: `${isChecked ? '2px' : '1px'} solid ${
                                            isChecked ? 'black' : 'lightgrey'
                                          }`,
                                          borderRadius: 4
                                        }}
                                        key={giftOption.highestPricedVariant?.id}
                                        button
                                        onClick={() => setValue('selectedVariantId', id)}
                                      >
                                        <ListItemAvatar>
                                          <img
                                            style={{
                                              marginRight: `12px`,
                                              maxWidth: 100,
                                              maxHeight: 50
                                            }}
                                            alt={giftOption.title}
                                            src={giftOption.imageUrl}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          id={labelId}
                                          secondary={formatDollars(price)}
                                          primary={
                                            giftOption.title + (isChecked ? ' (Selected)' : '')
                                          }
                                        />
                                        <ListItemIcon>
                                          <Radio
                                            required
                                            value={id}
                                            aria-labelledby={giftOption.title}
                                          />
                                        </ListItemIcon>
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              ) : (
                                <>
                                  <List style={{ maxHeight: 400, overflow: 'scroll' }}>
                                    {charityOptions.map((giftOption) => {
                                      const id = giftOption.highestPricedVariant.id;
                                      const price = giftOption.highestPricedVariant.price;
                                      const isChecked = watch('selectedVariantId') === id;
                                      const labelId = `checkbox-list-secondary-label-${giftOption.highestPricedVariant.id}`;
                                      return (
                                        <ListItem
                                          key={giftOption.highestPricedVariant?.id}
                                          button
                                          onClick={() => setValue('selectedVariantId', id)}
                                          style={{
                                            marginBottom: 8,
                                            border: `${isChecked ? '2px' : '1px'} solid ${
                                              isChecked ? 'black' : 'lightgrey'
                                            }`,
                                            borderRadius: 4
                                          }}
                                        >
                                          <ListItemAvatar>
                                            <img
                                              alt={giftOption.title}
                                              src={giftOption.imageUrl}
                                              style={{
                                                marginRight: `12px`,
                                                maxWidth: 100,
                                                maxHeight: 50
                                              }}
                                            />
                                          </ListItemAvatar>
                                          <ListItemText
                                            id={labelId}
                                            secondary={formatDollars(price)}
                                            primary={
                                              giftOption.title +
                                              (watch('selectedVariantId') === id
                                                ? ' (Selected)'
                                                : '')
                                            }
                                          />
                                          <ListItemIcon>
                                            <Radio
                                              required
                                              value={id}
                                              aria-labelledby={giftOption.title}
                                            />
                                          </ListItemIcon>
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </>
                              )}
                            </RadioGroup>
                          </>
                        )}
                      />

                      <br />
                      <Divider light />
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom>
                        Confirm your email. This email must match an email valid for this reward.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <label className={inputClasses.neutralLabel}>Email</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={'email'}
                            required
                            className={inputClasses.neutralInput}
                            maxLength={100}
                            {...field}
                          />
                        )}
                      />
                      <br />
                      <Divider light />
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <ActionButton
                        logoPrimaryColor={logoPrimaryColor}
                        contrastTextColor={contrastTextColor}
                        loading={sendCreateLoading || redeemLoading || redirecting}
                        actionText={watch('spendChoice') === 'accept' ? 'Redeem' : 'Donate'}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default DigitalCampaignLanding;
