import gql from 'graphql-tag';
import { Send } from './queries';

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      tokens {
        key
        value
      }
    }
  }
`;

export const LOGIN_AS_USER = gql`
  mutation LoginAsUser($input: LoginAsUserInput!) {
    loginAsUser(input: $input) {
      tokens {
        key
        value
      }
    }
  }
`;

export const ACCEPT_INVITE = gql`
  mutation AcceptInvite($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      tokens {
        key
        value
      }
    }
  }
`;

export const REVOKE_INVITE = gql`
  mutation RevokeInvite($input: RevokeInviteInput!) {
    revokeInvite(input: $input)
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
    }
  }
`;

export const SAVE_NOTIFICATION_SETTINGS = gql`
  mutation UpsertNotificationSettings($input: UpsertNotificationSettingsInput!) {
    upsertNotificationSettings(input: $input) {
      id
      channel
      onSendCreated
      onSendStatusRedeemed
      onSendStatusDelivered
      onSendStatusCanceled
      onSendStatusShipped
    }
  }
`;

export const CREATE_SEND = gql`
  mutation CreateSend($input: CreateSendInput!) {
    createSend(input: $input) {
      ...SendSummary
    }
  }
  ${Send.fragments.sendSummary}
`;

export const UPDATE_BUDGET = gql`
  mutation UpdateBudget($input: UpdateBudgetInput!) {
    updateBudget(input: $input) {
      id
      isRollover
      amount
      refillAmount
      frequency
    }
  }
`;

export const UPDATE_CARD = gql`
  mutation UpdateCard($input: UpdateCardInput!) {
    updateCard(input: $input) {
      id
      card {
        id
        last4
        brand
      }
    }
  }
`;

export const ADD_CREDIT = gql`
  mutation AddCredit($dollars: Float) {
    addCredit(dollars: $dollars)
  }
`;

export const CREATE_SLACK_TOKEN = gql`
  mutation CreateSlackToken($input: CreateSlackTokenInput!) {
    createSlackToken(input: $input)
  }
`;

export const CREATE_SALESFORCE_TOKEN = gql`
  mutation CreateSalesForceToken($input: CreateSalesForceTokenInput) {
    createSalesForceToken(input: $input) {
      id
      instanceUrl
    }
  }
`;

export const CREATE_HUBSPOT_TOKEN = gql`
  mutation CreateHubSpotToken($input: CreateHubSpotTokenInput!) {
    createHubSpotToken(input: $input) {
      user
      hubSpotUserId
      appId
      hubId
      hubDomain
    }
  }
`;

export const UNLINK_SALESFORCE = gql`
  mutation UnlinkSalesForce {
    unlinkSalesForce
  }
`;

export const DELETE_HUBSPOT_TOKEN = gql`
  mutation DeleteHubSpotToken {
    deleteHubSpotToken
  }
`;

export const SEND_HANDWRITTEN_NOTE = gql`
  mutation SendHandwrittenNote($input: SendHandwrittenNoteInput) {
    sendHandwrittenNote(input: $input)
  }
`;

export const REDEEM_GIFTCARD = gql`
  mutation RedeemGiftCard($input: RedeemGiftCardInput!) {
    redeemGiftCard(input: $input)
  }
`;

export const UPDATE_ORG = gql`
  mutation UpdateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      address {
        id
        name
        street1
        street2
        city
        state
        zip
      }
      settings {
        id
        requireSalesForceContact
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput) {
    requestPasswordReset(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      tokens {
        key
        value
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      status
      name
      salesForceUserId
    }
  }
`;

export const LOGIN_AS_ORG = gql`
  mutation LoginAsOrg($input: LoginAsOrgInput!) {
    loginAsOrg(input: $input) {
      tokens {
        key
        value
      }
    }
  }
`;

export const UPDATE_SEND = gql`
  mutation UpdateSend($input: UpdateSendInput!) {
    updateSend(input: $input) {
      id
      status
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input)
  }
`;

export const ASSIGN_USER_TO_TEAM = gql`
  mutation AssignUserToTeam($input: AssignUserToTeamInput!) {
    assignUserToTeam(input: $input) {
      id
      users {
        id
      }
    }
  }
`;

export const UPDATE_INVITATION = gql`
  mutation UpdateInvitation($input: UpdateInvitationInput!) {
    updateInvitation(input: $input) {
      id
      email
    }
  }
`;

export const RESEND_INVITATION_EMAIL = gql`
  mutation ResendInvitationEmail($input: IdInput!) {
    resendInvitationEmail(input: $input)
  }
`;

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($input: IdInput!) {
    deleteInvitation(input: $input)
  }
`;

export const CREATE_INVOICE_ITEM = gql`
  mutation CreateInvoiceItem($input: CreateInvoiceItemInput!) {
    createInvoiceItem(input: $input) {
      id
    }
  }
`;

export const GIFT_CREATE = gql`
  mutation GiftCreate($input: GiftInput!) {
    giftCreate(input: $input) {
      id
      title
      description
    }
  }
`;

export const GIFT_REQUEST_NEW = gql`
  mutation GiftRequestNew($input: GiftRequestNewInput!) {
    giftRequestNew(input: $input)
  }
`;

export const GIFT_DELETE = gql`
  mutation GiftDelete($id: ID!) {
    giftDelete(id: $id)
  }
`;

export const GIFT_UPDATE = gql`
  mutation GiftUpdate($input: GiftInput!, $id: ID!) {
    giftUpdate(input: $input, id: $id) {
      id
      title
      description
    }
  }
`;

export const VALIDATE_ADDRESS = gql`
  mutation ValidateAddress($input: CreateAddressInput!) {
    validateAddress(input: $input) {
      id
      name
      street1
      street2
      city
      state
      zip
    }
  }
`;

export const CREATE_GIFT_CARD_BARCODE = gql`
  mutation CreateGiftCardBarcode($input: CreateGiftCardBarcodeInput!) {
    createGiftCardBarcode(input: $input) {
      id
      barcodeKind
      barcodeValue
      number
      csc
    }
  }
`;

export const ASSET_UPLOAD_URL_CREATE = gql`
  mutation AssetUploadUrlCreate($input: AssetUploadUrlInput!) {
    assetUploadUrlCreate(input: $input) {
      url
      key
    }
  }
`;

export const MESSAGE_TEMPLATE_UPDATE = gql`
  mutation MessageTemplateUpdate($id: ID!, $input: MessageTemplateInput!) {
    messageTemplateUpdate(id: $id, input: $input) {
      id
    }
  }
`;

export const MESSAGE_TEMPLATE_CREATE = gql`
  mutation MessageTemplateCreate($input: MessageTemplateInput!) {
    messageTemplateCreate(input: $input) {
      id
    }
  }
`;

export const MESSAGE_TEMPLATE_DELETE = gql`
  mutation MessageTemplateDelete($id: ID!) {
    messageTemplateDelete(id: $id)
  }
`;

export const SEND_CREATE_FROM_CAMPAIGN = gql`
  mutation SendCreateFromCampaign($input: SendCreateFromCampaignInput!) {
    sendCreateFromCampaign(input: $input) {
      id
      gift {
        id
        isCharity
      }
    }
  }
`;

export const CAMPAIGN_CREATE = gql`
  mutation CampaignCreate($input: CampaignInput!) {
    campaignCreate(input: $input) {
      id
    }
  }
`;

export const CAMPAIGN_UPDATE = gql`
  mutation CampaignUpdate($id: ID!, $input: CampaignInput!) {
    campaignUpdate(id: $id, input: $input) {
      id
      message
      recipients {
        id
        name
        email
        salesForceAccountId
        salesForceContactId
        hubSpotContactId
        hubSpotCompanyId
      }
      gifts {
        id
        title
      }
      expiresAt
      name
    }
  }
`;

export const CAMPAIGN_ADD_RECIPIENTS = gql`
  mutation CampaignAddRecipient($input: CampaignAddRecipientsInput!) {
    campaignAddRecipients(input: $input) {
      id
    }
  }
`;

export const SEND_GIFTCODE_EMAIL = gql`
  mutation SendGiftCodeEmail($sendId: ID!) {
    sendGiftBarcodeEmail(sendId: $sendId)
  }
`;
