import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import moment from 'moment-timezone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ScrollingList from '../../../../components/ScrollingList/ScrollingList';
import { UpcomingInvoice_upcomingInvoice } from '../../../../__generated__/types';
import { formatDollars } from '../../../../helpers/formatters';
export type UpcomingInvoiceSummaryProps = {
  invoice: UpcomingInvoice_upcomingInvoice | null;
  title: string;
  note?: string | null;
};

export default function UpcomingInvoiceSummary({
  invoice,
  title,
  note
}: UpcomingInvoiceSummaryProps) {
  return (
    <Fragment>
      <Card>
        <CardHeader title={title} />
        <Divider light />
        <CardContent>
          {note && (
            <Fragment>
              <Alert severity={'info'}>{note}</Alert>
              <br />
            </Fragment>
          )}
          {invoice && (
            <Fragment>
              <Typography variant="body2" color="textSecondary">
                Finalized on:{' '}
                {moment(invoice.periodEnd).tz(moment.tz.guess()).format(`MMM. Do YYYY`)}
              </Typography>
              <ScrollingList dense>
                {invoice.lines.map((invoiceItem) => (
                  <ListItem key={invoiceItem.id}>
                    <ListItemText primary={invoiceItem.description} />
                    <Typography variant="body2">{formatDollars(invoiceItem.dollars)}</Typography>
                  </ListItem>
                ))}
              </ScrollingList>
              <List>
                <Divider light />
                <ListItem>
                  <ListItemText primary={'Total'} />
                  <Typography variant="body2">{formatDollars(invoice.totalDollars)}</Typography>
                </ListItem>
                <Divider light />
                <ListItem>
                  <ListItemText
                    primary={'Amount Due'}
                    secondary={
                      invoice.amountDueDollars
                        ? undefined
                        : `✔ This invoice is pre-paid with credited funds`
                    }
                  />
                  <Typography variant="body1">{formatDollars(invoice.amountDueDollars)}</Typography>
                </ListItem>
              </List>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </Fragment>
  );
}
