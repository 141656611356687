import { ApolloError, FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client/react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import { VALIDATE_ADDRESS } from '../../graphql/mutations';
import {
  ValidateAddress,
  ValidateAddressVariables,
  ValidateAddress_validateAddress
} from '../../__generated__/types';
import { AddressFormVars } from '../AddressForm/AddressForm';
import FormAlert from '../FormAlert/FormAlert';

export function useValidateAddressDialog(): {
  // The mutation hook for validating an address
  validateAddress: ({
    variables
  }: {
    variables: ValidateAddressVariables;
  }) => Promise<FetchResult<ValidateAddress>>;

  // Render method for the dialoge
  renderValidateAddressDialog: ({
    onAccept,
    userAddress
  }: {
    onAccept: (address: ValidateAddress_validateAddress) => void;
    userAddress: AddressFormVars;
  }) => React.ReactElement;
} {
  const [validateAddress, { loading, error, data }] = useMutation<
    ValidateAddress,
    ValidateAddressVariables
  >(VALIDATE_ADDRESS);

  return {
    validateAddress,
    renderValidateAddressDialog: ({ onAccept, userAddress }) => (
      <ValidateAddressDialog
        onAccept={onAccept}
        loading={loading}
        error={error}
        data={data}
        userAddress={userAddress}
      />
    )
  };
}

type ValidateAddressDialogProps = {
  userAddress: AddressFormVars;
  onAccept: (address: ValidateAddress_validateAddress) => void;
  loading?: boolean;
  error?: ApolloError;
  data?: ValidateAddress | null;
};
export default function ValidateAddressDialog({
  onAccept,
  loading,
  error,
  data,
  userAddress
}: ValidateAddressDialogProps) {
  // Local State
  const [open, setOpen] = React.useState<boolean>(false);

  // Effects
  // Open the dialog whenever a request is being made
  React.useEffect(() => {
    if (loading) {
      setOpen(true);
    }
  }, [loading]);

  // Event Handlers
  function handleAcceptMatchedAddress() {
    if (data?.validateAddress) {
      onAccept(data?.validateAddress);
      setOpen(false);
    }
  }

  return (
    <>
      <ValidateAddressDialogComponent
        onAccept={handleAcceptMatchedAddress}
        loading={loading}
        error={error}
        data={data}
        open={open}
        setOpen={setOpen}
        userAddress={userAddress}
      />
    </>
  );
}

type ValidateAddressDialogComponentProps = ValidateAddressDialogProps & {
  userAddress: AddressFormVars;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export function ValidateAddressDialogComponent({
  onAccept,
  loading,
  error,
  data,
  userAddress,
  open,
  setOpen
}: ValidateAddressDialogComponentProps) {
  const validatedAddress = data?.validateAddress;
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Address Validation Required</DialogTitle>
        <Divider light />
        <DialogContent>
          {loading ? (
            'Loading...'
          ) : (
            <>
              {Boolean(validatedAddress) && (
                <Grid container spacing={4} justify={'flex-start'} alignItems={'flex-start'}>
                  <Grid item xs={12}>
                    <Alert severity={'success'}>
                      We were able match the address you submitted with a validated address
                    </Alert>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction={'column'} spacing={1}>
                      <Grid item>You submitted</Grid>
                      <Divider light />
                      <Grid item>
                        {userAddress.name}
                        <br />
                        {userAddress.street1}
                        <br />
                        {userAddress.street2 ? ` ${userAddress.street2}` : ''}
                        <br />
                        {userAddress.city}, {userAddress.state} {userAddress.zip}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction={'column'} spacing={1}>
                      <Grid item>Validated Address ✅</Grid>
                      <Divider light />
                      {validatedAddress && (
                        <Grid item>
                          {validatedAddress.name}
                          <br />
                          {validatedAddress.street1}
                          <br />
                          {validatedAddress.street2 ? ` ${validatedAddress.street2}` : ''}
                          <br />
                          {validatedAddress.city}, {validatedAddress.state} {validatedAddress.zip}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <br />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {Boolean(error) && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormAlert error={error} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item>You submitted</Grid>
                    <Divider light />
                    <Grid item>
                      <br />
                      {userAddress.name}
                      <br />
                      {userAddress.street1}
                      <br />
                      {userAddress.street2 ? ` ${userAddress.street2}` : ''}
                      <br />
                      {userAddress.city}, {userAddress.state} {userAddress.zip}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    Please double check your address and update. If you believe this address is
                    correct, contact support for help.
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {data?.validateAddress && (
            <>
              <Button onClick={() => setOpen(false)}>Cancel</Button>

              <Button
                color={'primary'}
                variant={'contained'}
                onClick={() => onAccept(data.validateAddress)}
              >
                Continue with Validated Address
              </Button>
            </>
          )}
          {error && (
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
