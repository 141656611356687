import React from 'react';
import { RecipientFormValues } from '../Recipient/RecipientForm';

export default function useRecipientForm() {
  return React.useState<RecipientFormValues>({
    name: '',
    email: '',
    orgName: '',
    salesForceContactId: '',
    salesForceAccountId: '',
    isCreateSalesForceTaskChecked: false,
    hubSpotContactId: '',
    hubSpotCompanyId: '',
    isCreateHubSpotTaskChecked: false
  });
}
