import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TodayIcon from '@material-ui/icons/Today';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { formatDate, formatDollars } from '../../../../helpers/formatters';
import {
  SubscriptionType,
  UpcomingInvoice,
  UpcomingInvoiceVariables
} from '../../../../__generated__/types';
import { UPCOMING_INVOICE } from '../../../../graphql/queries';
import BudgetChargeDetail from '../../Budgets/components/BudgetChargeDetail/BudgetChargeDetail';

export default function ScheduledCharges() {
  const {
    data: { upcomingInvoice: saasInvoice = null } = {},
    loading: saasInvoiceLoading,
    error: saasInvoiceError
  } = useQuery<UpcomingInvoice, UpcomingInvoiceVariables>(UPCOMING_INVOICE, {
    variables: { input: { subscriptionType: SubscriptionType.SAAS } }
  });

  return (
    <Fragment>
      <Card>
        <CardHeader title={'Scheduled Charges'} />
        <Divider light />
        <CardContent>
          <BudgetChargeDetail />
          {saasInvoice && (
            <List>
              <ListItem>
                <ListItemIcon>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText
                  primary={formatDate(saasInvoice.periodEnd) + ' - Subscription Fee'}
                  secondary={'See users invoice below for details'}
                />
                {formatDollars(saasInvoice.totalDollars)}
              </ListItem>
            </List>
          )}
        </CardContent>
      </Card>
    </Fragment>
  );
}
