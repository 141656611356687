import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

export type MenuItemCardProps = {
  title: string;
  description: string;
  icon: React.ReactElement;
  link: string;
  locked?: boolean;
};

export default function MenuItemCard(props: MenuItemCardProps) {
  const classes = useStyles();
  const history = useHistory();
  function handleClick() {
    if (props.locked) {
      return;
    }
    history.push(props.link);
  }
  return (
    <Card className={classes.card} onClick={handleClick} elevation={1}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar className={classes.avatar} variant="square">
              {props.icon}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Link variant={'body1'} color={props.locked ? 'textSecondary' : 'primary'}>
              {props.title}
            </Link>
            <br />
            {props.locked ? (
              <Typography variant="body2" color="textSecondary">
                Talk to your admin to gain permission to access this.
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                {props.description}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  }
}));
