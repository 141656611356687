import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment-timezone';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ScrollingList from '../../../../components/ScrollingList/ScrollingList';
import { formatDollars } from '../../../../helpers/formatters';
import { Charges_charges } from '../../../../__generated__/types';

export type CreditAddSummaryProps = {
  charges: Charges_charges[] | null;
  periodStart: Date;
};

export default function CreditAddSummary({ charges, periodStart }: CreditAddSummaryProps) {
  return (
    <Card>
      <CardHeader
        title="Credit Additions"
        subheader={`From ${moment(periodStart).utc().format(`MMM 'YY`)} to present`}
      />
      <Divider light />
      <CardContent>
        <ScrollingList dense>
          {!charges || !charges.length ? (
            <ListItem>
              <ListItemText primary={'No credit additions for this period'} />
            </ListItem>
          ) : (
            charges.map((charge) => (
              <ListItem key={charge.id}>
                <ListItemText
                  primary={charge.description}
                  secondary={moment(charge.created).format('MMMM Do, YYYY')}
                />
                <Typography variant="body2">{formatDollars(charge.dollars)}</Typography>
              </ListItem>
            ))
          )}
        </ScrollingList>
      </CardContent>
    </Card>
  );
}
