import React, { Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SendAnalytics from './components/SendAnalytics/SendAnalytics';
import SalesForceAnalytics from './components/SalesForceAnalytics/SalesForceAnalytics';
import UserAnalytics from './components/UserAnalytics/UserAnalytics';
import HubSpotAnalytics from './components/HubSpotAnalytics/HubSpotAnalytics';

export default function Analytics() {
  const tabs = ['Sends', 'SalesForce', 'HubSpot', 'Users'];
  const [tab, setTab] = React.useState(tabs[0]);

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Fragment>
      <Grid container spacing={1} direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Analytics
          </Typography>
        </Grid>
        <Grid item>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            {tabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <Divider light />
          <br />
        </Grid>
        <Grid item xs>
          {tab === tabs[0] && <SendAnalytics />}
          {tab === tabs[1] && <SalesForceAnalytics />}
          {tab === tabs[2] && <HubSpotAnalytics />}
          {tab === tabs[3] && <UserAnalytics />}
        </Grid>
      </Grid>
    </Fragment>
  );
}
