import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { PureQueryOptions } from 'apollo-client';
import { useMutation } from '@apollo/react-hooks';
import {
  CarrierCode,
  SendStatus,
  UpdateSend,
  UpdateSendVariables
} from '../../__generated__/types';
import { UPDATE_SEND } from '../../graphql/mutations';
import CarrierSelect from './CarrierSelect';
import FormAlert from '../../components/FormAlert/FormAlert';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';

type DropShipTrackingFormComponentProps = {
  serverError: any;
  loading: boolean;
} & FormikProps<{ trackingNumber: string; carrierCode: CarrierCode }>;

function DropShipTrackingFormComponent({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  serverError
}: DropShipTrackingFormComponentProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    textCenter: {
      textAlign: 'center'
    }
  }));
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column" alignItems="stretch">
      <Grid item xs className={classes.textCenter}>
        <SendIcon color="secondary" />
      </Grid>
      <Grid item xs>
        <Typography align="center" component="h1" variant="h5">
          Mark Amazon Send as Shipped
        </Typography>
      </Grid>
      {!!serverError && <FormAlert error={serverError} />}
      <Grid item xs>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="column" alignItems="stretch">
            <Grid item xs>
              <PrimaryTextField
                required
                fullWidth
                id="trackingNumber"
                label="Tracking Number"
                name="trackingNumber"
                autoComplete="trackingNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.trackingNumber}
                error={!!(touched.trackingNumber && errors.trackingNumber)}
                helperText={(touched.trackingNumber && errors.trackingNumber) || ' '}
              />
              <CarrierSelect onChange={handleChange} value={values.carrierCode} />
            </Grid>
            <Grid item xs>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Update!
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export type DropShipTrackingFormProps = {
  sendId: string;
  onSuccess: () => void;
  refetchQueries?: Array<string | PureQueryOptions>;
};

export default function DropShipTrackingForm({
  sendId,
  onSuccess,
  refetchQueries
}: DropShipTrackingFormProps) {
  const [updateSend, { loading, error }] = useMutation<UpdateSend, UpdateSendVariables>(
    UPDATE_SEND,
    {
      refetchQueries,
      onCompleted: () => onSuccess()
    }
  );

  return (
    <Formik
      initialValues={{
        trackingNumber: '',
        carrierCode: CarrierCode.UPS
      }}
      onSubmit={(values) =>
        updateSend({
          variables: {
            input: {
              trackingNumber: values.trackingNumber,
              carrierCode: values.carrierCode,
              id: sendId,
              status: SendStatus.SHIPPED_OUTBOUND
            }
          }
        })
      }
      validationSchema={Yup.object().shape({
        trackingNumber: Yup.string().required(),
        carrierCode: Yup.string().required()
      })}
      render={(formikProps) => (
        <DropShipTrackingFormComponent {...formikProps} serverError={error} loading={loading} />
      )}
    />
  );
}
