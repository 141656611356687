import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import Card from '@material-ui/core/Card';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Divider from '@material-ui/core/Divider';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Alert from '@material-ui/lab/Alert';

export const chartColors = ['#4BC0C0', '#36a2eb', '#ffce56', '#ff6384', '#cc65fe'];
export type SendTypesCardProps = {
  sends?: Array<{ giftName: string }> | null;
};
export default function SendTypesCard({ sends = [] }: SendTypesCardProps) {
  const noData = [
    { x: 1, y: 1 },
    { x: 2, y: 2 },
    { x: 3, y: 4 }
  ];
  const groupedSends = groupSendsByName(sends);
  // todo figure out what to render when there is no send data
  return (
    <Card>
      <CardHeader title="Gifts" />
      <Divider light />
      <CardContent>
        <Grid alignItems="center" container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ResponsiveContainer width="100%" height={200}>
              {groupedSends.length ? (
                <PieChart>
                  <Pie
                    dataKey="count"
                    nameKey="giftName"
                    data={groupedSends || []}
                    innerRadius="70%"
                    outerRadius="95%"
                    paddingAngle={5}
                  >
                    {groupedSends.map((entry: any, index: number) => (
                      <Cell fill={chartColors[index % chartColors.length]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value: any, name: any) => [
                      Math.round((Number(value) * 100) / ((sends && sends.length) || 0)) + '%',
                      name
                    ]}
                  />
                </PieChart>
              ) : (
                <PieChart>
                  <Pie
                    dataKey="y"
                    nameKey="x"
                    data={noData}
                    innerRadius="70%"
                    outerRadius="95%"
                    paddingAngle={5}
                  >
                    {noData.map(() => (
                      <Cell fill="#DEDEDE" />
                    ))}
                  </Pie>
                </PieChart>
              )}
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {groupedSends.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Gift</TableCell>
                    <TableCell># Sends</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedSends
                    .sort((a, b) => b.count - a.count)
                    .map((groupedSend) => (
                      <TableRow key={groupedSend.giftName}>
                        <TableCell>{groupedSend.giftName}</TableCell>
                        <TableCell>{groupedSend.count}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              <Alert severity={'info'}>No send data.</Alert>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export type sendTypeCount = { giftName: string; count: number };

function groupSendsByName(sends?: Array<{ giftName: string }> | null): sendTypeCount[] {
  return sends
    ? sends.reduce((groupedSends: sendTypeCount[], send: { giftName: string }) => {
        const groupedSend = groupedSends.find(
          (groupedSend: sendTypeCount) => groupedSend.giftName === send.giftName
        );
        if (groupedSend) {
          groupedSend.count += 1;
          return groupedSends;
        }
        return groupedSends.concat({ giftName: send.giftName, count: 1 });
      }, [])
    : [];
}
