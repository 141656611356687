import palette from '../palette';
import typography from '../typography';
import { colors } from '@material-ui/core';

export default {
  root: {
    ...typography.body1
  },
  head: {
    color: colors.blueGrey[600],
    fontSize: '.8rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    backgroundColor: colors.grey[50]
  }
};
