import React from 'react';
import { UserStatus } from '../../__generated__/types';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import StatusDot, { StatusDotProps } from '../StatusDot/StatusDot';
import { formatUserStatus } from '../../domains/user/formatters';

export type UserStatusComponentProps = {
  status: UserStatus;
  TypographyProps?: TypographyProps;
};

const UserStatusComponent: React.FC<UserStatusComponentProps> = (props) => {
  const { status, TypographyProps } = props;
  return (
    <Typography variant={'body2'} color={'textPrimary'} {...TypographyProps}>
      <StatusDot size={'md'} color={getUserStatusColor(status)} /> {formatUserStatus(status)}
    </Typography>
  );
};

const getUserStatusColor = (sendStatus: UserStatus): StatusDotProps['color'] => {
  switch (sendStatus) {
    case UserStatus.ACTIVE:
      return 'success';
    case UserStatus.INVITED:
      return 'warning';
    case UserStatus.INACTIVE:
      return 'error';
    default:
      return 'info';
  }
};

export default UserStatusComponent;
