import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const GiftMenuCardSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton variant={'rect'} height={200} width={'100%'} />
      <Skeleton width={'100%'} />
      <Skeleton width={'60%'} />
    </Box>
  );
};

export default GiftMenuCardSkeleton;
