import React, { Fragment } from 'react';
import Alert from '@material-ui/lab/Alert';
import capitalize from 'lodash/capitalize';
import { isDatePast } from './utils';
export type InvoiceStatusBadgeProps = {
  status?: string | null;
  dueDate: string;
};

export default function InvoiceStatusBadge({ status, dueDate }: InvoiceStatusBadgeProps) {
  return (
    <Fragment>
      <Alert severity={getStatusSeverity(status || 'n/a', dueDate)}>
        {capitalize(status || 'n/a')}
      </Alert>
    </Fragment>
  );
}

function getStatusSeverity(
  status: string,
  dueDate: string
): 'error' | 'warning' | 'success' | 'info' {
  if (status === 'open') {
    return isDatePast(dueDate) ? 'error' : 'warning';
  }
  if (status === 'paid') {
    return 'success';
  }
  return 'info';
}
