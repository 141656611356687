import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import { MySends, MySends_me_sends, SendStatus } from '../../__generated__/types';
import { MY_SENDS } from '../../graphql/queries';
import { formatDateTime, formatDollars } from '../../helpers/formatters';
import { calculateSendsCost } from './helpers';
import { Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router-dom';
import StatusDot from '../../components/StatusDot/StatusDot';
import SendStatusComponent from '../../components/SendStatusComponent/SendStatusComponent';

export type UserSendsRow = {
  id: string;
  createdAt: string;
  recipient: string;
  orgName?: string | null;
  giftName: string;
  totalCost: number;
  status: SendStatus;
};

export default function UserSendsTable() {
  const { loading, data } = useQuery<MySends>(MY_SENDS, { fetchPolicy: 'network-only' });
  const sends = (data && data.me && data.me.sends) || [];
  const history = useHistory();

  return (
    <Fragment>
      {/*<FormAlert error={error} />*/}
      <Card>
        <CardHeader title={'Send History'} />
        <Divider light />
        <CardContent>
          <MaterialTable
            components={{
              Container: (props: any) => <Fragment>{props.children}</Fragment>
            }}
            isLoading={loading}
            options={{
              search: true,
              showTitle: false,
              loadingType: 'overlay',
              showEmptyDataSourceMessage: !loading
            }}
            actions={[
              {
                icon: 'launch',
                tooltip: 'View Details',
                onClick: (event, rowData) => {
                  // Make sure that selected data is only one row
                  if (Array.isArray(rowData)) return;
                  history.push(`/send/${rowData.id}`);
                }
              }
            ]}
            columns={[
              { field: 'id', hidden: true },
              {
                field: 'createdAt',
                defaultSort: 'desc',
                type: 'date',
                title: 'Created At',
                render: (data) => <>{formatDateTime(data.createdAt, `MMM. D 'YY`)}</>
              },
              { field: 'recipient', title: 'Recipient' },
              { field: 'orgName', title: 'Account / Org' },
              { field: 'giftName', title: 'Gift' },
              {
                field: 'status',
                title: 'Status',
                render: (data) => (
                  <>
                    <SendStatusComponent status={data.status} />
                  </>
                )
              },
              {
                field: 'totalCost',
                title: 'Expense',
                render: (data) => <>{formatDollars(data.totalCost)}</>
              }
            ]}
            data={processData(sends)}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
}

function processData(sends: MySends_me_sends[] | null): UserSendsRow[] {
  return sends
    ? sends.map((send) => ({
        ...send,
        status: send.status ?? SendStatus.NEW,
        creator: send.creator.email,
        recipient: send.recipient.name,
        orgName: send.recipient.orgName,
        totalCost: calculateSendsCost([send])
      }))
    : [];
}
