import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import BudgetChargeDetail from './components/BudgetChargeDetail/BudgetChargeDetail';
import AccountCreditSummary from './components/AccountCreditSummary/AccountCreditSummary';
import BudgetsTable from './components/BudgetsTable/BudgetsTable';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

export default function BudgetsPage() {
  return (
    <Fragment>
      <Grid container alignItems="stretch" spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">Budgets</Typography>
          <Divider light />
        </Grid>
        <br />
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Scheduled Refill Charge" />
            <Divider light />
            <CardContent>
              <BudgetChargeDetail />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AccountCreditSummary />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="User Budgets" />
            <Divider light />
            <CardContent>
              <BudgetsTable />
            </CardContent>
          </Card>
        </Grid>
        <br />
      </Grid>
    </Fragment>
  );
}
