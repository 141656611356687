import Client, { GraphModel } from 'shopify-buy';
import { AsyncState, useAsync } from 'react-async';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Gift_gift_items } from '../__generated__/types';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider
} from 'react-query';

const storefrontAccessToken = process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN;
if (!storefrontAccessToken) {
  throw new Error('Missing env var REACT_APP_SHOPIFY_STOREFRONT_TOKEN');
}

const shopName = process.env.REACT_APP_SHOPIFY_STORE_NAME;
if (!shopName) {
  throw new Error('Missing env var REACT_APP_SHOPIFY_STORE_NAME');
}

const client = Client.buildClient({
  domain: `${shopName}.myshopify.com`,
  storefrontAccessToken
});

export default client;

/**
 * Fixed typings for incorrect @types/shopify-buy
 */
export interface Collection extends GraphModel {
  id: string;
  title: string;
  products?: Array<Product>;
}

export interface Product extends GraphModel {
  /**
   * A product description.
   */
  description: string;

  /**
   * Product unique ID.
   * Updated to be string.
   */
  id: string;

  /**
   * An Array of Objects that contain meta data about an image including src of the images.
   */
  images: Array<Image>;

  /**
   * All variants of a product.
   */
  variants: Array<ProductVariant>;

  /**
   * Get an array of Product Options. Product Options can be used to define
   * the currently selectedVariant from which you can get a checkout url (ProductVariant.checkoutUrl)
   * or can be added to a cart (Cart.createLineItemsFromVariants).
   */
  options: Array<Option>;

  /**
   * A read only Array of Strings represented currently selected option values. eg. ["Large", "Red"]
   */
  selections: Array<string>;

  /**
   * The product title
   */
  title: string;

  /**
   * The product’s vendor name
   */
  vendor: string;

  availableForSale: boolean;
}

export interface ProductVariant extends GraphModel {
  id: string;
  title: string;
  price: string;
  priceV2: {
    amount: string;
    currencyCode: string;
  };
  presentmentPrices: Array<{
    price: {
      amount: string;
      currencyCode: string;
    };
    compareAtPrice: null | string;
    type: {
      name: 'ProductVariantPricePair';
      kind: 'OBJECT';
      fieldBaseTypes: {
        compareAtPrice: 'MoneyV2';
        price: 'MoneyV2';
      };
      implementsNode: false;
    };
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    variableValues: {
      id: string;
    };
  }>;
  weight: number;
  available: boolean;
  sku: string;
  compareAtPrice: null | string;
  compareAtPriceV2: null | string;
  image: {
    id: string;
    src: string;
    altText: null | string;
    type: {
      name: 'Image';
      kind: 'OBJECT';
      fieldBaseTypes: {
        altText: 'String';
        id: 'ID';
        originalSrc: 'URL';
        src: 'URL';
      };
      implementsNode: false;
    };
  };
  selectedOptions: [
    {
      name: string;
      value: string;
      type: {
        name: 'SelectedOption';
        kind: 'OBJECT';
        fieldBaseTypes: {
          name: 'String';
          value: 'String';
        };
        implementsNode: false;
      };
    }
  ];
  unitPrice: null | string;
  type: {
    name: 'ProductVariant';
    kind: 'OBJECT';
    fieldBaseTypes: {
      availableForSale: 'Boolean';
      compareAtPrice: 'Money';
      compareAtPriceV2: 'MoneyV2';
      id: 'ID';
      image: 'Image';
      presentmentPrices: 'ProductVariantPricePairConnection';
      price: 'Money';
      priceV2: 'MoneyV2';
      product: 'Product';
      selectedOptions: 'SelectedOption';
      sku: 'String';
      title: 'String';
      unitPrice: 'MoneyV2';
      unitPriceMeasurement: 'UnitPriceMeasurement';
      weight: 'Float';
    };
    implementsNode: true;
  };
  hasNextPage: {
    value: true;
  };
  hasPreviousPage: false;
  variableValues: {
    id: string;
  };
}

export interface Option extends GraphModel {
  id: string;
  name: string;
  values: Array<{
    value: string;
    type: {
      name: 'String';
      kind: 'SCALAR';
    };
  }>;
  type: {
    name: 'ProductOption';
    kind: 'OBJECT';
    fieldBaseTypes: {
      name: 'String';
      values: 'String';
    };
    implementsNode: true;
  };
}

export interface OptionValue {
  value: string;
}

export interface Image extends GraphModel {
  id: string;
  altText: string | null;
  src: string;
}

// Hooks
export const useFetchCollection = (
  storefrontCollectionId?: string | null
): AsyncState<Collection | null> => {
  console.log('useFetchCollection with:', storefrontCollectionId);
  const memoizedPromise = useMemo<Promise<Collection | null>>(
    () =>
      storefrontCollectionId
        ? ((client.collection.fetchWithProducts(
            storefrontCollectionId
          ) as unknown) as Promise<Collection>)
        : Promise.resolve(null),
    [storefrontCollectionId]
  );
  return useAsync({ promise: memoizedPromise });
};

export const useFetchProduct = (storefrontId: string): AsyncState<Product> => {
  const memoizedPromise = useMemo<Promise<Product>>(
    () => (client.product.fetch(storefrontId) as unknown) as Promise<Product>,
    [storefrontId]
  );
  return useAsync({ promise: memoizedPromise });
};

export const useFetchProducts = (giftItems: Array<Gift_gift_items>): AsyncState<Array<Product>> => {
  const memoizedPromise = useMemo<Promise<Array<Product>>>(() => {
    const storefrontIds = giftItems.map((giftItem) =>
      giftItem?.product?.id ? btoa(giftItem.product.id) : ''
    );
    return Promise.all(
      storefrontIds.map(
        (storefrontId) => (client.product.fetch(storefrontId) as unknown) as Promise<Product>
      )
    );
  }, [giftItems]);
  return useAsync({ promise: memoizedPromise });
};
