import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import {
  Opportunities,
  OpportunitiesVariables,
  OrgSends,
  OrgSendsVariables
} from '../../../../__generated__/types';
import { formatDollars } from '../../../../helpers/formatters';
import { calculateOppRevenue, mapEasyRangeToRange } from '../../../Dashboard/helpers';
import { OPPORTUNITIES, ORG_SENDS } from '../../../../graphql/queries';
import SalesForceAnalyticsForm, {
  SalesForceAnalyticsFormVars
} from './SalesForceAnalyticsForm';
import { DateRange } from '../../../../types';
import SummaryStatCard from '../../../Dashboard/SummaryStatCard';
import { EasyDateRangeOption } from '../../../../components/EasyDateRange/EasyDateRange';
import SendOppsCard from '../../../Dashboard/SendOppsCard';
import OpportunityAnalyticsTable from './OpportunityAnalyticsTable';

export default function SalesForceAnalytics() {
  const [formValues, setFormValues] = useState<SalesForceAnalyticsFormVars>({
    easyDateRange: EasyDateRangeOption.MONTH_TO_DATE,
    dateFilterField: 'CloseDate'
  });
  const [range, setRange] = useState<DateRange>(
    mapEasyRangeToRange(EasyDateRangeOption.MONTH_TO_DATE)
  );
  const { data: { opportunities = [] } = {}, loading: oppsLoading, error: oppsError } = useQuery<
    Opportunities,
    OpportunitiesVariables
  >(OPPORTUNITIES, {
    variables: { input: { dateFilterField: formValues.dateFilterField, ...range } }
  });
  const {
    data: { opportunities: yearsOpps = [] } = {},
    loading: yearsOppsLoading,
    error: yearsOppsError
  } = useQuery<Opportunities, OpportunitiesVariables>(OPPORTUNITIES, {
    variables: { input: mapEasyRangeToRange(EasyDateRangeOption.LAST_YEAR) }
  });
  const {
    data: { org: { sends = [] } = {} } = {},
    loading: sendsLoading,
    error: sendsError
  } = useQuery<OrgSends, OrgSendsVariables>(ORG_SENDS, {
    variables: { input: mapEasyRangeToRange(EasyDateRangeOption.LAST_YEAR) }
  });

  function handleSubmit(values: SalesForceAnalyticsFormVars) {
    setFormValues(values);
    console.log(values);
    setRange(mapEasyRangeToRange(values.easyDateRange));
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SalesForceAnalyticsForm
            initialValues={formValues}
            onSubmit={(values) => handleSubmit(values)}
          />
        </Grid>
        <Grid item xs={12}>
          <OpportunityAnalyticsTable data={opportunities} loading={oppsLoading} />
        </Grid>
        <Grid item xs={12} md={9}>
          <SendOppsCard
            opportunities={yearsOpps}
            sends={sends}
            loading={yearsOppsLoading || sendsLoading}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryStatCard
            title="Revenue"
            label={formValues.easyDateRange}
            content={formatDollars(calculateOppRevenue(opportunities, range.from, range.to))}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
