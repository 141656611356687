import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import {
  Customer,
  OrgProfile,
  SubscriptionStatus,
  SubscriptionType,
  UpcomingInvoice,
  UpcomingInvoiceVariables
} from '../../../../__generated__/types';
import { CUSTOMER, ORG_PROFILE, UPCOMING_INVOICE } from '../../../../graphql/queries';
import ScheduledCharges from './ScheduledCharges';
import Loading from '../../../../components/Loading/Loading';
import UpcomingInvoiceSummary from './UpcomingInvoiceSummary';

export default function CurrentInvoice() {
  const { data: { customer = null } = {}, loading, error } = useQuery<Customer>(CUSTOMER);
  const {
    data: { upcomingInvoice: saasInvoice = null } = {},
    loading: saasInvoiceLoading,
    error: saasInvoiceError
  } = useQuery<UpcomingInvoice, UpcomingInvoiceVariables>(UPCOMING_INVOICE, {
    variables: { input: { subscriptionType: SubscriptionType.SAAS } }
  });
  const {
    data: { upcomingInvoice: creditInvoice = null } = {},
    loading: creditInvoiceLoading,
    error: creditInvoiceError
  } = useQuery<UpcomingInvoice, UpcomingInvoiceVariables>(UPCOMING_INVOICE, {
    variables: { input: { subscriptionType: SubscriptionType.CREDIT } }
  });
  const { data: { org = null } = {}, loading: orgLoading } = useQuery<OrgProfile>(ORG_PROFILE);
  const subscriptionNote = !saasInvoice
    ? 'Your subscription invoice is still being finalized'
    : org &&
      org.saasSubscriptions &&
      org.saasSubscriptions.find((sub) => sub.status === SubscriptionStatus.trialing)
    ? 'Your free trial ends on the date below. You will not be charged if you cancel before then. Contact support with any questions.'
    : null;

  if (saasInvoiceLoading || creditInvoiceLoading) return <Loading />;
  if (customer) {
    return (
      <Grid container spacing={2} direction="row" alignItems="stretch" justify="center">
        <Grid item xs>
          <ScheduledCharges />
          <br />
          <UpcomingInvoiceSummary
            invoice={saasInvoice}
            title={'Subscription'}
            note={subscriptionNote}
          />
          <br />
          <UpcomingInvoiceSummary invoice={creditInvoice} title={'Sends'} />
        </Grid>
      </Grid>
    );
  }
  return <div>There was an error</div>;
}
