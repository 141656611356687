import React from 'react';
import gql from 'graphql-tag';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { AddUser, AddUserVariables } from '../../../../../__generated__/types';
import { INVITATIONS } from '../InvitationsTable/InvitationsTable';
import AddUserForm from './components/AddUserForm/AddUserForm';
import { useMutation } from '@apollo/react-hooks';
import { ORG_USERS } from '../../../../../graphql/queries';

const ADD_USER = gql`
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input) {
      id
    }
  }
`;

type AddUserDialogProps = {
  close: () => void;
} & DialogProps;

export default function AddUserDialog(props: AddUserDialogProps) {
  const [addUser, { data, loading, error }] = useMutation<AddUser, AddUserVariables>(ADD_USER, {
    refetchQueries: [{ query: ORG_USERS }],
    onCompleted(data) {
      if (data?.addUser?.id) {
        props.close();
      }
    }
  });
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>Invite User</DialogTitle>
      <Divider light />
      <DialogContent>
        <AddUserForm submit={addUser} serverError={error} loading={loading} />
      </DialogContent>
    </Dialog>
  );
}
