import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { formatDollars } from '../../helpers/formatters';
import Grid from '@material-ui/core/Grid';
import SummaryStatCard from './SummaryStatCard';
import Typography from '@material-ui/core/Typography';
import UserSendsTable from './UserSendsTable';
import moment from 'moment-timezone';
import SendTypesCard from './SendTypesCard';
import SendOppsCard from './SendOppsCard';
import { Theme } from '@material-ui/core';
import Loading from '../../components/Loading/Loading';
import { EasyDateRangeOption } from '../../components/EasyDateRange/EasyDateRange';
import { calcNumSends, calcSpend, mapEasyRangeToRange } from './helpers';
import { MY_PROFILE } from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { MyProfile, MyProfileVariables } from '../../__generated__/types';

const Dashboard = () => {
  const { data: { me = null } = {}, loading: meLoading, error: meError } = useQuery<
    MyProfile,
    MyProfileVariables
  >(MY_PROFILE, {
    variables: { input: mapEasyRangeToRange(EasyDateRangeOption.YEAR_TO_DATE) }
  });

  if (meLoading) {
    return <Loading />;
  }

  if (meError) {
    return <div>Error, try again!</div>;
  }

  return (
    <Fragment data-testid={'dashboard'}>
      <Typography variant="subtitle1">Welcome back, {me && me.name.split(' ')[0]}</Typography>
      <br />
      <Grid container justify="space-around" spacing={2} alignItems="stretch">
        <Grid key={'Spend'} item xs={12} md={6} lg={4}>
          <SummaryStatCard
            title="Spend"
            content={formatDollars(
              calcSpend(moment().startOf('month').toDate(), moment().toDate(), me && me.sends)
            )}
            label="This Month"
          />
        </Grid>
        <Grid key={'spend'} item xs={12} md={6} lg={4}>
          <SummaryStatCard
            title="Sends"
            content={calcNumSends(
              moment().startOf('month').toDate(),
              moment().toDate(),
              me && me.sends
            )}
            label="This Month"
          />
        </Grid>
        <Grid key={'budget'} item xs={12} md={6} lg={4}>
          <SummaryStatCard
            title="Budget"
            label="Current"
            content={formatDollars(me && me.budget && me.budget.amount)}
          />
        </Grid>
        <Grid key={'opps'} item xs={12} sm={12} md={12} lg={6}>
          <SendOppsCard opportunities={me && me.opportunities} sends={me && me.sends} />
        </Grid>
        <Grid key={'types'} item xs={12} sm={12} md={12} lg={6}>
          <SendTypesCard sends={me && me.sends} />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="stretch" spacing={2}>
        <Grid item xs>
          <UserSendsTable />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Dashboard;
