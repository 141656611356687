import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  CreateInvoiceItem,
  CreateInvoiceItemInput,
  CreateInvoiceItemVariables,
  Send_send,
  SendStatus,
  UpdateSend,
  UpdateSendVariables
} from '../../__generated__/types';
import { formatDollars } from '../../helpers/formatters';
import { SEND } from '../../graphql/queries';
import { CREATE_INVOICE_ITEM, UPDATE_SEND } from '../../graphql/mutations';
import FormAlert from '../../components/FormAlert/FormAlert';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';

export type SendRefundFormProps = {
  send: Send_send;
};

export default function SendRefundForm({ send }: SendRefundFormProps) {
  const [cancelSend, setCancelSend] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [updateSend, { loading: updateLoading }] = useMutation<UpdateSend, UpdateSendVariables>(
    UPDATE_SEND,
    {
      refetchQueries: [{ query: SEND, variables: { input: { id: send.id } } }],
      onCompleted: () => setSuccessMsg('Success')
    }
  );
  const [createInvoiceItem, { loading, error }] = useMutation<
    CreateInvoiceItem,
    CreateInvoiceItemVariables
  >(CREATE_INVOICE_ITEM, {
    refetchQueries: cancelSend
      ? undefined
      : [{ query: SEND, variables: { input: { id: send.id } } }],
    onCompleted: () => console.log(loading)
  });
  const sendCost: number = send.invoiceItems
    ? send.invoiceItems.reduce((sum, invoiceItem) => sum + invoiceItem.dollars, 0)
    : 0;
  return (
    <Grid container spacing={2} direction="column" alignItems="stretch">
      <Grid item xs>
        <Typography align="center" component="h1" variant="h5">
          Refund Send
        </Typography>
      </Grid>
      {!!error && <FormAlert error={error} />}
      {successMsg && (
        <Alert severity={'success'} onClose={() => setSuccessMsg(null)}>
          {successMsg}
        </Alert>
      )}
      {!successMsg && (
        <Grid item xs>
          <FormControlLabel
            control={
              <Checkbox
                //edge="start"
                checked={cancelSend}
                tabIndex={2}
                onChange={(event, checked) => setCancelSend(checked)}
                inputProps={{ 'aria-labelledby': 'Cancel and refund Send?' }}
              />
            }
            label={'Cancel and issue full refund'}
          />
          <Formik
            initialValues={{
              sendId: send.id,
              amountDollars: 0,
              description: ''
            }}
            onSubmit={async (values) => {
              const input: CreateInvoiceItemInput = cancelSend
                ? {
                    amountDollars: -1 * sendCost,
                    description: 'Refund for cancellation of send',
                    sendId: send.id
                  }
                : {
                    amountDollars: -1 * values.amountDollars,
                    description: values.description,
                    sendId: send.id
                  };
              await createInvoiceItem({ variables: { input } });
              if (cancelSend) {
                await updateSend({
                  variables: { input: { id: send.id, status: SendStatus.CANCELED } }
                });
              }
              setSuccessMsg('Success');
            }}
            validationSchema={Yup.object().shape({
              amountDollars: cancelSend
                ? Yup.number().notRequired()
                : Yup.number()
                    .required()
                    .max(
                      sendCost,
                      `Refund cannot be greater than remaining cost (${formatDollars(sendCost)})`
                    ),
              description: cancelSend ? Yup.string().notRequired() : Yup.string().required()
            })}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            }: FormikProps<CreateInvoiceItemInput>) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={1} direction="column" alignItems="stretch">
                  {!cancelSend && (
                    <Fragment>
                      <Grid item xs>
                        <PrimaryTextField
                          required
                          fullWidth
                          type="number"
                          id="amountDollars"
                          label="Amount to be refunded"
                          name="amountDollars"
                          autoComplete="amountDollars"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.amountDollars}
                          error={!!(touched.amountDollars && errors.amountDollars)}
                          helperText={(touched.amountDollars && errors.amountDollars) || ' '}
                        />
                      </Grid>
                      <Grid item xs>
                        <PrimaryTextField
                          required
                          fullWidth
                          id="description"
                          label="Reason"
                          name="description"
                          autoComplete="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          error={!!(touched.description && errors.description)}
                          helperText={(touched.description && errors.description) || ' '}
                        />
                      </Grid>
                    </Fragment>
                  )}
                  <Grid item xs>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={loading || updateLoading}
                    >
                      {loading || updateLoading ? 'Loading...' : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}
