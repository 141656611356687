import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';

export type IntegrationCardProps = {
  title: string;
  link?: string;
  image: string;
  description?: string;
};

export default function IntegrationCard({ title, link, image, description }: IntegrationCardProps) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Fragment>
      <Card className={classes.card}>
        <CardContent>
          <div
            className={classes.media}
            style={{ background: `url(${image}) center no-repeat` }}
          ></div>
          <Typography gutterBottom variant={'h5'} align={'center'}>
            {title}
          </Typography>
          <Typography variant={'subtitle2'} align={'center'} color={'textSecondary'}>
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          {link ? (
            <Button fullWidth color="primary" onClick={() => history.push(link)}>
              View
            </Button>
          ) : (
            <Button fullWidth color="primary" disabled>
              Coming Soon
            </Button>
          )}
        </CardActions>
      </Card>
    </Fragment>
  );
}

const useStyles = makeStyles({
  media: {
    height: 140
  },
  card: {
    height: '100%'
  }
});
