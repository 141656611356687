import { useMutation } from '@apollo/client/react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import { ApolloError } from 'apollo-client';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { VALIDATE_ADDRESS } from '../../graphql/mutations';
import { useSingleColumnFormStyles } from '../../helpers/styles';
import {
  SalesForceContacts_salesForceContacts,
  ValidateAddress,
  ValidateAddressVariables
} from '../../__generated__/types';
import FormAlert from '../FormAlert/FormAlert';
import PrimaryTextField from '../PrimaryTextField/PrimaryTextField';
import { useValidateAddressDialog } from '../ValidateAddressDialog/ValidateAddressDialog';

type AddressFormComponentProps = FormikProps<AddressFormVars> & {
  loading: boolean;
  error?: ApolloError;
  title?: string;
};

function AddressFormComponent({
  loading,
  values,
  touched,
  errors,
  error,
  handleChange,
  handleBlur,
  handleSubmit,
  title
}: AddressFormComponentProps) {
  const classes = useSingleColumnFormStyles();

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <HomeIcon />
      </Avatar>
      <Typography component="h1" variant="h5" align="center">
        {title || 'Address'}
      </Typography>
      {error && <FormAlert error={error} />}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <PrimaryTextField
          type="text"
          fullWidth
          id="name"
          required
          dataTestId={'name'}
          label="Name"
          name="name"
          autoComplete="no"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          error={!!(touched.name && errors.name)}
          helperText={(touched.name && errors.name) || ''}
        />
        <PrimaryTextField
          type="text"
          fullWidth
          required
          id="street1"
          dataTestId={'street1'}
          label="Address Line 1"
          autoComplete="no"
          name="street1"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.street1}
          error={!!(touched.street1 && errors.street1)}
          helperText={(touched.street1 && errors.street1) || ''}
        />
        <PrimaryTextField
          type="text"
          fullWidth
          id="street2"
          dataTestId={'street2'}
          label="Address Line 2"
          name="street2"
          autoComplete="no"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.street2}
        />
        <Grid container spacing={1}>
          <Grid item xs={8} sm={12} md={6}>
            <PrimaryTextField
              fullWidth
              required
              type="text"
              id="city"
              dataTestId={'city'}
              label="City"
              name="city"
              onChange={handleChange}
              autoComplete="no"
              onBlur={handleBlur}
              value={values.city}
              error={!!(touched.city && errors.city)}
              helperText={(touched.city && errors.city) || undefined}
            />
          </Grid>
          <Grid item xs={4} sm={12} md={2}>
            <PrimaryTextField
              type="text"
              fullWidth
              required
              id="state"
              inputProps={{
                'data-testid': 'state',
                maxLength: 2
              }}
              label="State"
              name="state"
              autoComplete="no"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={!!(touched.state && errors.state)}
              helperText={(touched.state && errors.state) || undefined}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PrimaryTextField
              type="text"
              fullWidth
              required
              id="zip"
              inputProps={{
                'data-testid': 'zip',
                maxLength: 5
              }}
              label="Zip"
              name="zip"
              autoComplete="no"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zip}
              error={!!(touched.zip && errors.zip)}
              helperText={(touched.zip && errors.zip) || undefined}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          data-testid="submitAddress"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          Continue
        </Button>
      </form>
    </Paper>
  );
}

export type AddressFormVars = {
  street1: string;
  street2: string | null;
  zip: string;
  city: string;
  state: string;
  name: string;
};

export type AddressFormProps = {
  salesForceContact?: SalesForceContacts_salesForceContacts | null;
  title?: string;
  onSubmit: (addressId: string) => void;
  values?: AddressFormVars | null;
  loading: boolean;
  error?: ApolloError;
};

export default function AddressForm({ title, onSubmit, values, loading, error }: AddressFormProps) {
  const { validateAddress, renderValidateAddressDialog } = useValidateAddressDialog();
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          values || {
            street1: '',
            street2: '',
            city: '',
            zip: '',
            state: '',
            name: ''
          }
        }
        onSubmit={(values) => {
          validateAddress({ variables: { input: values } });
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required'),
          street1: Yup.string().required('Address 1 is required'),
          city: Yup.string().required('City is required'),
          zip: Yup.string().required(),
          state: Yup.string().required()
        })}
        render={(formikProps) => (
          <>
            {renderValidateAddressDialog({
              onAccept: ({ id }) => onSubmit(id),
              userAddress: formikProps.values
            })}
            <AddressFormComponent {...formikProps} title={title} loading={loading} error={error} />
          </>
        )}
      />
    </>
  );
}
