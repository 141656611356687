import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import moment from 'moment';
import gql from 'graphql-tag';
import UpdateInvitationDialog from '../UpdateInvitationDialog/UpdateInviationDialog';
import { Invitations, Invitations_invitations } from '../../../../../__generated__/types';
import { CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';

export const INVITATIONS = gql`
  query Invitations {
    invitations {
      isAccepted
      id
      org {
        name
      }
      createdAt
      creator {
        email
      }
      email
    }
  }
`;

export type InvitationRow = {
  id: string;
  isAccepted: boolean;
  email: string;
  createdAt: Date;
  creator: string;
};

export default function InvitationsTable(props: any) {
  const [selectedInvitation, setSelectedInvitation] = useState<InvitationRow | null>(null);
  const [resendDialogOpen, setResendDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  function handleDialogClose() {
    setResendDialogOpen(false);
    setDeleteDialogOpen(false);
    setSelectedInvitation(null);
  }

  const { loading, data: { invitations = null } = {} } = useQuery<Invitations>(INVITATIONS);
  return (
    <Fragment>
      <UpdateInvitationDialog
        action={deleteDialogOpen ? 'delete' : 'resend'}
        invitationId={selectedInvitation && selectedInvitation.id}
        email={selectedInvitation && selectedInvitation.email}
        open={deleteDialogOpen || resendDialogOpen}
        onClose={handleDialogClose}
      />
      <Card>
        <CardHeader title={'Invitations'} />
        <Divider light />
        <CardContent>
          <MaterialTable
            isLoading={loading}
            components={{
              Container: (props) => <div>{props.children}</div>
            }}
            options={{
              search: true,
              showTitle: false,
              toolbar: false,
              loadingType: 'linear',

              showEmptyDataSourceMessage: !loading
            }}
            actions={[
              {
                icon: 'send',
                tooltip: 'Resend Email',
                onClick: (event, rowData) => {
                  if (Array.isArray(rowData)) return;
                  setSelectedInvitation(rowData);
                  setResendDialogOpen(true);
                }
              },
              {
                icon: 'delete',
                tooltip: 'Revoke Invite',
                onClick: (event, rowData) => {
                  if (Array.isArray(rowData)) return;
                  setSelectedInvitation(rowData);
                  setDeleteDialogOpen(true);
                }
              }
            ]}
            columns={[
              { field: 'id', hidden: true },
              { field: 'email', title: 'Email' },
              {
                field: 'createdAt',
                type: 'datetime',
                render: (data) => <>{moment(data.createdAt).format('MM/DD/YY hh:mm A')}</>,
                title: 'Sent At'
              },
              { field: 'creator', title: 'Creator' }
            ]}
            data={processData(invitations)}
            title="Invitations"
            localization={{
              body: {
                emptyDataSourceMessage: 'There are no outstanding invitations ✅'
              }
            }}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
}

function processData(
  invitations: Array<Invitations_invitations | null> | null
): Array<InvitationRow> {
  return invitations
    ? invitations
        .filter((invitation) => invitation && !invitation.isAccepted)
        .map((invitation: Invitations_invitations | null) => ({
          ...invitation!,
          creator: invitation!.creator.email
        }))
    : [];
}
