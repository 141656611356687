import React, { Fragment } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

export default function ResetPassword() {
  const classes = useStyles();
  const { resetPasswordCode } = useParams<any>();
  return (
    <Container className={classes.authFormContainer} component="main" maxWidth="xs">
      <Paper className={classes.authPaper}>
        {resetPasswordCode ? (
          <Fragment>
            <ResetPasswordForm resetPasswordCode={resetPasswordCode} />
          </Fragment>
        ) : (
          <Fragment>
            <ForgotPasswordForm />
            <Grid container>
              <Grid item xs>
                <RouterLink to="/">
                  <Link variant="body2">Login</Link>
                </RouterLink>
              </Grid>
              <Grid item>
                <Link href="/signup">{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  authFormContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6)
  },
  authPaper: {
    padding: theme.spacing(2)
  }
}));
