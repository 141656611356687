import palette from '../palette';

export default {
  root: {
    color: palette.secondary.main,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  }
};
