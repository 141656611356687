import React, { Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PersonAdd } from '@material-ui/icons';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ApolloError } from 'apollo-client';
import ReCAPTCHA from 'react-google-recaptcha';
import { SignupAndCreateOrgVariables, AcceptInviteInput } from '../../__generated__/types';
import FormAlert from '../../components/FormAlert/FormAlert';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

type SignupFormComponentProps = SignupFormProps &
  FormikProps<SignupAndCreateOrgVariables & AcceptInviteInput>;

function SignupFormComponent({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  inviteCode,
  orgName,
  loading,
  serverError,
  setFieldValue
}: SignupFormComponentProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Avatar className={classes.avatar}>
        <PersonAdd />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      {inviteCode && (
        <Fragment>
          <Typography variant="body1" align="center">
            Accept invitation to join {orgName}.
          </Typography>
          <br />
          <Typography variant="subtitle2" align="center">
            Note that the email below is your login username:
          </Typography>
        </Fragment>
      )}
      {!!serverError && <FormAlert error={serverError} />}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        {!inviteCode && (
          <PrimaryTextField
            required
            fullWidth
            id="orgName"
            label="Company Name"
            name="orgName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.orgName}
            error={!!(touched.orgName && errors.orgName)}
            helperText={(touched.orgName && errors.orgName) || ' '}
          />
        )}
        <PrimaryTextField
          required
          fullWidth
          id="name"
          label="Your Full Name"
          name="name"
          autoComplete="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          error={!!(touched.name && errors.name)}
          helperText={(touched.name && errors.name) || ' '}
        />
        <PrimaryTextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          inputProps={{
            disabled: Boolean(inviteCode)
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={!!(touched.email && errors.email)}
          helperText={(touched.email && errors.email) || ' '}
        />
        <PrimaryTextField
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onBlur={handleBlur}
          autoComplete="current-password"
          value={values.password}
          onChange={handleChange}
          error={!!(touched.password && errors.password)}
          helperText={(touched.password && errors.password) || ' '}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
          onChange={(token) => setFieldValue('recaptchaToken', token)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading || !values.recaptchaToken}
        >
          Sign Up
        </Button>
      </form>
    </Paper>
  );
}

type SignupFormProps = {
  loading: boolean;
  signup: (arg0: any) => any;
  serverError: ApolloError | undefined;
  initialValues?: {
    email: string;
    orgName: string;
    password: string;
    inviteCode: string;
    name: string;
    recaptchaToken: string;
  };
  inviteCode?: string;
  orgName?: string;
};

export default function SignupForm(signupFormProps: SignupFormProps) {
  const { loading, signup, serverError, initialValues, inviteCode } = signupFormProps;
  return (
    <Formik
      initialValues={
        initialValues || {
          name: '',
          orgName: '',
          email: '',
          password: '',
          inviteCode: '',
          recaptchaToken: ''
        }
      }
      onSubmit={(values) =>
        signup({
          variables: inviteCode
            ? { inviteCode: values.inviteCode, password: values.password, name: values.name }
            : {
                email: values.email,
                password: values.password,
                orgName: values.orgName,
                name: values.name,
                recaptchaToken: values.recaptchaToken
              }
        })
      }
      validationSchema={Yup.object().shape({
        orgName: Yup.string().required('required'),
        name: Yup.string().required(),
        email: Yup.string().email('Invalid Email').required(),
        password: Yup.string().max(50, 'Must be under 50 characters').required()
      })}
      render={(formikProps) => <SignupFormComponent {...formikProps} {...signupFormProps} />}
    />
  );
}
