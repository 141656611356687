import React, { useEffect, useState } from 'react';
import { Autocomplete, Alert } from '@material-ui/lab';
import { Product } from 'shopify-buy';
import storefrontClient from '../../graphql/storefrontClient';
import PrimaryTextField from '../../components/TextField/PrimaryTextField';
import FormAlert from '../../components/FormAlert/FormAlert';

/**
 * Hook wrapper for StorefrontClient.product.fetchQuery
 * @param search
 */
const useProductFetchQuery = (
  search: string
): { loading: boolean; data: Array<Product> | null; error: string | null } => {
  const [data, setData] = useState<Array<Product> | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>('');
  useEffect(() => {
    // Set to defaults if no search is provided
    if (!search) {
      setData(null);
      return;
    }
    setLoading(true);
    setError(null);
    storefrontClient.product
      .fetchQuery({ query: `${search}`, sortBy: 'title' })
      .then((products) => setData(products))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, [search]);
  return { data, loading, error };
};

export type ProductSearchProps = {
  onProductSelect: (product: Product) => void;
};
const ProductSearch: React.FC<ProductSearchProps> = ({ onProductSelect }) => {
  const [search, setSearch] = useState<string>('');
  const { data, loading, error } = useProductFetchQuery(search);
  const products = data ?? [];

  const handleProductSelect = (product: Product | string | null): void => {
    if (!product || typeof product === 'string') {
      return;
    }
    onProductSelect(product);
  };
  return (
    <>
      <Autocomplete
        id="productSelect"
        freeSolo
        onInputChange={(event, value) => setSearch(value)}
        inputValue={search}
        options={products}
        onChange={(event, product) => handleProductSelect(product)}
        getOptionLabel={(option) => option.title}
        loading={loading}
        renderInput={(params) => <PrimaryTextField {...params} required label="Search Products" />}
      />
      {error && <Alert severity={'error'}>{error}</Alert>}
    </>
  );
};

export default ProductSearch;
