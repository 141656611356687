import * as React from 'react';
import round from 'lodash/round';

export type Product = {
  id: string;
  isAddon: boolean;
  variants: Array<{ id: string; price: number }>;
};

export type SendItems = Array<{
  productId: string;
  variantId: string;
  quantity: number;
  isAdded: boolean;
}>;

export function initSendItems(products: Array<Product>): SendItems {
  // Get the saved state from localStorage
  let savedSendItemsForm: SendItems | null = null;
  try {
    savedSendItemsForm = JSON.parse(localStorage.getItem('sendItemsForm') || '[]') as SendItems;
  } catch (error) {
    console.error('Error parsing sendItemsForm from localStorage', error);
  }
  // Build the form values
  const initialSendItems: SendItems = [];
  const addonProducts = products.filter((product) => product.isAddon);
  const mandatoryProducts = products.filter((product) => !product.isAddon);
  // Init the mandatory products
  mandatoryProducts.forEach((product) => {
    if (!product.variants[0]) return;
    initialSendItems.push({
      productId: product.id,
      // Default to the first variant
      variantId: product.variants[0].id,
      // Quantity is fixed at 1
      quantity: 1,
      // Mandatory products are always added
      isAdded: true
    });
  });

  // Init the addon (optional) products
  addonProducts.forEach((product) => {
    if (!product.variants[0]) return;

    // Get the saved form value for this product
    const savedProduct = savedSendItemsForm?.find(
      (savedProduct) => savedProduct.productId === product.id
    );
    const initialValue = {
      productId: product.id,
      // Fallback to the first variant
      variantId: savedProduct?.variantId ?? product.variants[0].id,
      // Fallback quantity to 1
      quantity: savedProduct?.quantity ?? 1,
      // Fallback isAdded to false
      isAdded: savedProduct?.isAdded ?? false
    };

    initialSendItems.push(initialValue);
  });

  return initialSendItems;
}
