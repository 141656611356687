import React, { Fragment, SyntheticEvent, useState } from 'react';
import { CardElement, Elements, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import { Theme } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { UpdateCard, UpdateCardVariables } from '../../../../__generated__/types';
import { UPDATE_CARD } from '../../../../graphql/mutations';
import FormAlert from '../../../../components/FormAlert/FormAlert';

const cardElementStyles = (theme: Theme) => {
  return {
    style: {
      base: {
        fontSize: `${theme.typography.fontSize + 4}px`,
        color: theme.palette.common.black,
        fontFamily: theme.typography.fontFamily,
        '::placeholder': {
          color: theme.palette.grey['500']
        },
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        margin: theme.spacing(1)
      },
      invalid: {
        color: theme.palette.error.main
      }
    }
  };
};

export type CardFormProps = {
  stripe?: ReactStripeElements.StripeProps;
  loading?: boolean;
  onSuccess: (tokens: Array<string>) => void;
};

export function CardForm({ stripe, loading, onSuccess }: CardFormProps) {
  const theme = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);

  async function handleSubmit(ev: SyntheticEvent) {
    ev.preventDefault();
    if (!stripe) return null;
    setError(null);
    setTokenLoading(true);
    // Get the token for the credit customer
    const tokenResponse1 = await stripe.createSource({ type: 'card' });
    // Get the token for the SaaS customer
    const tokenResponse2 = await stripe.createSource({ type: 'card' });
    setTokenLoading(false);

    if (tokenResponse1.error || !tokenResponse1.source) {
      setError(tokenResponse1.error!.message || 'There was an error. Try again.');
      return;
    }

    if (tokenResponse2.error || !tokenResponse2.source) {
      setError(tokenResponse2.error!.message || 'There was an error. Try again.');
      return;
    }
    onSuccess([tokenResponse1.source.id, tokenResponse2.source.id]);
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement {...cardElementStyles(theme)} />
      <Typography color="error" variant="body2">
        {error}&nbsp;
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={loading || tokenLoading}
        type="submit"
      >
        {loading || tokenLoading ? 'Processing...' : 'Submit'}
      </Button>
    </form>
  );
}

export const InjectedCardForm = injectStripe(CardForm);

export type UpdateCardFormProps = {
  onSuccess: () => void;
};
export default function UpdateCardForm({ onSuccess }: UpdateCardFormProps) {
  const [updateCard, { error, loading }] = useMutation<UpdateCard, UpdateCardVariables>(
    UPDATE_CARD,
    {
      onCompleted: () => onSuccess()
    }
  );

  return (
    <Fragment>
      {error && <FormAlert error={error} />}
      <Elements>
        <InjectedCardForm
          onSuccess={(sources) => updateCard({ variables: { input: { sources } } })}
          loading={loading}
        />
      </Elements>
    </Fragment>
  );
}
