import React from 'react';
import { IS_LOGGED_IN, LOGIN_PAGE } from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { IsLoggedIn, LoginPage } from '../../__generated__/types';
import { Redirect, Switch } from 'react-router';
import legacyRedirects from '../legacyRedirects';
import RedirectWithQueryString from '../components/RedirectWithQueryString/RedirectWithSearch';
import { RouteWithLayout } from '../../components';
import { Main as MainLayout, Minimal as MinimalLayout } from '../../layouts';
import protectedPages from '../protectedPages';
import unprotectedPages from '../unprotectedPages';
import authPages from '../authPages';

const Routes = () => {
  const { data: { isLoggedIn = false } = {} } = useQuery<IsLoggedIn>(IS_LOGGED_IN);
  const { data: { loginPage = null } = {} } = useQuery<LoginPage>(LOGIN_PAGE);
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      {legacyRedirects.map((redirectProps) => (
        <RedirectWithQueryString key={redirectProps.to} {...redirectProps} />
      ))}
      {/* Redirect unauthenticated users from protected pages */}
      {/* Todo capture the link they were trying to go to and redirect to that */}
      {!isLoggedIn &&
        protectedPages.map((page) => (
          <Redirect key={page.path} exact from={page.path} to="/login" />
        ))}
      {/* Redirect authenticated users from auth pages */}
      {isLoggedIn &&
        authPages.map((page) => (
          <Redirect
            key={page.path}
            from={page.path}
            to={loginPage?.redirectRoute || '/dashboard'}
          />
        ))}
      {protectedPages.map((page) => (
        <RouteWithLayout
          key={page.path}
          component={page.content}
          exact
          layout={page.layout}
          path={page.path}
        />
      ))}
      {[...unprotectedPages, ...authPages].map((page) => (
        <RouteWithLayout
          key={page.path}
          component={page.content}
          exact
          layout={page.layout}
          path={page.path}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
