import Grid from '@material-ui/core/Grid';
import React, { Fragment, useEffect } from 'react';
import {
  Customer,
  Gifts,
  Me,
  Me_me,
  Gifts_gifts,
  PermissionType,
  SalesForceContact,
  SalesForceContactVariables,
  SelectedSalesForceContact,
  GiftFilterFormValues,
  StorefrontCollection,
  StorefrontCollectionVariables,
  GiftsVariables
} from '../../../../__generated__/types';
import { useQuery } from '@apollo/react-hooks';
import {
  STOREFRONT_COLLECTION,
  GIFT_FILTER_FORM_VALUES,
  GIFTS,
  ME
} from '../../../../graphql/queries';
import GiftMenuCard from '../../../../components/GiftMenuCard/GiftMenuCard';
import GiftMenuCardSkeleton from '../../../../components/GiftMenuCard/GiftMenuCardSkeleton';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import shopImage from '../../../../assets/images/storefront-colour.svg';
import { Collection, Product, useFetchCollection } from '../../../../graphql/storefrontClient';
import keyBy from 'lodash/keyBy';
import max from 'lodash/max';
import min from 'lodash/min';
import sum from 'lodash/sum';

export default function GiftGrid() {
  // Get the Gift Filter form values
  const { data: { giftFilterFormValues = null } = {} } =
    useQuery<GiftFilterFormValues>(GIFT_FILTER_FORM_VALUES);
  // Get the collection with products
  const collectionId = giftFilterFormValues?.storefrontCollectionId ?? '';
  const { data: storefrontCollection = null, isLoading: collectionLoading } =
    useFetchCollection(collectionId);
  // Get the gifts based on the products
  const productIds = storefrontCollection?.products?.map((product) => atob(product.id));
  console.log(storefrontCollection);
  const { data: { gifts = [] } = {}, loading: giftsLoading } = useQuery<Gifts, GiftsVariables>(
    GIFTS,
    {
      variables: { input: { products: productIds } },
      skip: !productIds
    }
  );

  if (collectionLoading || giftsLoading) {
    return (
      <Grid container spacing={2} justify={'space-evenly'} alignItems={'stretch'}>
        {[0, 1, 2, 3, 4, 5].map((value) => (
          <Grid item xs={6} sm={6} md={4} lg={4} key={value}>
            <GiftMenuCardSkeleton />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!giftFilterFormValues?.storefrontCollectionId) {
    return (
      <Grid container direction={'column'} spacing={4} justify={'center'} alignItems={'stretch'}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant={'h4'} align={'center'}>
                Welcome to the RevSend shop!
              </Typography>
              <Typography variant={'body1'} align={'center'}>
                Choose a filter to see gifting options
              </Typography>
              <br />
              <img
                src={shopImage}
                alt={'Choose Filters'}
                width={350}
                style={{ margin: 'auto', display: 'block' }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2} justify={'space-evenly'} alignItems={'stretch'}>
      {[...gifts]
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((gift) => (
          <Grid item xs={6} sm={6} md={4} lg={4} key={gift.id}>
            <GiftMenuCard
              id={gift.id}
              image={gift.imageUrl ?? ''}
              title={gift.title}
              description={gift.description ?? ''}
              prices={
                (gift.items &&
                  storefrontCollection &&
                  findPriceRange({
                    giftProducts: gift.items.map((item) => item.product.id),
                    storefrontProducts: storefrontCollection.products,
                    fixedShippingCost: gift.fixedShippingCost ?? 0
                  })) ||
                undefined
              }
            />
          </Grid>
        ))}
    </Grid>
  );
}

// Todo: test this?
function findPriceRange({
  giftProducts,
  storefrontProducts,
  fixedShippingCost = 0
}: {
  giftProducts: Array<string>;
  storefrontProducts: Collection['products'];
  fixedShippingCost?: number;
}): { min?: number; max?: number } {
  // Find all the variants
  const productsByStorefrontId: Record<string, Product | undefined> = keyBy(
    storefrontProducts,
    'id'
  );
  // Create the array of variants
  const variantPriceLists: Array<Array<number>> = giftProducts.map(
    (productId) =>
      productsByStorefrontId[btoa(productId)]?.variants.map((variant) => Number(variant.price)) ?? [
        0
      ]
  );
  const mins: Array<number> = [];
  const maxes: Array<number> = [];
  // For each of the price lists, add the min and max to the mins and maxes
  variantPriceLists.forEach((variantPriceList) => {
    const minPrice = min(variantPriceList);
    if (minPrice) {
      mins.push(minPrice);
    }
    const maxPrice = max(variantPriceList);
    if (maxPrice) {
      maxes.push(maxPrice);
    }
  });
  return {
    min: mins.length > 0 ? sum(mins) + fixedShippingCost : undefined,
    max: maxes.length > 0 ? sum(maxes) + fixedShippingCost : undefined
  };
}

// Todo: check gift permissions
function canUserSendGift(me: Me_me | null, gift: Gifts_gifts): boolean {
  if (!me || !me.permissions) return false;
  if (me.permissions.find((permission) => permission.type === PermissionType.All)) return true;
  return !!me.permissions.find((permission) => permission.gift && permission.gift.id === gift.id);
}
