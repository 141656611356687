import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as qs from 'query-string';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CREATE_SALESFORCE_TOKEN, UNLINK_SALESFORCE } from '../../../../../graphql/mutations';
import {
  CreateSalesForceToken,
  CreateSalesForceTokenVariables,
  Me,
  PermissionType,
  SalesForceUserInfo,
  UnlinkSalesForce
} from '../../../../../__generated__/types';
import Loading from '../../../../../components/Loading/Loading';
import { ME, SALESFORCE_USER_INFO } from '../../../../../graphql/queries';
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';

function getSalesforceAuthUrl() {
  return `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9LBJLApeX_PBhKnzylh7HB.mkOXNrO7oT8ZxzFUAv4AhCCD4b7PLXdDg0aFkqQ9tYvVgeNBJZoFHXILBQ&redirect_uri=${getSalesForceRedirectUri()}`;
}

function getSalesForceRedirectUri() {
  // Note: This must remain as `/dashboard/integration/salesforce` in order to work
  return `${window.location.origin}/dashboard/integrations/salesforce`;
}

export default function SalesForce() {
  const {
    data: { salesForceUserInfo = null } = {},
    loading: getUserLoading
  } = useQuery<SalesForceUserInfo>(SALESFORCE_USER_INFO);
  const [createSalesForceToken, { loading: linkLoading }] = useMutation<
    CreateSalesForceToken,
    CreateSalesForceTokenVariables
  >(CREATE_SALESFORCE_TOKEN, {
    // Clear the query params
    onCompleted: () => history.push('/settings/integrations/salesforce'),
    refetchQueries: [{ query: SALESFORCE_USER_INFO }]
  });
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const [unlinkSalesForce, { loading: unlinkLoading }] = useMutation<UnlinkSalesForce>(
    UNLINK_SALESFORCE,
    {
      refetchQueries: [{ query: SALESFORCE_USER_INFO }]
    }
  );
  const history = useHistory();

  React.useEffect(() => {
    const { code } = qs.parse(window.location.search);
    if (code && typeof code === 'string') {
      createSalesForceToken({
        variables: { input: { code, redirectUri: getSalesForceRedirectUri() } }
      });
    }
  }, []);

  const classes = useStyles();

  if (linkLoading || getUserLoading || unlinkLoading) {
    return <Loading />;
  }
  return (
    <Grid container direction="row" spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">SalesForce Integration</Typography>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <ListItem>
          {salesForceUserInfo && (
            <ListItemAvatar>
              <CheckCircleOutline className={classes.successIcon} fontSize={'large'} />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={
              salesForceUserInfo
                ? `Successfully connected by ${salesForceUserInfo.displayName}`
                : 'Your organization does not have SalesForce connected. '
            }
            secondary={
              salesForceUserInfo
                ? 'A user with SalesForce privileges may disconnect'
                : 'A user with SalesForce privileges may set this up.'
            }
          />
        </ListItem>
        <br />
      </Grid>
      {me &&
        me.permissions &&
        me.permissions.find((permission) => permission.type === PermissionType.All) && (
          <Grid item xs>
            <Typography gutterBottom>Admin</Typography>
            {salesForceUserInfo ? (
              <Button
                color="secondary"
                disabled={unlinkLoading || getUserLoading}
                onClick={() => unlinkSalesForce()}
              >
                Unlink SalesForce
              </Button>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                disabled={getUserLoading || linkLoading}
                onClick={() => (window.location.href = getSalesforceAuthUrl())}
              >
                Click here to integrate SalesForce
              </Button>
            )}
          </Grid>
        )}
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  successIcon: {
    color: theme.palette.success.main
  }
}));
