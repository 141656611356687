import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import {
  OrgUsers,
  OrgUsers_org_salesForceUsers,
  OrgUsers_org_users,
  UserStatus
} from '../../../../../__generated__/types';
import { ORG_USERS } from '../../../../../graphql/queries';
import UpdateUserDialog from '../UpdateUserDialog/UpdateUserDialog';
import FormAlert from '../../../../../components/FormAlert/FormAlert';
import UserStatusComponent from '../../../../../components/UserStatusComponent/UserStatusComponent';

export type OrgUsersRow = {
  salesForceUserEmail?: string;
} & OrgUsers_org_users;

export default function OrgUsersTable() {
  const [selectedUser, setSelectedUser] = useState<OrgUsers_org_users | null>(null);
  const {
    loading,
    error,
    data: { org: { users = null, salesForceUsers = null } = {} } = {}
  } = useQuery<OrgUsers>(ORG_USERS);

  function closeUpdateUser() {
    setSelectedUser(null);
  }

  return (
    <Card>
      <UpdateUserDialog
        open={!!selectedUser}
        user={selectedUser}
        salesForceUsers={salesForceUsers}
        onClose={closeUpdateUser}
        onSuccess={closeUpdateUser}
      />
      <CardHeader title="Org Users" />
      <Divider light />
      <CardContent>
        <FormAlert error={error} />
        <MaterialTable
          components={{
            Container: (props) => <div>{props.children}</div>
          }}
          isLoading={loading}
          options={{
            search: true,
            showTitle: false,
            loadingType: 'linear',
            showEmptyDataSourceMessage: !loading
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit User',
              onClick: (event, rowData) => {
                if (Array.isArray(rowData)) return; // Material tables believes that multiple rows can be returned!
                setSelectedUser(rowData);
              }
            }
          ]}
          columns={[
            { field: 'id', hidden: true },
            { field: 'salesForceUserId', hidden: true },
            { field: 'name', title: 'Name' },
            { field: 'salesForceUserEmail', title: 'SalesForce User Connection' },
            {
              field: 'status',
              title: 'Status',
              render: (data) => <UserStatusComponent status={data.status} />
            }
          ]}
          data={processData(users, salesForceUsers)}
          title="Org Members"
        />
      </CardContent>
    </Card>
  );
}

function processData(
  users: OrgUsers_org_users[] | null,
  salesForceUsers: OrgUsers_org_salesForceUsers[] | null
): Array<OrgUsersRow> {
  if (!users) return [];
  return users.map((user) => {
    const salesForceUser =
      salesForceUsers && salesForceUsers.find((sfUser) => sfUser.id === user.salesForceUserId);
    return {
      ...user,
      salesForceUserEmail: salesForceUser ? salesForceUser.email : 'Not Connected'
    };
  });
}
