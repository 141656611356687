import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Form, Formik, Field } from 'formik';
import FileDropzone from '../../../components/FileDropzone/FileDropzone';
import PrimaryTextField from '../../../components/TextField/PrimaryTextField';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import MessageTemplates from './MessageTemplates';
import { UPDATE_ORG } from '../../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/client/react';
import { Me, OrgBranding, UpdateOrg, UpdateOrgVariables } from '../../../__generated__/types';
import { ME, ORG_BRANDING } from '../../../graphql/queries';
import Loading from '../../../components/Loading/Loading';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import * as Yup from 'yup';
import LandingPreviews from './LandingPreviews';
import { getContrastTextColor } from './utils';

export default function Branding() {
  const defaultLogoBackgroundColor = '#FFFFFF';
  const defaultLogoPrimaryColor = '#4C81DB';
  const { enqueueSnackbar } = useSnackbar();
  const { data: { org = null } = {}, loading: orgLoading } = useQuery<OrgBranding>(ORG_BRANDING);
  const [updateOrg, { loading }] = useMutation<UpdateOrg, UpdateOrgVariables>(UPDATE_ORG, {
    refetchQueries: [{ query: ORG_BRANDING }],
    awaitRefetchQueries: true,
    onCompleted: () => enqueueSnackbar('Saved Brand Assets!', { variant: 'success' })
  });
  const { data: { me = null } = {}, loading: meLoading } = useQuery<Me>(ME);

  if (orgLoading || meLoading || !org || !me) {
    return <Loading />;
  }
  const initialLogoFormValues = {
    logoUrl: org.logoUrl ?? '',
    logoBackgroundColor: org.logoBackgroundColor ?? defaultLogoBackgroundColor,
    logoPrimaryColor: org.logoPrimaryColor ?? defaultLogoPrimaryColor
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs />
        <br />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Branding
        </Typography>
        <Divider light />
        <br />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Brand Assets" />
              <Divider light />
              <CardContent>
                <Formik
                  enableReinitialize
                  initialValues={initialLogoFormValues}
                  validationSchema={Yup.object().shape({
                    logoBackgroundColor: Yup.string().matches(/^#([0-9a-f]{3}){1,2}$/i),
                    logoPrimaryColor: Yup.string().matches(/^#([0-9a-f]{3}){1,2}$/i)
                  })}
                  onSubmit={(values) => {
                    updateOrg({
                      variables: {
                        input: {
                          logoBackgroundColor: values.logoBackgroundColor,
                          logoPrimaryColor: values.logoPrimaryColor,
                          logoUrl: values.logoUrl
                        }
                      }
                    });
                  }}
                >
                  {({ values, submitForm, handleChange, errors, setFieldValue, isValid }) => {
                    const hasUnsavedChanges = !isEqual(initialLogoFormValues, values);
                    return (
                      <Form>
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                borderRadius: 2,
                                p: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: values.logoBackgroundColor
                              }}
                            >
                              {values.logoUrl ? (
                                <img src={values.logoUrl} alt="logo" width={200} />
                              ) : (
                                'No logo uploaded'
                              )}
                            </Box>
                          </Grid>
                          <Grid item>
                            <FileDropzone onUpload={(url) => setFieldValue('logoUrl', url)} />
                            <Field
                              hidden
                              name="logoUrl"
                              variant="outlined"
                              label="Logo Url"
                              as="input"
                            />
                            <br />
                          </Grid>
                          <Grid item>
                            <Grid container spacing={2}>
                              <Grid item>
                                <PrimaryTextField
                                  name="logoBackgroundColor"
                                  variant="outlined"
                                  label="Logo Background Color (Hex)"
                                  required
                                  onChange={handleChange}
                                  value={values.logoBackgroundColor}
                                  error={Boolean(errors?.logoBackgroundColor)}
                                  helperText={
                                    errors?.logoBackgroundColor
                                      ? 'Invalid hex code'
                                      : 'E.g. #000000'
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <PrimaryTextField
                                  name="logoPrimaryColor"
                                  variant="outlined"
                                  label="Logo Primary Color (Hex)"
                                  required
                                  onChange={handleChange}
                                  value={values.logoPrimaryColor}
                                  error={Boolean(errors?.logoPrimaryColor)}
                                  helperText={
                                    errors?.logoPrimaryColor ? 'Invalid hex code' : 'E.g. #000000'
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <Button
                                  disabled={Boolean(!hasUnsavedChanges || loading || !isValid)}
                                  size="large"
                                  color="primary"
                                  variant="contained"
                                  onClick={submitForm}
                                >
                                  {loading ? 'Saving...' : 'Save Changes'}
                                </Button>
                              </Grid>
                              <Grid item xs>
                                {Boolean(hasUnsavedChanges && !loading && isValid) && (
                                  <Alert severity="info">Unsaved changes</Alert>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <LandingPreviews
            {...initialLogoFormValues}
            orgName={org.formattedName}
            creatorName={me.name}
            contrastTextColor={getContrastTextColor(defaultLogoPrimaryColor)}
          />
        </Grid>
        <br />
      </Grid>
    </Grid>
  );
}
