import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  ResetPassword,
  ResetPasswordInput,
  ResetPasswordVariables
} from '../../__generated__/types';
import { ApolloError } from 'apollo-client';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { RESET_PASSWORD } from '../../graphql/mutations';
import FormAlert from '../../components/FormAlert/FormAlert';
import { IS_LOGGED_IN } from '../../graphql/queries';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

type ResetPasswordFormComponentProps = FormikProps<ResetPasswordInput> & {
  loading: boolean;
  error?: ApolloError;
};

function ResetPasswordFormComponent({
  loading,
  values,
  touched,
  error,
  errors,
  handleChange,
  handleBlur,
  handleSubmit
}: ResetPasswordFormComponentProps) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <FormAlert error={error} />
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <PrimaryTextField
          required
          fullWidth
          name="newPassword"
          label="Password"
          type="password"
          id="newPassword"
          onBlur={handleBlur}
          value={values.newPassword}
          onChange={handleChange}
          error={!!(touched.newPassword && errors.newPassword)}
          helperText={(touched.newPassword && errors.newPassword) || ' '}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

type ResetPasswordFormProps = {
  resetPasswordCode: string;
};

export default function ResetPasswordForm({ resetPasswordCode }: ResetPasswordFormProps) {
  const client = useApolloClient();
  const history = useHistory();
  const [resetPassword, { error, loading }] = useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD,
    {
      onCompleted: ({ resetPassword: { tokens } }: ResetPassword) => {
        if (!(tokens && tokens[0])) return;
        localStorage.setItem('token', tokens[0].value);
        client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
        history.push('/dashboard');
      }
    }
  );
  return (
    <Formik
      initialValues={{
        newPassword: '',
        resetPasswordCode
      }}
      onSubmit={(values) => resetPassword({ variables: { input: { ...values } } })}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().required()
      })}
    >
      {(formikProps) => (
        <ResetPasswordFormComponent {...formikProps} loading={loading} error={error} />
      )}
    </Formik>
  );
}
