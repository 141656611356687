import moment from 'moment-timezone';
import { EasyDateRangeOption } from '../../components/EasyDateRange/EasyDateRange';
import { SalesForceStageName } from './types';
import { DateRange } from '../../types';
import { MySends_me_sends, Opportunities_opportunities } from '../../__generated__/types';

export function calculateSendsCost(
  sends: Array<{ invoiceItems: Array<{ dollars: number }> | null }>
): number {
  return sends.reduce(
    (sum, send) =>
      send.invoiceItems
        ? send.invoiceItems.reduce((sum, invoiceItem) => sum + invoiceItem.dollars, 0) + sum
        : 0,
    0
  );
}

export function calculateOppRevenue(
  opportunities?: Opportunities_opportunities[] | null,
  from?: Date,
  to?: Date
): number {
  if (!opportunities) {
    return 0;
  }
  const res = opportunities
    .filter(
      (opp) =>
        opp.sends &&
        opp.sends.length > 0 &&
        opp.closeDate &&
        moment(opp.closeDate).isAfter(from || moment().startOf('month')) &&
        moment(opp.closeDate).isBefore(to || moment()) &&
        opp.stageName === SalesForceStageName.CLOSED_WON
    )
    .reduce((sum, opp) => sum + opp.amount!, 0);
  return res;
}

export function calcNumSends(from: Date, to: Date, sends?: MySends_me_sends[] | null): number {
  if (!sends) return 0;
  return sends.filter(
    (send) =>
      moment(send.createdAt).isAfter(moment(from)) && moment(send.createdAt).isBefore(moment(to))
  ).length;
}

export function calcSpend(from: Date, to: Date, sends?: MySends_me_sends[] | null): number {
  if (!sends) return 0;
  return calculateSendsCost(
    sends.filter(
      (send) =>
        moment(send.createdAt).isAfter(moment(from)) && moment(send.createdAt).isBefore(moment(to))
    )
  );
}

export const mapEasyRangeToRange = (easyRange: EasyDateRangeOption): DateRange => {
  const timezone = moment.tz.guess();
  const to = moment().tz(timezone).endOf('day').toDate();
  let from: Date;
  if (easyRange === EasyDateRangeOption.MONTH_TO_DATE) {
    from = moment().tz(timezone).startOf('month').toDate();
  } else if (easyRange === EasyDateRangeOption.YEAR_TO_DATE) {
    from = moment().tz(timezone).startOf('year').toDate();
  } else if (easyRange === EasyDateRangeOption.LAST_YEAR) {
    from = moment().tz(timezone).subtract(1, 'year').startOf('day').toDate();
  } else {
    const splitValue = easyRange.split(' ');
    from = moment().tz(timezone).subtract(Number(splitValue[1]), 'days').startOf('day').toDate();
  }
  return { from, to };
};
