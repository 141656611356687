import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Me,
  OrgProfile,
  PermissionType,
  UpdateOrg,
  UpdateOrgVariables
} from '../../../__generated__/types';
import { ME, ORG_PROFILE } from '../../../graphql/queries';
import { UPDATE_ORG } from '../../../graphql/mutations';
import Loading from '../../../components/Loading/Loading';
import { Button } from '@material-ui/core';
import OrgSettingsForm from './components/OrgSettingsForm/OrgSettingsForm';

export default function Org() {
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const { data: { org = null } = {}, loading: orgLoading } = useQuery<OrgProfile>(ORG_PROFILE);

  const isAdmin: boolean = !!(
    me &&
    me.permissions &&
    me.permissions.find((permission) => permission.type === PermissionType.All)
  );
  return (
    <Fragment>
      {orgLoading && <Loading />}
      {!orgLoading && org && (
        <Grid container direction="row" justify="flex-start" alignItems="stretch">
          <Grid item xs={12}>
            <Typography variant="h5">{org.formattedName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h6">Settings</Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <OrgSettingsForm readOnly={!isAdmin} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
