import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import FullScreenDialog from 'components/FullScreenDialog/FullScreenDialog';
import React from 'react';
import { Theme } from 'theme';
import { RecipientLanding, RecipientLandingProps } from 'views/RecipientLanding/RecipientLanding';
import { DeliveryType, PlacementMethod } from '__generated__/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    appBar: {
      position: 'relative'
    },
    content: {
      padding: theme.spacing(2)
    }
  })
);

export type LandingPreviewProps = {
  logoUrl: string;
  logoPrimaryColor: string;
  logoBackgroundColor: string;
  contrastTextColor: string;
  orgName: string;
  creatorName: string;
};
export default function LandingPreviews(props: LandingPreviewProps) {
  const {
    logoBackgroundColor,
    logoPrimaryColor,
    logoUrl,
    orgName,
    creatorName,
    contrastTextColor
  } = props;
  const [physicalOpen, setPhysicalOpen] = React.useState<boolean>(false);
  const [digitalOpen, setDigitalOpen] = React.useState<boolean>(false);

  const classes = useStyles();

  const digitalRecipientLandingProps: RecipientLandingProps = {
    isPreview: true,
    logoUrl,
    logoPrimaryColor,
    logoBackgroundColor,
    contrastTextColor,
    productPrice: '50',
    deliveryType: DeliveryType.DIGITAL,
    placementMethod: PlacementMethod.RAISE,
    productImage:
      'https://cdn.shopify.com/s/files/1/0326/9391/1688/products/zamazon.png?v=1610168785',
    isAddressSubmitted: false,
    addressNeedsToBeSubmited: false,
    isGiftCardRedeemed: false,
    needsToBeRedeemed: true,
    sendId: '1',
    sendMessage:
      "Hi Bob,\n\nThank you so much for taking the time to meet with me. Here's a token of our team's appreciation. Enjoy! \n\nBest,\nKelly",
    orgName,
    giftName: 'Amazon Gift Card',
    isGiftCard: true,
    creatorName,
    sendGrandTotal: 50,
    recipientEmail: 'bob@example.com'
  };

  const physicalRecipientLandingProps: RecipientLandingProps = {
    productDescription:
      'Born from the rarest and most expensive whiskies in the world, individually numbered and produced in limited quantities. Johnnie Walker Blue has the authentic character & flavor of a traditional 19th century blend with traces of smoke, honey & spice. A silky finish.',
    isPreview: true,
    logoUrl,
    logoPrimaryColor,
    logoBackgroundColor,
    contrastTextColor,
    productPrice: '360',
    deliveryType: DeliveryType.PHYSICAL,
    placementMethod: PlacementMethod.RS_CS_TO_SHOPIFY,
    productImage:
      'https://cdn.shopify.com/s/files/1/0326/9391/1688/products/ScreenShot2021-06-19at4.30.12AM.png?v=1624102223',
    isAddressSubmitted: false,
    addressNeedsToBeSubmited: true,
    isGiftCardRedeemed: false,
    needsToBeRedeemed: false,
    sendId: '2',
    sendMessage:
      "Hi Bob,\n\nThank you so much for taking the time to meet with me. Here's a token of our team's appreciation. Enjoy! \n\nBest,\nKelly",
    orgName,
    giftName: 'Johnnie Walker Blue Label Scotch Gift Set',
    isGiftCard: false,
    creatorName,
    sendGrandTotal: 360
  };

  return (
    <>
      <Grid item xs={12} style={{ border: `1px solid #cbcbcb`, borderRadius: 8 }}>
        <Typography variant="h4" gutterBottom>
          Digital Gift Page Preview
        </Typography>
        <Divider light />
        <RecipientLanding {...digitalRecipientLandingProps} />
      </Grid>
      <Grid item xs={12}>
        <br />
      </Grid>
      <Grid item xs={12} style={{ border: `1px solid #cbcbcb`, borderRadius: 8 }}>
        <Typography variant="h4" gutterBottom>
          Physical Gift Page Preview
        </Typography>
        <Divider light />
        <RecipientLanding {...physicalRecipientLandingProps} />
      </Grid>
    </>
  );
}
