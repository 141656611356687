import { useMutation, useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import useTheme from '@material-ui/styles/useTheme';
import { useSnackbar } from 'notistack';
import React from 'react';
import Loading from '../../../components/Loading/Loading';
import MessageField from '../../../components/MessageField/MessageField';
import NumberCircle from '../../../components/NumberCircle/NumberCircle';
import PrimaryTextField from '../../../components/PrimaryTextField/PrimaryTextField';
import { useValidateAddressDialog } from '../../../components/ValidateAddressDialog/ValidateAddressDialog';
import { CREATE_SEND } from '../../../graphql/mutations';
import { ADDON_PRODUCTS, GIFT, ORG_PROFILE } from '../../../graphql/queries';
import { formatDollars } from '../../../helpers/formatters';
import theme, { Theme } from '../../../theme';
import {
  AddonProducts,
  CreateSend,
  CreateSendVariables,
  CreateSend_createSend,
  Gift,
  OrgProfile,
  ValidateAddress_validateAddress
} from '../../../__generated__/types';
import RecipientForm from '../Recipient/RecipientForm';
import SuccessStep from '../SuccessStep';
import useAddonForm from '../hooks/useAddonForm';
import useAddressForm from '../hooks/useAddressForm';
import useAmazonItemQueryParams from '../hooks/useAmazonItemParams';
import useRecipientForm from '../hooks/useRecipientForm';

/** Screen size query. Returns true if screen is over or equal 'md' in width */
function useBreakpointUp(breakpoint: Breakpoint): boolean {
  const theme: Theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

export type AmazonCheckoutProps = {
  giftId: string;
};
const AmazonCheckout: React.FC<AmazonCheckoutProps> = ({ giftId }) => {
  // Queries
  const { data: { gift = null } = {}, loading: giftLoading } = useQuery<Gift>(GIFT, {
    variables: { input: { id: giftId } }
  });
  const { data: { org = null } = {} } = useQuery<OrgProfile>(ORG_PROFILE);
  const { data: { products: addonProducts = [] } = {} } = useQuery<AddonProducts>(ADDON_PRODUCTS);

  // Mutations
  const [createSend, { loading }] = useMutation<CreateSend, CreateSendVariables>(CREATE_SEND);

  // Helpers
  const snackbar = useSnackbar();

  // Local State
  const {
    pricesByProductId,
    addToCart,
    removeFromCart,
    selectProductVariant,
    lineItems,
    addonForm,
    subtotal
  } = useAddonForm(addonProducts);
  const amazonItem = useAmazonItemQueryParams();
  const [addressForm, setAddressForm] = useAddressForm();
  const [recipientForm, setRecipientForm] = useRecipientForm();
  const [message, setMessage] = React.useState<string>('');
  const [isPrivateAddressChecked, setIsPrivateAddressChecked] = React.useState<boolean>(false);
  const [formErrors, setFormErrors] = React.useState<Record<string, string>>({});
  const [createdSend, setCreatedSend] = React.useState<CreateSend_createSend | null>(null);
  const isScreenUpMd = useBreakpointUp('md');
  const { validateAddress, renderValidateAddressDialog } = useValidateAddressDialog();

  // Event handlers
  function validateForm() {
    const errors: Record<string, string> = {};
    // Recipient Form
    if (org?.settings?.requireSalesForceContact && !recipientForm.salesForceContactId) {
      errors.salesForceContactId = 'Your org requires a SalesForce contact to be selected';
    }
    if (!recipientForm.name) {
      errors.name = 'Name is required.';
    }
    if (!recipientForm.email) {
      errors.email = 'Email is required.';
    }
    // Message
    if (!message) {
      errors.message = 'Message is required.';
    }
    // Address
    if (!isPrivateAddressChecked && !addressForm.street1) {
      errors.address = 'Address is required.';
    }
    setFormErrors(errors);
    return errors;
  }

  function submit() {
    createSend({
      variables: {
        input: {
          giftId: 'AMAZON',
          amazonItem,
          recipient: {
            email: recipientForm.email,
            name: recipientForm.name,
            salesForceContactId: recipientForm.salesForceContactId,
            salesForceAccountId: recipientForm.salesForceAccountId,
            hubSpotContactId: recipientForm.hubSpotContactId,
            hubSpotCompanyId: recipientForm.hubSpotCompanyId,
            orgName: recipientForm.orgName
          },
          sendItems: lineItems.map((lineItem) => ({
            productVariantId: lineItem.variantId,
            quantity: lineItem.quantity
          })),
          message,
          address: addressForm,
          isAddressPrivate: isPrivateAddressChecked,
          createHubSpotTask: recipientForm.isCreateHubSpotTaskChecked,
          createSalesForceTask: recipientForm.isCreateSalesForceTaskChecked
        }
      }
    })
      .then((response) => {
        if (response.data) {
          setCreatedSend(response.data.createSend);
          snackbar.enqueueSnackbar('Send created successfully!', {
            variant: 'success'
          });
        } else {
          snackbar.enqueueSnackbar('There was an error. Try again, and if this ', {
            variant: 'error'
          });
        }
      })
      .catch((error) => {
        snackbar.enqueueSnackbar(error.message, { variant: 'error' });
      });
  }
  function onSubmit() {
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      snackbar.enqueueSnackbar('Please fix the form errors: ' + Object.values(errors).join(' '), {
        variant: 'error'
      });
      return;
    }
    // Validate address, unless private address is checked.
    if (!isPrivateAddressChecked) {
      // Don't submit the form. Instead, wait for the user to accept the address validation,
      // and then handle that event in onAddressAccept, which submits.
      validateAddress({ variables: { input: addressForm } });
      return;
    }
    submit();
  }

  // User accepts the validated address.
  function onAddressAccept(address: ValidateAddress_validateAddress) {
    setAddressForm({ ...address, street2: address.street2 ?? '' });
    submit();
  }
  if (giftLoading) {
    return <Loading />;
  }
  if (!gift) {
    return <div>Can't find gift</div>;
  }
  return createdSend ? (
    <SuccessStep send={createdSend} />
  ) : (
    <Grid container spacing={2} direction="row" alignItems="flex-start" justify="space-around">
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Send Gift
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Grid
          direction="column"
          container
          spacing={4}
          justifyContent={'center'}
          alignItems={'stretch'}
        >
          <Grid item>
            <SectionTitle step={'1'} title={'Recipient'} />
          </Grid>
          <Grid item>
            <RecipientForm
              onChange={(recipient) => setRecipientForm(recipient)}
              errors={formErrors}
            />
          </Grid>
          <Grid item>
            <SectionTitle step={'2'} title={'Personalization'} />
          </Grid>
          <Grid item>
            <Card variant="outlined" style={{ padding: 12 }}>
              {addonProducts?.length > 0 ? (
                <>
                  <Typography color="textPrimary" variant="h6">
                    Gift Addons
                  </Typography>
                  {addonProducts.map((product) => {
                    const optionLabel =
                      product.options.length > 0
                        ? [...product.options]
                            .sort((a, b) => a.position - b.position)
                            .map((option) => option.name)
                            .join(' / ')
                        : 'Variant';
                    return (
                      <Grid container spacing={1} alignItems={'center'}>
                        <Grid item key={product.id}>
                          <Box
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              overflow: 'hidden',
                              width: 100
                            }}
                          >
                            {product.featuredImageSrc && (
                              <img
                                style={{ width: '100%', height: 'auto' }}
                                alt={product.title}
                                src={product.featuredImageSrc}
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs>
                          <ListItemText
                            primary={
                              <Typography
                                color="textPrimary"
                                style={{ fontWeight: 'bold' }}
                                variant="subtitle2"
                              >
                                {product.title}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                color="textSecondary"
                                style={{ marginTop: 2 }}
                                variant="body1"
                              >
                                {formatDollars(pricesByProductId[product.id])}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item>
                          {product.variants.length > 1 && (
                            <FormControl size="small" variant="outlined" style={{ marginRight: 8 }}>
                              <InputLabel id={`product-${product.id}-variant`}>
                                {optionLabel}
                              </InputLabel>
                              <Select
                                labelId={`product-${product.id}-variant`}
                                value={addonForm[product.id]?.variantId}
                                onChange={({ target: { value } }) =>
                                  selectProductVariant(product.id, value as string)
                                }
                                label={optionLabel}
                                native
                              >
                                {product.variants.map((variant) => (
                                  <option key={variant.id} value={variant.id}>
                                    {variant.title}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {addonForm[product.id]?.isAdded ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => removeFromCart(product.id)}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addToCart(product.id)}
                            >
                              Add
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              ) : null}
              <Typography color="textPrimary" variant="h6">
                Message
              </Typography>
              <MessageField
                fullWidth
                required
                multiline
                charLimit={650}
                rows={6}
                name={'message'}
                id={'message'}
                onChange={({ target: { value } }) => setMessage(value)}
                value={message}
                error={Boolean(formErrors.message)}
                helperText={formErrors.message ?? ''}
              />
            </Card>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <NumberCircle>3</NumberCircle>
              </Grid>
              <Grid item>
                <Typography>Shipping</Typography>
              </Grid>
              <Grid item xs>
                <Divider light />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <React.Fragment>
              <Card>
                <CardContent>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={'isAddressPrivate'}
                        name={'isAddressPrivate'}
                        onChange={(_, checked) => {
                          setIsPrivateAddressChecked(checked);
                        }}
                        checked={isPrivateAddressChecked}
                      />
                    }
                    label={'Generate link for recipient to submit their address.'}
                  />
                  <Collapse in={!isPrivateAddressChecked}>
                    {renderValidateAddressDialog({
                      onAccept: onAddressAccept,
                      userAddress: addressForm
                    })}
                    {formErrors.address && <Alert severity="error">{formErrors.address}</Alert>}
                    <PrimaryTextField
                      type="text"
                      fullWidth
                      id="recipientAddress.name"
                      inputProps={{
                        'data-testid': 'name'
                      }}
                      label="Name"
                      autoComplete="no"
                      name="recipientAddress.name"
                      value={addressForm.name}
                      onChange={({ target: { value } }) =>
                        setAddressForm({ ...addressForm, name: value })
                      }
                      helperText={"The name you'd like to appear on the shipping label."}
                    />
                    <PrimaryTextField
                      type="text"
                      fullWidth
                      id="recipientAddress.street1"
                      inputProps={{
                        'data-testid': 'street1'
                      }}
                      label="Address Line 1"
                      autoComplete="no"
                      name="recipientAddress.street1"
                      value={addressForm.street1}
                      onChange={({ target: { value } }) =>
                        setAddressForm({ ...addressForm, street1: value })
                      }
                    />
                    <PrimaryTextField
                      type="text"
                      fullWidth
                      id="recipientAddress.street2"
                      inputProps={{
                        'data-testid': 'street2'
                      }}
                      label="Address Line 2"
                      name="recipientAddress.street2"
                      autoComplete="no"
                      value={addressForm.street2}
                      onChange={({ target: { value } }) =>
                        setAddressForm({ ...addressForm, street2: value })
                      }
                    />
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={12} md={6}>
                        <PrimaryTextField
                          fullWidth
                          id="recipientAddress.city"
                          inputProps={{
                            'data-testid': 'city'
                          }}
                          label="City"
                          name="recipientAddress.city"
                          value={addressForm.city}
                          onChange={({ target: { value } }) =>
                            setAddressForm({ ...addressForm, city: value })
                          }
                        />
                      </Grid>
                      <Grid item xs={4} sm={12} md={2}>
                        <PrimaryTextField
                          fullWidth
                          id="recipientAddress.state"
                          inputProps={{
                            'data-testid': 'state',
                            maxLength: 2
                          }}
                          label="State"
                          name="recipientAddress.state"
                          autoComplete="no"
                          value={addressForm.state}
                          onChange={({ target: { value } }) =>
                            setAddressForm({ ...addressForm, state: value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <PrimaryTextField
                          fullWidth
                          id="recipientAddress.zip"
                          inputProps={{
                            'data-testid': 'zip',
                            maxLength: 5
                          }}
                          label="Zip"
                          name="recipientAddress.zip"
                          autoComplete="no"
                          value={addressForm.zip}
                          onChange={({ target: { value } }) =>
                            setAddressForm({ ...addressForm, zip: value })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </CardContent>
              </Card>
            </React.Fragment>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={loading}
              type="button"
              onClick={onSubmit}
              fullWidth
            >
              {loading ? <Loading /> : 'Send Gift!'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card style={{ padding: 12 }}>
          <Typography color="textPrimary" gutterBottom>
            Order Summary
          </Typography>
          <List style={{ marginTop: 8 }}>
            {amazonItem && (
              <ListItem disableGutters>
                <ListItemAvatar style={{ marginRight: 12 }}>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      height: 100,
                      justifyContent: 'center',
                      overflow: 'hidden',
                      width: 100
                    }}
                  >
                    {amazonItem.imgUrl && (
                      <img
                        style={{ width: '100%', height: 'auto' }}
                        alt={amazonItem.productName ?? 'Amazon Item Image'}
                        src={amazonItem.imgUrl}
                      />
                    )}
                  </Box>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      color="textPrimary"
                      style={{ fontWeight: 'bold' }}
                      variant="subtitle2"
                    >
                      {amazonItem.productName}
                    </Typography>
                  }
                  secondary={
                    <Typography color="textSecondary" style={{ marginTop: 2 }} variant="body1">
                      {formatDollars(amazonItem.price)}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {addonProducts
              .filter((product) => addonForm[product.id]?.isAdded)
              .map((product) => (
                <ListItem disableGutters key={product.id}>
                  <ListItemAvatar style={{ marginRight: 12 }}>
                    <Box
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: 100,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 100
                      }}
                    >
                      {product.featuredImageSrc && (
                        <img
                          style={{ width: '100%', height: 'auto' }}
                          alt={product.title}
                          src={product.featuredImageSrc}
                        />
                      )}
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        color="textPrimary"
                        style={{ fontWeight: 'bold' }}
                        variant="subtitle2"
                      >
                        {product.title}
                      </Typography>
                    }
                    secondary={
                      <Typography color="textSecondary" style={{ marginTop: 2 }} variant="body1">
                        {formatDollars(pricesByProductId[product.id])}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
          </List>
          <Divider light />
          <List>
            <ListItem disableGutters>
              <ListItemText>Shipping</ListItemText>
              <ListItemSecondaryAction>
                <Typography>{formatDollars(gift.fixedShippingCost)}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText>Total</ListItemText>
              <ListItemSecondaryAction>
                <Typography>
                  {formatDollars(
                    (gift.fixedShippingCost ?? 0) + (amazonItem?.price ?? 0) + subtotal
                  )}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
};

// Components
function SectionTitle({ step, title }: { step: string; title: string }) {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <NumberCircle>{step}</NumberCircle>
      </Grid>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs>
        <Divider light />
      </Grid>
    </Grid>
  );
}
export default AmazonCheckout;
