import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import {
  Charges,
  ChargesVariables,
  Invoices,
  InvoicesVariables,
  SubscriptionType
} from '../../../../__generated__/types';
import Loading from '../../../../components/Loading/Loading';
import { CHARGES, INVOICES } from '../../../../graphql/queries';
import CreditAddSummary from '../CurrentInvoice/CreditAddSummary';
import { useQuery } from '@apollo/react-hooks';
import PastInvoicesSummary from './PastInvoicesSummary';

export type BillingHistoryProps = {};

export default function BillingHistory({}: BillingHistoryProps) {
  const chargesFrom = moment().endOf('day').subtract(1, 'year').toDate();

  const { data: { invoices: saasInvoices = [] } = {}, loading: saasLoading } = useQuery<
    Invoices,
    InvoicesVariables
  >(INVOICES, {
    variables: { input: { subscriptionType: SubscriptionType.SAAS } }
  });
  const { data: { invoices: creditInvoices = [] } = {}, loading: creditLoading } = useQuery<
    Invoices,
    InvoicesVariables
  >(INVOICES, {
    variables: { input: { subscriptionType: SubscriptionType.CREDIT } }
  });
  const { data: { charges = [] } = {}, loading: chargesLoading, error: chargesError } = useQuery<
    Charges,
    ChargesVariables
  >(CHARGES, {
    variables: {
      input: { range: { from: chargesFrom, to: moment().endOf('day').toDate() } }
    }
  });

  if (creditLoading || saasLoading || chargesLoading) {
    return <Loading />;
  }
  return (
    <Fragment>
      <Grid container spacing={2} direction="row" alignItems="stretch" justify="center">
        <Grid item xs={12} lg>
          <PastInvoicesSummary invoices={saasInvoices} title={'Subscription Invoices'} />
        </Grid>
        <Grid item xs={12} lg>
          <PastInvoicesSummary invoices={creditInvoices} title={'Send Invoices'} />
        </Grid>
        <Grid item xs={12}>
          {!chargesLoading && <CreditAddSummary periodStart={chargesFrom} charges={charges} />}
        </Grid>
      </Grid>
    </Fragment>
  );
}
