import React, { ReactChildren } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Topbar } from './components';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    padding: theme.spacing(2),
    height: '100%'
  }
}));

const Minimal: React.FC<MinimalProps> = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export type MinimalProps = {
  children?: React.ReactNode;
  className?: string;
};
export default Minimal;
