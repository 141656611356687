import gql from 'graphql-tag';

const SalesForce = {
  fragments: {
    opportunityInfo: gql`
      fragment SalesForceOpportunityInfo on SalesForceOpp {
        id
        name
        stageName
        amount
        closeDate
        createdDate
        account {
          name
        }
        sends {
          id
          grandTotal
          giftName
          status
          createdAt
          invoiceItems {
            id
            dollars
          }
        }
      }
    `,
    contactInfo: gql`
      fragment SalesForceContactInfo on SalesForceContact {
        id
        name
        email
        phone
        account {
          id
          name
        }
        mailingAddress {
          street
          city
          state
          postalCode
          country
        }
      }
    `
  }
};

const Stripe = {
  fragments: {
    invoice: gql`
      fragment StripeInvoice on Invoice {
        id
        amountDueDollars
        amountPaidDollars
        periodStart
        periodEnd
        totalDollars
        subTotalDollars
        status
        dueDate
        lines {
          id
          description
          dollars
          createdAt
        }
      }
    `
  }
};

const User = {
  fragments: {
    info: gql`
      fragment UserInfo on User {
        id
        name
        email
        permissions {
          id
          type
          gift {
            id
          }
        }
        team {
          id
          name
          isAdmin
        }
        role
        invitationsCreated {
          id
          createdAt
          isAccepted
          email
        }
        budget {
          id
          amount
          refillAmount
        }
        salesForceUserId
        notificationSettings {
          id
          channel
          onSendStatusDelivered
          onSendStatusRedeemed
          onSendStatusShipped
          onSendStatusCanceled
        }
        isSlackConnected
      }
    `
  }
};

const Gift = {
  fragments: {
    summary: gql`
      fragment GiftSummary on Gift {
        id
        title
        deliveryType
        imageUrl
        isEnabled
        description
        fixedShippingCost
        items {
          id
          quantity
          product {
            id
          }
        }
      }
    `,
    detail: gql`
      fragment GiftDetail on Gift {
        id
        title
        description
        deliveryType
        fixedShippingCost
        items {
          id
          quantity
          product {
            id
            title
            featuredImageSrc
            isAddon
            variants {
              id
              price
              title
            }
            options {
              id
              name
              position
            }
            description
          }
        }
        imageUrl
        placementMethod
        placementId
      }
    `
  }
};

export const Send = {
  fragments: {
    sendSummary: gql`
      fragment SendSummary on Send {
        id
        recipient {
          id
          name
          email
          orgName
        }
        creator {
          id
          email
          name
        }
        giftName
        grandTotal
        amount
        createdAt
        status
        shippingCost
        deliveryType
        isAddressPrivate
        shopifyOrderName
      }
    `,
    sendDetail: gql`
      fragment SendDetail on Send {
        recipient {
          salesForceAccountId
          salesForceContactId
        }
        shippingCost
        trackingNumber
        trackingUrl
        amount
        amazonItem {
          id
          productUrl
          name
          price
          size
        }
        sendItems {
          id
          quantity
          title
          description
          price
        }
        invoiceItems {
          id
          dollars
          description
          createdAt
        }
        org {
          id
          formattedName
        }
        message
        address {
          street1
          street2
          city
          state
          zip
        }
        isAddressPrivate
        placementMethod
        placementId
        shopifyOrderName
      }
    `
  }
};

const Org = {
  fragments: {
    // todo
  }
};

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn @client
  }
`;

export const CREATED_SEND = gql`
  query CreatedSend {
    createdSend @client {
      ...SendSummary
    }
  }
  ${Send.fragments.sendSummary}
`;

export const IS_CREATE_SALESFORCE_TASK_CHECKED = gql`
  query IsCreateSalesForceTaskChecked {
    isCreateSalesForceTaskChecked @client
  }
`;

export const IS_CREATE_HUBSPOT_TASK_CHECKED = gql`
  query IsCreateHubSpotTaskChecked {
    isCreateHubSpotTaskChecked @client
  }
`;

export const IS_PRIVATE_ADDRESS_CHECKED = gql`
  query IsPrivateAddressChecked {
    isPrivateAddressChecked @client
  }
`;

export const LOGIN_PAGE = gql`
  query LoginPage {
    loginPage @client {
      message
      isSuccess
      redirectRoute
    }
  }
`;

export const SELECTED_SALESFORCE_CONTACT = gql`
  query SelectedSalesForceContact {
    selectedSalesForceContact @client {
      ...SalesForceContactInfo
    }
  }
  ${SalesForce.fragments.contactInfo}
`;

export const SALESFORCE_CONTACT = gql`
  query SalesForceContact($input: SalesForceContactInput!) {
    salesForceContact(input: $input) {
      ...SalesForceContactInfo
    }
  }
  ${SalesForce.fragments.contactInfo}
`;

export const HUBSPOT_DEALS = gql`
  query HubSpotDeals($connection: ConnectionInput!, $input: HubSpotDealsInput!) {
    hubSpotDeals(connection: $connection, input: $input) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          dealname
          dealstage
          dealstageLabel
          amount
          closedate
          createdate
        }
      }
    }
  }
`;

export const SELECTED_HUBSPOT_CONTACT = gql`
  query SelectedHubSpotContact {
    selectedHubSpotContact @client {
      id
      companyId
      company {
        id
        name
      }
      name
      firstname
      lastname
      email
      address
      city
      state
      zip
    }
  }
`;

export const RECIPIENT_FORM = gql`
  query RecipientForm {
    recipientForm @client {
      name
      email
      orgName
    }
  }
`;

export const RECIPIENT_ADDRESS_FORM = gql`
  query RecipientAddressForm {
    recipientAddressForm @client {
      name
      street1
      street2
      city
      state
      zip
    }
  }
`;

export const HANDWRITTEN_SENDER_FORM = gql`
  query HandwrittenSenderForm {
    handwrittenSenderForm @client {
      name
      street1
      street2
      city
      state
      zip
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      ...UserInfo
    }
  }
  ${User.fragments.info}
`;

export const USER_BY_INVITE_CODE = gql`
  query UserByInviteCode($input: UserByInviteCodeInput!) {
    userByInviteCode(input: $input) {
      id
      name
      email
      org {
        id
        formattedName
      }
    }
  }
`;

export const MY_PROFILE = gql`
  query MyProfile($input: DateRangeInput!) {
    me {
      ...UserInfo
      opportunities(input: $input) {
        ...SalesForceOpportunityInfo
      }
      sends {
        ...SendSummary
        invoiceItems {
          id
          dollars
          description
        }
      }
    }
  }
  ${User.fragments.info}
  ${Send.fragments.sendSummary}
  ${SalesForce.fragments.opportunityInfo}
`;

export const MY_SENDS = gql`
  query MySends {
    me {
      id
      sends {
        ...SendSummary
        invoiceItems {
          id
          dollars
          description
        }
      }
    }
  }
  ${Send.fragments.sendSummary}
`;

export const BUDGETS = gql`
  query Budgets {
    budgets {
      id
      refillAmount
      frequency
      amount
      isRollover
      user {
        email
        name
      }
    }
  }
`;

export const INVITATION = gql`
  query Invitation($id: ID!) {
    invitation(id: $id) {
      id
      org {
        formattedName
      }
      creator {
        name
        email
      }
      email
    }
  }
`;

export const UPCOMING_INVOICE = gql`
  query UpcomingInvoice($input: UpcomingInvoiceInput) {
    upcomingInvoice(input: $input) {
      ...StripeInvoice
    }
  }
  ${Stripe.fragments.invoice}
`;

export const INVOICES = gql`
  query Invoices($input: InvoicesInput!) {
    invoices(input: $input) {
      id
      status
      periodEnd
      totalDollars
      dueDate
      hostedInvoiceUrl
    }
  }
`;

export const GIFTS = gql`
  query Gifts($input: QueryGiftsInput!) {
    gifts(input: $input) {
      ...GiftSummary
    }
  }
  ${Gift.fragments.summary}
`;

export const ALL_GIFTS = gql`
  query AllGifts {
    allGifts {
      ...GiftSummary
    }
  }
  ${Gift.fragments.summary}
`;

export const ADMIN_GIFT = gql`
  query AdminGift($input: FindGiftInput!) {
    gift(input: $input) {
      ...GiftDetail
      orgSettings {
        org {
          id
          formattedName
        }
        isEnabled
      }
    }
  }
  ${Gift.fragments.detail}
`;

export const GIFT = gql`
  query Gift($input: FindGiftInput!) {
    gift(input: $input) {
      ...GiftDetail
    }
  }
  ${Gift.fragments.detail}
`;

export const CHARGES = gql`
  query Charges($input: ChargesInput!) {
    charges(input: $input) {
      id
      description
      dollars
      created
    }
  }
`;

export const CUSTOMER = gql`
  query Customer {
    customer {
      id
      email
      card {
        id
        last4
        brand
      }
    }
  }
`;

export const GIFT_REDEMPTION_SEND = gql`
  query GiftRedemptionSend($input: SendInput!) {
    send(input: $input) {
      id
      amount
      expiresAt
      giftName
      redemptionUrl
      status
      placementMethod
      placementId
      sendItems {
        id
        price
        title
      }
      creator {
        id
        name
      }
      message
      org {
        id
        formattedName
      }
    }
  }
`;

export const RECIPIENT_VIEW_SEND = gql`
  query RecipientViewSend($input: SendInput!) {
    send(input: $input) {
      id
      amount
      expiresAt
      giftName
      deliveryType
      redemptionUrl
      status
      isAddressPrivate
      address {
        id
        name
      }
      org {
        id
        formattedName
        logoUrl
        logoBackgroundColor
        logoPrimaryColor
      }
      creator {
        id
        name
      }
      sendItems {
        id
        price
        quantity
        productVariant {
          id
          product {
            featuredImageSrc
            description
          }
        }
      }
      message
      placementMethod
      isGiftCard
      giftName
      grandTotal
      gift {
        isCharity
        id
      }
      recipient {
        id
        name
        orgName
        email
      }
    }
  }
`;

export const ADMIN_SENDS = gql`
  query Sends($input: QuerySendsInput!) {
    sends(input: $input) {
      ...SendSummary
      externalId
      trackingUrl
      trackingNumber
      carrierCode
      message
      sendItems {
        id
        quantity
        title
        description
        price
      }
      gift {
        id
      }
      org {
        id
        formattedName
      }
      address {
        name
        street1
        street2
        city
        state
        zip
      }
      amazonItem {
        id
        productUrl
        name
        price
        size
      }
      sendItems {
        id
        quantity
        title
        description
        price
      }
      placementMethod
      placementId
      shopifyOrderName
    }
  }
  ${Send.fragments.sendSummary}
`;

export const ORG_BRANDING = gql`
  query OrgBranding {
    org {
      id
      name
      formattedName
      logoUrl
      logoBackgroundColor
      logoPrimaryColor
      messageTemplates {
        id
        text
        slug
        name
        emailSubject
        emailFrom
      }
    }
  }
`;

export const ORG_SENDS = gql`
  query OrgSends($input: DateRangeInput!) {
    org {
      id
      sends(input: $input) {
        id
        recipient {
          id
          name
          email
          orgName
        }
        creator {
          id
          email
          name
        }
        giftName
        grandTotal
        amount
        createdAt
        status
        shippingCost
        invoiceItems {
          id
          dollars
        }
      }
    }
  }
`;

export const ORG_PROFILE = gql`
  query OrgProfile {
    org {
      id
      name
      formattedName
      address {
        id
        name
        street1
        street2
        city
        state
        zip
      }
      settings {
        id
        requireSalesForceContact
      }
      saasSubscriptions {
        id
        status
      }
      activeSaasSubscription {
        id
        plan {
          id
          amountDollars
          nickname
        }
        periodStart
        periodEnd
        status
      }
    }
  }
`;

export const ALL_USERS = gql`
  query AllUsers {
    allUsers {
      id
      name
      email
    }
  }
`;

export const ORG_USERS = gql`
  query OrgUsers {
    org {
      id
      users {
        id
        name
        email
        role
        status
        salesForceUserId
      }
      salesForceUsers {
        id
        email
        name
      }
    }
  }
`;

export const ORG_TEAMS = gql`
  query OrgTeams {
    org {
      id
      teams {
        id
        name
        isAdmin
        permissions {
          id
          type
          gift {
            id
            title
          }
        }
        users {
          id
          name
        }
        invitations {
          id
          email
        }
      }
    }
  }
`;

export const HUBSPOT_OWNERS = gql`
  query HubSpotOwners {
    hubSpotOwners {
      id
      email
      name
      hubSpotUserId
    }
  }
`;

export const teamQueries = [{ query: ORG_USERS }, { query: ORG_TEAMS }];

export const ORG_CREDIT = gql`
  query OrgCredit {
    credit
  }
`;

export const SEND = gql`
  query Send($input: SendInput!) {
    send(input: $input) {
      ...SendSummary
      ...SendDetail
    }
  }
  ${Send.fragments.sendSummary}
  ${Send.fragments.sendDetail}
`;

export const USER_SENDS = gql`
  query UserSends {
    me {
      id
      sends {
        ...SendSummary
      }
    }
  }
  ${Send.fragments.sendSummary}
`;

export const HUBSPOT_TOKEN = gql`
  query HubSpotToken {
    hubSpotToken {
      appId
      user
      hubSpotUserId
      hubDomain
      hubId
    }
  }
`;

export const SALESFORCE_TOKEN = gql`
  query SalesForceToken {
    salesForceToken {
      id
      instanceUrl
    }
  }
`;

export const SALESFORCE_USER_INFO = gql`
  query SalesForceUserInfo {
    salesForceUserInfo {
      displayName
      id
      nickName
      username
    }
  }
`;

export const SALESFORCE_CONTACTS = gql`
  query SalesForceContacts($input: SalesForceContactsInput) {
    salesForceContacts(input: $input) {
      id
      name
      email
      phone
      account {
        id
        name
      }
      mailingAddress {
        street
        city
        state
        postalCode
        country
      }
    }
  }
`;

export const HUBSPOT_CONTACTS = gql`
  query HubSpotContacts($input: HubSpotContactsInput!) {
    hubSpotContacts(input: $input) {
      id
      companyId
      name
      company {
        id
        name
      }
      firstname
      lastname
      email
      address
      city
      state
      zip
    }
  }
`;

export const GIFTCARD_SEND_DETAILS = gql`
  query GiftCardSendDetails($input: GiftCardSendDetailsInput!) {
    giftCardSendDetails(input: $input) {
      amount
      link
      status
      expiresAt
    }
  }
`;

export const ORG_USERS_SENDS = gql`
  query OrgUserSends($sendsInput: QuerySendsInput) {
    org {
      id
      users {
        id
        name
        budget {
          id
          amount
        }
        sends(input: $sendsInput) {
          id
          invoiceItems {
            id
            dollars
          }
        }
      }
    }
  }
`;

export const OPPORTUNITIES = gql`
  query Opportunities($input: OpportunitiesInput!) {
    opportunities(input: $input) {
      ...SalesForceOpportunityInfo
    }
  }
  ${SalesForce.fragments.opportunityInfo}
`;

export const ALL_ORGS = gql`
  query AllOrgs {
    allOrgs {
      id
      formattedName
    }
  }
`;

export const STOREFRONT_COLLECTIONS = gql`
  query StorefrontCollections {
    storefrontCollections @client {
      id
      title
    }
  }
`;

export const STOREFRONT_COLLECTION = gql`
  query StorefrontCollection($id: ID!) {
    storefrontCollection(id: $id) @client {
      id
      products {
        id
        availableForSale
        title
      }
    }
  }
`;

export const GIFT_FILTER_FORM_VALUES = gql`
  query GiftFilterFormValues {
    giftFilterFormValues @client {
      storefrontCollectionId
    }
  }
`;

export const ADDON_PRODUCTS = gql`
  query AddonProducts {
    products(input: { isAddon: true }) {
      id
      title
      description
      featuredImageSrc
      isAddon
      options {
        id
        name
        position
      }
      variants {
        id
        title
        price
      }
    }
  }
`;

export const LOCATIONS = gql`
  query Locations {
    locations {
      id
      name
    }
  }
`;

export const GIFT_CARD_BRAND = gql`
  query GiftCardBrand($id: ID!) {
    giftCardBrand(id: $id) {
      id
      name
      iconUrl
      description
      redemptionMethods
      redemptionDisclaimer
      legalTerms
      faceplateUrl
    }
  }
`;

export const GIFT_CARD_BARCODE = gql`
  query GiftCardBarcode($input: GiftCardBarcodeInput!) {
    giftCardBarcode(input: $input) {
      id
      barcodeKind
      barcodeValue
      number
      csc
      viewCardUrl
    }
  }
`;

export const GIFT_CARD_CODE = gql`
  query GiftCardCode($id: ID!) {
    giftCardCode(id: $id) {
      id
      expiresAt
      send {
        id
        giftName
        placementId
        sendItems {
          id
          price
        }
      }
    }
  }
`;

export const CHARITY_GIFTS = gql`
  query CharityGifts {
    charityGifts {
      imageUrl
      id
      title
      isCharity
      items {
        id
        product {
          id
          variants {
            id
            price
            title
          }
        }
      }
    }
  }
`;

export const CAMPAIGNS = gql`
  query Campaigns {
    campaigns {
      expiresAt
      id
      name
      recipients {
        id
      }
    }
  }
`;

export const CAMPAIGN = gql`
  query Campaign($id: ID!) {
    campaign(id: $id) {
      message
      name
      expiresAt
      maxCostPerGift
      gifts {
        imageUrl
        id
        title
        isCharity
        items {
          id
          product {
            id
            variants {
              id
              price
              title
            }
          }
        }
      }
      creator {
        id
        name
        org {
          formattedName
          logoUrl
          logoBackgroundColor
          logoPrimaryColor
        }
      }
    }
  }
`;
