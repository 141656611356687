import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { Theme, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    display: 'inline-block',
    borderRadius: '50%',
    flexGrow: 0,
    flexShrink: 0
  },
  sm: {
    height: theme.spacing(0.5),
    width: theme.spacing(0.5)
  },
  md: {
    height: theme.spacing(1),
    width: theme.spacing(1)
  },
  lg: {
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  grey: {
    backgroundColor: theme.palette.grey['500']
  },
  primary: {
    backgroundColor: theme.palette.primary.light
  },
  info: {
    backgroundColor: theme.palette.info.light
  },
  warning: {
    backgroundColor: theme.palette.warning.light
  },
  error: {
    backgroundColor: theme.palette.error.light
  },
  success: {
    backgroundColor: theme.palette.success.light
  }
});

export type StatusDotProps = {
  classes: Record<string, any>;
  size: 'sm' | 'md' | 'lg';
  color: 'primary' | 'info' | 'success' | 'warning' | 'error' | 'grey';
};

const StatusDot: React.FC<StatusDotProps> = (props) => {
  const { classes, size, color } = props;

  const rootClassName = clsx({
    [classes.root]: true,
    [classes[size]]: size,
    [classes[color]]: color
  });

  return <span className={rootClassName} />;
};

export default withStyles(styles)(StatusDot);
