import { useQuery } from '@apollo/react-hooks';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { GIFT_REDEMPTION_SEND } from '../../graphql/queries';
import {
  GiftRedemptionSend,
  GiftRedemptionSendVariables,
  PlacementMethod
} from '../../__generated__/types';
import RedeemRaise from './RedeemRaise';
import RedeemTremendous from './RedeemTremendous';

export default function RedeemEGift() {
  const { sendId } = useParams<{ sendId: string }>();
  return <Redirect to={`/view/${sendId}`} />;
}
