import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Formik, FormikProps } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { PureQueryOptions } from 'apollo-client';
import Divider from '@material-ui/core/Divider';
import {
  Sends_sends,
  SendStatus,
  UpdateSend,
  UpdateSendVariables
} from '../../__generated__/types';
import { UPDATE_SEND } from '../../graphql/mutations';
import FormAlert from '../../components/FormAlert/FormAlert';

export type DropShipDeliverFormProps = {
  send: Sends_sends;
  onSuccess: () => void;
  refetchQueries?: Array<string | PureQueryOptions>;
};

export default function DropShipDeliverForm({
  send,
  onSuccess,
  refetchQueries
}: DropShipDeliverFormProps) {
  const [updateSend, { loading, error }] = useMutation<UpdateSend, UpdateSendVariables>(
    UPDATE_SEND,
    {
      refetchQueries,
      onCompleted: () => onSuccess()
    }
  );

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) =>
        updateSend({
          variables: { input: { id: send.id, status: SendStatus.DELIVERED } }
        })
      }
    >
      {({ handleSubmit }: FormikProps<{}>) => (
        <Grid container spacing={2} direction="column" alignItems="stretch">
          <Grid item xs>
            <Typography align="center" component="h1" variant="h5">
              Mark Delivered?
            </Typography>
            <br />
            <Divider light />
            <br />
          </Grid>
          {error && <FormAlert error={error} />}
          <Grid item xs>
            <form noValidate onSubmit={handleSubmit}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
