import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SendStatusComponent from '../../components/SendStatusComponent/SendStatusComponent';
import { DeliveryType, SendStatus } from '../../__generated__/types';
import SendStatusStepper from './SendStatusStepper';

export type SendStatusCardProps = {
  status: SendStatus | null;
  deliveryType: DeliveryType;
  isAddressPrivate?: boolean | null;
};

const SendStatusCard: React.FC<SendStatusCardProps> = (props) => {
  const { status, deliveryType, isAddressPrivate } = props;
  return (
    <>
      <Card>
        <CardContent>
          <SendStatusComponent status={status || SendStatus.NEW} />
          <SendStatusStepper
            status={status}
            deliveryType={deliveryType}
            isAddressPrivate={isAddressPrivate}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default SendStatusCard;
