import { useMutation } from '@apollo/client/react';
import {
  Box, Link, Typography
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { ASSET_UPLOAD_URL_CREATE } from '../../graphql/mutations';
import { Theme } from '../../theme';
import { AssetUploadUrlCreate, AssetUploadUrlCreateVariables } from '../../__generated__/types';

const useStyles = makeStyles<Theme, { isDragActive: boolean }>((theme) => ({
  container: {
    alignItems: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5
    }
  }
}));

type CsvDropzoneProps = {
  onDropAccepted: DropzoneOptions['onDropAccepted'];
};

const CsvDropzone: FC<CsvDropzoneProps> = (props) => {
  const { onDropAccepted } = props;
  const [createAssetUploadUrl] = useMutation<AssetUploadUrlCreate, AssetUploadUrlCreateVariables>(
    ASSET_UPLOAD_URL_CREATE
  );
  const [isUploading, setIsUploading] = React.useState(false);
  const snackbar = useSnackbar();
  const accept = '.csv';
  const maxSize = 1000000;
  const maxFiles = 1;

  const onDropRejected: DropzoneOptions['onDropRejected'] = (files, event) => {
    alert('Please upload a single csv. Max file size is 1MB.');
  };

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    onDropAccepted,
    onDropRejected
  });

  const classes = useStyles({ isDragActive });
  return (
    <Box className={classes.container} {...getRootProps()}>
      <input {...getInputProps()} />
      <Box>
        <Typography color="textPrimary" variant="body1">
          {`Drop logo file${maxFiles && maxFiles === 1 ? '' : 's'}`}
          {' or '}
          <Link color="primary" underline="always">
            browse
          </Link>{' '}
          thorough your computer. Only submit .csv
        </Typography>
      </Box>
    </Box>
  );
};

export default CsvDropzone;
