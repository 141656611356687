import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import isEqual from 'lodash/isEqual';
import EasyDateRange, {
  EasyDateRangeOption
} from '../../../../components/EasyDateRange/EasyDateRange';

export type EasyDateRangeProps = {
  onSubmit: (form: SalesForceAnalyticsFormVars) => void;
  initialValues?: SalesForceAnalyticsFormVars;
};

export type SalesForceAnalyticsFormVars = {
  easyDateRange: EasyDateRangeOption;
  dateFilterField?: string | null;
};

export default function SalesForceAnalyticsForm({ onSubmit, initialValues }: EasyDateRangeProps) {
  const [lastSubmit, setLastSubmit] = useState<SalesForceAnalyticsFormVars | null>(null);
  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValues || {
          easyDateRange: EasyDateRangeOption.MONTH_TO_DATE,
          dateFilterField: 'CloseDate'
        }
      }
      onSubmit={(values) => {
        setLastSubmit(values);
        onSubmit({ ...values });
      }}
    >
      {({ handleSubmit, values, handleChange }: FormikProps<SalesForceAnalyticsFormVars>) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <EasyDateRange onChange={handleChange} value={values.easyDateRange} />
                </Grid>
                <Grid item xs={12} sm>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="dateFilterField">Date applies to</InputLabel>
                    <Select
                      value={values.dateFilterField}
                      onChange={handleChange}
                      inputProps={{
                        name: 'dateFilterField',
                        id: 'dateFilterField'
                      }}
                    >
                      {['CloseDate', 'CreatedDate'].map((value) => (
                        <MenuItem value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm>
                  <Button
                    variant={!lastSubmit || isEqual(values, lastSubmit) ? 'outlined' : 'contained'}
                    color="primary"
                    type="submit"
                  >
                    Update Data
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}
