export function getContrastTextColor(hexcolor?: string) {
  if (!hexcolor || !/^#([0-9a-f]{3}){1,2}$/i.test(hexcolor)) {
    return '#000000';
  }

  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
}
