import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CreatedSend, CreatedSend_createdSend, DeliveryType } from '../../__generated__/types';
import CopyTextField from '../../components/CopyTextField/CopyTextField';
import { CREATED_SEND } from '../../graphql/queries';
import EGiftRedemptionCard from '../../components/EGiftRedemptionCard/EGiftRedemptionCard';
import Link from '@material-ui/core/Link';

type SuccessStepProps = {
  send?: CreatedSend_createdSend;
};
export default function SuccessStep(props: SuccessStepProps) {
  const { send } = props;
  const history = useHistory();
  const { data: { createdSend: savedCreatedSend = null } = {} } =
    useQuery<CreatedSend>(CREATED_SEND);
  const createdSend = send ?? savedCreatedSend;
  const isPhysicalGift = Boolean(createdSend?.deliveryType === DeliveryType.PHYSICAL);

  return (
    <Fragment>
      <Grid container justify="center" alignItems="center" spacing={2}>
        {!isPhysicalGift && createdSend && (
          <>
            <Grid item xs={12} sm={12} lg={8}>
              <Card>
                <CardContent>
                  <Alert severity={'info'}>
                    Don't forget the last step! Deliver this link to your recipient so they can
                    redeem the gift card you just created.
                  </Alert>
                  <br />
                  <Typography>Do not share this link with anyone besides the recipient.</Typography>
                  <br />
                  <CopyTextField
                    fullWidth
                    label={'Redeem Gift Card Link'}
                    id={'redeemGiftLink'}
                    value={window.location.origin + '/view/' + createdSend.id}
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
        {isPhysicalGift && createdSend && !createdSend.isAddressPrivate && (
          <Grid item xs sm={8} lg={8}>
            <Alert severity={'success'}>Success!</Alert>
            <br />
            <Card>
              <CardContent>
                <Typography component="span">
                  Your send for {createdSend.recipient.name} has been created! You may check for
                  status updates in the
                </Typography>{' '}
                <Link href="/history" component="a" style={{ fontSize: '1rem' }}>
                  History
                </Link>
                <Typography component="span"> tab.</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {isPhysicalGift && createdSend && createdSend.isAddressPrivate && (
          <Grid item xs sm={8} lg={8}>
            <Card>
              <CardContent>
                <Alert severity={'info'}>
                  Don't forget the last step! Your recipient needs to submit their address.
                </Alert>
                <br />
                <Typography>
                  Share the link below so they can complete the final step. Do not share this link
                  with anyone besides the recipient.
                </Typography>
                <br />
                <CopyTextField
                  fullWidth
                  label={'Address Submit Link'}
                  id={'addressSubmitLink'}
                  value={window.location.origin + '/view/' + createdSend.id}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
