import { makeStyles, Theme } from '@material-ui/core';

export const useSingleColumnFormStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const dangerButton = (theme: Theme) => ({
  dangerButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '&:active': {
      backgroundColor: theme.palette.error.light
    },
    '&:focus': {
      backgroundColor: theme.palette.error.main
    }
  }
});

export const dashboardContent = (theme: Theme) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 4)
  }
});

export const giftStepper = () => ({
  backgroundColor: `rgba(255,255,255,0)`
});

export const stepperBackButton = (theme: Theme) => ({
  marginBottom: theme.spacing(2)
});

export const secondaryAvatar = (theme: Theme) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main
});

export const scrollingList = (theme: Theme) => ({
  maxHeight: '300px',
  overflow: 'scroll'
});

export const chartColors = ['#4BC0C0', '#36a2eb', '#ffce56', '#ff6384', '#cc65fe'];
