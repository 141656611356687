import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { DeliveryType, SendStatus } from '../../__generated__/types';
import { formatSendStatus } from '../../domains/send/formatters';

export type SendStatusStepperProps = {
  orientation?: 'vertical' | 'horizontal';
  status: SendStatus | null;
  deliveryType: DeliveryType;
  isAddressPrivate?: boolean | null;
};

export default function SendStatusStepper({
  orientation = 'vertical',
  status,
  deliveryType,
  isAddressPrivate
}: SendStatusStepperProps) {
  const eGiftSteps = [SendStatus.NEW, SendStatus.REDEEMED];
  const shipmentSteps = [
    SendStatus.NEW,
    SendStatus.PROCESSING,
    SendStatus.SHIPPED_OUTBOUND,
    SendStatus.DELIVERED
  ];
  const privateAddressSteps = [
    SendStatus.NEW,
    SendStatus.PENDING_ADDRESS,
    SendStatus.ADDRESS_SUBMITTED,
    SendStatus.PROCESSING,
    SendStatus.SHIPPED_OUTBOUND,
    SendStatus.DELIVERED
  ];

  const physicalSends = [DeliveryType.PHYSICAL];
  const digitalSends = [DeliveryType.DIGITAL];
  const steps = physicalSends.includes(deliveryType)
    ? isAddressPrivate
      ? privateAddressSteps
      : shipmentSteps
    : eGiftSteps;
  const activeStepNumber = steps.indexOf(status || SendStatus.NEW);
  const classes = useStyles();

  return (
    <Stepper
      orientation={orientation}
      className={classes.giftStepper}
      activeStep={activeStepNumber}
    >
      {steps.map((step, index) => (
        <Step
          key={step}
          // Mark the current step as completed to avoid confusion
          completed={index <= activeStepNumber}
        >
          <StepLabel>{formatSendStatus(step)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    giftStepper: {
      backgroundColor: `rgba(255,255,255,0)`
    }
  })
);
