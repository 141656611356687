import React, { Fragment } from 'react';
import { ApolloError } from 'apollo-client';
import { formatDateTime, formatDollars } from '../../../../helpers/formatters';
import { OrgSends_org_sends, SendStatus } from '../../../../__generated__/types';
import FilterTable from '../../../../components/FilterTable/FilterTable';
import { calculateSendsCost } from '../../../Dashboard/helpers';
import FormAlert from '../../../../components/FormAlert/FormAlert';
import { useHistory } from 'react-router-dom';
import SendStatusComponent from '../../../../components/SendStatusComponent/SendStatusComponent';

export type SendAnalyticsRow = {
  id: string;
  creator: string;
  recipient: string;
  giftName: string;
  grandTotal: number;
  createdAt: string;
  status: SendStatus;
  orgName?: string | null;
  totalExpense: number;
};

export type SendAnalyticsTableProps = {
  data: Array<OrgSends_org_sends>;
  loading: boolean;
  error?: ApolloError;
};

export default function SendAnalyticsTable({ data, loading, error }: SendAnalyticsTableProps) {
  const history = useHistory();
  return (
    <Fragment>
      {error && <FormAlert error={error} />}
      <FilterTable
        isLoading={loading}
        options={{
          search: true,
          showTitle: true,
          loadingType: 'linear',
          exportButton: true,
          exportAllData: true,
          showEmptyDataSourceMessage: !loading
        }}
        actions={[
          {
            icon: 'launch',
            tooltip: 'View Details',
            onClick: (event, rowData) => {
              // Make sure that selected data is only one row
              if (Array.isArray(rowData)) return;
              history.push(`/send/${rowData.id}`);
            }
          }
        ]}
        columns={[
          { field: 'id', hidden: true },
          { field: 'creator', title: 'Creator', filtering: true },
          {
            field: 'createdAt',
            filtering: false,
            defaultSort: 'desc',
            title: 'Created At',
            render: (data) => <>{formatDateTime(data.createdAt)}</>
          },
          { field: 'recipient', title: 'Recipient', filtering: true },
          { field: 'recipientEmail', title: 'Recipient Email', filtering: true },
          { field: 'orgName', title: 'Acct / Org', filtering: true },
          {
            field: 'totalExpense',
            type: 'numeric',
            title: 'Total Expense',
            render: (data) => <>{formatDollars(data.totalExpense)}</>
          },
          { field: 'giftName', title: 'Gift', filtering: true },
          {
            field: 'status',
            title: 'Status',
            lookup: SendStatus,
            filtering: true,
            render: (data) => (
              <>
                <SendStatusComponent status={data.status} />
              </>
            )
          }
        ]}
        data={processData(data)}
        title="Send History"
      />
    </Fragment>
  );
}

function processData(sends: OrgSends_org_sends[]): SendAnalyticsRow[] {
  return sends.map((send) => ({
    ...send,
    totalExpense: calculateSendsCost([send]),
    status: send.status || SendStatus.NEW,
    creator: send.creator.name || send.creator.email,
    recipient: send.recipient.name,
    orgName: send.recipient.orgName,
    recipientEmail: send.recipient.email
  }));
}
