import React, { ReactNode, ReactText } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

export type SummaryStatCardProps = {
  title: string;
  label?: string;
  content: ReactText | ReactNode;
};
export default function SummaryStatCard({ title, content, label }: SummaryStatCardProps) {
  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        action={
          label && (
            <Chip
              size={'small'}
              label={label}
              color={label === 'This Month' ? 'primary' : 'secondary'}
            />
          )
        }
      />
      <CardContent>
        <Typography variant="h5">{content}</Typography>
      </CardContent>
    </Card>
  );
}
