import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {
  GiftRequestNew,
  GiftRequestNewInput,
  GiftRequestNewVariables
} from '../../__generated__/types';
import { useMutation } from '@apollo/react-hooks';
import { GIFT_REQUEST_NEW } from '../../graphql/mutations';
import { Formik, FormikConfig } from 'formik';
import PrimaryTextField from '../TextField/PrimaryTextField';
import MessageField from '../MessageField/MessageField';
import Button from '../Button/Button';
import { useSnackbar } from 'notistack';

export type RequestNewGiftDialogProps = DialogProps & { onSuccess: () => void };

const RequestNewGiftDialog: React.FC<RequestNewGiftDialogProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues: { productUrl: string; message: string } = {
    productUrl: '',
    message: ''
  };
  const onSubmit: FormikConfig<typeof initialValues>['onSubmit'] = async (
    values: GiftRequestNewInput,
    { setValues }
  ): Promise<void> => {
    await requestNewGift({ variables: { input: values } })
      .then(() => {
        setValues(initialValues);
        enqueueSnackbar(
          'Request received! We will reach out to you over email for updates on your request',
          { variant: 'success' }
        );
        props.onSuccess();
      })
      .catch(() => enqueueSnackbar('Something went wrong. Try again.', { variant: 'error' }));
  };
  const [requestNewGift, { loading }] = useMutation<GiftRequestNew, GiftRequestNewVariables>(
    GIFT_REQUEST_NEW
  );
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleChange, submitForm, values }) => (
        <Dialog {...props}>
          <DialogContent>
            <Typography gutterBottom variant={'h4'}>
              Request for a new gift option to be added.
            </Typography>
            <Typography variant={'body2'}>
              We'll get back to you over email for any follow up questions and updates about your
              gift request.
            </Typography>
            <br />
            <PrimaryTextField
              fullWidth
              required
              id={'productUrl'}
              label={'Url to the product'}
              value={values.productUrl}
              onChange={handleChange}
              name={'productUrl'}
            />
            <MessageField
              fullWidth
              required
              rows={4}
              charLimit={1000}
              id={'message'}
              label={'Tell us about what you want'}
              value={values.message}
              onChange={handleChange}
              name={'message'}
            />
            <Button loading={loading} variant={'contained'} color={'primary'} onClick={submitForm}>
              Submit
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};

export default RequestNewGiftDialog;
