import React, { Fragment, useEffect, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DangerButton from '../../../../../components/DangerButton/DangerButton';
import {
  OrgUsers_org_salesForceUsers,
  OrgUsers_org_users,
  ResendInvitationEmail,
  ResendInvitationEmailVariables,
  UpdateUser,
  UpdateUserVariables,
  UserStatus,
  RevokeInvite,
  RevokeInviteVariables
} from '../../../../../__generated__/types';
import { ME, ORG_USERS } from '../../../../../graphql/queries';
import {
  RESEND_INVITATION_EMAIL,
  UPDATE_USER,
  REVOKE_INVITE
} from '../../../../../graphql/mutations';
import FormAlert from '../../../../../components/FormAlert/FormAlert';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PrimaryTextField from '../../../../../components/PrimaryTextField/PrimaryTextField';
type UpdateUserDialogProps = {
  onSuccess: () => void;
  salesForceUsers: OrgUsers_org_salesForceUsers[] | null;
  user: OrgUsers_org_users | null;
} & DialogProps;

export default function UpdateUserDialog({
  user,
  onSuccess,
  open,
  onClose,
  salesForceUsers
}: UpdateUserDialogProps) {
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [updateUser, { loading, error }] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER,
    {
      onCompleted: () => onSuccess(),
      refetchQueries: [{ query: ME }, { query: ORG_USERS }]
    }
  );
  const [
    revokeInvite,
    { loading: revokeInviteLoading, error: revokeInvitationError }
  ] = useMutation<RevokeInvite, RevokeInviteVariables>(REVOKE_INVITE, {
    onCompleted: () => {
      setShowSuccess(true);
    },
    refetchQueries: [{ query: ORG_USERS }]
  });
  const [
    resendInvitationEmail,
    { loading: resendInvitationLoading, error: resendInvitationError }
  ] = useMutation<ResendInvitationEmail, ResendInvitationEmailVariables>(RESEND_INVITATION_EMAIL, {
    onCompleted: () => {
      setShowSuccess(true);
    }
  });

  function handleUserDelete(user: OrgUsers_org_users) {
    return updateUser({ variables: { input: { id: user.id, status: UserStatus.INACTIVE } } });
  }

  function handleUserActivate(user: OrgUsers_org_users) {
    return updateUser({ variables: { input: { id: user.id, status: UserStatus.ACTIVE } } });
  }

  function onExit() {
    setShowSuccess(false);
  }

  return (
    <Dialog open={open} onClose={onClose} onExit={onExit} fullWidth maxWidth="sm">
      <DialogContent>
        {user && user.status === UserStatus.INVITED && (
          <Fragment>
            {resendInvitationError && <FormAlert error={resendInvitationError} />}
            {revokeInvitationError && <FormAlert error={revokeInvitationError} />}
            {showSuccess && <Alert severity={'success'}>Success</Alert>}
            {!showSuccess && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom>Invite to {user.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() =>
                      resendInvitationEmail({
                        variables: {
                          input: {
                            id: user.id
                          }
                        }
                      })
                    }
                    disabled={resendInvitationLoading}
                  >
                    Resend Invitation Email
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <br />
                  <Divider light />
                </Grid>
                <Grid item xs={12}>
                  <DangerButton
                    onClick={() => revokeInvite({ variables: { input: { userId: user.id } } })}
                    disabled={revokeInviteLoading}
                  >
                    Revoke Invitation
                  </DangerButton>
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
        {user && user.status === UserStatus.INACTIVE && (
          <Fragment>
            <Typography variant={'h5'}>Reactivate {user.name}</Typography>
            <br />
            <Typography>
              Are you sure you want to activate {user.name}? This will incur billing charges.
            </Typography>
            <br />
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth
              disabled={loading}
              onClick={() => handleUserActivate(user)}
            >
              {loading ? 'Loading...' : 'Accept Charges and Activate'}
            </Button>
          </Fragment>
        )}
        {user && user.status === UserStatus.ACTIVE && (
          <Fragment>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h4'}>{user.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox onChange={() => setShowDelete((prevState) => !prevState)} />}
                  label={'Remove User'}
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={showDelete}>
                  <Typography>Are you sure you wish to remove this user?</Typography>
                  <DangerButton
                    fullWidth
                    variant="contained"
                    color="inherit"
                    disabled={loading}
                    onClick={() => handleUserDelete(user)}
                  >
                    Confirm User Removal
                  </DangerButton>
                </Collapse>
              </Grid>
            </Grid>
            <br />
            {error && <FormAlert error={error} />}
            <Formik
              initialValues={{
                id: user.id,
                name: user.name,
                salesForceUserId: user.salesForceUserId || ''
              }}
              onSubmit={(values) =>
                updateUser({
                  variables: {
                    input: { ...values, salesForceUserId: values.salesForceUserId || null }
                  }
                })
              }
              validationSchema={Yup.object().shape({
                id: Yup.string().required()
              })}
            >
              {({ handleSubmit, values, handleChange, handleBlur }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="salesForceUserId">
                          Connected SalesForce User
                        </InputLabel>
                        <Select
                          disabled={!salesForceUsers}
                          fullWidth
                          value={values.salesForceUserId}
                          onChange={handleChange}
                          inputProps={{
                            name: 'salesForceUserId',
                            id: 'salesForceUserId'
                          }}
                        >
                          <MenuItem key={'Not Connected'} value={''}>
                            Not Connected
                          </MenuItem>
                          {(salesForceUsers ? [...salesForceUsers] : [])
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((salesForceUser: OrgUsers_org_salesForceUsers) => (
                              <MenuItem key={salesForceUser.id} value={salesForceUser.id}>
                                {salesForceUser.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {!salesForceUsers && (
                          <FormHelperText>Connect with SalesForce to link users.</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <PrimaryTextField
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="no"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant={'contained'}
                        type={'submit'}
                        color={'primary'}
                        disabled={loading}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                </form>
              )}
            </Formik>
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}
