import type { FC } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import styled from '@material-ui/styles/styled';
import { Theme } from '../../theme';
import makeStyles from '@material-ui/styles/makeStyles';
import FileIcon from '@material-ui/icons/FileCopy';
import { ASSET_UPLOAD_URL_CREATE } from '../../graphql/mutations';
import { AssetUploadUrlCreate, AssetUploadUrlCreateVariables } from '../../__generated__/types';
import { useMutation } from '@apollo/client/react';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles<Theme, { isDragActive: boolean }>((theme) => ({
  container: {
    alignItems: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5
    }
  }
}));

type FileDropzoneProps = {
  onUpload: (url: string) => void;
};

const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const { onUpload } = props;
  const [createAssetUploadUrl] = useMutation<AssetUploadUrlCreate, AssetUploadUrlCreateVariables>(
    ASSET_UPLOAD_URL_CREATE
  );
  const [isUploading, setIsUploading] = React.useState(false);
  const snackbar = useSnackbar();
  const accept = 'image/jpeg, image/png';
  const maxSize = 1000000;
  const maxFiles = 1;

  const onDropAccepted: DropzoneOptions['onDropAccepted'] = async (files) => {
    const file = files[0];
    const contentType = file.type;
    try {
      setIsUploading(true);
      const { data, errors } = await createAssetUploadUrl({
        variables: { input: { contentType } }
      });
      if (errors || !data?.assetUploadUrlCreate) {
        throw errors || new Error('Failed');
      }
      const result = await fetch(data.assetUploadUrlCreate.url, {
        method: 'PUT',
        body: file
      });
      console.log(result);
      const assetUrl = data.assetUploadUrlCreate.url.split('?')[0];
      onUpload(assetUrl);
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar('There was an issue uploading your image. Try again.', {
        variant: 'error'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const onDropRejected: DropzoneOptions['onDropRejected'] = (files, event) => {
    alert('Please upload a single .jpeg or .png file. Max file size is 1MB.');
  };

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    onDropAccepted,
    onDropRejected
  });

  const classes = useStyles({ isDragActive });
  return (
    <Box className={classes.container} {...getRootProps()}>
      <input {...getInputProps()} />
      <Box>
        <Typography color="textPrimary" variant="body1">
          {`Drop logo file${maxFiles && maxFiles === 1 ? '' : 's'}`}
          {' or '}
          <Link color="primary" underline="always">
            browse
          </Link>{' '}
          thorough your computer. Only submit .png or .jpeg
        </Typography>
      </Box>
    </Box>
  );
};

export default FileDropzone;
