import React, { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import EasyDateRange, {
  EasyDateRangeOption
} from '../../../../components/EasyDateRange/EasyDateRange';
import { mapEasyRangeToRange } from '../../../Dashboard/helpers';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

type HubSpotDealsFormValues = {
  dateRangeOption: EasyDateRangeOption;
  from: Date;
  to: Date;
};

const useHubSpotDealsForm = () => {
  const { values, submitForm, setFieldValue } = useFormik<HubSpotDealsFormValues>({
    initialValues: {
      dateRangeOption: EasyDateRangeOption.LAST_30_DAYS,
      ...mapEasyRangeToRange(EasyDateRangeOption.LAST_30_DAYS)
    },
    onSubmit: () => {}
  });

  const handleDateRangeChange = ({
    target: { value }
  }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { to, from } = mapEasyRangeToRange(value as EasyDateRangeOption);
    setFieldValue('to', to);
    setFieldValue('from', from);
    setFieldValue('dateRangeOption', value);
    return submitForm();
  };

  const HubSpotDealsForm: React.FC = () => (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <EasyDateRange
              label="Sends created within:"
              onChange={handleDateRangeChange}
              value={values.dateRangeOption}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return {
    values,
    HubSpotDealsForm
  };
};

export default useHubSpotDealsForm;
