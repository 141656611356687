import Grid from '@material-ui/core/Grid';
import React, { Fragment, useEffect } from 'react';
import {
  Customer,
  Gifts,
  Me,
  Me_me,
  Gifts_gifts,
  PermissionType,
  SalesForceContact,
  SalesForceContactVariables,
  SelectedSalesForceContact,
  GiftFilterFormValues,
  StorefrontCollection,
  StorefrontCollectionVariables,
  GiftsVariables
} from '../../__generated__/types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  STOREFRONT_COLLECTION,
  CUSTOMER,
  GIFT_FILTER_FORM_VALUES,
  GIFTS,
  ME,
  SALESFORCE_CONTACT,
  SELECTED_SALESFORCE_CONTACT
} from '../../graphql/queries';
import * as qs from 'query-string';
import { handleSalesForceContactSelect } from '../SendGift/helpers';
import Loading from '../../components/Loading/Loading';
import FormAlert from '../../components/FormAlert/FormAlert';
import NoCardMessage from './components/NoCardMessage/NoCardMessage';
import FancyCard from './components/FancyCard/FancyCard';
import GiftMenuFilter from '../../components/GiftMenuFilter/GiftMenuFilter';
import GiftMenuCard from '../../components/GiftMenuCard/GiftMenuCard';
import GiftGrid from './components/GiftGrid/GiftGrid';
import RequestNewGiftDialogButton from '../../components/RequestNewGiftDialog/RequestNewGiftDialogButton';
import round from 'lodash/round';

export default function SendGiftMenu() {
  const client = useApolloClient();
  const history = useHistory();
  const { data: { me = null } = {}, loading: meLoading, error: meError } = useQuery<Me>(ME);
  const {
    data: { customer = null } = {},
    loading: customerLoading,
    error: customerError
  } = useQuery<Customer>(CUSTOMER);
  const { data: { selectedSalesForceContact = null } = {} } = useQuery<SelectedSalesForceContact>(
    SELECTED_SALESFORCE_CONTACT
  );

  // START handle landing from salesforce button "Send Gift"
  const [
    findSalesForceContact,
    { loading: salesForceContactLoading, error: salesForceContactError }
  ] = useLazyQuery<SalesForceContact, SalesForceContactVariables>(SALESFORCE_CONTACT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ salesForceContact }) => {
      // Select the result
      if (salesForceContact) {
        handleSalesForceContactSelect(salesForceContact, true, client.cache);
      }
      // Remove the query params
      history.push(`/dashboard/send-gift`);
    }
  });

  useEffect(() => {
    const params = qs.parse(window.location.search);
    if (!(params.salesForceContactId && typeof params.salesForceContactId === 'string')) {
      return;
    }
    findSalesForceContact({ variables: { input: { id: params.salesForceContactId } } });
  }, [window.location.search]);
  // END handle landing from salesforce button "Send Gift"

  if (meLoading || customerLoading || salesForceContactLoading) {
    return <Loading />;
  }
  if (meError || customerError) {
    return (
      <Fragment>
        <FormAlert error={meError || customerError} />
      </Fragment>
    );
  }
  return (
    <Grid container direction="row" alignItems="flex-start" justify="space-around" spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h3'}>Your budget is ${round(me?.budget?.amount ?? 0, 2)}</Typography>
        {selectedSalesForceContact && <Typography>to {selectedSalesForceContact.name}</Typography>}
        <Divider light />
        <br />
        {me && me.team?.name === 'Admins' && (
          <Typography gutterBottom>
            Want to send something you don't see here?{' '}
            <RequestNewGiftDialogButton text={'Just ask us to add it!'} />
          </Typography>
        )}
      </Grid>
      {salesForceContactError && (
        <Grid item xs={12}>
          <FormAlert error={salesForceContactError} />
        </Grid>
      )}
      <Grid item style={{ width: 300 }}>
        <GiftMenuFilter />
      </Grid>
      <Grid item xs={12} sm={12} md style={{ width: `calc(100% - 300px)` }}>
        <GiftGrid />
      </Grid>
    </Grid>
  );
}

function canUserSendGift(me: Me_me | null, gift: Gifts_gifts): boolean {
  if (!me || !me.permissions) return false;
  if (me.permissions.find((permission) => permission.type === PermissionType.All)) return true;
  return !!me.permissions.find((permission) => permission.gift && permission.gift.id === gift.id);
}
