import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import round from 'lodash/round';
import qs from 'query-string';

export type AmazonItem = {
  productUrl: string;
  productName: string;
  amazonProductId: string;
  quantity: number;
  price: number;
  isPrime: boolean;
  size: string | null;
  imgUrl: string | null;
};

/**
 * These are the types defined in the chrome extension.
 * Todo: Define this interface in a shared package! Right now
 *   whenever this is updated in the chrome extension, it will need to
 *   be manually updated here.
 */
export type QueryParams = {
  productUrl: string;
  amazonProductId: string;
  quantity: string;
  name: string;
  price: string;
  isPrime: string;
  size: string;
  imgUrl?: string;
};

/**
 * Use the query params supplied to this page to fill the input to the hidden AmazonItemForm.
 * It's expected that the user will be redirected to this page from the Amazon product page,
 * and the query params will contain the product information needed to checkout.
 */
export default function useAmazonItemQueryParams(): AmazonItem | null {
  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackbar();
  const [amazonItem, setAmazonItem] = React.useState<AmazonItem | null>(null);

  const onSuccess = () => {
    snackbar.enqueueSnackbar(
      'Successfully imported product from Amazon! Continue with recipient information.',
      { variant: 'success' }
    );
    // Remove the params
    history.push(`/send-gift/amazon`);
  };

  const onError = () => {
    snackbar.enqueueSnackbar(
      'Unsuccessful import of Amazon product. Ensure that size / options were selected. Contact support if this persists.',
      { variant: 'error', persist: true }
    );
  };
  React.useEffect(() => {
    const params = qs.parse(location.search) as QueryParams;
    console.log('Amazon Item Params:', params);

    // Todo: use better validation for params. It could fail if other params are missing,
    // but currently wouldn't throw an error.
    if (params && params.amazonProductId) {
      // Add 10% to the price to account for tax
      const price = round(Number(params.price) * 1.1, 2);
      if (!(price > 0)) {
        onError();
        return;
      }
      setAmazonItem({
        productUrl: String(params.productUrl),
        amazonProductId: String(params.amazonProductId),
        quantity: Number(params.quantity) || 1,
        productName: String(params.name),
        price,
        isPrime: Boolean(Number(params.isPrime)),
        size: params.size,
        imgUrl: params.imgUrl ?? null
      });
      onSuccess();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return amazonItem;
}
