import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UserSendsTable from '../Dashboard/UserSendsTable';

export default function History() {
  return (
    <Fragment>
      <Grid container direction="row" spacing={2} justify="flex-start" alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h3">History</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs>
          <UserSendsTable />
        </Grid>
      </Grid>
    </Fragment>
  );
}
