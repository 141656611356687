import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IntegrationCard from './components/IntegrationCard/IntegrationCard';
import hubSpotLogo from '../../../assets/images/hubspot-logo.png';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

export default function Integrations() {
  return (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item xs={12}>
        <Breadcrumbs />
        <br />
      </Grid>
      <Grid item>
        <Typography variant="h3">Integrations</Typography>
        <Divider light />
        <br />
      </Grid>
      <Grid item>
        <Grid container spacing={4} justify={'flex-start'} alignItems={'stretch'}>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <IntegrationCard
              title={'SalesForce'}
              link={'/dashboard/integrations/salesforce'}
              image={'https://img.icons8.com/color/96/000000/salesforce.png'}
              description={'Link your SalesForce and RevSend metrics'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <IntegrationCard
              title={'HubSpot'}
              image={hubSpotLogo}
              link={'/dashboard/integrations/hubspot'}
              description={'Link your RevSend data into HubSpot'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <IntegrationCard
              title={'Outreach.io'}
              image={'https://www.outreach.io/_resources/img/logo.min.svg'}
              description={'Link to OutReach.io '}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <IntegrationCard
              title={'Amazon.com'}
              link={'/dashboard/integrations/amazon'}
              image={'https://img.icons8.com/color/96/000000/amazon.png'}
              description={'Send gifts on RevSend by shopping in Amazon'}
            />
          </Grid>
          {/*<Grid item xs={12} sm={4} md={4} lg={3}>*/}
          {/*  <IntegrationCard*/}
          {/*    title={'Slack'}*/}
          {/*    link={'/dashboard/integrations/slack'}*/}
          {/*    image={'https://cdn.brandfolder.io/5H442O3W/as/pl546j-7le8zk-5guop3/Slack_RGB.png?width=200&height=82'}*/}
          {/*    description={'Get RevSend notifications through slack'}*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    </Grid>
  );
}
