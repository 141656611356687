import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import Loading from 'components/Loading/Loading';
import { useValidateAddressDialog } from 'components/ValidateAddressDialog/ValidateAddressDialog';
import { REDEEM_GIFTCARD, UPDATE_SEND, SEND_GIFTCODE_EMAIL } from 'graphql/mutations';
import { RECIPIENT_VIEW_SEND } from 'graphql/queries';
import { formatDollars } from 'helpers/formatters';
import { Topbar } from 'layouts/Minimal/components';
import { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import theme, { Theme } from 'theme';
import { getContrastTextColor } from 'views/Settings/Branding/utils';
import {
  DeliveryType,
  PlacementMethod,
  RecipientViewSend,
  RecipientViewSendVariables,
  RedeemGiftCard,
  RedeemGiftCardVariables,
  SendGiftCodeEmail,
  SendGiftCodeEmailVariables,
  SendStatus,
  UpdateSend,
  ValidateAddress_validateAddress
} from '__generated__/types';
import { AddressFormValues } from '../SendGift/Product/AddressForm';
import CloseIcon from '@material-ui/icons/Close';
import { VISA_TERMS, VISA_TERMS_URL, VISA_TERMS_URL_LABEL } from 'helpers/gift';
import ActionButton from './ActionButton';
import { useInputStyles } from './inputStyles';
import { getMessagesFromApolloError } from 'graphql/utils';

type StyleProps = {
  logoBackgroundColor: string;
  logoPrimaryColor: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  main: {
    padding: theme.spacing(4)
  },
  giftCardIcon: {
    position: 'absolute',
    margin: `-18px auto 0 auto`,
    left: 0,
    right: 0,
    width: 40,
    color: 'white',
    backgroundColor: '#bdbdbd'
  },
  giftIconAvatar: {
    position: 'absolute'
  },
  logoImg: {
    maxWidth: 200,
    margin: 16,
    flex: '1 0 auto'
  },
  header: {
    backgroundColor: (props) => props.logoBackgroundColor,
    color: (props) => getContrastTextColor(props.logoBackgroundColor),
    display: 'flex',
    justifyContent: 'center'
  },
  actionButton: {
    backgroundColor: (props) => props.logoPrimaryColor,
    color: (props) => getContrastTextColor(props.logoPrimaryColor)
  },
  giftCardPaper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  giftDetails: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1)
  },
  giftInstructions: {
    padding: theme.spacing(1)
  },
  message: {
    fontFamily: `'Caveat', cursive`,
    fontSize: 22,
    whiteSpace: 'pre-wrap'
  },
  columnContainer: {
    maxWidth: 500
  }
}));

export type RecipientLandingProps = {
  logoUrl: string;
  contrastTextColor: string;
  productPrice: string;
  deliveryType: DeliveryType;
  placementMethod: PlacementMethod;
  productImage?: string;
  productDescription?: string;
  isAddressSubmitted: boolean;
  addressNeedsToBeSubmited: boolean;
  isGiftCardRedeemed: boolean;
  needsToBeRedeemed: boolean;
  sendId: string;
  sendMessage: string;
  sendGrandTotal?: number;
  orgName: string;
  giftName: string;
  isGiftCard: boolean;
  logoPrimaryColor: string;
  logoBackgroundColor: string;
  creatorName: string;
  isPreview: boolean;
  recipientEmail?: string;
  hasBeenDonated?: boolean;
};

export const RecipientLanding: React.FC<RecipientLandingProps> = (props) => {
  const {
    logoUrl,
    logoBackgroundColor,
    logoPrimaryColor,
    contrastTextColor,
    productPrice,
    deliveryType,
    placementMethod,
    productImage,
    productDescription,
    isAddressSubmitted,
    addressNeedsToBeSubmited,
    isGiftCardRedeemed,
    needsToBeRedeemed,
    giftName,
    orgName,
    isGiftCard,
    creatorName,
    sendId,
    sendGrandTotal,
    sendMessage,
    isPreview,
    recipientEmail,
    hasBeenDonated
  } = props;
  const classes = useStyles({ logoBackgroundColor, logoPrimaryColor });

  const [productDetailOpen, setProductDetailOpen] = React.useState<boolean>(false);

  const isVisaCard = /^visa\s/i.test(props.giftName);
  const giftEuphamism = isVisaCard ? 'Reward' : 'Gift';

  if (hasBeenDonated) {
    return (
      <main className={classes.main}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item className={classes.columnContainer}>
            <Alert>
              Your charitable donation has been successfully processed. {orgName} thanks you for
              your generosity.
            </Alert>
          </Grid>
        </Grid>
      </main>
    );
  }

  return (
    <>
      <Dialog open={productDetailOpen} onClose={() => setProductDetailOpen(false)} maxWidth="xs">
        <DialogTitle style={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton aria-label="close" onClick={() => setProductDetailOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider light />
        <DialogContent>
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <Typography>{giftName}</Typography>
            </Grid>
            <Grid item>
              <img width={250} alt={giftName} src={productImage} />
            </Grid>
            <Grid item>
              {productDescription ? <Typography>{productDescription}</Typography> : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <main className={classes.main}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item className={classes.columnContainer}></Grid>
          <Grid item className={classes.columnContainer}>
            <Card>
              {logoUrl ? (
                <header className={clsx(classes.header)}>
                  <img src={logoUrl} alt={orgName} className={classes.logoImg} />
                </header>
              ) : null}
              <Divider light />
              <Box sx={{ p: 2 }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Typography gutterBottom align="center">
                      {creatorName} sent you a {giftEuphamism}!
                    </Typography>
                    <Typography align="center" gutterBottom>
                      <strong>
                        {giftName}
                        {isGiftCard && sendGrandTotal ? ` - ${formatDollars(sendGrandTotal)}` : ''}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a
                      style={{
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        color: contrastTextColor,
                        textAlign: 'center',
                        borderRadius: '3px',
                        backgroundColor: logoPrimaryColor,
                        textDecoration: 'none',
                        padding: '4px 6px',
                        cursor: 'pointer',
                        border: 'none',
                        marginTop: 4
                      }}
                      onClick={() => setProductDetailOpen(true)}
                      role="button"
                    >
                      View {giftEuphamism} Details
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                    <Divider light />
                    <br />
                    <Typography color="textSecondary" className={classes.message}>
                      {sendMessage}
                    </Typography>
                    <br />
                    <Divider light />
                    <br />
                    {/* Show the address submit form */}
                    {!isGiftCard && addressNeedsToBeSubmited && (
                      <PhysicalDeliveryInstuctions
                        logoPrimaryColor={logoPrimaryColor}
                        contrastTextColor={contrastTextColor}
                        sendId={sendId}
                        isPreview={isPreview}
                      />
                    )}
                    {/* Show the redeem button */}
                    {isGiftCard && (
                      <GiftCardInstructions
                        logoPrimaryColor={logoPrimaryColor}
                        contrastTextColor={contrastTextColor}
                        sendId={sendId}
                        isPreview={isPreview}
                        isGiftCardRedeemed={isGiftCardRedeemed}
                        needsToBeRedeemed={needsToBeRedeemed}
                        placementMethod={placementMethod}
                        giftName={giftName}
                        recipientEmail={recipientEmail}
                      />
                    )}

                    {/* Show the address submit success message */}
                    {isAddressSubmitted && (
                      <Alert severity="success">
                        Your address has been successfully submitted. Please allow up to 10 business
                        days for your gift to ship.
                      </Alert>
                    )}
                    <br />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

// Route: /view/:sendId
const RecipientLandingContainer: React.FC = () => {
  const { sendId } = useParams<{ sendId: string }>();
  const { data: { send = null } = {}, loading } = useQuery<
    RecipientViewSend,
    RecipientViewSendVariables
  >(RECIPIENT_VIEW_SEND, {
    variables: { input: { id: sendId } }
  });
  const logoBackgroundColor = send?.org?.logoBackgroundColor ?? '#FFFFFF';
  const logoPrimaryColor = send?.org?.logoPrimaryColor ?? theme.palette.primary.main;

  const classes = useStyles({ logoBackgroundColor, logoPrimaryColor });

  if (loading) {
    return (
      <Grid container spacing={10} justifyContent="center" style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }
  // Capture exceptions / bad send ID's
  if (!send || !send.org) {
    return (
      <>
        <Topbar />
        <Grid container spacing={10} justifyContent="center" style={{ marginTop: 8 }}>
          <Grid item>
            There's a temporary problem with this gift. Comeback later, or contact customer support
            for help.
          </Grid>
        </Grid>
      </>
    );
  }

  // Redirect to Tremendous if gift card is a Tremendous card
  if (send.placementMethod === PlacementMethod.TREMENDOUS && send.redemptionUrl) {
    window.location.replace(send.redemptionUrl);
  }

  // Derived state
  const logoUrl = send?.org?.logoUrl;
  const contrastTextColor = getContrastTextColor(logoPrimaryColor);
  const productPrice = String(send?.sendItems?.[0]?.price ?? 0);
  const deliveryType = send?.deliveryType;
  const placementMethod = send?.placementMethod;
  const product = send.sendItems?.map((item) => item.productVariant?.product)?.[0];
  const isAddressSubmitted = Boolean(!send.isGiftCard && send.isAddressPrivate && send.address);
  const addressNeedsToBeSubmited = Boolean(
    !send.isGiftCard && send.isAddressPrivate && !send.address
  );
  const isGiftCardRedeemed = Boolean(send.isGiftCard && send.status === SendStatus.REDEEMED);
  const needsToBeRedeemed = Boolean(send.isGiftCard && send.status !== SendStatus.REDEEMED);
  const hasBeenDonated = Boolean(isGiftCardRedeemed && send.gift?.isCharity);

  const recipientLandingProps: RecipientLandingProps = {
    logoUrl: logoUrl ?? '',
    logoPrimaryColor,
    logoBackgroundColor,
    contrastTextColor,
    productPrice,
    deliveryType,
    placementMethod,
    productImage: product?.featuredImageSrc ?? '',
    productDescription: product?.description,
    isAddressSubmitted,
    addressNeedsToBeSubmited,
    isGiftCardRedeemed,
    needsToBeRedeemed,
    sendId: send.id,
    sendMessage: send.message ?? '',
    sendGrandTotal: send.grandTotal,
    orgName: send.org.formattedName,
    giftName: send.giftName,
    isGiftCard: Boolean(send.isGiftCard),
    creatorName: send.creator.name,
    isPreview: false,
    recipientEmail: send.recipient.email,
    hasBeenDonated
  };

  return <RecipientLanding {...recipientLandingProps} />;
};

function GiftCardInstructions(
  props: Pick<
    RecipientLandingProps,
    | 'logoPrimaryColor'
    | 'contrastTextColor'
    | 'isPreview'
    | 'sendId'
    | 'placementMethod'
    | 'isGiftCardRedeemed'
    | 'needsToBeRedeemed'
    | 'giftName'
    | 'recipientEmail'
  >
) {
  const { enqueueSnackbar } = useSnackbar();

  // TODO: Handle raise vs. tremendous gift cards.
  const [redeemGiftCard, { loading }] = useMutation<RedeemGiftCard, RedeemGiftCardVariables>(
    REDEEM_GIFTCARD,
    {
      onCompleted: () => {
        enqueueSnackbar('Success!', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(
          'There was an issue with this card. Try again, or contact support to redeem your card.',
          { variant: 'error' }
        );
      },
      refetchQueries: [{ query: RECIPIENT_VIEW_SEND, variables: { input: { id: props.sendId } } }],
      awaitRefetchQueries: true,
      fetchPolicy: 'network-only'
    }
  );

  const [resendGiftCardEmail, { loading: resendLoading, data: resendEmailData }] = useMutation<
    SendGiftCodeEmail,
    SendGiftCodeEmailVariables
  >(SEND_GIFTCODE_EMAIL);

  function onSubmit() {
    // Don't do anything if it's in a preview
    if (props.isPreview) {
      return;
    }

    redeemGiftCard({ variables: { input: { redemptionCode: props.sendId } } });
  }
  const { handleSubmit } = useForm();
  const isVisaCard = /^visa\s/i.test(props.giftName);
  const giftEuphamism = isVisaCard ? 'Reward' : 'Gift';

  function handleResendSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resendGiftCardEmail({
      variables: {
        sendId: props.sendId
      }
    })
      .then(() => {
        enqueueSnackbar('Email sent to ' + props.recipientEmail, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(getMessagesFromApolloError(error), { variant: 'error' });
      });
  }

  const RaiseInstructions = () => (
    <>
      <Typography>Redemption Instructions</Typography>
      <Typography variant="body2">
        1. Click "Redeem {giftEuphamism} Card" to access this card.
      </Typography>
      <Typography variant="body2">
        2. Check your email. A link to this {giftEuphamism} card will be sent to you.
      </Typography>
      <br />
      <Typography>
        The email this card will be sent to is <strong>{props.recipientEmail}</strong>
      </Typography>
    </>
  );
  const TremendousInstructions = () => (
    <>
      <Typography>Redemption Instructions</Typography>
      <Typography variant="body2">
        1. Click "Redeem Gift {giftEuphamism}" to access this gift card.
      </Typography>
      <Typography variant="body2">
        2. You will be redirected to the {giftEuphamism} card page. Do not press Back or Refresh.
      </Typography>
    </>
  );
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {props.placementMethod === PlacementMethod.TREMENDOUS && <TremendousInstructions />}
        {props.placementMethod === PlacementMethod.RAISE && !props.isGiftCardRedeemed && (
          <RaiseInstructions />
        )}
      </Grid>
      <Grid item>
        {props.needsToBeRedeemed && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <ActionButton
              {...props}
              loading={loading}
              actionText={`Redeem ${giftEuphamism} Card`}
            />
          </form>
        )}
        {/* Show the Raise success message */}
        {props.isGiftCardRedeemed && props.placementMethod === PlacementMethod.RAISE && (
          <>
            <Alert severity="success">
              Your {giftEuphamism} card was successfully redeemed.{' '}
              <p>
                Check your email <strong>{props.recipientEmail}</strong> for the {giftEuphamism}{' '}
                card!
              </p>{' '}
              <p>If you do not see the email, check your Spam/Junk/Promotions folder.</p>
              The subject will be "Here's your gift card" from Gifts@RevSend.com. If you need
              further assistance, use the chat button below or email Contact@RevSend.com.
            </Alert>
            <br />
            {!resendEmailData && (
              <form onSubmit={handleResendSubmit}>
                <p>If you'd like to re-send the card, use the button below.</p>
                <ActionButton
                  actionText="Re-send Email"
                  logoPrimaryColor={props.logoPrimaryColor}
                  contrastTextColor={props.contrastTextColor}
                  loading={resendLoading}
                />
              </form>
            )}
          </>
        )}
      </Grid>
      {isVisaCard && (
        <Grid item>
          <Typography variant="body2">{VISA_TERMS}</Typography>
          <Link href={VISA_TERMS_URL}>{VISA_TERMS_URL_LABEL}</Link>
        </Grid>
      )}
    </Grid>
  );
}

type PhysicalDeliveryInstuctionsProps = Pick<
  RecipientLandingProps,
  'contrastTextColor' | 'logoPrimaryColor' | 'isPreview' | 'sendId'
>;
function PhysicalDeliveryInstuctions({
  sendId,
  contrastTextColor,
  logoPrimaryColor,
  isPreview
}: PhysicalDeliveryInstuctionsProps) {
  const classes = useInputStyles();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues: AddressFormValues = {
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    isPrivateAddress: true
  };
  const [submittedAddress, setSubmittedAddress] = React.useState<AddressFormValues>(initialValues);
  const { control, handleSubmit } = useForm({ defaultValues: initialValues });

  const [updateSend, { loading: updateSendLoading }] = useMutation<UpdateSend>(UPDATE_SEND, {
    onCompleted: () => {
      enqueueSnackbar('Success!', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(
        'There was an issue submitting your address. Try again, or contact support.',
        { variant: 'error' }
      );
    },
    refetchQueries: [{ query: RECIPIENT_VIEW_SEND, variables: { input: { id: sendId } } }],
    awaitRefetchQueries: true
  });

  const { validateAddress, renderValidateAddressDialog } = useValidateAddressDialog();

  function onAddressSubmit(values: AddressFormValues) {
    if (isPreview) {
      return;
    }
    // Update form state locally
    setSubmittedAddress(values);
    // Validate the address
    validateAddress({ variables: { input: omit(values, 'isPrivateAddress') } });
  }

  // This will be called after the user accepts the suggested validated address
  function onAddressAccept(address: ValidateAddress_validateAddress) {
    updateSend({
      variables: {
        input: {
          id: sendId,
          addressId: address.id
        }
      }
    });
  }

  return (
    <>
      {renderValidateAddressDialog({ onAccept: onAddressAccept, userAddress: submittedAddress })}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom>Where should this gift be sent?</Typography>
          <Typography variant="body2" color="textSecondary">
            The address will be used only for shipping this gift and will not be shared with the
            gift sender.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onAddressSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <label className={classes.neutralLabel}>Name</label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input className={classes.neutralInput} maxLength={100} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <label className={classes.neutralLabel}>Address Line 1</label>
                <Controller
                  name="street1"
                  control={control}
                  render={({ field }) => (
                    <input className={classes.neutralInput} maxLength={100} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <label className={classes.neutralLabel}>Address Line 2</label>
                <Controller
                  name="street2"
                  control={control}
                  render={({ field }) => (
                    <input className={classes.neutralInput} maxLength={100} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <label className={classes.neutralLabel}>City</label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <input className={classes.neutralInput} maxLength={100} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <label className={classes.neutralLabel}>State</label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <input className={classes.neutralInput} maxLength={2} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className={classes.neutralLabel}>Postal Code</label>
                <Controller
                  name="zip"
                  control={control}
                  render={({ field }) => (
                    <input className={classes.neutralInput} maxLength={5} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <br />
                <ActionButton
                  actionText="Submit Address"
                  logoPrimaryColor={logoPrimaryColor}
                  contrastTextColor={contrastTextColor}
                  loading={updateSendLoading}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default RecipientLandingContainer;
