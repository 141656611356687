import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { OrgSends, OrgSendsVariables } from '../../../../__generated__/types';
import { formatDollars } from '../../../../helpers/formatters';
import { calculateSendsCost, mapEasyRangeToRange } from '../../../Dashboard/helpers';
import SendTypesCard from '../../../Dashboard/SendTypesCard';
import { DateRange } from '../../../../types';
import SummaryStatCard from '../../../Dashboard/SummaryStatCard';
import { EasyDateRangeOption } from '../../../../components/EasyDateRange/EasyDateRange';
import { ORG_SENDS } from '../../../../graphql/queries';
import UserAnalyticsForm, {
  UserAnalyticsFormVars
} from '../UserAnalytics/UserAnalyticsForm';
import SendAnalyticsTable from './SendAnalyticsTable';

export default function SendAnalytics() {
  const [formValues, setFormValues] = useState<UserAnalyticsFormVars>({
    easyDateRange: EasyDateRangeOption.MONTH_TO_DATE
  });
  const [range, setRange] = useState<DateRange>(
    mapEasyRangeToRange(EasyDateRangeOption.MONTH_TO_DATE)
  );
  const { data: { org: { sends = [] } = {} } = {}, loading, error } = useQuery<
    OrgSends,
    OrgSendsVariables
  >(ORG_SENDS, {
    variables: { input: range }
  });

  function handleSubmit(values: UserAnalyticsFormVars) {
    setFormValues(values);
    setRange(mapEasyRangeToRange(values.easyDateRange));
  }

  return (
    <Fragment>
      <Grid container direction="column" spacing={2} justify="center" alignItems="stretch">
        <Grid item xs>
          <UserAnalyticsForm
            initialValues={formValues}
            onSubmit={(values) => handleSubmit(values)}
          />
        </Grid>
        <Grid item xs>
          <SendAnalyticsTable data={sends || []} loading={loading} />
        </Grid>
        {sends && sends.length > 0 && (
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <SendTypesCard sends={sends} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SummaryStatCard
                  title="Total Sends"
                  content={sends.length}
                  label={formValues.easyDateRange}
                />
                <br />
                <SummaryStatCard
                  title="Total Spend"
                  content={formatDollars(calculateSendsCost(sends || []))}
                  label={formValues.easyDateRange}
                />
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
