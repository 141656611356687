import React from 'react';
import List, { ListProps } from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default function ScrollingList(props: ListProps) {
  const classes = useStyles();
  return (
    <List className={classes.list} {...props}>
      {props.children}
    </List>
  );
}

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: '300px',
    overflow: 'scroll'
  }
}));
