import React, { Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BillingHistory from './BillingHistory/BillingHistory';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import CurrentInvoice from './CurrentInvoice/CurrentInvoice';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

const tabs = ['Payment Options', 'Upcoming Invoice', 'Billing History'];

export default function Billing() {
  const [tab, setTab] = React.useState(tabs[0]);

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Breadcrumbs />
        </Grid>
        <Grid item>
          <Typography variant="h3">Billing</Typography>
        </Grid>
        <Grid item>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            {tabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <Divider light />
          <br />
        </Grid>
        <Grid item>
          {tab === tabs[0] && <PaymentOptions />}
          {tab === tabs[1] && <CurrentInvoice />}
          {tab === tabs[2] && <BillingHistory />}
        </Grid>
      </Grid>
    </Fragment>
  );
}
