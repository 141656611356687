import React, { Fragment } from 'react';
import { formatDate, formatDollars } from '../../../../helpers/formatters';
import { calculateSendsCost } from '../../../Dashboard/helpers';
import { Opportunities_opportunities } from '../../../../__generated__/types';
import FilterTable from '../../../../components/FilterTable/FilterTable';

export type OpportunityAnalyticsTableProps = {
  data: Opportunities_opportunities[];
  loading: any;
};

export type OpportunityAnalyticsRow = {
  name: string;
  closeDate: string;
  createdDate: string;
  amount: number;
  totalSends: number;
  totalExpense: number;
  stageName: string;
};

export default function OpportunityAnalyticsTable({
  data,
  loading
}: OpportunityAnalyticsTableProps) {
  return (
    <Fragment>
      <FilterTable
        isLoading={loading}
        options={{
          search: true,
          showTitle: true,
          loadingType: 'linear',
          exportButton: true,
          exportAllData: true,
          showEmptyDataSourceMessage: !loading
        }}
        columns={[
          {
            field: 'name',
            title: 'Name',
            filtering: true
          },
          {
            field: 'totalSends',
            title: 'Total Sends',
            type: 'numeric',
            filtering: false
          },
          {
            field: 'closeDate',
            title: 'Close Date',
            filtering: false,
            render: (data: OpportunityAnalyticsRow) => (
              <Fragment>{data.closeDate ? formatDate(data.closeDate) : 'N/A'}</Fragment>
            )
          },
          {
            field: 'stageName',
            title: 'Stage',
            filtering: true
          },
          {
            field: 'totalExpense',
            title: 'Total Expense',
            type: 'numeric',
            filtering: false,
            render: (data: OpportunityAnalyticsRow) => (
              <Fragment>{formatDollars(data.totalExpense)}</Fragment>
            )
          },
          {
            field: 'amount',
            title: 'I/O Amount',
            type: 'numeric',
            filtering: false,
            render: (data: OpportunityAnalyticsRow) => (
              <Fragment>{formatDollars(data.amount)}</Fragment>
            )
          }
        ]}
        data={processData(data)}
        title="Opportunities with Sends"
      />
    </Fragment>
  );
}

function processData(data: Opportunities_opportunities[]): Array<OpportunityAnalyticsRow> {
  return data
    .filter((opp) => opp.sends && opp.sends.length > 0)
    .map((opp) => {
      return {
        name: opp.name || '',
        closeDate: opp.closeDate,
        stageName: opp.stageName || 'N/A',
        createdDate: opp.createdDate,
        amount: opp.amount || 0,
        totalSends: opp.sends ? opp.sends.length : 0,
        totalExpense: opp.sends ? calculateSendsCost(opp.sends) : 0
      };
    });
}
