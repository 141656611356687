import { useQuery } from '@apollo/react-hooks';
import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React, { Fragment, useState } from 'react';
import CopyTextField from '../../components/CopyTextField/CopyTextField';
import EGiftRedemptionCard from '../../components/EGiftRedemptionCard/EGiftRedemptionCard';
import ProductSendSummary from '../../components/ProductSendSummary/ProductSendSummary';
import { ME } from '../../graphql/queries';
import { formatDateTime } from '../../helpers/formatters';
import {
  Me,
  PlacementMethod,
  Role,
  SalesForceToken_salesForceToken,
  Send_send
} from '../../__generated__/types';
import SendRecipientCard from './SendRecipientCard';
import SendRefundForm from './SendRefundForm';
import SendStatusCard from './SendStatusCard';
export type SendDetailProps = {
  send: Send_send;
  salesForceToken?: SalesForceToken_salesForceToken | null;
};
export default function SendDetail({ send, salesForceToken }: SendDetailProps) {
  const [refundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const sendCost: number = send.invoiceItems
    ? send.invoiceItems.reduce((sum, invoiceItem) => sum + invoiceItem.dollars, 0)
    : 0;
  console.log(send.invoiceItems);
  const invoiceItemAdjustments = send.invoiceItems
    ? [...send.invoiceItems]
        .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
        .filter((invoiceItem, index) => index !== 0)
    : [];
  const classes = useStyles();
  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        onClose={() => setRefundDialogOpen(false)}
        open={refundDialogOpen}
      >
        <DialogContent>
          <SendRefundForm send={send} />
        </DialogContent>
      </Dialog>
      <Grid container justify={'center'} spacing={2} alignItems={'flex-start'}>
        <Grid item xs={12} md={4}>
          <Typography variant={'h6'}>Summary</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography gutterBottom>{send.giftName}</Typography>
              <Typography gutterBottom>Created at {formatDateTime(send.createdAt)}</Typography>
              <Typography gutterBottom>
                Created by {send.creator.name} at {send.org!.formattedName}
              </Typography>
              {send.trackingNumber && (
                <Typography>
                  Tracking # <a href={send.trackingUrl || ''}>{send.trackingNumber}</a>
                </Typography>
              )}
              <Typography>Message:</Typography>
              <Typography variant={'body2'} gutterBottom>
                {send.message}
              </Typography>
              {send.isAddressPrivate && (
                <CopyTextField
                  id={'confirmationLink'}
                  value={window.location.origin + '/view/' + send.id}
                  fullWidth
                  label={'Address Submit Link'}
                />
              )}
              {isGiftCard(send) && (
                <Fragment>
                  <EGiftRedemptionCard sendId={send.id} />
                </Fragment>
              )}
            </CardContent>
          </Card>
        </Grid>
        {send.address && (
          <Fragment>
            <Grid item xs={12}>
              <br />
              <Divider light />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant={'h6'}>Delivery Address</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography gutterBottom>{send.address.street1}</Typography>
                  <Typography gutterBottom>{send.address.street2}</Typography>
                  <Typography gutterBottom>
                    {send.address.city}, {send.address.state} {send.address.zip}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12}>
          <br />
          <Divider light />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant={'h6'}>Status</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <SendStatusCard
            status={send.status}
            deliveryType={send.deliveryType}
            isAddressPrivate={send.isAddressPrivate}
          />
        </Grid>
        {send.trackingUrl && <>
          <Grid item xs={12} md={4}>
          <Typography variant={'h6'}>Tracking URL</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <a target="_blank" href={send.trackingUrl}>{send.trackingUrl}</a>
            </CardContent>
          </Card>
        </Grid>
        </>}
        <Grid item xs={12}>
          <br />
          <Divider light />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant={'h6'}>Recipient</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          {send?.recipient && (
            <SendRecipientCard recipient={send.recipient} salesForceToken={salesForceToken} />
          )}
        </Grid>
        <Grid item xs={12}>
          <br />
          <Divider light />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant={'h6'}>Order Summary</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader title={send.giftName} />
            <Divider light />
            <CardContent>
              {isProducts(send) && send.sendItems && (
                <ProductSendSummary
                  sendItems={send.sendItems}
                  shippingCost={send.shippingCost}
                  showHeader={false}
                  invoiceItemAdjustments={invoiceItemAdjustments}
                />
              )}
            </CardContent>
          </Card>
          <br />
        </Grid>
        {me && me.role === Role.RS_ADMIN && (
          <Fragment>
            <Grid item xs={12}>
              <br />
              <Divider light />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant={'h6'}>Admin</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Button
                variant={'outlined'}
                color={'secondary'}
                onClick={() => setRefundDialogOpen(true)}
              >
                Refund
              </Button>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
}

function isAmazonSend(send: Send_send): boolean {
  return Boolean(send.amazonItem);
}

function isGiftCard(send: Send_send): boolean {
  // Gift cards come from Tremendous
  return Boolean(
    send.placementMethod === PlacementMethod.TREMENDOUS ||
      send.placementMethod === PlacementMethod.RAISE
  );
}

function isProducts(send: Send_send): boolean {
  return Boolean(send?.sendItems?.length! > 0);
}

const useStyles = makeStyles((theme: Theme) => ({
  successText: {
    color: theme.palette.success.main
  }
}));
