import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import csvToJson from 'csvtojson';
import { CAMPAIGN_CREATE } from 'graphql/mutations';
import { getMessagesFromApolloError } from 'graphql/utils';
import { formatDollars } from 'helpers/formatters';
import { update } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Loading from '../../components/Loading/Loading';
import { GIFTS, ME } from '../../graphql/queries';
import {
  CampaignCreate,
  CampaignCreateVariables,
  DeliveryType,
  Gifts,
  GiftsVariables,
  Me
} from '../../__generated__/types';
import CsvDropzone from './CsvDropzone';

type CampaignRow = {
  recipient_name: string;
  recipient_email: string;
  recipient_organization: string;
  salesforce_account_id: string;
  salesforce_contact_id: string;
  hubspot_contact_id: string;
  hubspot_company_id: string;
};
type CampaignData = CampaignRow[];

type CampaignFormFields = {
  gifts: Array<{ giftId: string; checked: boolean; title: string }>;
  message: string;
  name: string;
  maxCostPerGift: string;
  expiresAt: string;
};
const CreateCampaign: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  // Queries
  const {
    data,
    loading: giftLoading,
    error
  } = useQuery<Gifts, GiftsVariables>(GIFTS, {
    variables: { input: { deliveryType: DeliveryType.DIGITAL } }
  });

  const { data: { me = null } = {}, loading: meLoading } = useQuery<Me>(ME);

  // Mutations
  const [campaignCreate, { error: createError, loading: createLoading }] = useMutation<
    CampaignCreate,
    CampaignCreateVariables
  >(CAMPAIGN_CREATE, {
    onCompleted: () => {
      enqueueSnackbar('Success', { variant: 'success' });
      window.location.href = '/campaigns';
    },
    onError: (error) => {
      enqueueSnackbar(getMessagesFromApolloError(error), { variant: 'error', persist: true });
    }
  });

  // Local State
  const [csv, setCsv] = React.useState<File | undefined>();
  const [jsonData, setJsonData] = React.useState<CampaignData | undefined>();
  const [validationError, setValidationError] = React.useState<string | undefined>();

  // Event Handlers
  function getTemplateUrl(): string {
    const columns: Array<keyof CampaignRow> = [
      'recipient_name',
      'recipient_email',
      'recipient_organization',
      'salesforce_account_id',
      'salesforce_contact_id',
      'hubspot_contact_id',
      'hubspot_company_id'
    ];
    const blob = new Blob([columns.join(',')], { type: 'text/csv' });
    // Create a URL for the blob object
    const url = URL.createObjectURL(blob);
    return url;
  }

  // Helpers
  function validateCsvData(json: any): boolean {
    let errorReason: string =
      'Unable to process CSV. Please download the template and try again. Do not edit or remove the first row of headers';

    if (!Array.isArray(json)) {
      setValidationError(errorReason);
      return false;
    }
    if (json.length === 0) {
      setValidationError('There was no data in the CSV uploaded');
      return false;
    }
    if (json.length > 500) {
      setValidationError('The max allowed recipients is 500.');
    }

    for (let index = 0; index < json.length; ++index) {
      const row = json[index];
      if (!row) {
        setValidationError(`Invalid CSV data. Double check data at row ${index + 2}`);
        return false;
      }
      if (!row.recipient_name) {
        setValidationError(
          `Invalid CSV data. Double check the "recipient_name" column at row ${
            index + 2
          }. This is a required column.`
        );
        return false;
      }
      if (!row.recipient_email) {
        setValidationError(
          `Invalid CSV data. Double check the "recipient_email" column at row ${
            index + 2
          }. This is a required column`
        );
        return false;
      }
    }
    // Success
    setValidationError(undefined);
    return true;
  }

  React.useEffect(() => {
    if (!csv) return;
    csv
      .text()
      .then((text) => csvToJson().fromString(text))
      .then((json) => {
        if (validateCsvData(json)) {
          setJsonData(json);
        } else {
          setJsonData(undefined);
        }
      })
      .catch(() => {
        setValidationError('Unable to process CSV. Please download the template and try again.');
      });
  }, [csv]);

  const classes = useStyles();
  const { control, watch, handleSubmit } = useForm<CampaignFormFields>({
    defaultValues: {
      gifts: [],
      message: '',
      name: '',
      maxCostPerGift: ''
    }
  });
  const giftsFieldArray = useFieldArray<CampaignFormFields>({
    control,
    name: 'gifts'
  });

  React.useEffect(() => {
    if (data?.gifts) {
      giftsFieldArray.replace(
        data.gifts.map((gift) => ({ giftId: gift.id, title: gift.title, checked: false }))
      );
    }
  }, [data?.gifts]);

  function onSubmit(values: CampaignFormFields) {
    campaignCreate({
      variables: {
        input: {
          gifts: values.gifts.filter((gift) => gift.checked).map((gift) => gift.giftId),
          maxCostPerGift: Number(values.maxCostPerGift),
          expiresAt: values.expiresAt ? moment(values.expiresAt).toDate() : null,
          name: values.name,
          message: values.message,
          recipients:
            jsonData?.map((row) => ({
              email: row.recipient_email,
              name: row.recipient_name,
              hubSpotCompanyId: row.hubspot_company_id,
              hubSpotContactId: row.hubspot_contact_id,
              salesForceAccountId: row.salesforce_account_id,
              salesForceContactId: row.salesforce_contact_id
            })) ?? []
        }
      }
    });
  }

  if (giftLoading) {
    return <Loading />;
  }
  if (!data?.gifts) {
    return (
      <Typography>
        No gifts are currently enabled for Campaigns. Reach out to customer support to get access to
        this feature.
      </Typography>
    );
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Create Campaign
          </Typography>
          <Divider light />
          <br />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>1. Choose campaign's name and expiration date</Typography>
                  <br />
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Campaign Name"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          style: {
                            maxWidth: 400
                          },
                          required: true
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Controller
                    name="expiresAt"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Expiration Date"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        helperText="Leave blank for no expiration date"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    2. Use the RevSend campaign CSV template to list your recipients
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    href={getTemplateUrl()}
                    download="revsend_bulk_send.csv"
                  >
                    Download CSV Template
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>3. Submit CSV</Typography>
                  {validationError ? <Alert severity="error">{validationError}</Alert> : null}
                  <CsvDropzone
                    onDropAccepted={(files: File[]) => {
                      setCsv(files[0]);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>4. Confirm Data with Preview</Typography>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Organization</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!jsonData?.length && (
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              rowSpan={3}
                              style={{ textAlign: 'center', height: 200 }}
                            >
                              No CSV uploaded
                            </TableCell>
                          </TableRow>
                        )}
                        {jsonData?.map((data) => (
                          <TableRow key={data.recipient_email}>
                            <TableCell>{data.recipient_name}</TableCell>
                            <TableCell>{data.recipient_email}</TableCell>
                            <TableCell>{data.recipient_organization}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    5. Select the gifts available to redeem for this campaign
                  </Typography>
                  <List style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    {giftsFieldArray.fields.map((field, index) => (
                      <ListItem key={field.id}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            checked={field.checked}
                            onChange={(event) =>
                              giftsFieldArray.update(index, {
                                giftId: field.giftId,
                                title: field.title,
                                checked: event.target.checked
                              })
                            }
                          />
                        </ListItemIcon>
                        <ListItemText id={field.giftId} primary={field.title} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>6. Set the max gift amount (in USD)</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Your recipients will be given the option to redeem a gift card with the closest
                    value to this amount. For example, if you choose $51 as your max amount, and
                    include the Amazon Gift Card in this campaign, the recipient will be able to
                    redeem a $50 Amazon Gift card, since there is no $51 Amazon Gift Card option.
                  </Typography>
                  <br />
                  <Controller
                    name="maxCostPerGift"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Gift Amount ($)"
                        variant="outlined"
                        inputProps={{ required: true }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography>7. Write Message</Typography>
                  <br />
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Message"
                        variant="outlined"
                        multiline
                        minRows={4}
                        fullWidth
                        inputProps={{
                          required: true,
                          style: {
                            fontFamily: `'Caveat', cursive`,
                            fontSize: 22,
                            whiteSpace: 'pre-wrap'
                          }
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            This campaign is valid for {jsonData?.length || 0} recipients, and will require $
            {(Number(watch('maxCostPerGift')) || 0) * (jsonData?.length || 0)} in budget.
          </Typography>
          <br />
          <Typography>You currently have {formatDollars(me?.budget?.amount)} in budget.</Typography>
          <br />
          <Typography>
            After submitting, you will be able to view a link to your campaign. Share this link with
            those you have invited to the campaign. RevSend{' '}
            <strong>does not send emails to the list of recipients you have uploaded.</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" color="primary" type="submit" disabled={createLoading}>
            Create Campaign
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateCampaign;

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxHeight: 300
  }
}));
