import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default function DangerButton(props: ButtonProps) {
  const classes = useStyles();
  return <Button {...props} className={classes.dangerButton} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  dangerButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '&:active': {
      backgroundColor: theme.palette.error.light
    },
    '&:focus': {
      backgroundColor: theme.palette.error.main
    }
  }
}));
