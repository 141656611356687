import React from 'react';
import Analytics from '../views/Analytics/Analytics';
import Dashboard from '../views/Dashboard/Dashboard';
import SendPage from '../views/Send/Send';
import SendGiftMenu from '../views/SendGiftMenu/SendGiftMenu';
import SendGift from '../views/SendGift/SendGift';
import History from '../views/History/History';
import General from '../views/Settings/General/General';
import Org from '../views/Settings/Org/Org';
import Integrations from '../views/Settings/Integrations/Integrations';
import Amazon from '../views/Settings/Integrations/components/Amazon/Amazon';
import HubSpot from '../views/Settings/Integrations/components/HubSpot/HubSpot';
import SalesForce from '../views/Settings/Integrations/components/SalesForce/SalesForce';
import Notifications from '../views/Settings/Notifications/Notifications';
import Teams from '../views/Settings/Teams/Teams';
import Users from '../views/Settings/Users/Users';
import Billing from '../views/Settings/Billing/Billing';
import Budgets from '../views/Settings/Budgets/Budgets';
import { Page } from './types';
import Settings from '../views/Settings/Settings';
import Fulfillment from '../views/Fulfillment/Fulfillment';
import Admin from '../views/Admin/Admin';
import Slack from '../views/Settings/Integrations/components/Slack/Slack';
import Branding from '../views/Settings/Branding/Branding';
import { Main } from 'layouts';
import Campaigns from 'views/Campaigns/Campaigns';
import CreateCampaign from 'views/Campaigns/CreateCampaign';
import EditCampaign from 'views/Campaigns/EditCampaign';

const protectedPages: Array<Page> = [
  {
    path: '/analytics',
    content: Analytics,
    layout: Main
  },
  {
    path: '/dashboard',
    content: Dashboard,
    layout: Main
  },
  {
    path: '/send/:id',
    content: SendPage,
    layout: Main
  },
  {
    path: '/send-gift',
    content: SendGiftMenu,
    layout: Main
  },
  {
    path: '/send-gift/:giftId',
    content: SendGift,
    layout: Main
  },
  {
    path: '/history',
    content: History,
    layout: Main
  },
  {
    path: '/settings',
    content: Settings,
    layout: Main
  },
  {
    path: '/settings/general',
    content: General,
    layout: Main
  },
  {
    path: '/settings/org',
    content: Org,
    layout: Main
  },
  {
    path: '/settings/integrations',
    content: Integrations,
    layout: Main
  },
  {
    path: '/settings/integrations/amazon',
    content: Amazon,
    layout: Main
  },
  {
    path: '/settings/integrations/hubspot',
    content: HubSpot,
    layout: Main
  },
  {
    path: '/settings/integrations/slack',
    content: Slack,
    layout: Main
  },
  {
    path: '/settings/integrations/salesforce',
    content: SalesForce,
    layout: Main
  },
  {
    path: '/settings/notifications',
    content: Notifications,
    layout: Main
  },
  {
    path: '/settings/teams',
    content: Teams,
    layout: Main
  },
  {
    path: '/settings/users',
    content: Users,
    layout: Main
  },
  {
    path: '/settings/billing',
    content: Billing,
    layout: Main
  },
  {
    path: '/settings/budgets',
    content: Budgets,
    layout: Main
  },
  {
    path: '/settings/branding',
    content: Branding,
    layout: Main
  },
  {
    path: '/fulfillment',
    content: Fulfillment,
    layout: Main
  },
  {
    path: '/admin',
    content: Admin,
    layout: Main
  },
  {
    path: '/campaigns',
    content: Campaigns,
    layout: Main
  },
  {
    path: '/campaigns/create',
    content: CreateCampaign,
    layout: Main
  },
  {
    path: '/campaigns/:campaignId',
    content: EditCampaign,
    layout: Main
  }
];

export default protectedPages;
