import React from 'react';
import { Formik, Form } from 'formik';
import {
  Me_me_notificationSettings,
  NotificationChannel,
  UpsertNotificationSettings,
  UpsertNotificationSettingsVariables
} from '../../../../../__generated__/types';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import snakeCase from 'lodash/snakeCase';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import { useMutation } from '@apollo/react-hooks';
import { SAVE_NOTIFICATION_SETTINGS } from '../../../../../graphql/mutations';

type NotificationOptions = Pick<
  Me_me_notificationSettings,
  'onSendStatusRedeemed' | 'onSendStatusDelivered' | 'onSendStatusShipped' | 'onSendStatusCanceled'
>;

function mapInitialValues(
  settings: Me_me_notificationSettings | NotificationOptions
): NotificationOptions {
  return pick(settings, [
    'onSendStatusRedeemed',
    'onSendStatusDelivered',
    'onSendStatusCanceled',
    'onSendStatusShipped'
  ]);
}

export type NotificationFormProps = {
  // This component is responsible for mapping the Me_me_notificationSettings query result to form values.
  settings?: Me_me_notificationSettings | null;
  channel: NotificationChannel;
  readOnly?: boolean | null;
};
const NotificationForm: React.FC<NotificationFormProps> = (props) => {
  const { settings, channel, readOnly } = props;
  const [saveNotificationSettings, { loading, error }] = useMutation<
    UpsertNotificationSettings,
    UpsertNotificationSettingsVariables
  >(SAVE_NOTIFICATION_SETTINGS);

  const defaultOptions: NotificationOptions = {
    onSendStatusRedeemed: false,
    onSendStatusDelivered: false,
    onSendStatusCanceled: false,
    onSendStatusShipped: false
  };
  return (
    <Formik
      initialValues={mapInitialValues(settings ?? defaultOptions)}
      onSubmit={(values) =>
        saveNotificationSettings({
          variables: {
            input: {
              ...values,
              channel
            }
          }
        })
      }
    >
      {({ values, setFieldValue, handleBlur, submitForm }) => (
        <Form>
          {Object.entries(values).map(([key, value]) => (
            <ListItem key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={key}
                    name={key}
                    disabled={Boolean(readOnly)}
                    onChange={(event, checked) => {
                      setFieldValue(key, checked);
                      return submitForm();
                    }}
                    onBlur={handleBlur}
                    checked={!!value}
                  />
                }
                label={snakeCase(key)!
                  .split('_')
                  .splice(1)
                  .map((part: string) => capitalize(part))
                  .join(' ')
                  .replace(/Status/gi, 'is')}
              />
            </ListItem>
          ))}
        </Form>
      )}
    </Formik>
  );
};

export default NotificationForm;
