import React, { Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Loading from '../../components/Loading/Loading';
import { AllOrgs, LoginAsOrg, LoginAsOrgVariables } from '../../__generated__/types';
import { ALL_ORGS } from '../../graphql/queries';
import { LOGIN_AS_ORG } from '../../graphql/mutations';
import { getInitialCache } from '../../graphql/client';
import { initializeCache } from '../../graphql/cacheUpdates';
import PrimaryTextField from '../../components/TextField/PrimaryTextField';
import { Autocomplete } from '@material-ui/lab';

export default function OrgSelectForm() {
  const client = useApolloClient();
  const {
    data: { allOrgs = [] } = {},
    loading: getOrgsLoading,
    error: getOrgsError
  } = useQuery<AllOrgs>(ALL_ORGS);

  const [loginAsOrg, { error: loginError, loading: loginLoading }] = useMutation<
    LoginAsOrg,
    LoginAsOrgVariables
  >(LOGIN_AS_ORG, {
    onCompleted: async ({ loginAsOrg: { tokens } }: LoginAsOrg) => {
      if (!(tokens && tokens[0])) return;
      localStorage.setItem('token', tokens[0].value);
      initializeCache(client.cache);
      await client.reFetchObservableQueries();
    }
  });

  if (getOrgsLoading) {
    return <Loading />;
  }

  return (
    <Formik
      initialValues={{
        orgId: '',
        password: ''
      }}
      onSubmit={(values) => loginAsOrg({ variables: { input: values } })}
      validationSchema={Yup.object().shape({
        orgId: Yup.string().required()
      })}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, handleBlur, errors, touched }) => (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'column'} spacing={2} alignItems={'stretch'}>
              <Grid item xs>
                <Autocomplete
                  options={allOrgs.map((org) => ({ id: org.id, name: org.formattedName }))}
                  onChange={(event, value) => setFieldValue('orgId', value?.id ?? '')}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <PrimaryTextField {...params} required label="Search Orgs" />
                  )}
                />
              </Grid>
              <Grid item xs>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={getOrgsLoading || loginLoading}
                >
                  Switch to Org
                </Button>
              </Grid>
            </Grid>
          </form>
        </Fragment>
      )}
    </Formik>
  );
}
