/// <reference types="@types/chrome" />

import { OPEN_AMAZON_TAB, PING } from './eventTypes';

export function isBrowserChrome(): boolean {
  return !!window.chrome;
}

export function pingChromeExt(): Promise<boolean> {
  return new Promise((resolve) => {
    if (!isBrowserChrome()) resolve(false);
    try {
      const extensionId = process.env.REACT_APP_CHROME_EXTENSION_ID || '';
      window.chrome.runtime.sendMessage(extensionId, { type: PING }, (response) => {
        console.log(response);
        // Not checking for this 'lastError' will result in a console.error!
        // See: https://stackoverflow.com/questions/28431505/unchecked-runtime-lasterror-when-using-chrome-api
        if (window.chrome.runtime.lastError) {
          resolve(false);
          return;
        }
        resolve(true);
      });
    } catch (error) {
      resolve(false);
    }
  });
}

export function openAmazonTab(): Promise<any> {
  return new Promise((resolve, reject) => {
    window.chrome.runtime.sendMessage(
      process.env.REACT_APP_CHROME_EXTENSION_ID || '',
      { type: OPEN_AMAZON_TAB },
      (response) => {
        const extensionError = window.chrome.runtime.lastError;
        if (extensionError) {
          reject(extensionError);
          return;
        }
        resolve(response);
      }
    );
  });
}
