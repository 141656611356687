import React from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import PrimaryFormControl from '../PrimaryTextField/PimaryFormControl/PrimaryFormControl';
import { FormControlProps } from '@material-ui/core/FormControl';
import PrimaryInputLabel from '../PrimaryTextField/PrimaryInputLabel/PrimaryInputLabel';
import PrimaryInput from '../PrimaryTextField/PrimaryInput/PrimaryInput';
import { InputBaseProps } from '@material-ui/core/InputBase';
export type PrimarySelectProps = SelectProps & {
  labelProps?: InputLabelProps;
  formControlProps?: FormControlProps;
  inputProps?: InputBaseProps;
  label: string;
};

const PrimarySelect: React.FC<PrimarySelectProps> = (props) => {
  const { formControlProps, labelProps, inputProps, label, ...selectProps } = props;
  return (
    <PrimaryFormControl {...formControlProps}>
      <PrimaryInputLabel {...labelProps}>{label}</PrimaryInputLabel>
      <Select {...selectProps} input={<PrimaryInput {...inputProps} />} />
    </PrimaryFormControl>
  );
};

export default PrimarySelect;
