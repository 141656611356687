import {
  RecipientAddressForm,
  RecipientForm,
  SalesForceContact_salesForceContact,
  SelectedSalesForceContact
} from '../../__generated__/types';
import { ApolloClient } from 'apollo-client';
import {
  SELECTED_SALESFORCE_CONTACT,
  RECIPIENT_FORM,
  RECIPIENT_ADDRESS_FORM,
  IS_CREATE_SALESFORCE_TASK_CHECKED
} from '../../graphql/queries';
import { ApolloCache } from '@apollo/client';

export function handleSalesForceContactSelect(
  contact: SalesForceContact_salesForceContact,
  checked: boolean,
  cache: ApolloCache<unknown>
): void {
  cache.writeQuery<SelectedSalesForceContact>({
    query: SELECTED_SALESFORCE_CONTACT,
    data: {
      selectedSalesForceContact: checked
        ? {
            ...contact,
            __typename: 'SalesForceContact'
          }
        : null
    }
  });
  cache.writeQuery<RecipientForm>({
    query: RECIPIENT_FORM,
    data: {
      recipientForm: checked
        ? {
            email: contact.email || '',
            name: contact.name || '',
            orgName: contact?.account?.name || '',
            __typename: 'RecipientFormVars'
          }
        : null
    }
  });
  cache.writeQuery<RecipientAddressForm>({
    query: RECIPIENT_ADDRESS_FORM,
    data: {
      recipientAddressForm: checked
        ? {
            street1: contact?.mailingAddress?.street || '',
            street2: '',
            city: contact?.mailingAddress?.city || '',
            state: contact?.mailingAddress?.state || '',
            zip: contact?.mailingAddress?.postalCode || '',
            name: contact?.name || '',
            __typename: 'AddressFormVars'
          }
        : null
    }
  });
  cache.writeQuery({
    query: IS_CREATE_SALESFORCE_TASK_CHECKED,
    data: { isCreateSalesForceTaskChecked: checked }
  });
}
