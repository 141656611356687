import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ApolloError } from 'apollo-client';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import round from 'lodash/round';
import { formatDollars } from '../../../../../../../helpers/formatters';
import { AddCredit, AddCreditVariables, Customer } from '../../../../../../../__generated__/types';
import { CUSTOMER, ORG_CREDIT } from '../../../../../../../graphql/queries';
import CreditCardListItem from './components/CreditCardListItem/CreditCardListItem';
import { ADD_CREDIT } from '../../../../../../../graphql/mutations';
import FormAlert from '../../../../../../../components/FormAlert/FormAlert';
import PrimaryTextField from '../../../../../../../components/PrimaryTextField/PrimaryTextField';

type AddCreditFormComponentProps = FormikProps<{ dollars: string }> & {
  loading: boolean;
  customerData?: Customer;
  error?: ApolloError;
};

export function calculateProcessingFee(netArg: string): number {
  const net = Number(netArg);
  if (!net) return 0;
  const netPlusFee = round((net + 0.3) / (1 - 0.029), 2) as number;
  return netPlusFee - net;
}

function AddCreditFormComponent({
  loading,
  values,
  touched,
  error,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  customerData
}: AddCreditFormComponentProps) {
  const card = customerData && customerData.customer && customerData.customer.card;
  return (
    <Fragment>
      <FormAlert error={error} />
      <form noValidate onSubmit={handleSubmit}>
        <PrimaryTextField
          type="number"
          required
          fullWidth
          id="dollars"
          inputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          label="Amount (dollars)"
          name="dollars"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.dollars}
          error={!!(touched.dollars && errors.dollars)}
          helperText={(touched.dollars && errors.dollars) || ' '}
        />
        <List>
          <ListItem>
            <ListItemText primary="Subtotal" secondary="Amount of credit to add" />
            <Typography variant="body2">{formatDollars(values.dollars)}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Processing Fee" secondary="3% card processing fee" />
            <Typography variant="body2">
              {formatDollars(calculateProcessingFee(values.dollars))}
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Total" />
            <Typography variant="body2">
              {formatDollars(Number(values.dollars) + calculateProcessingFee(values.dollars))}
            </Typography>
          </ListItem>
          <Divider />
          {card ? (
            <CreditCardListItem primaryText={card.brand} secondaryText={card.last4} />
          ) : (
            <ListItem>
              <div>No card on file! You need to add one before you can add credit.</div>
            </ListItem>
          )}
        </List>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading || !card}
        >
          {loading ? 'Processing...' : 'Add Credit'}
        </Button>
      </form>
    </Fragment>
  );
}

export type AddCreditFormProps = {
  onSuccess: () => void;
};

export default function AddCreditForm({ onSuccess }: AddCreditFormProps) {
  const [addCredit, { loading, error }] = useMutation<AddCredit, AddCreditVariables>(ADD_CREDIT, {
    refetchQueries: [{ query: ORG_CREDIT }],
    onCompleted: () => onSuccess()
  });
  const { data: customerData } = useQuery<Customer>(CUSTOMER);

  return (
    <Formik
      initialValues={{
        dollars: ''
      }}
      onSubmit={(values) => addCredit({ variables: { dollars: Number(values.dollars) } })}
      validationSchema={Yup.object().shape({
        dollars: Yup.number()
          .min(10, 'The minimum is $10') // This doesn't work. not sure why
          .required('Enter a valid dollar amount')
      })}
      render={(formikProps) => (
        <AddCreditFormComponent
          {...formikProps}
          loading={loading}
          error={error}
          customerData={customerData}
        />
      )}
    />
  );
}
