import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TodayIcon from '@material-ui/icons/Today';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { formatDate, formatDollars } from '../../../../../helpers/formatters';
import { BUDGETS, ORG_CREDIT } from '../../../../../graphql/queries';
import Loading from '../../../../../components/Loading/Loading';
import { Budgets, Budgets_budgets, OrgCredit } from '../../../../../__generated__/types';
import { calculateProcessingFee } from './utils';

export default function BudgetChargeDetail() {
  const [showChargeDetails, setShowChargeDetails] = useState<boolean>(false);
  const { data: { credit = null } = {}, loading: creditLoading } = useQuery<OrgCredit>(ORG_CREDIT, {
    fetchPolicy: 'network-only'
  });
  const {
    loading: budgetsLoading,
    error: budgetsError,
    data: { budgets = [] } = {}
  } = useQuery<Budgets>(BUDGETS);

  const scheduledDate: string = moment()
    .tz(moment.tz.guess())
    .add(1, 'month')
    .startOf('month')
    .format();
  const unallocatedBudget = credit
    ? credit - budgets.reduce((sum, budget) => sum + budget.amount, 0)
    : 0;
  const totalRefillAmount =
    budgets.reduce((sum, budget) => sum + calculateRefillAmount({ budget }), 0) - unallocatedBudget;
  const processingFee = Math.max(calculateProcessingFee(totalRefillAmount), 0);
  const totalCharge = totalRefillAmount + processingFee;

  const classes = useStyles();
  if (budgetsLoading || creditLoading) {
    return <Loading />;
  }
  return (
    <Fragment>
      <List>
        <ListItem button onClick={() => setShowChargeDetails((prevState) => !prevState)}>
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary={`${formatDate(scheduledDate)} - Budget Refill`} />
          {showChargeDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}{' '}
          {formatDollars(Math.max(totalCharge, 0))}
        </ListItem>
        {showChargeDetails && <Divider />}
        <Collapse in={showChargeDetails} timeout="auto" unmountOnExit>
          <div className={classes.scrollList}>
            {budgets.map((budget) => (
              <ListItem key={budget.id}>
                <ListItemText
                  primary={budget.user && budget.user.name}
                  secondary={
                    budget.isRollover
                      ? `Add ${formatDollars(budget.refillAmount)} and rollover any unused`
                      : `Reset to ${formatDollars(
                          budget.refillAmount
                        )}. Currently at ${formatDollars(budget.amount)}`
                  }
                />
                <Typography
                  variant={'body2'}
                  className={clsx(calculateRefillAmount({ budget }) < 0 && classes.successText)}
                >
                  {formatDollars(calculateRefillAmount({ budget }))}
                </Typography>
              </ListItem>
            ))}
          </div>
          {unallocatedBudget > 0 && (
            <ListItem>
              <ListItemText
                primary="Applied unallocated budget"
                secondary={'Unallocated budget will be used to reduce your refill cost'}
              />
              <Typography variant={'body2'} className={clsx(classes.successText)}>
                {formatDollars(unallocatedBudget * -1)}
              </Typography>
            </ListItem>
          )}
          <Divider light />
          <ListItem>
            <ListItemText
              primary="Total refill cost"
              secondary={
                totalRefillAmount < 0
                  ? 'This credit will be added to the unallocated budget.'
                  : undefined
              }
            />
            <Typography
              variant={'body2'}
              className={clsx(totalRefillAmount < 0 && classes.successText)}
            >
              {formatDollars(totalRefillAmount)}
            </Typography>
          </ListItem>
          {processingFee > 0 && (
            <ListItem>
              <ListItemText primary="Processing fee" />
              {formatDollars(processingFee)}
            </ListItem>
          )}
          <Divider light />
          <ListItem>
            <ListItemText
              primary="Scheduled charge"
              secondary={
                totalRefillAmount <= 0
                  ? 'No charge is needed to apply your monthly budget refills'
                  : undefined
              }
            />
            <Typography
              variant={'body2'}
              className={clsx(totalRefillAmount <= 0 && classes.successText)}
            >
              {totalRefillAmount <= 0 ? 'None' : formatDollars(totalRefillAmount + processingFee)}
            </Typography>
          </ListItem>
        </Collapse>
      </List>
    </Fragment>
  );
}

function calculateRefillAmount({ budget }: { budget: Budgets_budgets }) {
  return budget.isRollover ? budget.refillAmount : budget.refillAmount - budget.amount;
}

const useStyles = makeStyles((theme: Theme) => ({
  successText: {
    color: theme.palette.success.main
  },
  scrollList: {
    maxHeight: 300,
    overflow: 'auto'
  }
}));
