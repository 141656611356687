import { useMutation, useQuery } from '@apollo/react-hooks';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MessageField from 'components/MessageField/MessageField';
import { getMessagesFromApolloError } from 'graphql/utils';
import { isRaiseVisaRewards, VISA_TERMS } from 'helpers/gift';
import { mean } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Loading from '../../../components/Loading/Loading';
import NumberCircle from '../../../components/NumberCircle/NumberCircle';
import { useValidateAddressDialog } from '../../../components/ValidateAddressDialog/ValidateAddressDialog';
import { CREATE_SEND } from '../../../graphql/mutations';
import { ADDON_PRODUCTS, GIFT, ME, ORG_PROFILE } from '../../../graphql/queries';
import { formatDollars } from '../../../helpers/formatters';
import theme from '../../../theme';
import {
  AddonProducts,
  AddonProducts_products,
  CreateSend,
  CreateSendVariables,
  CreateSend_createSend,
  DeliveryType,
  Gift,
  Gift_gift,
  Me,
  Me_me,
  OrgProfile,
  OrgProfile_org
} from '../../../__generated__/types';
import { initSendItems } from '../hooks/useProductsForm';
import SuccessStep from '../SuccessStep';
import AddressForm from './AddressForm';
import ErrorBoundary from './ErrorBoundary';
import OrderSummary from './OrderSummary';
import RecipientForm from './RecipientForm';

export type ProductCheckoutContainerProps = {
  giftId: string;
};

const ProductCheckoutContainer: React.FC<ProductCheckoutContainerProps> = ({ giftId }) => {
  // Queries
  const { data: { gift = null } = {}, loading: giftLoading } = useQuery<Gift>(GIFT, {
    variables: { input: { id: giftId } }
  });
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const { data: { org = null } = {}, loading: orgLoading } = useQuery<OrgProfile>(ORG_PROFILE);
  const { data: { products: addonProducts = [] } = {}, loading: addonLoading } =
    useQuery<AddonProducts>(ADDON_PRODUCTS);

  if (giftLoading || orgLoading || addonLoading) {
    return <Loading />;
  }
  if (!gift || !org || !me) {
    return (
      <div>We're having trouble with that gift right now. Try agin later or contact support.</div>
    );
  }

  return (
    <ErrorBoundary>
      <ProductCheckout me={me} gift={gift} org={org} addonProducts={addonProducts ?? []} />
    </ErrorBoundary>
  );
};

type ProductCheckoutProps = {
  gift: Gift_gift;
  me: Me_me;
  org: OrgProfile_org;
  addonProducts: AddonProducts_products[];
};
const ProductCheckout: React.FC<ProductCheckoutProps> = ({ gift, me, addonProducts }) => {
  // Check for exceptions
  if (!(gift.items && gift.items.length > 0)) {
    throw new Error('Expected more than 0 items in gift with id ' + gift.id);
  }

  // Mutations
  const [createSend, { loading }] = useMutation<CreateSend, CreateSendVariables>(CREATE_SEND);

  // Helpers
  const { enqueueSnackbar } = useSnackbar();
  const [createdSend, setCreatedSend] = React.useState<CreateSend_createSend | null>(null);
  const isGiftCard = gift.deliveryType === DeliveryType.DIGITAL;
  const initialValues = {
    address: {
      name: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      isPrivateAddress: false
    },
    recipient: {
      name: '',
      email: '',
      orgName: '',
      salesForceContactId: '',
      salesForceAccountId: '',
      isCreateSalesForceTaskChecked: false,
      hubSpotContactId: '',
      hubSpotCompanyId: '',
      isCreateHubSpotTaskChecked: false
    },
    message: {
      text: '',
      templateId: ''
    },
    requiredItems: initSendItems(
      gift.items.map((item) => ({
        ...item.product,
        isAddon: Boolean(item.product.isAddon)
      }))
    ),
    addonItems: initSendItems(
      addonProducts.map((product) => ({
        ...product,
        isAddon: Boolean(product.isAddon)
      }))
    )
  };
  const form = useForm({
    defaultValues: initialValues
  });
  useFieldArray({
    control: form.control,
    keyName: 'productId',
    name: 'requiredItems'
  });
  useFieldArray({
    control: form.control,
    keyName: 'productId',
    name: 'addonItems'
  });
  function onSubmit(form: typeof initialValues) {
    createSend({
      variables: {
        input: {
          recipient: {
            email: form.recipient.email,
            name: form.recipient.name,
            orgName: form.recipient.orgName,
            salesForceAccountId: form.recipient.salesForceAccountId || undefined,
            salesForceContactId: form.recipient.salesForceContactId || undefined,
            hubSpotCompanyId: form.recipient.hubSpotCompanyId || undefined,
            hubSpotContactId: form.recipient.hubSpotContactId || undefined
          },
          giftId: gift.id,
          message: form.message.text,
          sendItems: [...form.requiredItems, ...form.addonItems]
            .filter((item) => item.isAdded)
            .map((item) => ({
              productVariantId: item.variantId,
              quantity: item.quantity
            })),
          expiresAt: /* isGiftCard ? moment().add(30, 'days').toDate() : */ null,
          createHubSpotTask: form.recipient.isCreateHubSpotTaskChecked,
          createSalesForceTask: form.recipient.isCreateSalesForceTaskChecked,
          address:
            gift.deliveryType === DeliveryType.PHYSICAL
              ? {
                  name: form.address.name,
                  street1: form.address.street1,
                  street2: form.address.street2,
                  city: form.address.city,
                  state: form.address.state,
                  zip: form.address.zip
                }
              : undefined,
          isAddressPrivate:
            gift.deliveryType === DeliveryType.PHYSICAL ? form.address.isPrivateAddress : undefined
        }
      }
    })
      .then(({ data }) => {
        if (!data?.createSend) {
          enqueueSnackbar('There was an issue with this gift. Contact support for help.', {
            variant: 'error'
          });
          return;
        }
        setCreatedSend(data.createSend);
        enqueueSnackbar('Successfully Created Send!', { variant: 'success' });
      })
      .catch((error) => {
        const errorStrings = getMessagesFromApolloError(error);
        enqueueSnackbar(errorStrings.join('. '), { variant: 'error' });
      });
  }

  // Derived state
  const isDigital = gift.deliveryType === DeliveryType.DIGITAL;
  const isPhysical = gift.deliveryType === DeliveryType.PHYSICAL;
  const areAddonsAvailable = /amazon/i.test(gift.id);
  const hasMultipleVariants = gift.items?.[0]?.product.variants.length > 1;

  return createdSend ? (
    <SuccessStep send={createdSend} />
  ) : (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="flex-start"
          justifyContent="space-around"
          style={{ marginBottom: 48 }}
        >
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Send {isRaiseVisaRewards(gift.title) ? 'Reward' : 'Gift'} (
              {formatDollars(me.budget?.amount)} Budget)
            </Typography>
            <Divider light />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'stretch',
                padding: 24
              }}
            >
              <Grid item>
                <SectionTitle
                  step={'1'}
                  title={(isRaiseVisaRewards(gift.title) ? 'Reward' : 'Gift') + ' Options'}
                />
              </Grid>
              <br />
              <Grid item>
                <Box sx={{ mt: 1 }}>
                  {form.watch('requiredItems').map((item, index) => {
                    // Find related items

                    const product = gift.items?.find(
                      (giftItem) => giftItem.product.id === item.productId
                    )?.product;
                    const selectedVariant = product?.variants.find(
                      (variant) => variant.id === item.variantId
                    );

                    // Exception handling
                    if (!product || !selectedVariant) {
                      console.error('Could not find related product');
                      return;
                    }

                    const optionLabel = Boolean(product.options.length)
                      ? [...product.options]
                          .sort((a, b) => a.position - b.position)
                          .map((option) => option.name)
                          .join(' / ')
                      : 'Variant';

                    return (
                      <Grid container key={item.productId} spacing={2}>
                        <Grid item>
                          <Box
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              overflow: 'hidden',
                              width: 150,
                              borderRadius: 4,
                              border: `1px solid ${theme.palette.divider}`,
                              marginBottom: 16
                            }}
                          >
                            {product.featuredImageSrc && (
                              <img
                                style={{ width: '100%', height: 'auto' }}
                                alt={product.title}
                                src={product.featuredImageSrc}
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs>
                          <ListItemText
                            primary={
                              <Typography
                                color="textPrimary"
                                style={{ fontWeight: 'bold' }}
                                variant="subtitle2"
                              >
                                {product.title}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                color="textSecondary"
                                style={{ marginTop: 2 }}
                                variant="body1"
                              >
                                {formatDollars(selectedVariant.price)}
                                {product.description ? ` - ${product.description}` : ''}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item>
                          {product.variants.length > 1 && (
                            <FormControl size="small" variant="outlined" style={{ marginRight: 8 }}>
                              <InputLabel id={`requiredItems.${index}.variantId`}>
                                {optionLabel}
                              </InputLabel>
                              <Controller
                                name={`requiredItems.${index}.variantId`}
                                render={({ field }) => (
                                  <Select
                                    labelId={`requiredItems.${index}.variantId`}
                                    label={optionLabel}
                                    native
                                    {...field}
                                  >
                                    {[...product.variants]
                                      .sort((a, b) => a.price - b.price)
                                      .map((variant) => (
                                        <option key={variant.id} value={variant.id}>
                                          {variant.title}
                                        </option>
                                      ))}
                                  </Select>
                                )}
                              />
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </Grid>
              <Divider light />
              <br />
              <Grid item>
                <SectionTitle step={'2'} title={'Recipient'} />
              </Grid>
              <br />
              <Grid item>
                <RecipientForm />
              </Grid>
              <Divider light />
              <br />
              <Grid item>
                <SectionTitle step={'3'} title={'Personalization'} />
              </Grid>
              <br />
              <Grid item>
                <div style={{ margin: `8px 0 0 8px` }}>
                  <Controller
                    control={form.control}
                    name="message.text"
                    rules={{
                      maxLength: 500
                    }}
                    render={({ field }) => (
                      <MessageField
                        label="Message"
                        rows={6}
                        fullWidth
                        variant="outlined"
                        charLimit={500}
                        required
                        {...field}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Divider light />
              <br />
              <Grid item>
                <SectionTitle step={'4'} title={'Delivery'} />
              </Grid>
              <br />
              {isPhysical ? (
                <Grid item>
                  <AddressForm />
                </Grid>
              ) : null}
              {isDigital ? (
                <Grid item>
                  <Typography>
                    A link will be generated for your recipient to accept the gift.
                  </Typography>
                </Grid>
              ) : null}
              <Divider light />
              <br />
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  type="submit"
                  fullWidth
                >
                  {loading ? (
                    <Loading />
                  ) : isPhysical ? (
                    form.watch('address.isPrivateAddress') ? (
                      'Generate Link for Recipient!'
                    ) : (
                      'Send Gift!'
                    )
                  ) : (
                    'Generate Link for Recipient!'
                  )}
                </Button>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <OrderSummary
              products={[
                ...addonProducts.map((addonProduct) => ({ ...addonProduct, isAddon: true })),
                ...gift.items.map((item) => ({ ...item.product, isAddon: false }))
              ]}
              sendItems={[...form.watch('requiredItems') /*, ...form.watch('addonItems') */]}
              fixedShippingCost={gift.fixedShippingCost ?? 0}
              isRaiseVisaRewards={isRaiseVisaRewards(gift.title)}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

// Components
function SectionTitle({ step, title }: { step: string; title: string }) {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <NumberCircle>{step}</NumberCircle>
      </Grid>
      <Grid item xs>
        <Typography>{title}</Typography>
      </Grid>
    </Grid>
  );
}

export default ProductCheckoutContainer;
