import React from 'react';
import { SnackbarProvider as OriginalSnackbarProvider, SnackbarProviderProps } from 'notistack';
import Button from '@material-ui/core/Button';

/**
 * Add a dismiss button to all snackbars. See https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars.
 * @param props
 * @constructor
 */
const SnackbarProvider: React.FC<SnackbarProviderProps> = (props) => {
  // add action to all snackbars
  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <OriginalSnackbarProvider
      ref={notistackRef}
      action={(key: any) => (
        <Button onClick={onClickDismiss(key)} color={'inherit'}>
          Dismiss
        </Button>
      )}
      {...props}
    >
      {props.children}
    </OriginalSnackbarProvider>
  );
};

export default SnackbarProvider;
