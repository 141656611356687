import React, { Fragment, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import * as qs from 'query-string';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { LoginPage, Logout } from '../../__generated__/types';
import { IS_LOGGED_IN, LOGIN_PAGE } from '../../graphql/queries';
import { LOGIN, LOGOUT } from '../../graphql/mutations';
import { useHistory } from 'react-router-dom';
import LoginForm from './LoginForm';
import RsButton from '../../components/Button/Button';

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const onClick = (): void => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };
  const { data: { loginPage = null } = {} } = useQuery<LoginPage>(LOGIN_PAGE);
  return (
    <Container className={classes.authFormContainer} component="main" maxWidth="xs">
      <Paper className={classes.authPaper}>
        {loginPage && (
          <Alert severity={loginPage.isSuccess ? 'success' : 'warning'}>{loginPage.message}</Alert>
        )}
        <LoginForm />
        <Grid container>
          <Grid item xs>
            <Link variant="body2" onClick={() => history.push('reset-password')}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup">{"Don't have an account? Sign Up"}</Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  authFormContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6)
  },
  authPaper: {
    padding: theme.spacing(2)
  }
}));
