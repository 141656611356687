import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ExecutionResult } from 'graphql';
import { ApolloError } from 'apollo-client';
import { MutationFunctionOptions, useQuery } from '@apollo/react-hooks';
import Divider from '@material-ui/core/Divider';
import moment from 'moment-timezone';
import { formatDollars } from '../../../../../../../helpers/formatters';
import {
  AddUser,
  AddUserVariables,
  Customer,
  HubSpotOwners,
  HubSpotOwners_hubSpotOwners,
  HubSpotToken,
  HubSpotToken_hubSpotToken,
  OrgProfile,
  OrgTeams,
  OrgTeams_org_teams,
  OrgUsers,
  OrgUsers_org_salesForceUsers,
  SubscriptionStatus
} from '../../../../../../../__generated__/types';
import {
  CUSTOMER,
  HUBSPOT_OWNERS,
  HUBSPOT_TOKEN,
  ORG_PROFILE,
  ORG_TEAMS,
  ORG_USERS
} from '../../../../../../../graphql/queries';
import Loading from '../../../../../../../components/Loading/Loading';
import FormAlert from '../../../../../../../components/FormAlert/FormAlert';
import FormHelperText from '@material-ui/core/FormHelperText';
import PrimaryTextField from '../../../../../../../components/PrimaryTextField/PrimaryTextField';

type AddUserFormComponentProps = {
  serverError: any;
  loading: boolean;
  teams: OrgTeams_org_teams[] | null;
  salesForceUsers: OrgUsers_org_salesForceUsers[] | null;
  usersLoading: boolean;
  teamsLoading: boolean;
  salesForceUser?: OrgUsers_org_salesForceUsers | null;
  hubSpotToken?: HubSpotToken_hubSpotToken | null;
  hubSpotOwners?: HubSpotOwners_hubSpotOwners[] | null;
} & FormikProps<{
  email: string;
  accept: boolean;
  teamId: string;
  salesForceUserId: string;
  name: string;
  hubSpotOwnerId: string;
}>;

function AddUserFormComponent({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  serverError,
  teams,
  teamsLoading,
  salesForceUsers,
  usersLoading,
  setFieldValue,
  hubSpotToken,
  hubSpotOwners
}: AddUserFormComponentProps) {
  const { data: { org = null } = {}, loading: orgLoading } = useQuery<OrgProfile>(ORG_PROFILE, {
    fetchPolicy: 'network-only'
  });
  const { data: { customer = null } = {}, loading: customerLoading } = useQuery<Customer>(
    CUSTOMER,
    { fetchPolicy: 'network-only' }
  );

  const subscription = org && org.activeSaasSubscription;
  const daysTilEndOfMonth = moment().endOf('month').diff(moment(), 'days');
  const prorateAmount =
    subscription && subscription.plan
      ? (subscription.plan.amountDollars || 0) * (daysTilEndOfMonth / moment().daysInMonth())
      : 0;

  if (teamsLoading || usersLoading || orgLoading || customerLoading) {
    return <Loading />;
  }
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} direction="column" justify="flex-start" alignItems="stretch">
        {!!serverError && (
          <Grid item>
            <FormAlert error={serverError} />
          </Grid>
        )}
        {hubSpotToken && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel htmlFor={'hubSpotOwnerId'}>HubSpot</InputLabel>
              <Select
                fullWidth
                value={values.hubSpotOwnerId}
                onChange={({ target: { value } }) => {
                  setFieldValue('hubSpotOwnerId', value);
                  const hubSpotOwner = hubSpotOwners?.find((hsOwner) => hsOwner.id === value);
                  setFieldValue('email', hubSpotOwner?.email ?? '');
                  setFieldValue('name', hubSpotOwner?.name ?? '');
                }}
                inputProps={{
                  name: 'hubSpotOwnerId',
                  id: 'hubSpotOwnerId'
                }}
              >
                <MenuItem key={''} value={''}>
                  Not Selected
                </MenuItem>
                {(hubSpotOwners ? [...hubSpotOwners] : [])
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((hubSpotOwner: HubSpotOwners_hubSpotOwners) => (
                    <MenuItem key={hubSpotOwner.id} value={hubSpotOwner.id}>
                      {hubSpotOwner.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                In order to complete integration, the user will need to individually integrate with
                HubSpot after accepting invite.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        {salesForceUsers && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel htmlFor={'salesForceUserId'}>SalesForce Connection</InputLabel>
              <Select
                disabled={!salesForceUsers}
                fullWidth
                value={values.salesForceUserId}
                onChange={({ target: { value } }) => {
                  setFieldValue('salesForceUserId', value);
                  const salesForceUser = salesForceUsers.find((sfUser) => sfUser.id === value);
                  setFieldValue('email', salesForceUser ? salesForceUser.email : '');
                  setFieldValue('name', salesForceUser ? salesForceUser.name : '');
                }}
                inputProps={{
                  name: 'salesForceUserId',
                  id: 'salesForceUserId'
                }}
              >
                <MenuItem key={'Not Connected'} value={''}>
                  Not Connected
                </MenuItem>
                {(salesForceUsers ? [...salesForceUsers] : [])
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((salesForceUser: OrgUsers_org_salesForceUsers) => (
                    <MenuItem key={salesForceUser.id} value={salesForceUser.id}>
                      {salesForceUser.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs>
          <PrimaryTextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="no"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={!!(touched.email && errors.email)}
          />
        </Grid>
        <Grid item xs>
          <PrimaryTextField
            required
            fullWidth
            id="name"
            label="Full Name"
            name="name"
            autoComplete="no"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={!!(touched.name && errors.name)}
          />
        </Grid>
        {teams && (
          <Grid item xs>
            <FormControl fullWidth required error={!!(touched.teamId && errors.teamId)}>
              <InputLabel htmlFor="teamId">Team</InputLabel>
              <Select
                value={values.teamId}
                onChange={handleChange}
                inputProps={{
                  name: 'teamId',
                  id: 'teamId'
                }}
              >
                {teams.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {subscription && subscription.plan && (
          <Grid item>
            <Divider light />
            <br />
            <Typography variant={'body2'}>
              Adding 1 user will add
              <strong> {formatDollars(subscription.plan.amountDollars)} </strong>
              to your subscription bill starting{' '}
              {moment(subscription.periodEnd).format('MMMM Do, YYYY')}
            </Typography>
            <br />
            {subscription.status !== SubscriptionStatus.trialing && customer && customer.card && (
              <Typography variant={'body2'}>
                And an estimated charge of
                <strong> {formatDollars(prorateAmount)} </strong>- prorated for {daysTilEndOfMonth}{' '}
                days until {moment(subscription.periodEnd).format('MMMM Do')} - will be applied to
                your {customer.card.brand} ending in {customer.card.last4}.
              </Typography>
            )}
            {subscription.status === SubscriptionStatus.trialing && (
              <Typography variant={'body2'}>
                You will not be charged if you cancel your subscription before then.
              </Typography>
            )}
            <br />
            <Divider light />
          </Grid>
        )}
        <Grid item xs>
          <FormControlLabel
            control={
              <Checkbox
                required
                id="accept"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.accept}
              />
            }
            label="I agree to the terms & conditions and accept the charges associated with adding a user. "
          />
        </Grid>
        <Grid item xs>
          <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
            Invite User
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export type InvitationFormProps = {
  loading: boolean;
  submit: (
    options?: MutationFunctionOptions<AddUser, AddUserVariables>
  ) => Promise<ExecutionResult<AddUser>>;
  serverError: ApolloError | undefined;
};

export default function AddUserForm({ loading, submit, serverError }: InvitationFormProps) {
  const {
    loading: usersLoading,
    data: { org: { salesForceUsers = null } = {} } = {}
  } = useQuery<OrgUsers>(ORG_USERS);
  const { loading: teamsLoading, data: { org: { teams = null } = {} } = {} } = useQuery<OrgTeams>(
    ORG_TEAMS
  );
  const { data: { hubSpotToken = null } = {} } = useQuery<HubSpotToken>(HUBSPOT_TOKEN);
  const { data: { hubSpotOwners = [] } = {} } = useQuery<HubSpotOwners>(HUBSPOT_OWNERS);

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        accept: false,
        teamId: '',
        salesForceUserId: '',
        hubSpotOwnerId: ''
      }}
      onSubmit={(values) => {
        const salesForceUser =
          salesForceUsers && salesForceUsers.find((user) => user.id === values.salesForceUserId);
        submit({
          variables: {
            input: {
              email: values.email,
              name: values.name,
              teamId: values.teamId,
              salesForceUserId: values.salesForceUserId
            }
          }
        });
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().when('salesForceUserId', {
          is: true,
          then: Yup.string(),
          otherwise: Yup.string().email('Invalid Email').required()
        }),
        name: Yup.string().required(),
        teamId: Yup.string().required(),
        accept: Yup.bool().oneOf([true], 'You must accept')
      })}
      render={(formikProps) => (
        <AddUserFormComponent
          {...formikProps}
          serverError={serverError}
          loading={loading}
          teams={teams}
          teamsLoading={teamsLoading}
          usersLoading={usersLoading}
          salesForceUsers={salesForceUsers}
          hubSpotToken={hubSpotToken}
          hubSpotOwners={hubSpotOwners}
        />
      )}
    />
  );
}
