import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Theme, alpha, createStyles, withStyles } from '@material-ui/core/styles';

const styles = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#f7fafc',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '8px 8px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 1px`,
        borderColor: theme.palette.primary.main
      }
    }
  })
);

export default styles(InputBase);
