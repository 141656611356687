import React from 'react';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

export type CreditCardListItemProps = {
  primaryText: string;
  secondaryText?: string;
};
export default function CreditCardListItem({
  primaryText,
  secondaryText
}: CreditCardListItemProps) {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar color="secondary">
          <CreditCardIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText && '•••• •••• •••• ' + secondaryText}
      />
    </ListItem>
  );
}
