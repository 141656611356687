import React from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { MySends_me_sends, Opportunities_opportunities } from '../../__generated__/types';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment-timezone';
import Divider from '@material-ui/core/Divider';

export type SendOppsCardProps = {
  sends?: Array<{ createdAt: Date }> | null;
  opportunities?: Opportunities_opportunities[] | null;
  loading?: boolean | null;
};

export default function SendOppsCard({ sends, opportunities, loading }: SendOppsCardProps) {
  const isThereData = sends && sends.length;
  return (
    <Card>
      <CardHeader title="Sends vs Opps Created" />
      <Divider light />
      <CardContent>
        {loading && <LinearProgress />}

        <ResponsiveContainer height={250} width="100%">
          {!isThereData ? (
            renderNoDataGraph()
          ) : (
            <LineChart data={createChartData(sends, opportunities)}>
              <XAxis dataKey="name" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sends" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="opps" stroke="#82ca9d" />
            </LineChart>
          )}
        </ResponsiveContainer>
        <br />
        {!isThereData && !loading && <Alert severity={'info'}>No send data.</Alert>}
      </CardContent>
    </Card>
  );
}

export type SendOppsDatum = {
  name: string;
  startTime: Date;
  endTime: Date;
  sends: number;
  opps: number;
};

function createChartData(
  sends?: Array<{ createdAt: Date }> | null,
  opportunities?: Opportunities_opportunities[] | null
): SendOppsDatum[] {
  const data: SendOppsDatum[] = [];
  // Initialize the graph data
  for (let i = 0; i < 12; i++) {
    const month = moment().tz(moment.tz.guess()).subtract(i, 'months');
    const startTime = month.startOf('month').toDate();
    const endTime = month.endOf('month').toDate();
    const monthlySends = sends
      ? sends.filter(
          (send) =>
            moment(send.createdAt).isAfter(startTime) && moment(send.createdAt).isBefore(endTime)
        ).length
      : 0;
    const monthlyOpps = opportunities
      ? opportunities.filter(
          (opp) =>
            moment(opp.createdDate).isAfter(startTime) && moment(opp.createdDate).isBefore(endTime)
        ).length
      : 0;

    data.push({
      startTime,
      endTime,
      name: month.format("MMM 'YY"),
      opps: monthlyOpps, // todo
      sends: monthlySends
    });
  }
  return data.reverse();
}

function renderNoDataGraph() {
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2.4,
      amt: 2.4
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2.2
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2.3
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2.1
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2.5
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2.1
    }
  ];
  return (
    <LineChart data={data}>
      <Line type="monotone" dataKey="uv" stroke="#DEDEDE" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="pv" stroke="#ADADAD" />
    </LineChart>
  );
}
