import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatDateTime } from '../../helpers/formatters';

export type ExpiredMessageProps = {
  expiresAt: string;
};

export default function ExpiredMessage({ expiresAt }: ExpiredMessageProps) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" align="center">
          Your gift has expired.
        </Typography>
        <Typography variant="h6" align="center">
          💔
        </Typography>
        <br />
        <Divider light />
        <br />
        <Typography color="textSecondary" align="center" variant="body1">
          It expired at {formatDateTime(expiresAt)}
        </Typography>
      </CardContent>
    </Card>
  );
}
