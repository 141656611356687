import React, { ChangeEvent, ReactNode } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export type EasyDateRangeProps = {
  onChange: (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => void;
  value: EasyDateRangeOption;
  label?: string;
};

export enum EasyDateRangeOption {
  MONTH_TO_DATE = 'Month to Date',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_90_DAYS = 'Last 90 Days',
  LAST_120_DAYS = 'Last 120 Days',
  LAST_YEAR = 'Last Year',
  YEAR_TO_DATE = 'Year to Date'
}

export default function EasyDateRange({
  onChange,
  value,
  label = 'Date Range'
}: EasyDateRangeProps) {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={'easyDateRange'}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name: 'easyDateRange'
        }}
      >
        {Object.values(EasyDateRangeOption).map((value) => (
          <MenuItem value={value}>{value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
