import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export type PageSectionProps = {
  title: string;
  subheader?: string | React.ReactElement;
  children: JSX.Element[] | JSX.Element;
};

export default function PageSection({ title, subheader, children }: PageSectionProps) {
  return (
    <Grid container justify={'center'} spacing={2} alignItems={'flex-start'}>
      <Grid item xs={12} md={4}>
        <Typography variant={'h6'} gutterBottom>
          {title}
        </Typography>
        {typeof subheader === 'string' ? (
          <Typography variant={'body1'} gutterBottom>
            {subheader}
          </Typography>
        ) : (
          subheader
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
    </Grid>
  );
}
