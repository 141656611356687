import React from 'react';
import { FormControlProps } from '@material-ui/core/FormControl';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { FormLabelProps } from '@material-ui/core/FormLabel';
import PrimaryInputLabel from './PrimaryInputLabel/PrimaryInputLabel';
import PrimaryInput from './PrimaryInput/PrimaryInput';
import Collapse from '@material-ui/core/Collapse';
import PrimaryFormControl from './PimaryFormControl/PrimaryFormControl';
import PrimaryFormHelperText from './PrimaryFormHelperText/PrimaryFormHelperText';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useTheme from '@material-ui/core/styles/useTheme';
export type PrimaryTextFieldProps = {
  label: string;
  id: string;
  name?: string;
  multiline?: boolean;
  required?: boolean;
  optional?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  error?: boolean;
  rows?: number;
  value?: InputBaseProps['value'];
  autoComplete?: string;
  type?: InputBaseProps['type'];
  onChange?: InputBaseProps['onChange'];
  onBlur?: InputBaseProps['onBlur'];
  dataTestId?: string;
  inputProps?: InputBaseProps['inputProps'];
  endAdornment?: InputBaseProps['endAdornment'];
  inputLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
};
const PrimaryTextField = (props: PrimaryTextFieldProps, ref: React.Ref<any>) => {
  const theme = useTheme();
  const {
    inputProps,
    endAdornment,
    inputLabelProps,
    formControlProps,
    label,
    id,
    dataTestId,
    required,
    fullWidth,
    error,
    helperText,
    value,
    onChange,
    onBlur,
    name,
    type,
    optional,
    multiline,
    rows,
    autoComplete
  } = props;
  return (
    <PrimaryFormControl error={error} fullWidth={fullWidth} {...formControlProps}>
      <PrimaryInputLabel htmlFor={id} {...inputLabelProps}>
        {label}
        {optional && ' (optional)'}
      </PrimaryInputLabel>
      <PrimaryInput
        inputRef={ref}
        id={id}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        rows={rows}
        multiline={multiline}
        endAdornment={endAdornment}
        inputProps={{
          'data-testid': dataTestId,
          autoComplete,
          ...inputProps
        }}
      />
      <Collapse in={Boolean(helperText)}>
        <PrimaryFormHelperText>
          {error && (
            <InfoOutlinedIcon
              style={{
                color: error ? theme.palette.error.main : theme.palette.text.secondary,
                fontSize: 14,
                margin: `0 4px -2px 0px`
              }}
            />
          )}
          {helperText}
        </PrimaryFormHelperText>
      </Collapse>
    </PrimaryFormControl>
  );
};

export default React.forwardRef(PrimaryTextField);
