import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateSlackToken,
  CreateSlackTokenVariables,
  Me
} from '../../../../../__generated__/types';
import { ME } from '../../../../../graphql/queries';
import { CREATE_SLACK_TOKEN } from '../../../../../graphql/mutations';

import Alert from '@material-ui/lab/Alert';
import Loading from '../../../../../components/Loading/Loading';

const DELETE_SLACK_TOKEN = gql`
  mutation DeleteSlackToken {
    deleteSlackToken
  }
`;

export default function Slack() {
  const history = useHistory();
  const { code } = queryString.parse(window.location.search);
  const slackClientId = '769228245510.1513119983061';
  const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const { data: { me } = {}, loading: meLoading } = useQuery<Me>(ME);
  const [createSlackToken, { loading, error }] = useMutation<
    CreateSlackToken,
    CreateSlackTokenVariables
  >(CREATE_SLACK_TOKEN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ME }]
  });
  const [deleteSlackToken, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_SLACK_TOKEN,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: ME }]
    }
  );
  useEffect(() => {
    if (code && typeof code === 'string') {
      createSlackToken({ variables: { input: { code, redirectUrl } } }).finally(() =>
        history.push('/settings/integrations/slack')
      );
    }
  }, [code]);
  if (meLoading) {
    return <Loading />;
  }
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs />
        <br />
        <Typography gutterBottom variant={'h3'}>
          Slack Integration
        </Typography>
        <Divider light />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity={'error'}>There was an error, try again.</Alert>
        </Grid>
      )}
      {!me?.isSlackConnected && (
        <Grid item>
          <Typography gutterBottom>Your RevSend account isn't connected to Slack yet.</Typography>
          <Button
            variant={'contained'}
            disabled={loading}
            href={`https://slack.com/oauth/v2/authorize?scope=chat:write&redirect_uri=${redirectUrl}&client_id=${slackClientId}`}
          >
            {loading ? 'Loading...' : 'Connect and Install the RevSend Slack app'}
          </Button>
        </Grid>
      )}
      {me?.isSlackConnected && (
        <Grid item xs={12}>
          <Typography gutterBottom>✅ Your Slack account is linked to RevSend</Typography>
          <Button onClick={() => deleteSlackToken()} variant={'outlined'} disabled={deleteLoading}>
            {deleteLoading ? 'Loading...' : 'Disconnect'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
