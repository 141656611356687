import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import HubSpotDealsForm from './useHubSpotDealsForm';
import useHubSpotDealsForm from './useHubSpotDealsForm';
import { useQuery } from '@apollo/client';
import { HubSpotDeals, HubSpotDealsVariables } from '../../../../__generated__/types';
import { HUBSPOT_DEALS } from '../../../../graphql/queries';
import HubSpotDealsTable from './HubSpotDealsTable';

const HubSpotAnalytics: React.FC = () => {
  const { values, HubSpotDealsForm } = useHubSpotDealsForm();
  const { data: { hubSpotDeals = null } = {}, loading } = useQuery<
    HubSpotDeals,
    HubSpotDealsVariables
  >(HUBSPOT_DEALS, {
    variables: {
      connection: { first: 100, after: '0' },
      input: { from: values.from, to: values.to }
    }
  });
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HubSpotDealsForm />
        </Grid>
        <Grid item xs={12}>
          <HubSpotDealsTable hubSpotDeals={hubSpotDeals} loading={loading} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HubSpotAnalytics;
