import React, { Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import { formatDollars } from '../../../../../helpers/formatters';
import { UPDATE_BUDGET } from '../../../../../graphql/mutations';
import { BUDGETS, ME } from '../../../../../graphql/queries';
import { Budgets, UpdateBudget, UpdateBudgetVariables } from '../../../../../__generated__/types';
import FormAlert from '../../../../../components/FormAlert/FormAlert';
import { isEmpty, round } from 'lodash';
import { useSnackbar } from 'notistack';

export default function BudgetsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: budgetsLoading,
    error: budgetsError,
    data: { budgets = [] } = {}
  } = useQuery<Budgets>(BUDGETS, { fetchPolicy: 'network-only', nextFetchPolicy: 'network-only' });

  const [updateBudget, { loading: updateLoading, error: updateError, data }] = useMutation<
    UpdateBudget,
    UpdateBudgetVariables
  >(UPDATE_BUDGET, {
    refetchQueries: [{ query: ME }, { query: BUDGETS }]
  });

  function validateUpdateBudget(input: UpdateBudgetVariables['input']): boolean {
    if (isNaN(Number(input.amount))) {
      enqueueSnackbar(
        `Failed to update: Please submit a valid number for "Current Amount" when updating budget.`,
        {
          variant: 'error',
          persist: true
        }
      );
      return false;
    }

    if (isNaN(Number(input.refillAmount))) {
      enqueueSnackbar(
        `Failed to update: Please submit a valid number for "Refill Amount" when updating budget.`,
        {
          variant: 'error',
          persist: true
        }
      );
      return false;
    }
    return true;
  }

  return (
    <Fragment>
      <FormAlert error={updateError || budgetsError} />
      <MaterialTable
        components={{
          Container: (props) => <div>{props.children}</div>
        }}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            const input: UpdateBudgetVariables['input'] = {
              amount: Number(newData.amount || 0),
              refillAmount: Number(newData.refillAmount || 0),
              isRollover: Boolean(Number(newData.isRollover)),
              id: newData.id,
              frequency: newData.frequency
            };
            if (validateUpdateBudget(input)) {
              return updateBudget({ variables: { input } });
            }
          }
        }}
        isLoading={budgetsLoading || updateLoading}
        options={{
          search: true,
          showTitle: false,
          loadingType: 'linear',
          showEmptyDataSourceMessage: !(budgetsLoading || updateLoading)
        }}
        columns={[
          { field: 'id', hidden: true },
          { field: 'frequency', hidden: true },
          { field: 'name', editable: 'never', title: 'User' },
          {
            field: 'amount',
            editable: 'always',
            type: 'numeric',
            title: 'Current Amount',
            render: (data) => <>{formatDollars(data.amount)}</>
          },
          {
            field: 'refillAmount',
            editable: 'always',
            type: 'numeric',
            title: 'Refill Amount',
            render: (data) => <>{formatDollars(data.refillAmount)}</>
          },
          {
            field: 'isRollover',
            editable: 'always',
            type: 'boolean',
            title: 'Refill Option',
            render: (data) => <>{data.isRollover ? 'Rollover' : 'Use it or Lose it'}</>,
            lookup: { 1: 'Rollover', 0: 'Use it or Lose it' }
          }
        ]}
        data={budgets.map((budget) => ({
          ...budget,
          refillAmount: round(budget.refillAmount, 2),
          amount: round(budget.amount, 2),
          isRollover: Number(budget.isRollover),
          name: budget.user && budget.user.name
        }))}
        title="Budgets"
      />
    </Fragment>
  );
}
