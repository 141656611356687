import { useQuery } from '@apollo/react-hooks';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import hubSpotLogo from '../../../assets/images/hubspot-logo.png';
import salesForceIcon from '../../../assets/images/salesforce-avatar.png';
import PrimaryTextField from '../../../components/PrimaryTextField/PrimaryTextField';
import { HUBSPOT_TOKEN, ME } from '../../../graphql/queries';
import { HubSpotToken, Me } from '../../../__generated__/types';
import HubSpotContactSearch from '../Recipient/HubSpotContactSearch';
import SalesForceContactSearch from '../Recipient/SalesForceContactSearch';

export type RecipientFormValues = {
  name: string;
  email: string;
  orgName: string;
  salesForceContactId: string;
  salesForceAccountId: string;
  isCreateSalesForceTaskChecked: boolean;
  hubSpotContactId: string;
  hubSpotCompanyId: string;
  isCreateHubSpotTaskChecked: boolean;
};

export type RecipientFormProps = {
  showCreateSalesForceTask?: boolean;
  showCreateHubSpotTask?: boolean;
};

function RecipientForm(props: RecipientFormProps) {
  const { showCreateHubSpotTask = true, showCreateSalesForceTask = true } = props;
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    watch
  } = useFormContext<{ recipient: RecipientFormValues }>();
  const values = getValues();
  // Queries
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const { data: { hubSpotToken = null } = {} } = useQuery<HubSpotToken>(HUBSPOT_TOKEN);
  return (
    <div style={{ marginLeft: 8, marginTop: 8 }}>
      {/* Hubspot Contact Section */}
      {hubSpotToken ? (
        <>
          <Grid container alignItems={'center'} spacing={1} style={{ marginBottom: 8 }}>
            <Grid item>
              <Avatar src={hubSpotLogo} />
            </Grid>
            <Grid item>
              <Typography>HubSpot Connection</Typography>
            </Grid>
            <Grid item xs>
              <Divider light />
            </Grid>
          </Grid>
          <HubSpotContactSearch
            onSelect={(hubSpotContact) => {
              setValue('recipient.email', hubSpotContact.email);
              setValue('recipient.hubSpotCompanyId', hubSpotContact.companyId);
              setValue('recipient.hubSpotContactId', hubSpotContact.id);
              setValue('recipient.name', hubSpotContact.name);
              setValue('recipient.orgName', hubSpotContact.companyName);
              // Clear the checkbox to create task if hubspot contact is cleared (empty hubspotContact.id)
              setValue(
                'recipient.isCreateHubSpotTaskChecked',
                !hubSpotContact.id ? false : values.recipient.isCreateHubSpotTaskChecked
              );
            }}
          />
          {showCreateHubSpotTask && (
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="recipient.isCreateHubSpotTaskChecked"
                  render={({ field }) => (
                    <Checkbox
                      disabled={!hubSpotToken || !watch('recipient.hubSpotContactId')}
                      tabIndex={2}
                      inputProps={{ 'aria-labelledby': 'Is Create HubSpot Task Selected' }}
                      {...field}
                    />
                  )}
                />
              }
              label="Create Task in HubSpot"
            />
          )}
          <Divider light />
          <br />
        </>
      ) : null}
      {/* SalesForce Contact Section */}
      {me?.salesForceUserId ? (
        <>
          <Grid container alignItems={'center'} spacing={1} style={{ marginBottom: 8 }}>
            <Grid item>
              <Avatar src={salesForceIcon} />
            </Grid>
            <Grid item>
              <Typography>SalesForce Connection</Typography>
            </Grid>
            <Grid item xs>
              <Divider light />
            </Grid>
          </Grid>
          {errors.recipient?.salesForceContactId?.message ? (
            <Alert severity="error">{errors.recipient?.salesForceContactId?.message}</Alert>
          ) : null}
          <SalesForceContactSearch
            onSelect={(salesForceContact) => {
              setValue('recipient.salesForceAccountId', salesForceContact.accountId);
              setValue('recipient.salesForceContactId', salesForceContact.id);
              setValue('recipient.email', salesForceContact.email);
              setValue('recipient.orgName', salesForceContact.accountName);
              setValue('recipient.name', salesForceContact.name);
              // Clear the checkbox to create task if contact is cleared (empty salesForceContact.id)
              const isCreateSalesForceTaskChecked = watch(
                'recipient.isCreateSalesForceTaskChecked'
              );
              setValue(
                'recipient.isCreateSalesForceTaskChecked',
                !salesForceContact.id ? false : isCreateSalesForceTaskChecked
              );
            }}
          />
          {showCreateSalesForceTask && (
            <FormControlLabel
              control={
                <Controller
                  name="recipient.isCreateSalesForceTaskChecked"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      disabled={!me?.salesForceUserId || !values.recipient.salesForceContactId}
                      tabIndex={2}
                      inputProps={{ 'aria-labelledby': 'Is Create SalesForce Task Selected' }}
                      {...field}
                    />
                  )}
                />
              }
              label="Create Task in SalesForce"
            />
          )}
          <Divider light />
          <br />
        </>
      ) : null}
      <Controller
        name="recipient.name"
        control={control}
        render={({ field }) => (
          <PrimaryTextField required fullWidth id="name" label="Name" {...field} />
        )}
      />
      <Controller
        name="recipient.email"
        control={control}
        render={({ field }) => (
          <PrimaryTextField
            fullWidth
            id="email"
            inputProps={{ 'data-testid': 'email' }}
            label="Email"
            {...field}
          />
        )}
      />
      <Controller
        name="recipient.orgName"
        control={control}
        render={({ field }) => (
          <PrimaryTextField
            fullWidth
            id="orgName"
            inputProps={{ 'data-testid': 'orgName' }}
            label="Org Name"
            {...field}
          />
        )}
      />
    </div>
  );
}

export default React.memo(RecipientForm);
