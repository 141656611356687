import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/FileCopy';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import PrimaryTextField from '../TextField/PrimaryTextField';
export type EGiftRedemptionCardProps = {
  sendId: string;
};
export default function EGiftRedemptionCard({ sendId }: EGiftRedemptionCardProps) {
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const redemptionUrl = `${window.location.origin}/view/${sendId}`;

  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <Card>
        <CardContent>
          <Typography variant="body1" align="center" gutterBottom>
            {`The following is a direct link to redeem the gift.
                  Do not share this with anyone besides the recipient.`}
          </Typography>
          <br />
          <PrimaryTextField
            id={'redemptionUrl'}
            label="Redemption URL (Copy this)"
            value={redemptionUrl}
            required
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard text={redemptionUrl} onCopy={() => setIsLinkCopied(true)}>
                    <Button color={'primary'} onClick={handleCopyClick}>
                      <CopyIcon />
                      &nbsp;Copy
                    </Button>
                  </CopyToClipboard>
                  <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    <Alert severity={'success'}>Copied!</Alert>
                  </Popover>
                </InputAdornment>
              )
            }}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
}
