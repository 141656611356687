import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PrimaryTextField from '../PrimaryTextField/PrimaryTextField';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { FieldArray, useFormik, Formik } from 'formik';
import storefrontClient from '../../graphql/storefrontClient';
import { Collection, Product } from 'shopify-buy';
import { CircularProgress } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GIFT_FILTER_FORM_VALUES, GIFTS, ME, STOREFRONT_COLLECTIONS } from '../../graphql/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Gifts,
  GiftsVariables,
  StorefrontCollections,
  GiftFilterFormValues,
  GiftFilterFormValues_giftFilterFormValues,
  Me
} from '../../__generated__/types';
import { useApolloClient } from '@apollo/react-hooks';
import round from 'lodash/round';

export type GiftMenuFilterProps = {};

const FilterCard = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    }
  })
)(Card);

export type FormValues = Omit<GiftFilterFormValues_giftFilterFormValues, '__typename'>;
const GiftMenuFilter: React.FC<GiftMenuFilterProps> = ({}) => {
  const client = useApolloClient();
  const {
    data: { storefrontCollections = [] } = {},
    loading: collectionsLoading
  } = useQuery<StorefrontCollections>(STOREFRONT_COLLECTIONS);
  const [queryGifts, { data: { gifts = [] } = {}, loading: giftsLoading }] = useLazyQuery<
    Gifts,
    GiftsVariables
  >(GIFTS);

  const initialValues: FormValues = {
    storefrontCollectionId: ''
  };

  const onSubmit = (values: FormValues): void => {
    client.writeQuery<GiftFilterFormValues>({
      query: GIFT_FILTER_FORM_VALUES,
      data: {
        giftFilterFormValues: {
          ...values,
          __typename: 'GiftFilterFormValues'
        }
      }
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, submitForm }) => (
        <FilterCard>
          <CardContent>
            <Typography variant={'h3'} gutterBottom>
              Filter {(collectionsLoading || giftsLoading) && <LinearProgress />}
            </Typography>
            <Divider light />
            <br />
            <Typography variant={'h5'} gutterBottom>
              Category
            </Typography>
            <FormControl>
              <RadioGroup
                aria-label="category"
                name="category"
                onChange={(event) => {
                  setFieldValue('storefrontCollectionId', event.target.value);
                  return submitForm();
                }}
                value={values.storefrontCollectionId}
              >
                {storefrontCollections &&
                  storefrontCollections.length > 0 &&
                  storefrontCollections.map((collection) => (
                    <FormControlLabel
                      value={collection.id}
                      control={<Radio />}
                      label={collection.title}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </CardContent>
        </FilterCard>
      )}
    </Formik>
  );
};

export default GiftMenuFilter;
