import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
    color: theme.palette.secondary.contrastText
  },
  orgName: {
    color: theme.palette.secondary.contrastText
  }
}));

export type ProfileProps = {
  className?: string;
  name?: string;
  orgName?: string;
};

const Profile = (props: ProfileProps) => {
  const { className, orgName } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.name} variant="h5">
        {props.name}
      </Typography>
      <Typography variant="body2" className={classes.orgName}>
        {orgName}
      </Typography>
    </div>
  );
};

export default Profile;
