import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { ORG_USERS_SENDS } from '../../../../graphql/queries';
import { DateRange } from '../../../../types';
import UserAnalyticsTable from './UserAnalyticsTable';
import { OrgUserSends, OrgUserSendsVariables } from '../../../../__generated__/types';
import { EasyDateRangeOption } from '../../../../components/EasyDateRange/EasyDateRange';
import { mapEasyRangeToRange } from '../../../Dashboard/helpers';
import UserAnalyticsForm, {
  UserAnalyticsFormVars
} from './UserAnalyticsForm';

export default function UserAnalytics() {
  const [formValues, setFormValues] = useState<UserAnalyticsFormVars>({
    easyDateRange: EasyDateRangeOption.MONTH_TO_DATE
  });
  const [range, setRange] = useState<DateRange>(
    mapEasyRangeToRange(EasyDateRangeOption.MONTH_TO_DATE)
  );
  const { data, loading } = useQuery<OrgUserSends, OrgUserSendsVariables>(ORG_USERS_SENDS, {
    variables: { sendsInput: { created: range } },
    fetchPolicy: 'network-only'
  });

  function handleSubmit(values: UserAnalyticsFormVars) {
    setFormValues(values);
    setRange(mapEasyRangeToRange(values.easyDateRange));
  }

  return (
    <Fragment>
      <Grid container direction="column" spacing={2} justify="center" alignItems="stretch">
        <Grid item xs>
          <UserAnalyticsForm
            initialValues={formValues}
            onSubmit={(values) => handleSubmit(values)}
          />
        </Grid>
        <Grid item xs>
          <UserAnalyticsTable
            data={(data && data.org && data.org.users) || null}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
