import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useQuery } from '@apollo/react-hooks';
import { SalesForceToken, Send, SendVariables } from '../../__generated__/types';
import { useParams } from 'react-router-dom';
import FormAlert from '../../components/FormAlert/FormAlert';
import { SALESFORCE_TOKEN, SEND } from '../../graphql/queries';
import Loading from '../../components/Loading/Loading';
import SendDetail from './SendDetail';

export default function SendPage() {
  const { id } = useParams<any>();
  const { data: { send = null } = {}, loading, error } = useQuery<Send, SendVariables>(SEND, {
    variables: { input: { id: id! } }
  });
  const { data: { salesForceToken = null } = {} } = useQuery<SalesForceToken>(SALESFORCE_TOKEN);

  return (
    <Grid container direction="column" alignItems="stretch">
      <Grid item>
        <Typography variant="h5">Send Detail</Typography>
      </Grid>
      <Grid item>
        <Divider light />
        <br />
      </Grid>
      <Grid item xs>
        {loading && <Loading />}
        {error && <FormAlert error={error} />}
        {send && (
          <Grid container>
            <Grid item xs={12}>
              <SendDetail send={send} salesForceToken={salesForceToken} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
