import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GIFTS, ALL_GIFTS } from '../../graphql/queries';
import { AllGifts, AllGifts_allGifts } from '../../__generated__/types';
import EditGiftForm from './EditGiftForm';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PrimaryTextField from '../../components/TextField/PrimaryTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export type GiftsProps = {};
const Gifts: React.FC<GiftsProps> = (props) => {
  const { data: { allGifts = [] } = {} } = useQuery<AllGifts>(ALL_GIFTS);
  const [giftId, setGiftId] = useState<string | undefined>();
  const {} = props;
  return (
    <>
      <Card>
        <CardContent>
          <Autocomplete
            id="giftSelect"
            freeSolo
            options={allGifts}
            onChange={(event, gift) =>
              setGiftId((gift as AllGifts_allGifts | null)?.id ?? undefined)
            }
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <PrimaryTextField {...params} required label="Search Gifts" />}
          />
        </CardContent>
      </Card>
      <br />
      <EditGiftForm id={giftId} onGiftDeleted={() => setGiftId(undefined)} />
    </>
  );
};

export default Gifts;
