import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { makeStyles, Theme } from '@material-ui/core/styles';
import queryString from 'query-string';
import { useMutation, useQuery } from '@apollo/client';
import { CreateSlackToken, CreateSlackTokenVariables, Me } from '../../__generated__/types';
import { ME } from '../../graphql/queries';
import { CREATE_SLACK_TOKEN } from '../../graphql/mutations';
import Loading from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormAlert from '../../components/FormAlert/FormAlert';
import { Alert } from '@material-ui/lab';
const slackClientId = process.env.REACT_APP_SLACK_CLIENT_ID;
const slackAppId = process.env.REACT_APP_SLACK_APP_ID;
if (!slackClientId || !slackAppId) {
  throw new Error('Missing env var REACT_APP_SLACK_CLIENT_ID or REACT_APP_SLACK_APP_ID');
}

const Slack: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { code } = queryString.parse(window.location.search);
  const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const { data: { me } = {}, loading: meLoading } = useQuery<Me>(ME);
  const [createSlackToken, { loading: createSlackLoading, error: createTokenError }] = useMutation<
    CreateSlackToken,
    CreateSlackTokenVariables
  >(CREATE_SLACK_TOKEN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ME }]
  });
  useEffect(() => {
    if (me && code && typeof code === 'string') {
      history.push('/slack');
      createSlackToken({ variables: { input: { code, redirectUrl } } }).then(() => {
        window.location.href = `https://slack.com/app_redirect?app=${slackAppId}`;
      });
    }
  }, [code, me]);
  if (meLoading || createSlackLoading) {
    return <Loading />;
  }
  return (
    <Container className={classes.authFormContainer}>
      <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={2}>
        {createTokenError && (
          <Grid item>
            <FormAlert error={createTokenError} />
          </Grid>
        )}
        {me?.isSlackConnected && (
          <Alert severity={'success'} style={{ maxWidth: 500 }}>
            Your RevSend account is currently linked to Slack. Open the RevSend app in Slack to view
            the integration.
          </Alert>
        )}
        <Grid item>
          <Typography gutterBottom align={'center'} variant={'h3'}>
            Integrate your RevSend account with Slack
          </Typography>
        </Grid>
        <Grid item>
          <Card style={{ maxWidth: 500 }}>
            <CardContent>
              <Grid container justify={'center'} alignItems={'center'} spacing={4}>
                <Grid item>
                  <img width={180} src={'https://revsend.com/images/rs-colors.svg'} />
                </Grid>
                <Grid item>
                  <SettingsEthernetIcon />
                </Grid>
                <Grid item>
                  <img
                    width={180}
                    src={
                      'https://cdn.brandfolder.io/5H442O3W/as/pl546j-7le8zk-5guop3/Slack_RGB.png\n'
                    }
                  />
                </Grid>
                {code && !me && (
                  <Grid item>
                    <Typography align={'center'}>Login to complete connection</Typography>
                    <br />
                    <Button color={'primary'} variant={'contained'} fullWidth>
                      Login to RevSend
                    </Button>
                  </Grid>
                )}
                {me && me.isSlackConnected && <Grid item></Grid>}
              </Grid>
              <Divider light />
              <br />
              {!code && (
                <Grid container direction={'column'} spacing={2}>
                  <Grid item>
                    <Typography variant={'h4'}>With the RevSend Slack App:</Typography>
                  </Grid>
                  <Grid item style={{ padding: 12 }}>
                    <Typography gutterBottom>
                      💯 Never waste budget! We'll let you know when you're about to lose any unused
                      budget.
                    </Typography>
                    <Typography gutterBottom>
                      🔔 Get notified through Slack when your sends are redeemed, shipped,
                      delivered, etc.
                    </Typography>
                    <Typography gutterBottom>
                      🔗 Receive direct links to at-a-glance information for your sends.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a
                      href={`https://slack.com/oauth/v2/authorize?scope=chat:write&redirect_uri=${redirectUrl}&client_id=${slackClientId}`}
                    >
                      <img
                        alt="Add to Slack"
                        height="40"
                        width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                      />
                    </a>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Slack;

const useStyles = makeStyles((theme: Theme) => ({
  authFormContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6)
  },
  authPaper: {
    padding: theme.spacing(2)
  }
}));
