import { Minimal } from 'layouts';
import Login from '../views/Login/Login';
import Signup from '../views/Signup/Signup';
import SignupWithInvitation from '../views/SignupWithInvitation/SignupWithInvitation';
import { Page } from './types';

const authPages: Array<Page> = [
  {
    path: '/login',
    content: Login,
    layout: Minimal
  },
  {
    path: '/signup',
    content: Signup,
    layout: Minimal
  },
  {
    path: '/signup-with-invitation/:inviteCode',
    content: SignupWithInvitation,
    layout: Minimal
  }
];

export default authPages;
