import { ListItemSecondaryAction } from '@material-ui/core';
import round from 'lodash/round';
import { Campaign_campaign_gifts, Campaign_campaign_gifts_items } from '__generated__/types';

export function getGiftCardOptions({
  maxCostPerGift,
  gifts
}: {
  maxCostPerGift: number;
  gifts: Campaign_campaign_gifts[];
}) {
  // Gift card gifts should only ever have one item
  const giftsWithItems = gifts.filter((gift) => gift.items && gift.items.length === 1) as Array<
    Campaign_campaign_gifts & { items: Array<Campaign_campaign_gifts_items> }
  >;
  const giftOptions = giftsWithItems.map((gift) => {
    return {
      giftId: gift.id,
      highestPricedVariant: gift.items[0].product.variants.reduce<{
        price: number;
        id: string;
      } | null>(
        (currentMaxVariant, variant) =>
          !currentMaxVariant ||
          (round(currentMaxVariant.price, 2) <= round(variant.price, 2) &&
            round(variant.price, 2) <= round(maxCostPerGift, 2))
            ? variant
            : currentMaxVariant,
        null
      ),
      title: gift.title,
      imageUrl: gift.imageUrl
    };
  });

  return giftOptions.filter((giftOption) => giftOption.highestPricedVariant) as Array<{
    highestPricedVariant: { price: number; id: string };
    title: string;
    imageUrl: string;
    giftId: string;
  }>;
}
