import React, { useEffect, useState, Fragment } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  ResendInvitationEmail,
  ResendInvitationEmailVariables
} from '../../../../../__generated__/types';
import { INVITATIONS } from '../InvitationsTable/InvitationsTable';
import { DELETE_INVITATION, RESEND_INVITATION_EMAIL } from '../../../../../graphql/mutations';
import FormAlert from '../../../../../components/FormAlert/FormAlert';

type UpdateInvitationDialogProps = {
  invitationId: string | null;
  email: string | null;
  action: 'delete' | 'resend';
} & DialogProps;

export default function UpdateInvitationDialog({
  open,
  onClose,
  invitationId,
  email,
  action
}: UpdateInvitationDialogProps) {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [deleteInvitation, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_INVITATION,
    {
      onCompleted: () => {
        setShowSuccess(true);
      },
      refetchQueries: [{ query: INVITATIONS }]
    }
  );

  const [resendInvitationEmail, { loading: resendLoading, error: resendError }] = useMutation<
    ResendInvitationEmail,
    ResendInvitationEmailVariables
  >(RESEND_INVITATION_EMAIL, {
    onCompleted: () => {
      setShowSuccess(true);
    }
  });

  useEffect(() => {
    if (!open) {
      setShowSuccess(false);
    }
  }, [showSuccess, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        {invitationId && (
          <Fragment>
            <Formik
              initialValues={{
                id: invitationId
              }}
              onSubmit={(values) => {
                if (action === 'delete') {
                  deleteInvitation({ variables: { input: values } });
                  return;
                }
                resendInvitationEmail({ variables: { input: { ...values } } });
              }}
              validationSchema={Yup.object().shape({
                id: Yup.string().required()
              })}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Typography variant={'h5'}>
                    {action === 'delete' ? 'Revoke' : 'Resend'} Invite to {email}
                  </Typography>
                  <br />
                  {resendError && <FormAlert error={resendError} />}
                  {deleteError && <FormAlert error={deleteError} />}
                  {showSuccess && (
                    <Alert severity={'success'}>
                      Your invite has been successfully{' '}
                      {action === 'delete' ? 'deleted' : 're-sent'}.
                    </Alert>
                  )}
                  {!showSuccess && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant={'contained'}
                          type={'submit'}
                          color={'primary'}
                          disabled={resendLoading || deleteLoading}
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  <br />
                </form>
              )}
            </Formik>
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}
