import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, ModalProps, Theme } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';
import SendIcon from '@material-ui/icons/Send';
import TimelineIcon from '@material-ui/icons/Timeline';

import { Profile, SidebarNav } from './components';
import { useQuery } from '@apollo/react-hooks';
import { Me, PermissionType, Role } from '../../../../__generated__/types';
import { ME } from '../../../../graphql/queries';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.secondary.contrastText
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props: SidebarProps) => {
  const { open, variant, onClose, className } = props;
  const { data: { me = null } = {} } = useQuery<Me>(ME);

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      to: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'History',
      to: '/history',
      icon: <HistoryIcon />
    },
    {
      title: 'Send Gift',
      to: '/send-gift',
      icon: <SendIcon />
    }
  ];

  // Check if we have admin privileges, and grant access to the analytics tab
  if (me?.permissions?.find((permission) => permission.type === PermissionType.All)) {
    pages.push({
      title: 'Analytics',
      to: '/analytics',
      icon: <TimelineIcon />
    });
  }

  pages.push(
    ...[
      {
        title: 'Settings',
        to: '/settings',
        icon: <SettingsIcon />
      }
    ]
  );

  // Check if we are a revsend admin, and display the fulfillment tab
  if (me?.role === Role.RS_ADMIN) {
    pages.push(
      ...[
        {
          title: 'Fulfillment',
          to: '/fulfillment',
          icon: <SendIcon />
        },
        {
          title: 'Admin',
          to: '/admin',
          icon: <LockIcon />
        }
      ]
    );
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <Profile name={props.name} orgName={props.orgName} />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

export type SidebarProps = {
  className?: string;
  name?: string;
  orgName?: string;
  onClose: ModalProps['onClose'];
  open: boolean;
  variant?: 'permanent' | 'persistent' | 'temporary';
};

export default Sidebar;
