import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export function getMessagesFromApolloError(error: ApolloError) {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map((gqlError: GraphQLError) => {
      if (gqlError.extensions && gqlError.extensions.code === 'INTERNAL_SERVER_ERROR') {
        return 'Oops! There was an error. Try again.';
      }
      return gqlError.message;
    });
  }

  if (error.networkError && error.networkError.message)
    return ['Network Error. Try refreshing, or logging out and back in.'];

  return ['System Error. Try refreshing or logging out.'];
}
