import moment from 'moment-timezone';
import round from 'lodash/round';
import { PermissionType } from '../__generated__/types';
import { AddressFormVars } from '../components/AddressForm/AddressForm';

export const formatDollars = (dollars?: string | number | null): string => {
  return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(
    round(Number(dollars), 2) || 0
  );
};

export const formatCents = (cents?: string | number | null) => {
  return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(
    round((Number(cents) || 0) / 100, 2)
  );
};

export const formatDateTime = (iso8601: string, format?: string): string => {
  return moment(iso8601)
    .tz(moment.tz.guess())
    .format(format || 'MM/DD/YY hh:mm A');
};

export const formatDate = (iso8601: string): string => {
  return moment(iso8601).tz(moment.tz.guess()).format('MM/DD/YY');
};

export const formatAddress = (address: AddressFormVars): string => {
  return `${address.name}, ${address.street1}${address.street2 ? ` ${address.street2}` : ''}, ${
    address.city
  }, ${address.state} ${address.zip}`;
};

export function formatAddressVertical(address: AddressFormVars): string {
  return `
    ${address.name}
    ${address.street1}${address.street2 ? ` ${address.street2}` : ''}
    ${address.city}, ${address.state} ${address.zip}
    `;
}

export const formattedPermissions: Record<PermissionType, string> = {
  [PermissionType.All]: 'All',
  [PermissionType.Analytics__Read]: 'View Analytics',
  [PermissionType.Sends__Create]: 'Send gifts',
  [PermissionType.Sends__Create__GiftType__ALCOHOL]: 'Send Physical Wine',
  [PermissionType.Sends__Create__GiftType__AMAZON]: 'Send Amazon',
  [PermissionType.Sends__Create__GiftType__CHARITY]: 'Send Charity',
  [PermissionType.Sends__Create__GiftType__COFFEE]: 'Send Coffee',
  [PermissionType.Sends__Create__GiftType__COOKIE_GOOD]: 'Send Cookie Good',
  [PermissionType.Sends__Create__GiftType__E_GIFT_CARD]: 'Send Retail e-Card',
  [PermissionType.Sends__Create__GiftType__HANDWRITTEN_NOTE]: 'Send Handwritten Note',
  [PermissionType.Sends__Create__GiftType__E_WINE]: 'Send e-Wine',
  [PermissionType.Sends__Create__GiftType__UBER_EATS]: 'Send Uber Eats',
  [PermissionType.Users__Create]: 'Invite Users',
  [PermissionType.Users__Delete]: 'Remove Users',
  [PermissionType.Users__Read]: 'View Users',
  [PermissionType.Users__Update]: 'Edit Users'
};
