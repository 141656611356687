import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import Loading from '../../components/Loading/Loading';
import PrimaryTextField from '../../components/TextField/PrimaryTextField';
import { LOGIN_AS_USER } from '../../graphql/mutations';
import { ALL_USERS, IS_LOGGED_IN } from '../../graphql/queries';
import { AllUsers, IsLoggedIn, LoginAsUser, LoginAsUserVariables } from '../../__generated__/types';

export default function LoginAsUserForm() {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { data: { allUsers = [] } = {}, loading: allUsersLoading } = useQuery<AllUsers>(ALL_USERS);

  const [loginAsUser, { loading: loginLoading }] = useMutation<LoginAsUser, LoginAsUserVariables>(
    LOGIN_AS_USER,
    {
      onCompleted: async (data) => {
        const tokens = data.loginAsUser.tokens;
        if (!(tokens && tokens[0])) return;
        localStorage.setItem('token', tokens[0].value);
        enqueueSnackbar(`Switched users!`, { variant: 'success' });
        client.clearStore().then(() => {
          window.location.pathname = '/';
        });
      }
    }
  );

  if (allUsersLoading) {
    return <Loading />;
  }

  // Internal facing, so we don't care about UI being pretty
  if (!allUsers) {
    return <>'There was an issue getting all users. Cannot login as user.'</>;
  }

  return (
    <Formik
      initialValues={{
        userId: ''
      }}
      onSubmit={(values) => loginAsUser({ variables: { input: values } })}
      validationSchema={Yup.object().shape({
        userId: Yup.string().required()
      })}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'column'} spacing={2} alignItems={'stretch'}>
              <Grid item xs>
                <Autocomplete
                  options={allUsers.map((user) => ({
                    id: user.id,
                    name: user.name,
                    email: user.email
                  }))}
                  onChange={(event, value) => setFieldValue('userId', value?.id ?? '')}
                  getOptionLabel={(option) => option.name + ' - ' + option.email}
                  renderInput={(params) => (
                    <PrimaryTextField {...params} required label="Search Users" />
                  )}
                />
              </Grid>
              <Grid item xs>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={allUsersLoading || loginLoading}
                >
                  Login as User
                </Button>
              </Grid>
            </Grid>
          </form>
        </Fragment>
      )}
    </Formik>
  );
}
