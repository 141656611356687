import * as React from 'react';
import round from 'lodash/round';

type AddonForm = Record<string, { variantId: string; quantity: number; isAdded: boolean }>;

type AddonFormAction =
  | { type: 'SELECT_VARIANT'; variantId: string; productId: string }
  | { type: 'ADD'; variantId: string; productId: string }
  | { type: 'REMOVE'; productId: string }
  | { type: 'INIT'; state: AddonForm };

function reducer(addonForm: AddonForm, action: AddonFormAction) {
  switch (action.type) {
    case 'SELECT_VARIANT':
      return {
        ...addonForm,
        [action.productId]: {
          ...addonForm[action.productId],
          variantId: action.variantId
        }
      };
    case 'ADD':
      return {
        ...addonForm,
        [action.productId]: {
          ...addonForm[action.productId],
          isAdded: true
        }
      };
    case 'REMOVE':
      return {
        ...addonForm,
        [action.productId]: {
          ...addonForm[action.productId],
          isAdded: false
        }
      };
    case 'INIT':
      return action.state;
  }
}

type Product = {
  id: string;
  variants: Array<{ id: string; price: number }>;
};

function initAddonForm(products: Array<Product>): AddonForm {
  // Get the saved state from localStorage
  let savedAddonForm: AddonForm | null = null;
  try {
    savedAddonForm = JSON.parse(localStorage.getItem('addonForm') || '{}') as AddonForm;
  } catch (error) {
    console.error('Error parsing addonForm from localStorage', error);
  }
  // Build the form values
  const initialAddonForm: AddonForm = {};
  products.forEach((product) => {
    // Get the saved form value for this product
    const savedVariantId = savedAddonForm?.[product.id]?.variantId;
    const savedQuantity = savedAddonForm?.[product.id]?.quantity;
    const savedIsAdded = savedAddonForm?.[product.id]?.isAdded;

    const initialValue = {
      // Fallback to the first variant
      variantId: savedVariantId ?? product.variants[0].id,
      // Fallback quantity to 1
      quantity: savedQuantity ?? 1,
      // Fallback isAdded to false
      isAdded: savedIsAdded ?? false
    };

    initialAddonForm[product.id] = initialValue;
  });
  return initialAddonForm;
}

export default function useAddonForm(products: Array<Product>) {
  const [addonForm, dispatch] = React.useReducer(reducer, products, initAddonForm);

  // Re-initialize the form when products change
  React.useEffect(() => {
    if (Object.keys(addonForm).length === products.length) {
      return;
    }
    dispatch({ type: 'INIT', state: initAddonForm(products) });
  }, [products, addonForm]);

  // Save the form state to local storage
  React.useEffect(() => {
    // Skip if the form is empty. This prevents overwriting the saved form
    // when the hook is initialized with an empty array of products.
    if (Object.keys(addonForm).length === 0) {
      return;
    }
    localStorage.setItem('addonForm', JSON.stringify(addonForm));
  }, [addonForm]);

  // BEGIN Derived State
  const pricesByProductId: Record<string, number> = React.useMemo(
    () =>
      products.reduce<Record<string, number>>((prices, product) => {
        // Find the selected variant for this addon product
        const selectedVariant = product.variants.find(
          (variant) => variant.id === addonForm[product.id]?.variantId
        );
        // Index the current price (based on the selected variant) by the product id
        prices[product.id] = selectedVariant
          ? round(selectedVariant.price * addonForm[product.id]?.quantity, 2)
          : 0;
        return prices;
      }, {}),
    [addonForm, products]
  );

  // Line items to be submitted when placing an order
  const lineItems: Array<{ variantId: string; quantity: number }> = React.useMemo(
    () =>
      Object.values(addonForm)
        .filter((variant) => variant.isAdded)
        .map((variant) => ({
          variantId: variant.variantId,
          quantity: variant.quantity
        })),
    [addonForm]
  );

  const subtotal: number = React.useMemo(
    () =>
      Object.entries(pricesByProductId).reduce(
        (subtotal, [productId, price]) => (addonForm[productId]?.isAdded ? subtotal + price : 0),
        0
      ),
    [addonForm, pricesByProductId]
  );
  // END Derived State

  // BEGIN Actions
  const selectProductVariant = (productId: string, variantId: string): void => {
    dispatch({ type: 'SELECT_VARIANT', variantId, productId });
  };
  const addToCart = (productId: string) => {
    // Find selected variant for product
    const variantId = addonForm[productId].variantId;
    dispatch({ type: 'ADD', variantId, productId });
  };
  const removeFromCart = (productId: string) => {
    dispatch({ type: 'REMOVE', productId });
  };
  // END Actions

  return {
    addonForm,
    subtotal,
    pricesByProductId,
    lineItems,
    selectProductVariant,
    addToCart,
    removeFromCart
  };
}
