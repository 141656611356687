import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { RECIPIENT_VIEW_SEND } from '../../graphql/queries';
import {
  CreateAddress,
  RecipientViewSend,
  RecipientViewSend_send,
  SendStatus,
  UpdateSend
} from '../../__generated__/types';
import AddressForm, { AddressFormVars } from '../../components/AddressForm/AddressForm';
import { CREATE_ADDRESS, UPDATE_SEND } from '../../graphql/mutations';
import { Alert } from '@material-ui/lab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ProductPreview from '../../components/ProductPreview/ProductPreview';

export default function Confirmation() {
  const { sendId } = useParams<{ sendId: string }>();
  const client = useApolloClient();
  const { data: { send } = {}, loading } = useQuery<RecipientViewSend>(RECIPIENT_VIEW_SEND, {
    variables: {
      input: {
        id: sendId
      }
    }
  });
  const [updateSend, { loading: updateSendLoading }] = useMutation<UpdateSend>(UPDATE_SEND, {
    onCompleted: () => {
      return client.cache.reset();
    }
  });

  function isAddressSubmitted(send?: RecipientViewSend_send | null): boolean {
    return send?.status !== SendStatus.PENDING_ADDRESS;
  }

  function handleAddressSubmit(addressId: string) {
    return updateSend({
      variables: { input: { id: sendId, addressId } }
    });
  }

  const classes = useStyles();
  if (loading) {
    return (
      <>
        <br />
        <Loading />
      </>
    );
  }
  if (!send) {
    return <>Not Found!</>;
  }
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      {isAddressSubmitted(send) && (
        <Grid item xs>
          <Alert severity={'success'}>Address submitted! Your gift is processing. Thank you!</Alert>
        </Grid>
      )}
      {/* Todo: preview product */}
      {/*<Grid item xs={12} sm={4} md={4} lg={3}>*/}
      {/*  <ProductPreview title={send.giftName} />*/}
      {/*</Grid>*/}
      <Grid item xs={12} sm={8} md={8} lg={6}>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center">
              {send.creator.name} from {send.org!.formattedName} bought you a gift! 🎉
            </Typography>
            <br />
            <Divider light />
            <br />
            <Typography variant={'body1'} color={'textSecondary'} align={'center'}>
              <i>{send.message}</i>
            </Typography>
            <br />
            <Divider light />
            <br />
            {!isAddressSubmitted(send) && (
              <>
                <Typography variant="body1" align="center" gutterBottom>
                  🚚 Fill out your address below for it to ship!
                </Typography>
                <Typography variant="body2" align="center">
                  Your address will not be shared with the sender of this gift or their
                  organization. It will be used by RevSend for fulfillment only.
                </Typography>
                <br />
                <AddressForm onSubmit={handleAddressSubmit} loading={updateSendLoading} />
                <br />
                <Typography variant="body2" align="center">
                  By submitting your address, you agree to RevSend's{' '}
                  <Link href={'https://revsend.com/terms-conditions.html'}>
                    Terms&nbsp;of&nbsp;Service
                  </Link>{' '}
                  and{' '}
                  <Link href={'https://revsend.com/privacy-policy.html'}>Privacy&nbsp;Policy</Link>.
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  redeemGiftPaper: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    padding: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(2)
  }
}));
