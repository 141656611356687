import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';
import Alert from '@material-ui/lab/Alert';

function getMessagesFromApolloError(error: ApolloError) {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map((gqlError: GraphQLError) => {
      if (gqlError.extensions && gqlError.extensions.code === 'INTERNAL_SERVER_ERROR') {
        return 'Oops! There was an error. Try again.';
      }
      return gqlError.message;
    });
  }

  if (error.networkError && error.networkError.message)
    return ['Network Error. Try refreshing, or logging out and back in.'];

  return ['System Error. Try refreshing or logging out.'];
}

export type FormAlertProps = {
  error?: ApolloError;
};

export default function FormAlert({ error }: FormAlertProps) {
  return (
    <Fragment>
      {error && (
        <Alert severity={'error'}>
          <Typography variant="body2">
            {getMessagesFromApolloError(error).map((message, index) => (
              <span key={index}>{message}</span>
            ))}
          </Typography>
        </Alert>
      )}
    </Fragment>
  );
}
