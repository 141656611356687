import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import omit from 'lodash/omit';
import { ORG_PROFILE } from '../../../../../graphql/queries';
import { UPDATE_ORG } from '../../../../../graphql/mutations';
import {
  OrgProfile,
  OrgProfile_org_settings,
  UpdateOrg,
  UpdateOrgVariables
} from '../../../../../__generated__/types';

export type OrgSettingsFormProps = {
  readOnly?: boolean;
};

export default function OrgSettingsForm({ readOnly }: OrgSettingsFormProps) {
  const { data: { org = null } = {} } = useQuery<OrgProfile>(ORG_PROFILE);
  const [updateOrg, { loading }] = useMutation<UpdateOrg, UpdateOrgVariables>(UPDATE_ORG);

  if (!org) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <Card>
        <CardContent>
          <Formik
            initialValues={mapInitialValues(org.settings)}
            onSubmit={(values) => updateOrg({ variables: { input: { settings: values } } })}
          >
            {({ handleSubmit, values, handleChange, handleBlur }) => (
              <form onSubmit={handleSubmit}>
                {Object.entries(values).map(
                  ([key, value]) =>
                    key !== 'id' && (
                      <ListItem key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={key}
                              disabled={readOnly}
                              name={key}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={!!value}
                            />
                          }
                          label={getSettingLabel(key)}
                        />
                      </ListItem>
                    )
                )}
                {!readOnly && (
                  <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    Save
                  </Button>
                )}
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Fragment>
  );
}

function mapInitialValues(settings: OrgProfile_org_settings | null) {
  return omit(settings, '__typename');
}

function getSettingLabel(settingKey: string): string {
  if (settingKey === 'requireSalesForceContact') {
    return 'Require sends to be linked to SalesForce Contact';
  }
  return settingKey;
}
