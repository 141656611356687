import { ApolloCache } from '@apollo/client';
import {
  IsCreateSalesForceTaskChecked,
  IsLoggedIn,
  SelectedSalesForceContact,
  LoginPage,
  SelectedHubSpotContact,
  IsCreateHubSpotTaskChecked
} from '../__generated__/types';
import {
  IS_CREATE_HUBSPOT_TASK_CHECKED,
  IS_CREATE_SALESFORCE_TASK_CHECKED,
  IS_LOGGED_IN,
  LOGIN_PAGE,
  SELECTED_SALESFORCE_CONTACT,
  SELECTED_HUBSPOT_CONTACT
} from './queries';
import { clearSendForms } from '../domains/send/cacheUpdates';

export function initializeCache<TData>(cache: ApolloCache<TData>): ApolloCache<TData> {
  // Clear the forms
  clearSendForms(cache);

  const isLoggedIn = !!localStorage.getItem('token');

  cache.writeQuery<IsLoggedIn>({
    query: IS_LOGGED_IN,
    data: {
      isLoggedIn
    }
  });
  cache.writeQuery<SelectedSalesForceContact>({
    query: SELECTED_SALESFORCE_CONTACT,
    data: {
      selectedSalesForceContact: null
    }
  });
  cache.writeQuery<SelectedHubSpotContact>({
    query: SELECTED_HUBSPOT_CONTACT,
    data: {
      selectedHubSpotContact: null
    }
  });
  cache.writeQuery<IsCreateSalesForceTaskChecked>({
    query: IS_CREATE_SALESFORCE_TASK_CHECKED,
    data: {
      isCreateSalesForceTaskChecked: false
    }
  });
  cache.writeQuery<IsCreateHubSpotTaskChecked>({
    query: IS_CREATE_HUBSPOT_TASK_CHECKED,
    data: {
      isCreateHubSpotTaskChecked: false
    }
  });
  cache.writeQuery<LoginPage>({
    query: LOGIN_PAGE,
    data: {
      loginPage: null
    }
  });

  return cache;
}
