import React, { Fragment } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useMutation } from '@apollo/react-hooks';
import { FieldArray, Formik, FormikProps } from 'formik';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  DeleteTeam,
  DeleteTeamVariables,
  OrgTeams_org_teams
} from '../../../../../__generated__/types';
import { teamQueries } from '../../../../../graphql/queries';
import { DELETE_TEAM } from '../../../../../graphql/mutations';
import FormAlert from '../../../../../components/FormAlert/FormAlert';

type DeleteTeamDialogProps = {
  onSuccess: () => void;
  team: OrgTeams_org_teams | null;
} & DialogProps;

export default function DeleteTeamDialog({
  team,
  onSuccess,
  open,
  onClose
}: DeleteTeamDialogProps) {
  const [deleteTeam, { error, loading }] = useMutation<DeleteTeam, DeleteTeamVariables>(
    DELETE_TEAM,
    {
      refetchQueries: teamQueries,
      onCompleted: () => onSuccess()
    }
  );

  const classes = useStyles();
  if (!team) {
    return <Fragment />;
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        {error && <FormAlert error={error} />}
        <Formik
          initialValues={{}}
          onSubmit={() => deleteTeam({ variables: { input: { id: team.id } } })}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant={'h5'} gutterBottom>
                Delete Team {team.name}
              </Typography>
              <br />
              <Typography variant={'body1'} gutterBottom>
                Are you sure?
              </Typography>
              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="inherit"
                classes={{ root: classes.dangerButton }}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Delete'}
              </Button>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dangerButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '&:active': {
      backgroundColor: theme.palette.error.light
    },
    '&:focus': {
      backgroundColor: theme.palette.error.main
    }
  }
}));
