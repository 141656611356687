import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Customer } from '../../../../__generated__/types';
import Loading from '../../../../components/Loading/Loading';
import { CUSTOMER } from '../../../../graphql/queries';
import UpdateCardForm from './UpdateCardForm';
import CreditCardListItem from '../../Budgets/components/AccountCreditSummary/components/AddCreditForm/components/CreditCardListItem/CreditCardListItem';
import Link from '@material-ui/core/Link';

export default function PaymentOptions() {
  const [isUpdateOpen, setIsUpdateOpen] = React.useState<boolean>(false);
  const { data, loading, error } = useQuery<Customer>(CUSTOMER);
  if (loading) return <Loading />;
  if (data) {
    const { customer } = data;
    return (
      <Grid container direction="row" alignItems="stretch" justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Typography variant="h5">Card on File</Typography>
                </Grid>
                <Grid item>
                  <List>
                    {customer && customer.card ? (
                      <CreditCardListItem
                        primaryText={customer.card.brand}
                        secondaryText={customer.card.last4}
                      />
                    ) : (
                      <ListItem>
                        <ListItemText primary="Looks like there's no card. Add one to start sending!" />
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Grid item>
                  <Button
                    variant={isUpdateOpen ? 'outlined' : 'contained'}
                    color="primary"
                    onClick={() => setIsUpdateOpen((prevState) => !prevState)}
                  >
                    {isUpdateOpen ? 'Cancel' : 'Update'}
                  </Button>
                </Grid>
              </Grid>
              <br />
              <Grid container direction="row" spacing={2} justify="center" alignItems="stretch">
                {isUpdateOpen && (
                  <>
                    <Grid item xs={12}>
                      <UpdateCardForm onSuccess={() => setIsUpdateOpen(false)} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center">
                        By submitting your payment information, you agree to RevSend's{' '}
                        <Link href={'https://revsend.com/terms-conditions.html'}>
                          Terms&nbsp;of&nbsp;Service
                        </Link>{' '}
                        and{' '}
                        <Link href={'https://revsend.com/privacy-policy.html'}>
                          Privacy&nbsp;Policy
                        </Link>
                        .
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
  return <div>There was an error</div>;
}
