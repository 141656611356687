import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AmazonGuide from './components/AmazonGuide/AmazonGuide';
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';

export default function Amazon() {
  return (
    <Grid container direction="row" spacing={2} justify="center" alignItems="center">
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Amazon Integration</Typography>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <AmazonGuide />
        <br />
      </Grid>
      <Grid item xs></Grid>
    </Grid>
  );
}
