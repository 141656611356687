import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import Routes from './routes/Routes/Routes';
import { StripeProvider } from 'react-stripe-elements';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './graphql/client';
import { CssBaseline } from '@material-ui/core';
import SnackbarProvider from './components/SnackbarProvider/SnackbarProvider';
import { useIdleTimer } from 'react-idle-timer';

const browserHistory = createBrowserHistory();

export default function App() {
  if (!process.env.REACT_APP_STRIPE_KEY) throw new Error('Missing stripe key.');
  // Reload the page every 30 minutes of idle time
  useIdleTimer({ timeout: 1000 * 60 * 30, onIdle: () => window.location.reload() });
  return (
    <ApolloProvider client={client}>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} maxSnack={1}>
            <CssBaseline />
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </StripeProvider>
    </ApolloProvider>
  );
}
