import React, { Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PersonAdd } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { AcceptInvite, AcceptInviteVariables } from '../../__generated__/types';
import FormAlert from '../../components/FormAlert/FormAlert';
import { useApolloClient, useMutation } from '@apollo/client';
import { ACCEPT_INVITE } from '../../graphql/mutations';
import { IS_LOGGED_IN } from '../../graphql/queries';
import { useHistory } from 'react-router-dom';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export type AcceptInviteFormProps = {
  inviteCode: string;
  orgName: string;
  email: string;
  name: string;
};

export default function AcceptInviteForm(signupFormProps: AcceptInviteFormProps) {
  const { orgName, inviteCode, email, name } = signupFormProps;
  const history = useHistory();
  const client = useApolloClient();
  const classes = useStyles();
  const [submitAcceptInvite, { loading, error }] = useMutation<AcceptInvite, AcceptInviteVariables>(
    ACCEPT_INVITE
  );

  function onComplete(data?: AcceptInvite | null) {
    const token = data?.acceptInvite?.tokens?.[0]?.value;
    if (!token) return;
    localStorage.setItem('token', token);
    client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
    history.push('/dashboard');
  }

  return (
    <Formik
      initialValues={{
        inviteCode,
        name,
        password: '',
        recaptchaToken: ''
      }}
      onSubmit={(values) => {
        return submitAcceptInvite({ variables: { input: { ...values } } }).then(({ data }) =>
          onComplete(data)
        );
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        password: Yup.string()
          .max(50, 'Must be under 50 characters')
          .min(6, 'Must be over 6 characters')
          .required()
      })}
    >
      {({ handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue }) => (
        <Paper className={classes.paper} elevation={0}>
          <Avatar className={classes.avatar}>
            <PersonAdd />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          {inviteCode && (
            <Fragment>
              <br />
              <Typography variant="body1" align="center">
                Accept invitation to join {orgName} on RevSend.
              </Typography>
              <br />
              <Typography variant="subtitle2" align="center">
                Note that the email below is your login username:
              </Typography>
            </Fragment>
          )}
          {!!error && <FormAlert error={error} />}
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <PrimaryTextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              inputProps={{
                readOnly: true
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={email}
              helperText={' '}
            />
            <PrimaryTextField
              required
              fullWidth
              id="name"
              label="Your Full Name"
              name="name"
              autoComplete="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={!!(touched.name && errors.name)}
              helperText={(touched.name && errors.name) || ' '}
            />
            <PrimaryTextField
              required
              fullWidth
              name="password"
              label="Password"
              inputProps={{
                'data-testid': 'password'
              }}
              type="password"
              id="password"
              onBlur={handleBlur}
              autoComplete="current-password"
              value={values.password}
              onChange={handleChange}
              error={!!(touched.password && errors.password)}
              helperText={(touched.password && errors.password) || ' '}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
              onChange={(token) => setFieldValue('recaptchaToken', token)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              data-testid={'submit'}
              className={classes.submit}
              disabled={loading || !values.recaptchaToken}
            >
              Sign Up!
            </Button>
          </form>
        </Paper>
      )}
    </Formik>
  );
}
