import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormAlert from '../../components/FormAlert/FormAlert';
import { Login, LoginVariables } from '../../__generated__/types';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { LOGIN } from '../../graphql/mutations';
import { getInitialCache } from '../../graphql/client';
import { initializeCache } from '../../graphql/cacheUpdates';
import PrimaryTextField from '../../components/PrimaryTextField/PrimaryTextField';
import RsButton from '../../components/Button/Button';
import { HearingSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

type LoginFormComponentProps = FormikProps<LoginVariables> & {
  loading: boolean;
  error?: ApolloError;
};

function LoginFormComponent({
  loading,
  values,
  touched,
  error,
  errors,
  handleChange,
  handleBlur,
  handleSubmit
}: LoginFormComponentProps) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Login
      </Typography>
      <FormAlert error={error} />
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <PrimaryTextField
          fullWidth
          required
          id="email"
          label="Email Address"
          name="email"
          onChange={handleChange}
          value={values.email}
          error={!!(touched.email && errors.email)}
          helperText={(touched.email && errors.email) || ''}
        />
        <PrimaryTextField
          fullWidth
          required
          name="password"
          label="Password"
          type="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          error={!!(touched.password && errors.password)}
          helperText={(touched.password && errors.password) || ''}
        />
        <RsButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          loading={loading}
        >
          Login
        </RsButton>
      </form>
    </div>
  );
}

export default function LoginForm() {
  const [login, { loading, error }] = useMutation<Login, LoginVariables>(LOGIN, {
    update(cache, { data }) {
      if (!data?.login?.tokens?.[0]) {
        return;
      }
      localStorage.setItem('token', data?.login.tokens[0].value);
      cache.reset().then(() => initializeCache(cache));
    }
  });
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={(values) =>
        login({ variables: { ...values } }).then(() => {
          // @ts-ignore
          window.heap?.identify(values.email);
        })
      }
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Invalid Email').required('Please enter your email'),
        password: Yup.string()
          .max(50, 'Must be under 50 characters')
          .required('Please enter your password')
      })}
    >
      {(formikProps) => <LoginFormComponent {...formikProps} loading={loading} error={error} />}
    </Formik>
  );
}
