import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Theme } from '../../theme';

type NumberCircleProps = {
  children: string;
};
export default function NumberCircle(props: NumberCircleProps) {
  const classes = useStyles();
  return (
    <Box className={classes.circle}>
      <Typography className={classes.number}>{props.children}</Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  circle: {
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 40
  },
  number: {
    fontWeight: 'bold'
  }
}));
