import React, { Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import DropShipFulfillment from './DropShipFulfillment';

const tabs = ['Drop Ship Orders (including Amazon)'];

export default function Fulfillment() {
  const [tab, setTab] = React.useState(tabs[0]);

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Fragment>
      <Tabs value={tab} onChange={handleChange} aria-label="simple tabs example">
        {tabs.map((tab) => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <Divider light />
      {tab === tabs[0] && (
        <Grid container>
          <Grid item xs={12}>
            <DropShipFulfillment />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
