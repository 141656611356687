import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { formatDollars } from '../../helpers/formatters';
import { Send_send_sendItems } from '../../__generated__/types';
import useTheme from '@material-ui/core/styles/useTheme';
import sumBy from 'lodash/sumBy';

export type ProductSendSummaryProps = {
  shippingCost?: number | null;
  invoiceItemAdjustments?: Array<{
    id: string;
    dollars: number;
    description?: string | null;
  }> | null;
  sendItems?: Array<{
    quantity: number;
    title: string;
    description?: string | null;
    price: number;
  }> | null;
  showHeader?: boolean;
};

function calculateGrandTotal(
  sendItems: ProductSendSummaryProps['sendItems'],
  shippingCost?: number,
  invoiceItemAdjustments?: ProductSendSummaryProps['invoiceItemAdjustments']
): number {
  return (
    calculateSubtotal(sendItems) +
    (shippingCost || 0) +
    calculateAdjustmentTotal(invoiceItemAdjustments)
  );
}

function calculateSubtotal(sendItems: ProductSendSummaryProps['sendItems']): number {
  return sendItems?.reduce((subTotal, sendItem) => subTotal + sendItem.price, 0) ?? 0;
}

function calculateAdjustmentTotal(
  invoiceItems: ProductSendSummaryProps['invoiceItemAdjustments']
): number {
  return invoiceItems?.reduce((subTotal, sendItem) => subTotal + sendItem.dollars, 0) ?? 0;
}
export default function ProductSendSummary({
  shippingCost,
  sendItems,
  invoiceItemAdjustments,
  showHeader = true
}: ProductSendSummaryProps) {
  const theme = useTheme();
  return (
    <React.Fragment>
      {showHeader && (
        <Typography variant="h5" gutterBottom>
          Order summary
        </Typography>
      )}
      <List dense>
        {sendItems &&
          sendItems.map((sendItem, index) => (
            <ListItem key={index}>
              <ListItemText primary={sendItem.title} secondary={sendItem.description} />
              <Typography variant={'body2'}>{formatDollars(sendItem.price)}</Typography>
            </ListItem>
          ))}
        <ListItem>
          <ListItemText primary="Shipping" />
          <Typography variant="body2">{formatDollars(shippingCost || 0)}</Typography>
        </ListItem>
        {invoiceItemAdjustments && invoiceItemAdjustments.length > 0 && (
          <>
            <Divider light />
            <br />
            <ListItem>
              <Typography variant={'body2'}>
                <strong>Adjustments</strong>
              </Typography>
            </ListItem>
            {invoiceItemAdjustments.map((adjustment) => (
              <ListItem key={adjustment.id}>
                <ListItemText primary={adjustment.description ?? 'Adjustment'} />
                <Typography
                  variant={'body2'}
                  style={{
                    color:
                      adjustment.dollars < 0
                        ? theme.palette.success.main
                        : theme.palette.text.primary
                  }}
                >
                  {formatDollars(adjustment.dollars)}
                </Typography>
              </ListItem>
            ))}
          </>
        )}
        <Divider light />
        <ListItem>
          <ListItemText primary="Total" />
          <Typography variant="body2">
            <strong>
              {formatDollars(
                calculateGrandTotal(sendItems, Number(shippingCost), invoiceItemAdjustments)
              )}
            </strong>
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
