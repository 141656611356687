import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import OrgSelectForm from './OrgSelectForm';
import EditGiftForm from './EditGiftForm';
import Gifts from './Gifts';
import LoginAsUserForm from './LoginAsUserForm';
import { useQuery } from '@apollo/client';
import { Me, Role } from '../../__generated__/types';
import { ME } from '../../graphql/queries';
import Loading from '../../components/Loading/Loading';
import { useHistory } from 'react-router';
import { QueryParams as AmazonItemQueryParams } from 'views/SendGift/hooks/useAmazonItemParams';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as qs from 'query-string';
import ConnectedSignupForm from 'views/Signup/ConnectedSignupForm';

export default function RevSendAdmin() {
  const { data: { me = null } = {}, loading } = useQuery<Me>(ME);
  const history = useHistory();
  const tabs = ['Orgs', 'Create Gift', 'Create Org'];
  const [tab, setTab] = React.useState(tabs[0]);
  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
  };
  const [amazonUrl, setAmazonUrl] = React.useState('');
  const defaultAmazonItemValues: AmazonItemQueryParams = {
    productUrl: '',
    amazonProductId: '',
    quantity: '',
    name: '',
    price: '',
    isPrime: '',
    size: '',
    imgUrl: ''
  };
  const { handleSubmit, control } = useForm<AmazonItemQueryParams>({
    defaultValues: defaultAmazonItemValues
  });

  function onAmazonLinkSubmit(form: AmazonItemQueryParams) {
    const parsedForm: Record<string, string> = {};

    Object.entries(form).forEach(([fieldName, value]) => {
      if (value.length > 0) {
        parsedForm[fieldName] = value;
      }
    });
    const queryString = qs.stringify(parsedForm);
    setAmazonUrl(window.location.origin + '/send-gift/amazon?' + queryString);
  }

  if (loading || !me) {
    return <Loading />;
  }
  if (me.role !== Role.RS_ADMIN) {
    history.push('/dashboard');
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5">RevSend Admin</Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          {tabs.map((tab) => (
            <Tab key={tab} label={tab} value={tab} />
          ))}
        </Tabs>
        <Divider light />
        <br />
      </Grid>
      {tab === tabs[0] && (
        <>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Switch to Org</Typography>
            <Divider light />
            <br />
            <OrgSelectForm />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Login as User</Typography>
            <Divider light />
            <Typography>Doing this will cause you to lose access to admin</Typography>
            <br />
            <LoginAsUserForm />
          </Grid>
        </>
      )}
      {tab === tabs[1] && (
        <>
          <Grid item xs={12} md={6}>
            <Gifts />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Create One-Off Amazon Send</Typography>
            <form onSubmit={handleSubmit(onAmazonLinkSubmit)}>
              {Object.keys(defaultAmazonItemValues).map((fieldName) => (
                <Controller
                  control={control}
                  name={fieldName as keyof typeof defaultAmazonItemValues}
                  render={({ field }) => (
                    <TextField fullWidth label={fieldName} style={{ marginBottom: 6 }} {...field} />
                  )}
                />
              ))}
              <Button variant="contained" type="submit">
                Get Amazon URL
              </Button>
            </form>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{amazonUrl}</pre>
          </Grid>
        </>
      )}
      {tab === tabs[2] && (
        <>
          <Grid item xs={12}>
            <ConnectedSignupForm />
          </Grid>
        </>
      )}
    </Grid>
  );
}
