import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import { LOGOUT } from '../../../../../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';

export default function TopbarMenuIcon() {
  const [logout] = useMutation(LOGOUT);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const history = useHistory();

  function handleMenuClose() {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  }
  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <IconButton onClick={handleProfileMenuOpen} color="inherit">
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl || mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            logout();
          }}
        >
          <ExitIcon />
          {'  '}Logout
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            history.push('/settings');
          }}
        >
          <SettingsIcon />
          {'  '}Settings
        </MenuItem>
      </Menu>
    </>
  );
}
