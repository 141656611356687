import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  CardContent,
  Link,
  Tabs,
  Tab
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import csvToJson from 'csvtojson';
import * as React from 'react';
import FileDropzone from 'react-dropzone';
import Loading from '../../components/Loading/Loading';
import { CAMPAIGNS, GIFTS } from '../../graphql/queries';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

import {
  Campaigns,
  DeliveryType,
  Gifts,
  GiftsVariables,
  GiftVariables
} from '../../__generated__/types';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';

type CampaignRow = {
  recipient_name: string;
  recipient_email: string;
  recipient_organization: string;
  salesforce_account_id: string;
  salesforce_contact_id: string;
  hubspot_contact_id: string;
  hubspot_company_id: string;
};
type CampaignData = CampaignRow[];

const CampaignsPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = React.useState<'active' | 'inactive'>('active');

  // Queries
  const { data, loading, error } = useQuery<Campaigns>(CAMPAIGNS);
  if (loading) {
    return <Loading />;
  }
  if (!data?.campaigns || error) {
    return (
      <Typography>
        No gifts are currently enabled for Campaigns. Reach out to customer support to get access to
        this feature.
      </Typography>
    );
  }

  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'First name'
    },
    {
      field: 'expiresAt',
      flex: 1,
      headerName: 'Expires At'
    },
    {
      field: 'recipients',
      flex: 1,
      headerName: 'Total Recipients',
      type: 'number'
    },
    {
      field: 'id',
      flex: 1,
      headerName: 'Actions'
    }
  ];
  const inactiveCampiagns = data.campaigns.filter(
    (campaign) => campaign.expiresAt && moment(campaign.expiresAt).isBefore(moment())
  );
  const activeCampaigns = data.campaigns.filter(
    (campaign) => !campaign.expiresAt || moment(campaign.expiresAt).isAfter(moment())
  );

  const visibleCampaigns = tab === 'active' ? activeCampaigns : inactiveCampiagns;

  const rows = visibleCampaigns.map((campaign) => ({
    name: campaign.name,
    expiresAt: campaign.expiresAt ? moment(campaign.expiresAt).format('MM/DD/YYYY') : 'Never',
    id: campaign.id,
    recipients: campaign.recipients?.length ?? 0
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Campaigns
        </Typography>
        <Divider light />
        <br />
      </Grid>
      <Grid item xs={12}>
        <Button href="/campaigns/create" color="primary" variant="contained">
          Create New Campaign
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Tabs value={tab} onChange={(event, tab) => setTab(tab)}>
              <Tab key={'active'} label={'Active'} value={'active'} />
              <Tab key={'inactive'} label={'Inactive'} value={'inactive'} />
            </Tabs>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Shareable Link</TableCell>
                    <TableCell>Total Recipients</TableCell>
                    <TableCell>Expires At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography align="center">No campaigns to show</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows.map((campaign) => (
                      <TableRow key={campaign.id}>
                        <TableCell>{campaign.name}</TableCell>
                        <TableCell>
                          <CopyToClipboard
                            text={window.location.hostname + '/redeem/' + campaign.id}
                            onCopy={() =>
                              enqueueSnackbar('Shareable Link Copied!', { variant: 'success' })
                            }
                          >
                            <Button color="secondary" variant="outlined">
                              <CopyIcon />
                              &nbsp; Copy Link
                            </Button>
                          </CopyToClipboard>
                        </TableCell>
                        <TableCell>{campaign.recipients}</TableCell>
                        <TableCell>{campaign.expiresAt}</TableCell>
                        <TableCell>
                          <Button
                            href={`/campaigns/${campaign.id}`}
                            color="primary"
                            variant="contained"
                          >
                            Manage
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
export default CampaignsPage;
