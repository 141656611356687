import {
  SelectedHubSpotContact,
  RecipientAddressForm,
  RecipientForm,
  SelectedSalesForceContact,
  IsCreateHubSpotTaskChecked
} from '../../__generated__/types';
import {
  SELECTED_HUBSPOT_CONTACT,
  RECIPIENT_ADDRESS_FORM,
  RECIPIENT_FORM,
  SELECTED_SALESFORCE_CONTACT,
  IS_CREATE_HUBSPOT_TASK_CHECKED
} from '../../graphql/queries';
import { ApolloCache, MutationUpdaterFn } from '@apollo/client/core';

export function clearSendForms<TData>(cache: ApolloCache<TData>): void {
  cache.writeQuery<RecipientForm>({
    query: RECIPIENT_FORM,
    data: {
      recipientForm: null
    }
  });
  cache.writeQuery<RecipientAddressForm>({
    query: RECIPIENT_ADDRESS_FORM,
    data: {
      recipientAddressForm: null
    }
  });
  cache.writeQuery<SelectedSalesForceContact>({
    query: SELECTED_SALESFORCE_CONTACT,
    data: {
      selectedSalesForceContact: null
    }
  });
  cache.writeQuery<SelectedHubSpotContact>({
    query: SELECTED_HUBSPOT_CONTACT,
    data: {
      selectedHubSpotContact: null
    }
  });
  cache.writeQuery<IsCreateHubSpotTaskChecked>({
    query: IS_CREATE_HUBSPOT_TASK_CHECKED,
    data: {
      isCreateHubSpotTaskChecked: false
    }
  });
}
