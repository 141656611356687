import { useLazyQuery } from '@apollo/client/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import PrimaryTextField from 'components/TextField/PrimaryTextField';
import { HUBSPOT_CONTACTS } from 'graphql/queries';
import { useDebounce } from 'helpers/hooks';
import {
  HubSpotContacts,
  HubSpotContactsVariables,
  HubSpotContacts_hubSpotContacts
} from '__generated__/types';

type HubSpotContactSearchProps = {
  onSelect: (contact: {
    id: string;
    name: string;
    email: string;
    companyName: string;
    companyId: string;
  }) => void;
};
export default function HubSpotContactSearch(props: HubSpotContactSearchProps) {
  const { onSelect } = props;
  const [searchInput, setSearchInput] = React.useState('');
  const debouncedSearchInput = useDebounce(searchInput, 300);
  const [searchHubSpotContacts, { data: { hubSpotContacts = null } = {}, loading }] = useLazyQuery<
    HubSpotContacts,
    HubSpotContactsVariables
  >(HUBSPOT_CONTACTS, {
    fetchPolicy: 'network-only'
  });

  // Only perform search when debounced input changes
  React.useEffect(() => {
    if (debouncedSearchInput) {
      searchHubSpotContacts({
        variables: {
          input: {
            name: debouncedSearchInput
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  // Only show results if input is non-empty string and search results are non-null
  const options: HubSpotContacts_hubSpotContacts[] =
    hubSpotContacts && searchInput ? hubSpotContacts : [];

  return (
    <Autocomplete<HubSpotContacts_hubSpotContacts>
      filterOptions={(option) => option}
      id="hubSpot-contact-search"
      fullWidth
      options={options}
      loading={loading}
      onChange={(event, option) =>
        onSelect({
          id: option?.id ?? '',
          name: option?.name ?? '',
          email: option?.email ?? '',
          companyId: option?.company?.id ?? '',
          companyName: option?.company?.name ?? ''
        })
      }
      getOptionLabel={(option) => option.name ?? ''}
      renderInput={(params) => (
        <PrimaryTextField
          {...params}
          required
          label="Search HubSpot"
          value={searchInput}
          onChange={({ target: { value } }) => setSearchInput(value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="primary"
                    variant="indeterminate"
                    disableShrink
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      noOptionsText="No search results"
      renderOption={(contact) => (
        <ListItem key={contact.id}>
          <ListItemText primary={contact.name} secondary={contact.company?.name} />
        </ListItem>
      )}
    />
  );
}
