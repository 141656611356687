import { useLazyQuery, useMutation, useQuery } from '@apollo/client/react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import bwipjs from 'bwip-js';
import { useSnackbar } from 'notistack';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { CREATE_GIFT_CARD_BARCODE } from '../../graphql/mutations';
import { GIFT_CARD_BARCODE, GIFT_CARD_BRAND, GIFT_CARD_CODE } from '../../graphql/queries';
import { formatDollars } from '../../helpers/formatters';
import {
  CreateGiftCardBarcode,
  CreateGiftCardBarcodeVariables,
  GiftCardBarcode,
  GiftCardBarcodeVariables,
  GiftCardBrand,
  GiftCardBrandVariables,
  GiftCardCode,
  GiftCardCodeVariables
} from '../../__generated__/types';
import ExpiredMessage from '../RedeemEGift/ExpiredMessage';
import { isDatePast } from '../Settings/Billing/BillingHistory/utils';
import { isRaiseVisaRewards } from 'helpers/gift';

// Route: /gift-code/${giftCodeId}
const RaiseGiftCode: React.FC = () => {
  const { giftCodeId } = useParams<{ giftCodeId: string }>();
  const [
    fetchBrand,
    { data: { giftCardBrand = null } = {}, loading: brandLoading, error: brandError }
  ] = useLazyQuery<GiftCardBrand, GiftCardBrandVariables>(GIFT_CARD_BRAND);

  const [fetchBarcode, { data: { giftCardBarcode = null } = {}, loading: barcodeLoading }] =
    useLazyQuery<GiftCardBarcode, GiftCardBarcodeVariables>(GIFT_CARD_BARCODE, {
      fetchPolicy: 'network-only'
    });

  const [createBarcode, { loading: createBarcodeLoading, error: createBarcodeError }] = useMutation<
    CreateGiftCardBarcode,
    CreateGiftCardBarcodeVariables
  >(CREATE_GIFT_CARD_BARCODE);

  const { enqueueSnackbar } = useSnackbar();

  // Fetch code, then fetch the brand once we have the code
  const {
    data: { giftCardCode = null } = {},
    loading: giftCardCodeLoading,
    error: giftCardCodeError
  } = useQuery<GiftCardCode, GiftCardCodeVariables>(GIFT_CARD_CODE, {
    variables: {
      id: giftCodeId || ''
    },
    // Fetch the brand once the gift card code loads the brand id
    onCompleted: (data) => {
      const send = data.giftCardCode.send;
      // These should always be truthy
      if (send.placementId && giftCodeId) {
        // The send.placementId is the Raise brand UUID
        fetchBrand({ variables: { id: send.placementId } });
        fetchBarcode({ variables: { input: { giftCodeId, sendId: send.id } } });
      }
    }
  });

  function handleCreateBarcode() {
    createBarcode({ variables: { input: { codeId: giftCodeId } } }).then(() => {
      if (giftCardCode?.send) {
        fetchBarcode({ variables: { input: { giftCodeId, sendId: giftCardCode.send.id } } });
      }
    });
  }
  const giftCardAmount =
    giftCardCode?.send?.sendItems?.reduce((subtotal, sendItem) => sendItem.price + subtotal, 0) ??
    0;

  if (brandLoading || giftCardCodeLoading) {
    return <Loading />;
  }
  if (giftCardCodeError || brandError || createBarcodeError) {
    return (
      <Grid container style={{ padding: 24 }} justifyContent="center">
        <Grid item>
          <Typography>
            There has been an issue with this gift card. Contact support for help, and we'll get
            this resolved.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (!giftCardBrand || !giftCardCode || !giftCodeId) {
    return <></>;
  }

  // If it's too late!
  if (isDatePast(giftCardCode.expiresAt)) {
    return (
      <Grid container style={{ padding: 24 }} justifyContent="center">
        <Grid item>
          <ExpiredMessage expiresAt={giftCardCode?.expiresAt} />;
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container style={{ padding: 24 }} justifyContent="center">
      <Grid item style={{ maxWidth: 500 }}>
        <Grid container justifyContent="center" alignItems="stretch" direction="column" spacing={2}>
          <Grid item xs={12} style={{ maxWidth: 500 }}>
            <Typography variant="h2" component="h1" align="center" gutterBottom>
              Your Gift Card
            </Typography>
            <Divider light />
          </Grid>
          {giftCardBrand.faceplateUrl ? (
            <Grid item xs>
              <Card style={{ padding: `8px 24px 8px 24px` }}>
                <Typography align="center" variant="h3" component="h3" gutterBottom>
                  {giftCardBrand.name} ({formatDollars(giftCardAmount)})
                </Typography>
                <Grid container justifyContent="center">
                  <Grid item>
                    <img
                      src={giftCardBrand.faceplateUrl}
                      alt={giftCardBrand.name}
                      width={300}
                      style={{ margin: 'auto' }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h3" component="h3" align="center">
                  Instructions
                </Typography>
                <br />
                {giftCardBrand.redemptionMethods?.map((method, index) =>
                  method.length ? (
                    <>
                      <Typography key={index}>{method}</Typography>
                      <br />
                    </>
                  ) : null
                )}
                <Typography variant="caption" color="textSecondary">
                  {giftCardBrand.redemptionDisclaimer}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item style={{ marginBottom: 48 }}>
            {!giftCardBarcode ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleCreateBarcode}
                disabled={createBarcodeLoading || barcodeLoading}
              >
                Reveal Barcode
              </Button>
            ) : (
              <Card>
                <CardContent>
                  <Grid container spacing={4}>
                    {(!giftCardBarcode.number && giftCardBarcode.viewCardUrl) ||
                    isRaiseVisaRewards(giftCardCode.send.giftName) ? (
                      <Grid item>
                        <Typography gutterBottom>Barcode URL</Typography>
                        <a href={giftCardBarcode.viewCardUrl!}>{giftCardBarcode.viewCardUrl}</a>
                      </Grid>
                    ) : (
                      <>
                        {giftCardBarcode.number ? (
                          <Grid item>
                            <Typography gutterBottom>Card Number</Typography>
                            <Typography
                              style={{ minWidth: 200, backgroundColor: '#DDD', padding: 8 }}
                              component="span"
                            >
                              {giftCardBarcode.number}
                            </Typography>
                            <CopyToClipboard
                              text={giftCardBarcode.number}
                              onCopy={() => enqueueSnackbar('Copied', { variant: 'success' })}
                            >
                              <Button color={'primary'} size="small">
                                &nbsp;Copy
                              </Button>
                            </CopyToClipboard>
                          </Grid>
                        ) : null}
                        {giftCardBarcode.csc ? (
                          <Grid item>
                            <Typography gutterBottom>Card Security Code</Typography>
                            <Typography
                              style={{ minWidth: 200, backgroundColor: '#DDD', padding: 8 }}
                              component="span"
                            >
                              {giftCardBarcode.csc}
                            </Typography>
                            <CopyToClipboard
                              text={giftCardBarcode.csc}
                              onCopy={() => enqueueSnackbar('Copied', { variant: 'success' })}
                            >
                              <Button color={'primary'} size="small">
                                &nbsp;Copy
                              </Button>
                            </CopyToClipboard>
                          </Grid>
                        ) : null}
                      </>
                    )}
                  </Grid>
                  <br />
                  {giftCardBarcode.barcodeValue && giftCardBarcode.barcodeKind ? (
                    <Barcode
                      text={giftCardBarcode.barcodeValue}
                      bcid={getBcidFromRaiseBarcode(giftCardBarcode.barcodeKind)}
                    />
                  ) : null}
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item style={{ maxWidth: 500 }}>
            <Divider light />
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              Legal Terms
            </Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              {giftCardBrand.legalTerms}
            </Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              The ability to generate this barcode expires after 90 days.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

function getBcidFromRaiseBarcode(raiseBarcode: string): string {
  const map: Record<string, string> = {
    qr_code: 'qrcode'
  };
  return map[raiseBarcode] ?? raiseBarcode;
}

function Barcode(props: { bcid: string; text: string }) {
  const { bcid, text } = props;
  React.useEffect(() => {
    try {
      // The return value is the canvas element
      bwipjs.toCanvas('mycanvas', {
        bcid, // Barcode type
        text, // Text to encode
        includetext: true, // Show human-readable text
        textxalign: 'center' // Always good to set this
      });
    } catch (e) {
      // `e` may be a string or Error object
      console.error(e);
    }
  }, []);
  return <canvas id="mycanvas"></canvas>;
}
export default RaiseGiftCode;
