import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { isRaiseVisaRewards, VISA_TERMS, VISA_TERMS_URL, VISA_TERMS_URL_LABEL } from 'helpers/gift';
import React from 'react';
import { formatDollars } from '../../../helpers/formatters';
import { SendItems, Product } from '../hooks/useProductsForm';

type OrderSummaryProps = {
  fixedShippingCost?: number;
  sendItems: SendItems;
  isRaiseVisaRewards?: boolean;
  products: Array<Product & { featuredImageSrc: string | null; title: string }>;
};
export default function OrderSummary(props: OrderSummaryProps) {
  const { sendItems, products, fixedShippingCost, isRaiseVisaRewards } = props;
  const grandTotal =
    (fixedShippingCost ?? 0) +
    sendItems.reduce(
      (sum, sendItem) =>
        sum +
        (products
          ?.find((product) => product.id === sendItem.productId)
          ?.variants?.find((variant) => variant.id === sendItem.variantId)?.price ?? 0),
      0
    );
  return (
    <Card style={{ padding: 12 }}>
      <Typography color="textPrimary" gutterBottom>
        Order Summary
      </Typography>
      <List>
        {sendItems.map((sendItem) => {
          const product = products.find((product) => product.id === sendItem.productId);
          const selectedVariant = product?.variants.find(
            (variant) => variant.id === sendItem.variantId
          );
          if (!product || !selectedVariant)
            throw new Error(
              'Cannot find related product for sendItem with product id: ' + sendItem.productId
            );
          return (
            <ListItem disableGutters key={sendItem.productId}>
              <ListItemAvatar style={{ marginRight: 12 }}>
                <Box
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: 100,
                    justifyContent: 'center',
                    overflow: 'hidden',
                    width: 100
                  }}
                >
                  {product.featuredImageSrc && (
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      alt={product.title}
                      src={product.featuredImageSrc}
                    />
                  )}
                </Box>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    color="textPrimary"
                    style={{ fontWeight: 'bold' }}
                    variant="subtitle2"
                  >
                    {product.title}
                  </Typography>
                }
                secondary={
                  <Typography color="textSecondary" style={{ marginTop: 2 }} variant="body1">
                    {formatDollars(selectedVariant.price)}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
        {fixedShippingCost ? (
          <ListItem disableGutters>
            <ListItemText>Shipping</ListItemText>
            <ListItemSecondaryAction>
              <Typography>{formatDollars(fixedShippingCost)}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ) : null}

        <ListItem disableGutters>
          <ListItemText>Total</ListItemText>
          <ListItemSecondaryAction>
            <Typography>{formatDollars(grandTotal)}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {isRaiseVisaRewards ? (
        <>
          <br />
          <Typography variant="body2">{VISA_TERMS}</Typography>
          <Link href={VISA_TERMS_URL}>{VISA_TERMS_URL_LABEL}</Link>
        </>
      ) : null}
    </Card>
  );
}
