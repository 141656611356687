import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import dhlLogo from '../../assets/images/dhl-logo.png';
import stampsComLogo from '../../assets/images/stamps-com-logo.png';
import fedexLogo from '../../assets/images/fedex-logo.png';
import upsLogo from '../../assets/images/ups-logo.png';
import uspsLogo from '../../assets/images/usps-logo.png';
import { CarrierCode } from '../../__generated__/types';

export type CarrierSelectProps = {
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void;
  value: CarrierCode;
};

export default function CarrierSelect({ onChange, value }: CarrierSelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="easyDateRange">Carrier </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name: 'carrierCode',
          id: 'carrierCode'
        }}
      >
        {Object.values(CarrierCode).map((value) => (
          <MenuItem value={value}>
            <img src={CarrierCodeIcons[value]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export const CarrierCodeIcons = {
  [CarrierCode.USPS]: uspsLogo,
  [CarrierCode.STAMPS_COM]: stampsComLogo,
  [CarrierCode.DHL_EXPRESS]: dhlLogo,
  [CarrierCode.FEDEX]: fedexLogo,
  [CarrierCode.UPS]: upsLogo
};
