import React, { ReactChildren, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar } from './components';
import { useQuery } from '@apollo/react-hooks';
import { Me, OrgProfile } from '../../__generated__/types';
import { ME, ORG_PROFILE } from '../../graphql/queries';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%',
    padding: theme.spacing(4),
    margin: 'auto',
    maxWidth: 1200
  }
}));

const Main: React.FC<MainProps> = (props) => {
  const { children } = props;
  const classes = useStyles();
  const { data: { me = null } = {} } = useQuery<Me>(ME);
  const { data: { org = null } = {} } = useQuery<OrgProfile>(ORG_PROFILE);
  const theme = useTheme<Theme>();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        name={me?.name}
        orgName={org?.formattedName}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export type MainProps = {
  children?: React.ReactNode;
};

export default Main;
