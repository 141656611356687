import React, { Fragment, useState } from 'react';
import MaterialTable, { Action, MaterialTableProps } from 'material-table';

export default function FilterTable<RowData extends object>(props: MaterialTableProps<RowData>) {
  const [isFilterRowVisible, setFilterRowVisible] = useState<boolean>(false);

  const filterAction: Action<RowData> = {
    icon: 'filter_list',
    tooltip: 'Filtering',
    isFreeAction: true,
    onClick: (event) => setFilterRowVisible((prevState) => !prevState)
  };

  return (
    <Fragment>
      <MaterialTable
        {...props}
        actions={props.actions ? [...props.actions, filterAction] : [filterAction]}
        options={
          props.options
            ? { ...props.options, filtering: isFilterRowVisible }
            : { filtering: isFilterRowVisible }
        }
      />
    </Fragment>
  );
}
