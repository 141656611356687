/// <reference types="@types/chrome" />
import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useApolloClient } from '@apollo/react-hooks';
import AmazonButton from '../AmazonButton/AmazonButton';
import Loading from '../../../../../../../components/Loading/Loading';
import { isBrowserChrome, pingChromeExt } from '../../../../../../../chrome/utils';

export type AmazonGuideProps = {
  showOnlyButton?: boolean;
};

export default function AmazonGuide({ showOnlyButton }: AmazonGuideProps) {
  const [isChromeExtInstalled, setIsChromeExtInstalled] = React.useState<boolean | null>(null);
  React.useEffect(() => {
    checkExtensionInstalled();
  });

  async function checkExtensionInstalled() {
    const pingResult = await pingChromeExt();
    setIsChromeExtInstalled(pingResult);
  }

  if (isChromeExtInstalled === null) {
    return <Loading />;
  }

  if (!isBrowserChrome()) {
    return (
      <Typography>
        Looks like you're not using Chrome Desktop. In order to unlock the full power of Revsend,
        use Chrome Desktop and install the Revsend Chrome Extension.
      </Typography>
    );
  }

  if (!isChromeExtInstalled) {
    return (
      <Fragment>
        <Typography>Install the Revsend Chrome extension to send gifts through Amazon!</Typography>
        <br />
        <Button
          variant={'outlined'}
          color={'primary'}
          href={
            'https://chrome.google.com/webstore/detail/revsend-chrome-extension/meckpddaahabmamcjppooiffknlmdkpg'
          }
        >
          Visit Chrome Store
        </Button>
        <br />
        <br />
        <Typography>
          Note that if you are in Incognito mode, you will have to enable the RevSend extension to
          work in Incognito mode. Or, you can open this page in a normal browsing window.
        </Typography>
      </Fragment>
    );
  }
  return (
    <Fragment>
      {!showOnlyButton && (
        <Fragment>
          <Grid container spacing={2} direction="column" alignItems="stretch">
            <Grid item xs>
              <Typography align={'center'}>
                Chrome extension installed! Click the button to shop on Amazon, find your product,
                and click "Send with RevSend".
              </Typography>
            </Grid>
          </Grid>
          <br />
        </Fragment>
      )}
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item xs>
          <AmazonButton />
        </Grid>
        <br />
      </Grid>
    </Fragment>
  );
}
