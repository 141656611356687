import { useQuery } from '@apollo/react-hooks';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import StoreFrontIcon from '@material-ui/icons/Storefront';
import React, { Fragment } from 'react';
import Loading from '../../components/Loading/Loading';
import MenuItemCard from '../../components/MenuItemCard/MenuItemCard';
import PaletteIcon from '@material-ui/icons/Palette';
import { ME } from '../../graphql/queries';
import { Me } from '../../__generated__/types';

export default function Settings() {
  const { data: { me = null } = {}, loading } = useQuery<Me>(ME);
  const isAdmin = me?.team?.isAdmin;
  return (
    <Fragment>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Settings
          </Typography>
          <Divider light />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MenuItemCard
            title="General"
            icon={<SettingsIcon />}
            link="/settings/general"
            description="General account settings"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MenuItemCard
            title="Integrations"
            icon={<SettingsEthernetIcon />}
            link="/settings/integrations"
            description="Set up your connected apps and extensions."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MenuItemCard
            title="Notifications"
            icon={<NotificationsIcon />}
            link="/settings/notifications"
            description="Manage when you receive updates about your sends."
          />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h3" gutterBottom>
        Admin Settings
      </Typography>
      <Divider light />
      <br />
      {loading && <Loading />}
      {!loading && (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <MenuItemCard
              title="Billing"
              locked={!isAdmin}
              icon={<CreditCardIcon />}
              link="/settings/billing"
              description="Check upcoming and past bills"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MenuItemCard
              title="Branding"
              locked={!isAdmin}
              icon={<PaletteIcon />}
              link="/settings/branding"
              description="Customize logos, colors, and templates"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MenuItemCard
              title="Budgets"
              locked={!isAdmin}
              icon={<MoneyIcon />}
              link="/settings/budgets"
              description="Manage users monthly budgets"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MenuItemCard
              title="Organization"
              locked={!isAdmin}
              icon={<StoreFrontIcon />}
              link="/settings/org"
              description="Manage organization's settings"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MenuItemCard
              title="Teams"
              locked={!isAdmin}
              icon={<PeopleIcon />}
              link="/settings/teams"
              description="Group users and grant permissions"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MenuItemCard
              title="Users"
              locked={!isAdmin}
              icon={<PersonIcon />}
              link="/settings/users"
              description="Add and remove users"
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
