import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import StatusDot, { StatusDotProps } from '../StatusDot/StatusDot';
import { SendStatus } from '../../__generated__/types';
import capitalize from 'lodash/capitalize';
import { formatSendStatus } from '../../domains/send/formatters';

export type SendStatusComponentProps = {
  status: SendStatus;
  size?: StatusDotProps['size'];
  TypographyProps?: TypographyProps;
};

const SendStatusComponent: React.FC<SendStatusComponentProps> = (props) => {
  const { size, status, TypographyProps } = props;
  return (
    <Typography variant={'body2'} color={'textPrimary'} {...TypographyProps}>
      <StatusDot size={size ?? 'md'} color={getSendStatusColor(status)} />{' '}
      {formatSendStatus(status)}
    </Typography>
  );
};

const getSendStatusColor = (sendStatus: SendStatus): StatusDotProps['color'] => {
  switch (sendStatus) {
    case SendStatus.DELIVERED:
    case SendStatus.REDEEMED:
      return 'success';
    case SendStatus.PENDING_ADDRESS:
      return 'warning';
    case SendStatus.CANCELED:
      return 'grey';
    case SendStatus.NOT_DELIVERED:
      return 'error';
    default:
      return 'info';
  }
};

export default SendStatusComponent;
