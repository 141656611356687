import { useMutation, useQuery } from '@apollo/client';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Product } from 'shopify-buy';
import Button from '../../components/Button/Button';
import DangerButton from '../../components/DangerButton/DangerButton';
import GiftMenuCard from '../../components/GiftMenuCard/GiftMenuCard';
import Loading from '../../components/Loading/Loading';
import PrimarySelect from '../../components/PrimarySelect/PrimarySelect';
import PrimaryTextField from '../../components/TextField/PrimaryTextField';
import { GIFT_CREATE, GIFT_DELETE, GIFT_UPDATE } from '../../graphql/mutations';
import { ADMIN_GIFT, ALL_GIFTS, ALL_ORGS } from '../../graphql/queries';
import storefrontClient from '../../graphql/storefrontClient';
import {
  AdminGift,
  AdminGift_gift,
  AllOrgs,
  DeliveryType,
  GiftCreate,
  GiftCreateVariables,
  GiftDelete,
  GiftDeleteVariables,
  GiftInput,
  GiftUpdate,
  GiftUpdateVariables,
  GiftVariables,
  PlacementMethod
} from '../../__generated__/types';
import ProductSearch from './ProductSearch';

export type GiftsProps = {
  id?: string;
  onGiftDeleted: () => void;
};

const placementMethodOptions: Array<{
  value: PlacementMethod;
  label: string;
  placementIdLabel: string;
}> = [
  {
    value: PlacementMethod.RS_CS,
    label: 'RevSend manual order, drop-shipping',
    placementIdLabel: 'External URL for product'
  },
  {
    value: PlacementMethod.RS_SHOPIFY,
    label: 'Warehouse via Shopify',
    placementIdLabel: 'N/A'
  },
  {
    value: PlacementMethod.TREMENDOUS,
    label: 'Tremendous API',
    placementIdLabel: 'Tremendous Campaign ID'
  },
  {
    value: PlacementMethod.RAISE,
    label: 'Raise API',
    placementIdLabel: 'Raise Brand UUID'
  },
  {
    value: PlacementMethod.HANDWRYTTEN,
    label: 'Handrytten API',
    placementIdLabel: 'Handrytten Card ID'
  }
];

const getInitialValues = (gift?: AdminGift_gift | null): GiftInput => {
  const orgSettings =
    gift?.orgSettings?.map((orgSetting) => ({
      orgId: orgSetting.org.id,
      isEnabled: Boolean(orgSetting.isEnabled)
    })) ?? [];
  const items =
    gift?.items?.map((item) => ({
      productId: item.product.id,
      quantity: item.quantity
    })) ?? [];
  return {
    title: gift?.title ?? '',
    description: gift?.description ?? '',
    imageUrl: gift?.imageUrl ?? '',
    orgSettings,
    placementMethod: gift?.placementMethod ?? PlacementMethod.RS_CS,
    deliveryType: gift?.deliveryType ?? DeliveryType.PHYSICAL,
    placementId: gift?.placementId ?? '',
    fixedShippingCost: String(gift?.fixedShippingCost ?? ''),
    items
  };
};

const EditGiftForm: React.FC<GiftsProps> = (props) => {
  const { id, onGiftDeleted } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState<Array<Product>>([]);
  const { data: { allOrgs = [] } = {} } = useQuery<AllOrgs>(ALL_ORGS);
  const { data: { gift = null } = {}, loading: giftLoading } = useQuery<AdminGift, GiftVariables>(
    ADMIN_GIFT,
    {
      variables: { input: { id: id ?? '' } },
      skip: !id,
      fetchPolicy: 'network-only'
    }
  );
  const [createGift, { loading: createLoading }] = useMutation<GiftCreate, GiftCreateVariables>(
    GIFT_CREATE,
    { refetchQueries: [{ query: ALL_GIFTS }] }
  );
  const [updateGift, { loading: updateLoading }] = useMutation<GiftUpdate, GiftUpdateVariables>(
    GIFT_UPDATE,
    { refetchQueries: [{ query: ALL_GIFTS }] }
  );
  const [deleteGift, { loading: deleteLoading }] = useMutation<GiftDelete, GiftDeleteVariables>(
    GIFT_DELETE,
    { refetchQueries: [{ query: ALL_GIFTS }] }
  );

  const sortedOrgs = useMemo(
    () => [...allOrgs].sort((a, b) => a.formattedName.localeCompare(b.formattedName)),
    [allOrgs]
  );
  const initialValues = getInitialValues(gift);
  useEffect(() => {
    storefrontClient.product
      .fetchAll()
      .then((products) =>
        setProducts(products.map((product) => ({ ...product, id: atob(String(product.id)) })))
      );
  }, []);

  if (giftLoading) {
    return <Loading />;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, { setValues }) => {
        // Update if id
        if (id) {
          return updateGift({ variables: { input: { ...values }, id } })
            .then(() => {
              enqueueSnackbar('Updated gift!', { variant: 'success' });
            })
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
        }

        // Create gift
        return createGift({ variables: { input: { ...values } } })
          .then(() => {
            enqueueSnackbar('Created gift!', { variant: 'success' });
            setValues(initialValues);
          })
          .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
      }}
    >
      {({ submitForm, setFieldValue, values, handleChange, setValues }) => {
        const handleProductSelect = (product: Product): void => {
          setFieldValue('items', [{ productId: atob(String(product.id)), quantity: 1 }]);
          setFieldValue('title', product.title);
          setFieldValue('imageUrl', product.images[0]?.src ?? '');
          setFieldValue('description', product.description);
          setProducts((products) => [...products, product]);
        };
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card>
                  <CardHeader
                    title={(id ? 'Edit ' : 'Create ') + 'Gift'}
                    action={
                      id ? (
                        <DangerButton
                          onClick={() =>
                            deleteGift({ variables: { id } }).then(() => {
                              enqueueSnackbar('Deleted that gift', { variant: 'success' });
                              onGiftDeleted();
                            })
                          }
                          disabled={deleteLoading}
                        >
                          Delete Gift
                        </DangerButton>
                      ) : null
                    }
                  />
                  <Divider light />
                  <CardContent>
                    <form name={'createGift'}>
                      <ProductSearch onProductSelect={handleProductSelect} />
                      <List>
                        {values.items.map((item) => (
                          <ListItem key={item.productId}>
                            <ListItemIcon>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  setFieldValue(
                                    'items',
                                    values.items.filter(
                                      (itemToKeep) => itemToKeep.productId !== item.productId
                                    )
                                  )
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                products.find((product) => item.productId === product.id)?.title
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                      <PrimaryTextField
                        required
                        name={'title'}
                        id={'title'}
                        fullWidth
                        label={'Title'}
                        onChange={handleChange}
                        value={values.title}
                      />
                      <br />
                      <PrimaryTextField
                        required
                        name={'description'}
                        id={'description'}
                        fullWidth
                        label={'Description'}
                        onChange={handleChange}
                        value={values.description}
                      />
                      <br />
                      <PrimarySelect
                        formControlProps={{
                          fullWidth: true
                        }}
                        label={'Placement Method'}
                        onChange={({ target: { value } }) => {
                          // Set the field value
                          setFieldValue('placementMethod', value as PlacementMethod);
                          // Update the DeliveryType based on placement method
                          if (
                            value === PlacementMethod.TREMENDOUS ||
                            value === PlacementMethod.RAISE
                          ) {
                            setFieldValue('deliveryType', DeliveryType.DIGITAL);
                          } else {
                            setFieldValue('deliveryType', DeliveryType.PHYSICAL);
                          }
                        }}
                        value={values.placementMethod}
                        name={'placementMethod'}
                      >
                        {placementMethodOptions.map((option) => (
                          <MenuItem value={option.value}>{option.label}</MenuItem>
                        ))}
                      </PrimarySelect>
                      <br />

                      {values.placementMethod !== PlacementMethod.RS_SHOPIFY && (
                        <PrimaryTextField
                          required
                          fullWidth
                          id={'placementId'}
                          name={'placementId'}
                          label={
                            placementMethodOptions.find(
                              (option) => option.value === values.placementMethod
                            )?.placementIdLabel ?? ''
                          }
                          onChange={handleChange}
                          value={values.placementId}
                        />
                      )}
                      <br />
                      <PrimaryTextField
                        fullWidth
                        id={'fixedShippingCost'}
                        label={'Shipping Cost'}
                        name={'fixedShippingCost'}
                        onChange={handleChange}
                        value={values.fixedShippingCost}
                        required
                      />
                      <br />
                      <ListItem>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            onChange={({ target: { checked } }) =>
                              setFieldValue(
                                'orgSettings',
                                allOrgs.map((org) => ({ orgId: org.id, isEnabled: checked }))
                              )
                            }
                            checked={
                              values.orgSettings.filter((setting) => setting.isEnabled).length ===
                              allOrgs.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText id={'enableAll'} primary={'Enable for All'} />
                      </ListItem>
                      <List style={{ maxHeight: 300, overflow: 'scroll' }}>
                        {sortedOrgs.map((org) => (
                          <ListItem key={org.id}>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                onChange={({ target: { checked } }) => {
                                  // Find existing setting
                                  const existingValue = values.orgSettings.find(
                                    (setting) => setting.orgId === org.id
                                  );

                                  const newSettings = existingValue
                                    ? // Replace existing setting
                                      values.orgSettings.map((setting) =>
                                        setting.orgId === org.id
                                          ? { ...setting, isEnabled: checked }
                                          : setting
                                      )
                                    : // Or add the setting if it's not initialized
                                      values.orgSettings.concat([
                                        { orgId: org.id, isEnabled: checked }
                                      ]);
                                  setFieldValue('orgSettings', newSettings);
                                }}
                                checked={Boolean(
                                  values.orgSettings.find(
                                    (setting) => setting.orgId === org.id && setting.isEnabled
                                  )
                                )}
                              />
                            </ListItemIcon>
                            <ListItemText id={'enableAll'} primary={org.formattedName} />
                          </ListItem>
                        ))}
                      </List>
                      <Button
                        type={'button'}
                        onClick={submitForm}
                        fullWidth
                        loading={createLoading || updateLoading}
                        color={'primary'}
                        variant={'contained'}
                      >
                        Submit
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <br />
            <Grid container>
              <Grid item xs>
                <GiftMenuCard
                  id={''}
                  disabled
                  image={values.imageUrl ?? ''}
                  title={values.title}
                  description={values.description}
                />
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default EditGiftForm;
