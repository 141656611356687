import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { alpha, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import round from 'lodash/round';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type GiftMenuCardProps = {
  image: string;
  title: string;
  id: string;
  description?: string;
  disabled?: boolean;
  prices?: {
    min?: number;
    max?: number;
  };
};

const MenuCard = withStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        boxShadow: `${alpha(theme.palette.primary.main, 1)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main
      },
      height: '100%',
      maxWidth: 300
    }
  })
)(Card);

const GiftMenuCard: React.FC<GiftMenuCardProps> = ({
  description,
  prices,
  title,
  image,
  id,
  disabled
}) => {
  const history = useHistory();
  // Must have both min and max price defined in order to display a price,
  // even if those prices are identical.
  const displayPrice: string | null =
    (prices &&
      prices.min &&
      prices.max &&
      (prices.min === prices.max
        ? `$${round(prices.max, 2)}`
        : `$${[round(prices.min), round(prices.max)].join('-')}`)) ||
    null;
  return (
    <>
      <MenuCard>
        <CardActionArea
          style={{ height: '100%' }}
          onClick={() => (disabled ? null : history.push(`send-gift/${id}`))}
        >
          <CardContent>
            <Grid container justify={'center'} alignItems={'center'} style={{ height: 200 }}>
              <Grid item xs={12}>
                <CardMedia
                  component="img"
                  alt={title}
                  style={{ maxHeight: '200px' }}
                  image={image}
                  title={title}
                />
              </Grid>
            </Grid>
            <br />
            <Typography gutterBottom variant="body1">
              {title}{' '}
              {displayPrice && (
                <Chip style={{ marginBottom: 2 }} label={displayPrice} size={'small'} />
              )}
            </Typography>
            {description && (
              <Typography variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </MenuCard>
    </>
  );
};

export default GiftMenuCard;
