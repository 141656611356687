import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Theme, Toolbar } from '@material-ui/core';
import appBarLogo from '../../../../assets/images/rs-2-white.svg';
import { useHistory } from 'react-router-dom';
const Topbar = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar position="relative" color="primary" className={classes.appBar}>
      <Toolbar disableGutters>
        <img
          className={classes.icon}
          src={appBarLogo}
          alt="RevSend"
          onClick={() => history.push('/')}
        />
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    padding: theme.spacing(1),
    width: '170px',
    cursor: 'pointer'
  },
  appBar: {
    alignItems: 'center',
    boxShadow: 'none'
  }
}));

export default Topbar;
