type ActionButtonProps = {
  logoPrimaryColor: string;
  contrastTextColor: string;
  loading: boolean;
  actionText: string;
};

export default function ActionButton(props: ActionButtonProps) {
  return (
    <button
      style={{
        backgroundColor: props.logoPrimaryColor,
        textAlign: 'center',
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: props.contrastTextColor,
        textDecoration: 'none',
        borderRadius: '3px',
        padding: '15px 25px',
        display: 'block',
        textTransform: 'uppercase',
        border: 'none',
        width: '100%',
        cursor: 'pointer',
        opacity: props.loading ? 0.4 : 1
      }}
      disabled={props.loading}
      role="button"
    >
      {props.loading ? 'Loading...' : props.actionText}
    </button>
  );
}
