import { useLazyQuery } from '@apollo/client/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import PrimaryTextField from '../../../components/TextField/PrimaryTextField';
import { SALESFORCE_CONTACTS } from '../../../graphql/queries';
import { useDebounce } from '../../../helpers/hooks';
import {
  SalesForceContacts,
  SalesForceContactsVariables,
  SalesForceContacts_salesForceContacts
} from '../../../__generated__/types';

type SalesForceContactSearchProps = {
  onSelect: (contact: {
    id: string;
    name: string;
    email: string;
    accountName: string;
    accountId: string;
    street1: string;
    city: string;
    state: string;
    zip: string;
  }) => void;
};
export default function SalesForceContactSearch(props: SalesForceContactSearchProps) {
  const { onSelect } = props;
  const [searchInput, setSearchInput] = React.useState('');
  const debouncedSearchInput = useDebounce(searchInput, 300);
  const [searchSalesForceContacts, { data: { salesForceContacts = null } = {}, loading }] =
    useLazyQuery<SalesForceContacts, SalesForceContactsVariables>(SALESFORCE_CONTACTS, {
      fetchPolicy: 'network-only'
    });

  // Only perform search when debounced input changes
  React.useEffect(() => {
    if (debouncedSearchInput) {
      searchSalesForceContacts({
        variables: {
          input: {
            contactName: debouncedSearchInput
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  // Only show results if input is non-empty string and search results are non-null
  const options: SalesForceContacts_salesForceContacts[] =
    salesForceContacts && searchInput ? salesForceContacts : [];

  return (
    <Autocomplete<SalesForceContacts_salesForceContacts>
      filterOptions={(option) => option}
      id="salesforce-contact-search"
      fullWidth
      options={options}
      loading={loading}
      onChange={(event, option) =>
        onSelect({
          id: option?.id ?? '',
          name: option?.name ?? '',
          email: option?.email ?? '',
          accountName: option?.account?.name ?? '',
          accountId: option?.account?.id ?? '',
          street1: option?.mailingAddress?.street ?? '',
          city: option?.mailingAddress?.city ?? '',
          state: option?.mailingAddress?.state ?? '',
          zip: option?.mailingAddress?.postalCode ?? ''
        })
      }
      renderOption={(option) => (
        <ListItem button>
          <ListItemText
            primary={option.name}
            secondary={`${option.email} - ${option.account?.name ?? ''}`}
          />
        </ListItem>
      )}
      getOptionLabel={(option) => option.name ?? ''}
      renderInput={(params) => (
        <PrimaryTextField
          {...params}
          required
          label="Search SalesForce"
          value={searchInput}
          onChange={({ target: { value } }) => setSearchInput(value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="primary"
                    variant="indeterminate"
                    disableShrink
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      noOptionsText="No search results"
    />
  );
}
