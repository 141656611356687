import React from 'react';
import { Formik, FormikProps } from 'formik';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EasyDateRange, {
  EasyDateRangeOption
} from '../../../../components/EasyDateRange/EasyDateRange';

export type EasyDateRangeProps = {
  onSubmit: (form: UserAnalyticsFormVars) => void;
  initialValues?: UserAnalyticsFormVars;
};

export type UserAnalyticsFormVars = {
  easyDateRange: EasyDateRangeOption;
};

export default function UserAnalyticsForm({ onSubmit, initialValues }: EasyDateRangeProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValues || {
          easyDateRange: EasyDateRangeOption.MONTH_TO_DATE
        }
      }
      onSubmit={(values) => onSubmit({ ...values })}
    >
      {({ handleSubmit, values, handleChange }: FormikProps<UserAnalyticsFormVars>) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <EasyDateRange onChange={handleChange} value={values.easyDateRange} />
                </Grid>
                <Grid item xs={12} sm>
                  <Button variant="contained" color="primary" type="submit">
                    Update Data
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}
