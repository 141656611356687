import React, { Fragment, useEffect, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useMutation } from '@apollo/react-hooks';
import { FieldArray, Formik, FormikProps } from 'formik';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  AssignUserToTeam,
  AssignUserToTeamVariables,
  OrgTeams_org_teams,
  OrgTeams_org_teams_invitations,
  OrgTeams_org_teams_users,
  UpdateInvitation,
  UpdateInvitationVariables
} from '../../../../../__generated__/types';
import { teamQueries } from '../../../../../graphql/queries';
import { ASSIGN_USER_TO_TEAM, UPDATE_INVITATION } from '../../../../../graphql/mutations';
import FormAlert from '../../../../../components/FormAlert/FormAlert';

type MoveUserDialogProps = {
  onSuccess: () => void;
  teams: OrgTeams_org_teams[] | null;
  currentTeamId: string | null;
  user: OrgTeams_org_teams_users | null;
  invitation: OrgTeams_org_teams_invitations | null;
} & DialogProps;

export default function MoveUserDialog({
  teams,
  currentTeamId,
  user,
  onSuccess,
  open,
  onClose,
  invitation
}: MoveUserDialogProps) {
  const [showError, setShowError] = useState<boolean>(false);

  const [assignUserToTeam, { error, loading }] = useMutation<
    AssignUserToTeam,
    AssignUserToTeamVariables
  >(ASSIGN_USER_TO_TEAM, {
    refetchQueries: teamQueries,
    onCompleted: () => onSuccess(),
    onError: () => setShowError(true)
  });

  const [updateInvitation, { error: invitationError, loading: invitationLoading }] = useMutation<
    UpdateInvitation,
    UpdateInvitationVariables
  >(UPDATE_INVITATION, {
    refetchQueries: teamQueries,
    onCompleted: () => onSuccess(),
    onError: () => setShowError(true)
  });

  useEffect(() => {
    if (!open) {
      setShowError(false);
    }
  }, [open, showError]);

  if (!teams || !(user || invitation) || !currentTeamId) {
    return <Fragment />;
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        {showError && <FormAlert error={error} />}
        <Formik
          initialValues={{
            teamId: currentTeamId,
            userId: (user && user.id) || '',
            invitationId: (invitation && invitation.id) || ''
          }}
          onSubmit={(values) => {
            if (values.invitationId) {
              updateInvitation({
                variables: { input: { id: values.invitationId, teamId: values.teamId } }
              });
              return;
            }
            assignUserToTeam({
              variables: { input: { userId: values.userId, teamId: values.teamId } }
            });
          }}
          validationSchema={Yup.object().shape({
            teamId: Yup.string().required()
          })}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleBlur,
            touched,
            errors
          }: FormikProps<{ teamId: string; userId: string }>) => (
            <form onSubmit={handleSubmit}>
              {user && (
                <Typography variant={'h5'} gutterBottom>
                  Move {user.name}
                </Typography>
              )}
              {invitation && (
                <Typography variant={'h5'} gutterBottom>
                  Move invite to {invitation.email}
                </Typography>
              )}
              <FormControl fullWidth>
                <InputLabel htmlFor="teamId">Team</InputLabel>
                <Select
                  value={values.teamId}
                  onChange={handleChange}
                  inputProps={{
                    name: 'teamId',
                    id: 'teamId'
                  }}
                >
                  {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              {user && (
                <Alert severity={'warning'}>
                  Moving this user will expire their session and require them to login again. Moving
                  yourself will log you out immediately.
                </Alert>
              )}
              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading || invitationLoading ? 'Loading...' : 'Move'}
              </Button>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
