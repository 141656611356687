import React, { useState } from 'react';
import RequestNewGiftDialog from './RequestNewGiftDialog';
import Link from '@material-ui/core/Link';
export type RequestNewGiftDialogButtonProps = {
  text: string;
};
const RequestNewGiftDialogButton: React.FC<RequestNewGiftDialogButtonProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Link href={'#'} onClick={() => setIsOpen(true)}>
        {props.text}
      </Link>
      <RequestNewGiftDialog
        open={isOpen}
        onSuccess={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default RequestNewGiftDialogButton;
