import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function Signup() {
  const classes = useStyles();

  return (
    <Container className={classes.authFormContainer} component="main" maxWidth="xs">
      <br />
      <Paper className={classes.authPaper} elevation={15}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography align="center">
              Sign Up with RevSend by contacting us{' '}
              <Link href="https://www.revsend.com/index.html#contact">Here</Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Link href="/reset-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/login" variant="body2">
              {'Have an account? Login'}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  authFormContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6)
  },
  authPaper: {
    padding: theme.spacing(2)
  }
}));
